import styled from 'styled-components/macro';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const NoResultSpan = styled.span`
  text-align: left;
  font-size: 14px;
`;

type SortingProps = {
  isSorted: boolean;
  isSortedDesc: boolean;
};

export const Sorting = styled.div<SortingProps>`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  text-align: center;

  i:first-of-type {
    margin-bottom: -20px;
    color: ${({ isSorted, isSortedDesc }) =>
      isSorted && !isSortedDesc ? 'grey' : 'lightgrey'};
  }

  i:last-of-type {
    color: ${({ isSorted, isSortedDesc }) =>
      isSorted && isSortedDesc ? 'grey' : 'lightgrey'};
  }
`;

export const TableStyling = styled.div`
  width: 100%;
  margin-bottom: 56px;
  box-sizing: border-box;
  table {
    border-spacing: 0;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid grey;
      text-align: left;

      :first-child {
        font-weight: 600;
        font-size: 18px;
        width: 20%;
      }

      :nth-last-child(2) {
        width: 45%;
      }
    }
  }
`;

export const ResultToggleIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
`;

export const Result = styled.div`
  background: white;
  position: relative;
  box-shadow: 0px 2px 3px 0px #00000014;
  padding: 15px;
  height: 178px;
  border: 1px solid transparent;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    border-color: var(--cogs-greyscale-grey3);
  }

  p {
    margin: 0;
  }

  &.is-open {
    height: auto;
    border: 1px solid var(--cogs-greyscale-grey3);

    ${ResultToggleIcon} {
      background: var(--cogs-greyscale-grey1);
    }
  }

  .result-description {
    color: var(--cogs-greyscale-grey6);
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const ResultOpenBody = styled.div`
  background: var(--cogs-greyscale-grey1);
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  margin-top: 5px;

  p + p {
    margin-top: 10px;
  }
`;

export const ResultActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: end;
  margin-top: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 0px;
  width: 0;
}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 65px;
`;

export const ResultHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-right: 35px;
  align-items: center;
  margin-bottom: 5px;

  .result-title-wrapper {
    max-width: calc(100% - 35px);
  }

  h2 {
    color: var(--cogs-midblue-2);
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 17px;
  }
`;
