import React, { useState } from 'react';
import { Button, Flex } from '@cognite/cogs.js';
import Modal from 'shared/Modal';
import { useResolvedProject, useResolvedWorkSteps } from 'hooks/useResolved';

type Props = {
  submitExecutionHandler: Function;
  rejectCompilationHandler: Function;
  workStepsViewed: boolean;
  hasDraftSteps: boolean;
  viewOnly: boolean;
};

const ApproveRejectProcedure = ({
  submitExecutionHandler,
  rejectCompilationHandler,
  workStepsViewed,
  hasDraftSteps,
  viewOnly,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalWillReject, setmodalWillReject] = useState(true);

  const handleOnApprove = () => {
    setmodalWillReject(false);
    setModalOpen(true);
    setModalMessage(
      "You won't be able to change the procedure after submission."
    );
    setModalTitle('Approve procedure for execution');
  };

  const handleOnReject = () => {
    setmodalWillReject(true);
    setModalOpen(true);
    setModalMessage("You won't be able to add more feedback after submission.");
    setModalTitle('Reject procedure and return to compiler');
  };

  const onOkHandler = () => {
    if (modalWillReject) {
      rejectCompilationHandler();
      setModalOpen(false);
    } else {
      submitExecutionHandler();
      setModalOpen(false);
    }
  };

  const renderActionButtons = () => {
    if (!viewOnly) {
      return (
        <>
          <Button
            type="primary"
            style={{
              width: 'max-content',
              marginRight: '10px',
              background: workStepsViewed ? '#FBE9ED' : undefined,
              color: workStepsViewed ? '#B30539' : undefined,
            }}
            disabled={!workStepsViewed}
            data-testid="reject-procedure-button"
            onClick={handleOnReject}
          >
            Reject procedure and return to compiler
          </Button>
          <Button
            type="primary"
            style={{ width: 'max-content' }}
            disabled={
              !workStepsViewed ||
              !isWorkStepsResolved ||
              !isProjectResolved ||
              hasDraftSteps
            }
            data-testid="approve-procedure-button"
            onClick={handleOnApprove}
          >
            Approve procedure for execution
          </Button>
        </>
      );
    }
    return null;
  };

  const isProjectResolved = useResolvedProject();
  const isWorkStepsResolved = useResolvedWorkSteps();

  return (
    <>
      <Modal
        visible={modalOpen}
        setVisible={setModalOpen}
        title={modalTitle}
        okText="Submit"
        okHandler={onOkHandler}
      >
        <div data-testid="approve-reject-confirmation-text">{modalMessage}</div>
      </Modal>
      <div style={{ textAlign: 'right' }}>
        <Flex direction="row" style={{ marginBottom: '8px' }}>
          {renderActionButtons()}
        </Flex>
        {hasDraftSteps && (
          <Flex direction="row-reverse">
            <div
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#B30539',
                width: '270px',
              }}
              data-testid="approve-reject-draft-steps-warning-dashboard"
            >
              There are work steps in draft mode which need to be handled before
              you can submit
            </div>
          </Flex>
        )}
        {!workStepsViewed && (
          <Flex direction="row-reverse">
            <div
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#B30539',
                width: '270px',
              }}
              data-testid="approve-reject-warning-dashboard"
            >
              You must resolve any issues marked in red before proceeding
            </div>
          </Flex>
        )}
      </div>
    </>
  );
};

export default ApproveRejectProcedure;
