import dayjs from 'dayjs';
import { logger } from './logger';

type DateFormat = 'DD.MM.YYYY' | 'h:mm a' | 'D.M.YYYY hh:mm a';

export const toDisplayDate = (
  timestamp: string | number | undefined,
  format: DateFormat
) => {
  if (!timestamp) {
    logger(`Invalid timestamp recevied in date utils ${timestamp}`);
    return '';
  }
  return dayjs(+timestamp.toString()).format(format);
};
