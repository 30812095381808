import * as Sentry from '@sentry/browser';

function shouldLog() {
  return (
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
  );
}

/* eslint-disable no-console */
export const logger = (
  error: string | unknown,
  title: string = 'An error occured',
  level: 'warning' | 'error' = 'error'
) => {
  if (shouldLog()) {
    if (level === 'warning') {
      Sentry.captureException(error, { level: Sentry.Severity.Warning });
    } else {
      Sentry.captureException(error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(title);
    console.error(error);
  }
};

// Please do not delete it is usefull for debugging proxy obj
export const createLogFunc = () => {
  if (
    process.env.REACT_APP_ENV === 'mock' ||
    process.env.REACT_APP_ENV === 'testcafe' ||
    process.env.REACT_APP_ENV === 'development'
  ) {
    // eslint-disable
    (window as any).logObj = (data: any) => {
      return JSON.parse(JSON.stringify(data));
    };
  }
};
