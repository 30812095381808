import React, { useState } from 'react';

import { Project } from 'types';
import { Button, Icon, Flex } from '@cognite/cogs.js';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import {
  Result,
  ResultOpenBody,
  ResultActions,
  ResultToggleIcon,
  ResultHeader,
} from './elements';
import { useProjectLoad } from '../../hooks/useProjectLoad';

export type SearchResultProps = {
  procedure: Project;
};

enum readableStatus {
  compilation = 'In planning',
  reviewReady = 'Ready for review',
  review = 'In review',
  executionReady = 'Ready for execution',
  execution = 'In execution',
  done = 'Done',
  deleted = 'Deleted',
}

export const SearchResult = ({ procedure }: SearchResultProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { copyCreateNewProcedure } = useProjectLoad();
  const history = useHistory();

  const pnidsList = procedure.pnIds?.length
    ? procedure.pnIds
        .reduce((arr, singlePnID) => {
          return `${arr}${singlePnID.id}, `;
        }, '')
        .slice(0, -2)
    : 'No P&Ids Attached';

  const pnidsTitles = procedure.pnidsTitles?.length
    ? procedure.pnidsTitles.join('\n')
    : 'No P&Ids Attached';

  return (
    <Result
      onClick={() => setIsOpen(!isOpen)}
      className={isOpen ? 'is-open' : ''}
      data-testid={`result-of-${procedure.id}`}
    >
      <ResultToggleIcon>
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Icon type={isOpen ? 'ChevronUp' : 'ChevronDown'} size={21} />
        </Flex>
      </ResultToggleIcon>
      <ResultHeader>
        <div className="result-title-wrapper">
          <h2 data-testid="name">{procedure.name}</h2>
        </div>
        {procedure.isApprovedProcedure && (
          <Icon
            data-testid="approval-file-icon"
            type="CheckmarkFilled"
            style={{ color: 'var(--cogs-green-3)' }}
            size={21}
          />
        )}
      </ResultHeader>
      <p>IC number: {procedure.certificateNumber || '/'}</p>
      <p data-testid="result-compiler">Compiler: {procedure.compiler}</p>
      <p>Creation date: {dayjs(procedure.created).format('DD.MM.YYYY')}</p>
      <p>
        Execution date:{' '}
        {procedure.executionStarted
          ? dayjs(procedure.executionStarted).format('DD.MM.YYYY')
          : '/'}
      </p>
      {isOpen ? (
        <ResultOpenBody>
          <p>
            <b>Status</b>
            <br />
            {procedure.status ? readableStatus[procedure.status] : ''}
          </p>
          <p>
            <b>P&IDs</b>
            <br />
            {procedure.pnidsTitles && procedure.pnidsTitles?.length > 0 ? (
              <div
                data-testid={`view-of-${procedure.id}-pnid-titles`}
                style={{ whiteSpace: 'pre-line' }}
              >
                {pnidsTitles}
              </div>
            ) : (
              <div data-testid={`view-of-${procedure.id}-pnid-list`}>
                {pnidsList}
              </div>
            )}
          </p>
          {procedure.objectives && (
            <p>
              <b>Objectives</b>
              <br />
              {procedure.objectives}
            </p>
          )}
          {procedure.method && (
            <p>
              <b>Method</b>
              <br />
              {procedure.method}
            </p>
          )}
          <ResultActions>
            <Button
              type="tertiary"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/project/${procedure.id}`);
              }}
              data-testid={`go-to-dashboard-${procedure.id}`}
            >
              Edit procedure
            </Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                copyCreateNewProcedure(procedure);
              }}
              data-testid={`use-as-basis-${procedure.id}`}
            >
              Use as basis for new procedure
            </Button>
          </ResultActions>
        </ResultOpenBody>
      ) : (
        <p className="result-description">{procedure.objectives}</p>
      )}
    </Result>
  );
};

export default SearchResult;
