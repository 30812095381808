import { Body } from '@cognite/cogs.js';
import React from 'react';
import { WarningIcon } from 'shared/Icons/Warning';
import { WarningBox } from './elements';

export interface WarningAreaProps {
  message: string;
  stepId: string;
}
export const WarningArea = ({ message, stepId }: WarningAreaProps) => {
  return (
    <WarningBox>
      <WarningIcon />{' '}
      <Body
        data-testid={`warning-area-${stepId}`}
        level={2}
        style={{ marginLeft: '5px' }}
      >
        {message}
      </Body>
    </WarningBox>
  );
};
