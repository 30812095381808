import { COMMENT_DATASET_ID, COMMENT_TYPE } from 'config/apiConfig';
import { modelFactory } from 'factories';
import { getClient } from '../config/cognitesdk';

export class ProcedureReviewCommentsService {
  async fetchReviewComments(procedureId: string, client = getClient()) {
    const rawComments = await client.events
      .list({
        filter: {
          dataSetIds: [{ externalId: COMMENT_DATASET_ID }],
          type: COMMENT_TYPE,
          subtype: procedureId,
        },
      })
      .autoPagingToArray({ limit: -1 });

    return rawComments.map((rawComment) =>
      modelFactory.createComment(rawComment)
    );
  }
}
