import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import config from 'config/config';
import { Metrics } from '@cognite/metrics';
import { createLogFunc } from 'utils/logger';
import App from './App';
import * as serviceWorker from './serviceWorker';

import '@cognite/cogs.js/dist/cogs.css';

createLogFunc();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // This is populated by the FAS build process. Change it if you want to
    // source this information from somewhere else.
    release: process.env.REACT_APP_RELEASE_ID,
    // This is populated by react-scripts. However, this can be overridden by
    // the app's build process if you wish.
    environment: config.env,
    debug: false,
    ignoreErrors: ['ResizeObserver loop'],
    beforeSend: (event) => {
      if (
        event.level &&
        event.level === Sentry.Severity.Error &&
        event.message &&
        event.message.match(/The API Failed to process some items/i)
      ) {
        // eslint-disable-next-line no-param-reassign
        event.level = Sentry.Severity.Warning;
      }
      return event;
    },
  });
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  Metrics.init({
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true',
    environment: config.env,
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
