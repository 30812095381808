import styled from 'styled-components/macro';
import zIndex from '../../../../utils/zindex';

export interface StyledDrawerProps {
  open: boolean;
  placement?: 'left' | 'right';
}

export const StyledDrawer = styled.div.attrs(
  ({ open, placement = 'left' }: StyledDrawerProps) => {
    const style = {
      transition: 'all ease-out 0.3s',
    } as any;
    if (placement === 'left') {
      style.boxShadow = 'var(--cogs-z-8)';
      style.left = -3;
      if (!open) {
        style.marginLeft = `-450px`;
      }
    }
    if (placement === 'right') {
      style.boxShadow = 'var(--cogs-z-8)';
      style.right = -3;
      if (!open) {
        style.marginRight = `-450px`;
      }
    }
    return { style };
  }
)`
  position: absolute;
  height: 100%;
  width: 450px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: var(--cogs-greyscale-grey2);
  z-index: ${zIndex.MAXIMUM};
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  background-color: var(--cogs-greyscale-grey2);
  z-index: ${zIndex.MAXIMUM};
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1em;
  span {
    margin: 0 10px;
  }
`;

export const Dummy = styled.div`
  width: 1px;
  height: 1px;
  opacity: 0;
`;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
`;

export const DrawerNob = styled.div.attrs(
  ({ placement }: StyledDrawerProps) => {
    const style = {} as any;
    if (placement === 'right') {
      style.left = '-28px';
    } else if (placement === 'left') {
      style.right = '-28px';
    }
    return {
      style,
    };
  }
)`
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 50%;
  border-radius: 8%;
  z-index: ${zIndex.POPUP};
  height: 50px;
  width: 30px;
  background-color: var(--cogs-greyscale-grey2);
  color: var(--cogs-greyscale-grey6);
  box-shadow: -2px 2px 2px 1px rgb(0 0 0 / 4%), -5px 4px 8px rgb(0 0 0 / 6%);
  &:hover {
    cursor: pointer;
  }
`;
