import { Button } from '@cognite/cogs.js';
import React from 'react';
import styled from 'styled-components/macro';

const StyledLink = styled(Button)`
  text-decoration: none;
  height: inherit !important;
  font-size: var(--cogs-b3-font-size) !important;
  font-weight: normal !important;
  &:hover {
    background: none !important;
  }
`;

export const LinkButton = ({
  onClick,
  children,
  'aria-hidden': _ariaHidden,
  ...rest
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledLink type="ghost" onClick={onClick} {...rest}>
      {children}
    </StyledLink>
  );
};
