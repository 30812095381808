import styled from 'styled-components/macro';

export const Page = styled.div`
  box-sizing: border-box;
  background-color: #ffffff;
  min-height: calc(100vh - 57px);
  padding: 24px;
`;

export const Contents = styled.div`
  background-color: #f6f7ff;
  box-sizing: border-box;
  padding: 16px;
  height: 100%;
  min-height: calc(100vh - 57px - 48px);

  @media (min-width: 2006px) {
    width: 1960px;
    margin: 0 auto;
  }
`;

export const FlexArea = styled.div`
  display: flex;
  height: 100%;
  margin-top: 8px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;

  .singleColumn {
    display: none;
  }
  .twoColumns {
    display: none;
  }

  @media (max-width: 1448px) {
    .twoColumns {
      display: block;
    }
  }

  @media (max-width: 984px) {
    .singleColumn {
      display: block;
    }
  }

  .c1 {
    width: 720px;
    flex-shrink: 1;
    min-width: 440px;

    @media (max-width: 1448px) {
      width: 50%;
      flex-grow: 1;
    }
  }

  .c2 {
    min-width: 440px;
    max-width: 720px;
    flex-grow: 1;
    margin: 0px 24px;

    @media (max-width: 1448px) {
      margin: 0px 0px 0px 24px;
      width: 50%;
    }

    @media (max-width: 984px) {
      display: none;
    }
  }

  .c3 {
    width: 440px;
    min-width: 440px;

    @media (max-width: 1448px) {
      display: none;
    }
  }
`;

export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LogoArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 11px;
`;

export const Title = styled.div`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--cogs-text-color);
`;

export const TextDetail = styled.div`
  line-height: 20px;
  color: var(--cogs-text-color);
  word-break: break-all;
`;

export const TextDetailSmall = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--cogs-text-color);
  word-break: break-all;
`;

export const TextDetailExtraSmall = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #000000;
`;

export const HorizontalLine = styled.hr`
  height: 0.5px;
  margin: 0px;
  max-width: 410px;
  width: 90%;
  background: var(--cogs-greyscale-grey6);
`;

export const DescriptionArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ProjectReadOnlyNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 8px 10px;
  border-radius: 6px;
  background: var(--cogs-midblue-6);
  color: var(--cogs-greyscale-grey8);
  font-size: 11px;
`;
