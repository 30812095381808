/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'redux/types';
import { assetsResourceService, projectsResourceService } from 'resources';
import { AssetMappingType, Project } from 'types';
import { DEFAULT_SITE, DEFAULT_SITE_NAME } from 'config/apiConfig';
import { withToastForError, withToastForErrorWithArgs } from 'redux/utils';

export type DataForIsolationsFetching = {
  assetMapping: Array<AssetMappingType>;
};

export const fetchRootAssetsContent = createAsyncThunk(
  'search/setRootAssetsContent',
  withToastForError(() => {
    return assetsResourceService.fetchRootAssets();
  })
);

export const fetchAllProcedures = createAsyncThunk(
  'search/AllProcedures',
  withToastForErrorWithArgs((selectedRootAsset) => {
    return projectsResourceService.fetchAllProcedures(
      selectedRootAsset as string,
      1000
    );
  })
);

export const fetchIsolationProcedures = async (
  dataForIsolationsFetching: DataForIsolationsFetching,
  selectedRootAsset: string
) => {
  const { assetMapping } = dataForIsolationsFetching;

  const assetIdsList = assetMapping.map((asset) => {
    return asset.assetId;
  });

  const isolationProcedures = await projectsResourceService.fetchProjects(
    selectedRootAsset,
    {
      assetIds: assetIdsList,
    },
    1000
  );

  return isolationProcedures.map((procedure) => {
    return { ...procedure } as Project;
  });
};

export const fetchProceduresForRootAsset = async (
  selectedRootAsset: string
) => {
  const allProcedures = await projectsResourceService.fetchAllProcedures(
    selectedRootAsset,
    1000
  );

  return allProcedures.map((procedure) => {
    return { ...procedure } as Project;
  });
};

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    rootAssetsContent: [] as AssetMappingType[],
    selectedRootAsset: DEFAULT_SITE as string,
    selectedRootAssetName: DEFAULT_SITE_NAME as string,
    statusRootAssets: Status.idle,
    errorRootAssetsFetch: undefined as string | undefined,
    assets: [] as AssetMappingType[],
    procedures: [] as Project[],
    flocInput: undefined as string | undefined,
    errorAssetsSearch: undefined as string | undefined,
    errorIsolationSearch: undefined as string | undefined,
    statusAssetSearch: Status.idle,
    statusIsolationSearch: Status.idle,
    allProcedures: [] as Project[],
    statusAllProcedures: Status.idle,
    errorAllProcedures: undefined as string | undefined,
  },
  reducers: {
    setFlocInput: (state, action: PayloadAction<string | undefined>) => {
      state.flocInput = action.payload;
    },
    setSelectedRootAsset: (state, action: PayloadAction<AssetMappingType>) => {
      state.selectedRootAsset = action.payload.assetFloc;
      state.selectedRootAssetName = action.payload.assetName;
    },
    setClearSearch: (state) => {
      state.flocInput = '';
      state.procedures = [];
      state.assets = [];
      state.statusIsolationSearch = Status.idle;
      state.statusAssetSearch = Status.idle;
    },
    startAssetSearch: (state) => {
      state.statusAssetSearch = Status.loading;
      state.statusIsolationSearch = Status.idle;
    },
    startProcedureSearch: (state) => {
      state.statusIsolationSearch = Status.loading;
    },
    searchAssetsSuccess: (state, action: PayloadAction<AssetMappingType[]>) => {
      state.assets = action.payload;
      state.statusAssetSearch = Status.success;
    },
    searchProceduresSuccess: (state, action: PayloadAction<Project[]>) => {
      state.procedures = action.payload;
      state.statusIsolationSearch = Status.success;
    },
    failedAssetsSearch: (state, action: PayloadAction<string>) => {
      state.errorAssetsSearch = action.payload;
      state.statusAssetSearch = Status.failed;
    },
    failedIsolationsSearch: (state, action: PayloadAction<string>) => {
      state.errorIsolationSearch = action.payload;
      state.statusIsolationSearch = Status.failed;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRootAssetsContent.pending, (state, _action) => {
      state.statusRootAssets = Status.loading;
    });
    builder.addCase(fetchRootAssetsContent.fulfilled, (state, action) => {
      state.rootAssetsContent = action.payload;
      state.statusRootAssets = Status.success;
    });
    builder.addCase(fetchRootAssetsContent.rejected, (state, action) => {
      state.errorRootAssetsFetch = action.error.message;
      state.statusRootAssets = Status.failed;
    });

    builder.addCase(fetchAllProcedures.pending, (state, _action) => {
      state.statusAllProcedures = Status.loading;
    });
    builder.addCase(fetchAllProcedures.fulfilled, (state, action) => {
      state.allProcedures = action.payload;
      state.statusAllProcedures = Status.success;
    });
    builder.addCase(fetchAllProcedures.rejected, (state, action) => {
      state.errorAllProcedures = action.error.message;
      state.statusAllProcedures = Status.failed;
    });
  },
});

export type SearchState = ReturnType<typeof searchSlice.reducer>;
export const { actions } = searchSlice;
export default searchSlice;
