import React from 'react';

import ReactToPrint from 'react-to-print';
import { Button } from '@cognite/cogs.js';

export type PrintButtonProps = {
  label: string;
  contentRef: any;
  projectTitle: string;
  buttonDataTestId: string;
  disabled?: boolean;
};

// string with inline styles
const printPageStyles = `
  @media print {
    svg {
      height: auto;
      max-width: 100%;
      width: 100%;
      padding: 20px;
    }
    span {
      width:100%;
      font-size: 7.5vw;
      text-align: center;
    }
    .page-template {
       display:flex;
       flex-direction: column;
       page-break-before: always;
    }
    .print-qrcode-svg {
      padding: 0px;
    }
  }
`;

const PrintButton = ({
  contentRef,
  label,
  projectTitle,
  buttonDataTestId,
  disabled = false,
}: PrintButtonProps) => {
  return (
    <ReactToPrint
      trigger={() => (
        <Button
          type="secondary"
          data-testid={buttonDataTestId}
          title="Print QR code"
          disabled={disabled}
        >
          {label}
        </Button>
      )}
      content={() => contentRef.current}
      removeAfterPrint
      pageStyle={printPageStyles}
      documentTitle={projectTitle}
    />
  );
};

export default PrintButton;
