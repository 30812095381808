import styled from 'styled-components/macro';
import {
  Flex,
  CogniteLogo as ImportedCogniteLogo,
  Button,
  Menu,
} from '@cognite/cogs.js';

export const ContentWrapper = styled(Flex).attrs({ direction: 'column' })`
  padding-left: 22px;
  padding-bottom: 22px;
  height: 100%;
  justify-content: space-between;
`;

export const LogoArea = styled(Flex).attrs({ direction: 'row' })`
  align-items: center;
  font-size: 13px;
  margin-top: 50px;
`;

export const CogniteLogo = styled(ImportedCogniteLogo).attrs({
  type: 'logomark',
})`
  margin-right: 12px;
`;

export const ProcedureButton = styled(Button).attrs({ type: 'tertiary' })`
  margin-left: 8px;
`;

export const Text = styled.span`
  font-size: 16px;
`;

export const VersionText = styled.div`
  font-size: 11px;
`;

export const SideBarParentMenu = styled(Menu)`
  padding-top: 25px;
  box-shadow: none;
`;

export const SideBarChildMenu = styled(Menu)`
  padding-left: 21px;
  box-shadow: none;
`;
