import React from 'react';
import { Icon } from '@cognite/cogs.js';
import { NotificationDiv } from './elements';

type Props = {
  name?: string;
  notifType:
    | 'reviewReturned'
    | 'reviewResubmitted'
    | 'newWo'
    | 'retractedCompilation'
    | 'retractedReview';
};

const Notification = ({ name, notifType }: Props) => {
  const pickBoxColor = () => {
    switch (notifType) {
      case 'reviewReturned':
        return '#FDCED6';
      case 'retractedCompilation':
        return '#FDCED6';
      case 'newWo':
        return '#C8F4E7';
      default:
        return 'var(--cogs-midblue-6)';
    }
  };

  const pickIconColor = () => {
    switch (notifType) {
      case 'reviewReturned':
        return '#D51A46';
      case 'retractedCompilation':
        return '#D51A46';
      default:
        return 'var(--cogs-greyscale-grey9)';
    }
  };

  const pickText = () => {
    switch (notifType) {
      case 'reviewReturned':
        return name ? (
          <span>{`Returned by reviewer (${name})`}</span>
        ) : (
          <span>Returned by reviewer</span>
        );
      case 'newWo':
        return <span>There are new work orders in the nearby area</span>;
      case 'retractedCompilation':
        return (
          <span data-testid="retracted-compilation-notif">
            Retracted from execution. Please edit your procedure to accomodate
            for the impediments you encountered.
          </span>
        );
      case 'retractedReview':
        return (
          <span>
            Procedure retracted from execution and updated by isolator.
          </span>
        );
      default:
        return name ? (
          <span>{`${name} resubmitted the procedure`}</span>
        ) : (
          <span>Compiler resubmitted the procedure</span>
        );
    }
  };

  return (
    <NotificationDiv color={pickBoxColor()}>
      <Icon
        type="Warning"
        style={{
          marginRight: '10px',
          color: pickIconColor(),
        }}
      />
      {pickText()}
    </NotificationDiv>
  );
};

export default Notification;
