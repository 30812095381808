import { Button } from '@cognite/cogs.js';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Project } from 'types';
import { Title, TextDetail, ProjectContainer, LogoArea } from '../elements';
import { ReactComponent as Icon } from './procedureIcon.svg';

type ProcedureBoxProps = {
  project: Project;
  isolationWorkstepsNumber: number;
  deIsolationWorkstepsNumber: number;
  viewOnly: boolean;
};

const ProcedureBox = ({
  project,
  isolationWorkstepsNumber,
  deIsolationWorkstepsNumber,
  viewOnly,
}: ProcedureBoxProps) => {
  const history = useHistory();

  const handleEditProcedure = (proj: Project) => {
    history.push(`/project/edit/${proj.id}`);
  };

  const isolationWorkstepsString =
    isolationWorkstepsNumber === 0 ? 'No' : String(isolationWorkstepsNumber);
  const deIsolationWorkstepsString =
    deIsolationWorkstepsNumber === 0
      ? 'No'
      : String(deIsolationWorkstepsNumber);

  const isolationNeedS = isolationWorkstepsNumber === 1 ? '' : 's';
  const deIsolationNeedS = deIsolationWorkstepsNumber === 1 ? '' : 's';

  const pickButtonText = () => {
    if (viewOnly) {
      return 'View procedure';
    }

    switch (project.status) {
      case 'review':
        return 'Review procedure';
      case 'executionReady':
        return 'View procedure';
      default:
        return 'Edit procedure';
    }
  };

  const buttonText = pickButtonText();

  const buttonType = project.status === 'compilation' ? 'primary' : 'secondary';

  return (
    <ProjectContainer>
      <LogoArea>
        <Icon style={{ marginRight: '6px' }} />
        <Title>Procedure</Title>
      </LogoArea>
      <TextDetail data-testid="isolation-steps-counter">
        {isolationWorkstepsString} isolation step{isolationNeedS}
      </TextDetail>
      <TextDetail>
        {deIsolationWorkstepsString} de-isolation step{deIsolationNeedS}
      </TextDetail>
      {project.status !== 'execution' && (
        <Button
          type={buttonType}
          data-testid="edit-procedure-button"
          onClick={() => handleEditProcedure(project)}
          style={{ alignSelf: 'flex-end' }}
        >
          {buttonText}
        </Button>
      )}
    </ProjectContainer>
  );
};

export default React.memo(ProcedureBox, (propsOld, propsNew) => {
  /* eslint-disable */
  return (
    propsOld.project.id === propsNew.project.id &&
    propsOld.project.status === propsNew.project.status &&
    propsOld.isolationWorkstepsNumber === propsNew.isolationWorkstepsNumber &&
    propsOld.deIsolationWorkstepsNumber === propsNew.deIsolationWorkstepsNumber
  );
});
