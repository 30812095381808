import React from 'react';
import { Loader as CogsLoader } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import { Message } from '../Message';

const Loader = styled(CogsLoader)`
  position: relative;
  top: none;
  left: none;
`;

type ShowFileProps = {
  fileId: number | undefined;
  fileIds: number[];
  isFileFullyLoaded: boolean;
  FileViewer: JSX.Element;
};

export const showFile = (props: ShowFileProps) => {
  const { fileId, fileIds, isFileFullyLoaded, FileViewer } = props;

  const numberOfFiles = Number(fileIds.length);

  if (!fileId && numberOfFiles > 1 && isFileFullyLoaded) {
    return (
      <Message data-testid="choosePnid-msg">
        <div>There are several P&IDs for this asset.</div>
        <div>Please select one to begin creating a procedure.</div>
      </Message>
    );
  }
  if (!fileId && numberOfFiles === 0 && isFileFullyLoaded) {
    return (
      <Message>
        <div data-testid="pnidPreview-empty">
          No P&IDs are connected to this asset.
        </div>
        <div>Please search and add a P&ID manually</div>
        <div>to begin creating a procedure.</div>
        <div>You can find the search field in the drawer on the right.</div>
      </Message>
    );
  }

  if (fileId && isFileFullyLoaded) {
    return FileViewer;
  }

  return (
    <div data-testid="pnidPreview-loader">
      <Loader />
    </div>
  );
};
