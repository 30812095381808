import React from 'react';
import GlobalStyles from 'global-styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { StoreType } from 'redux/store';
import { ToastContainer } from '@cognite/cogs.js';
import { RouterProvider } from './RouterProviders';

export type Props = {
  store: StoreType;
  children: React.ReactNode;
  tenant: string;
};

const AppProviders = ({ store, children, tenant }: Props) => {
  return (
    <ReduxProvider store={store}>
      <GlobalStyles />
      <BrowserRouter basename={`/${tenant}`}>
        <ToastContainer />
        <RouterProvider>{children}</RouterProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default AppProviders;
