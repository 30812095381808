import React from 'react';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { CardLine } from '../../elements';

export const StartPageLoading = React.memo(() => {
  return (
    <CardLine>
      <div className="item">
        <LoadingPlaceholder />
      </div>
      <div className="item">
        <LoadingPlaceholder />
      </div>
      <div className="item">
        <LoadingPlaceholder />
      </div>
      <div className="item">
        <LoadingPlaceholder />
      </div>
    </CardLine>
  );
});
