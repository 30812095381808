import React from 'react';

import { Flex } from '@cognite/cogs.js';

import { useSelector } from 'react-redux';

import { RootState } from '../../../../redux/store';
import { ProjectState } from '../../../../redux/reducers/project';
import { useWorkSteps } from '../../../../redux/reducers/worksteps';
import { MiniWidgetsLabel } from './elements';

export const PnidMiniWidget = () => {
  const { passValves, workorders, project } = useSelector<
    RootState,
    ProjectState
  >((state: RootState) => state.project);

  const { stepsNeedRevision } = useWorkSteps();

  if (project.status !== 'compilation' && project.status !== 'review') {
    return null;
  }

  return (
    <Flex direction="row" style={{ backgroundColor: 'white' }}>
      <MiniWidgetsLabel>
        {stepsNeedRevision()?.length || 0} Steps needing revision
      </MiniWidgetsLabel>
      <MiniWidgetsLabel>
        {passValves?.length || 0} Passing valves
      </MiniWidgetsLabel>
      <MiniWidgetsLabel>
        {workorders?.length || 0} Nearby open work orders
      </MiniWidgetsLabel>
    </Flex>
  );
};
