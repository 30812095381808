import styled from 'styled-components/macro';

export const Bar = styled.div`
  border-left: 5px solid #d51a46;
  height: 100%;
  margin-right: 5px;
  padding-left: 5px;
`;

export const SafeguardingValue = styled.span`
  color: #b30539;
  margin-left: 6px;
  margin-right: 10px;
`;

export const SafeguardingEditWrapper = styled.div`
  margin-right: 5px;
  padding-left: 5px;

  label {
    display: flex;
    flex-direction: row;
  }
`;
