import React, { useState } from 'react';
import { Button, Input } from '@cognite/cogs.js';

import { ErrorMessage, NewExecutorItemWrapper } from '../elements';

type NewExecutorItemProps = {
  handleNew: (executor: string) => void;
  executorsList: string[];
};

const NewExecutorItem = ({
  handleNew,
  executorsList,
}: NewExecutorItemProps) => {
  const [updatedExecutor, setUpdateExecutor] = useState<string>();
  const [fieldError, setFieldError] = useState<boolean>(false);

  const newExecutorHandler = () => {
    if (updatedExecutor) {
      const hasDuplicate = executorsList?.find(
        (element) => element === updatedExecutor
      );

      if (!hasDuplicate) {
        handleNew(updatedExecutor);
        setUpdateExecutor('');
      } else {
        setFieldError(true);
      }
    }
  };

  return (
    <>
      <NewExecutorItemWrapper>
        <Input
          data-testid="executor-text-field-new"
          fullWidth
          style={{
            flexGrow: 1,
            alignSelf: 'stretch',
            padding: '0px 10px',
            height: '30px',
            minWidth: '80px',
            marginRight: '8px',
          }}
          value={updatedExecutor}
          onChange={({ target }) => {
            setFieldError(false);
            setUpdateExecutor(target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setUpdateExecutor(e.currentTarget.value);
            }
          }}
        />
        <Button
          key="executor-add-new"
          data-testid="executor-add-new"
          type="secondary"
          size="small"
          onClick={newExecutorHandler}
        >
          Add
        </Button>
      </NewExecutorItemWrapper>

      {fieldError && (
        <ErrorMessage data-testid="executor-field-error">
          <span>Username already in list</span>
        </ErrorMessage>
      )}
    </>
  );
};

export default NewExecutorItem;
