import React from 'react';

export type TagProps = {
  color: string;
  width: string;
  height: string;
};

export const TagIcon = (props: TagProps) => {
  const svgProps = {
    width: props.width,
    height: props.height,
  };

  return (
    <svg
      viewBox="0.5 1 8 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.668945 19.5868H4.26787L8.47455 10.002L4.26787 0.586807H0.668945V19.5868ZM2.83777 8.01232C1.69204 8.01232 0.763244 8.94111 0.763244 10.0868C0.763244 11.2326 1.69204 12.1614 2.83777 12.1614C3.98349 12.1614 4.91229 11.2326 4.91229 10.0868C4.91229 8.94111 3.98349 8.01232 2.83777 8.01232Z"
        fill={props.color}
      />
    </svg>
  );
};
