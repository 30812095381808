import React from 'react';
import { ProjectStatus, WorkOrder } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import WorkorderBlock from './WorkorderBlock/WorkorderBlock';
import { TextDetail, WorkordersInnerWrapper } from './WorkorderBlock/elements';
import { ReactComponent as Icon } from './woIcon.svg';
import { Title, ProjectContainer, LogoArea } from '../elements';

type WorkordersCardProps = {
  workorders: WorkOrder[];
  timeToCompareWith?: number;
  projectStatus?: ProjectStatus;
  showLoading?: boolean;
  isForDrawer?: boolean;
};

const Workorders = ({
  workorders,
  timeToCompareWith,
  projectStatus,
  showLoading = false,
  isForDrawer = false,
}: WorkordersCardProps) => {
  return (
    <ProjectContainer>
      {!isForDrawer && (
        <LogoArea>
          <Icon style={{ marginRight: '6px' }} />
          <Title>Nearby open work orders</Title>
        </LogoArea>
      )}
      {showLoading && (
        <div style={{ display: 'block', overflow: 'hidden', height: '180px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}
      {workorders.length > 0 && (
        <WorkordersInnerWrapper>
          {workorders.map((wo) => {
            return (
              <WorkorderBlock
                key={`workorder-${wo.id}`}
                workorder={wo}
                projectStatus={projectStatus}
                timeToCompareWith={timeToCompareWith}
              />
            );
          })}
        </WorkordersInnerWrapper>
      )}
      {!showLoading && !workorders.length && (
        <TextDetail
          data-testid="no-workorders-text"
          style={{ marginLeft: '3px' }}
        >
          No relevant work orders found
        </TextDetail>
      )}
    </ProjectContainer>
  );
};

export default React.memo(Workorders);
