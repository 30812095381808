import React, { useCallback } from 'react';
import { CreateNewWorkStepDTO, Phase, PhaseType } from 'types';
import { AddWorkstepArea } from './elements';

export interface AddNextWorkStepProps {
  phase: Phase;
  phasesType: PhaseType;
  workStepId: string;
  workStepPosition: number;
  workStepIndex: number;
  isWorkstepEditable: boolean;
  onCreateNewWorkStep: (dto: CreateNewWorkStepDTO) => void;
  onOverlayClicked: () => void;
}

export const AddNextWorkStep = React.memo(
  ({
    phase,
    phasesType,
    workStepId,
    workStepPosition,
    workStepIndex,
    isWorkstepEditable,
    onCreateNewWorkStep,
    onOverlayClicked,
  }: AddNextWorkStepProps) => {
    const createNewWorkStep = useCallback(() => {
      if (!isWorkstepEditable) {
        onCreateNewWorkStep({
          phase,
          position: workStepPosition + 1,
          workStepIndex,
        });
      } else {
        onOverlayClicked();
      }
    }, [
      onCreateNewWorkStep,
      workStepIndex,
      workStepPosition,
      phase,
      isWorkstepEditable,
      onOverlayClicked,
    ]);
    return (
      <AddWorkstepArea deisolation={phasesType === 'deisolation'}>
        <div
          data-testid={`add-workstep-below-${workStepId}`}
          tabIndex={0}
          role="button"
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              createNewWorkStep();
            }
          }}
          onClick={() => createNewWorkStep()}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/CirclePlus.svg`}
            title="Add step between"
            alt="+"
          />
        </div>
      </AddWorkstepArea>
    );
  }
);
