import styled from 'styled-components/macro';

export const TextDetail = styled.div`
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #262626;
  word-break: break-word;
  white-space: pre-wrap;
  text-align: left;
`;
