import { Detail } from '@cognite/cogs.js';
import React from 'react';
import IsoPlanIcon from 'shared/TopBar/IsoPlanIcon.svg';

export const IsoPlanLogo = () => {
  return (
    <div
      style={{
        display: 'block',
        overflow: 'hidden',
        paddingRight: '50px',
      }}
    >
      <img src={IsoPlanIcon} alt="React Logo" style={{ marginRight: '10px' }} />{' '}
      <Detail strong style={{ fontWeight: 'bold' }}>
        Cognite Isoplan
      </Detail>
    </div>
  );
};
