import { combineReducers } from '@reduxjs/toolkit';
import projectReducer from './project/projectReducer';
import authReducer from './auth/authReducer';
import searchReducer from './search/searchReducer';
import workStepsReducer from './worksteps/workstepsReducer';
import filesReducer from './files/filesReducer';
import workStepTemplateReducer from './activeWorkStep/activeWorkStepReducer';
import hotSpotReducer from './hotSpot/hotSpotReducer';
import annotationsReducer from './annotations/annotationsReducer';
import globalReducer from './global';

export default combineReducers({
  project: projectReducer.reducer,
  auth: authReducer.reducer,
  search: searchReducer.reducer,
  workSteps: workStepsReducer.reducer,
  files: filesReducer.reducer,
  activeWorkStep: workStepTemplateReducer.reducer,
  hotSpot: hotSpotReducer.reducer,
  annotations: annotationsReducer.reducer,
  global: globalReducer.reducer,
});
