import { AnnotationsResourceService } from './annotations-resource.service';
import { AssetsResourceService } from './assets-resource.service';
import { PassValvesResourceService } from './pass-valves-resource.service';
import { PreWorkItemsResourceService } from './pre-work-items-resource.service';
import { ProcedureAttachmentsResourceService } from './procedure-attachments-resource.service';
import { ProcedureReviewCommentsService } from './procedure-review-comments-resource.service';
import { ProjectsResourceService } from './projects-resource.service';
import { WorkOrdersResourceService } from './work-orders-resource.service';
import { WorkstepsResourceService } from './worksteps-resource.service';
import { FilesResourceService } from './files-resource.service';
import { DrawingsResourceService } from './drawings-resource.service';
import { ProcedureApprovalFilesResourceService } from './procedure-approval-files-resource.service';
import { GlobalResourceService } from './global-resource.service';

export const annotationsResourceService = new AnnotationsResourceService();
export const assetsResourceService = new AssetsResourceService();
export const drawingsResourceService = new DrawingsResourceService();
export const projectsResourceService = new ProjectsResourceService();
export const preWorkItemsResourceService = new PreWorkItemsResourceService();
export const procedureReviewCommentsService =
  new ProcedureReviewCommentsService();
export const procedureAttachmentsResourceService =
  new ProcedureAttachmentsResourceService();
export const procedureApprovalFilesResourceService =
  new ProcedureApprovalFilesResourceService();
export const workOrdersResourceService = new WorkOrdersResourceService();
export const passValvesResourceService = new PassValvesResourceService();
export const workstepsResourceService = new WorkstepsResourceService();
export const filesResourceService = new FilesResourceService();
export const globalResourceService = new GlobalResourceService();
