import { Body, Button, Flex, Icon, Micro, Switch } from '@cognite/cogs.js';
import { useSave } from 'hooks/useSave';
import React, { lazy, useCallback, useState, Suspense } from 'react';

import { useAnnotations } from 'redux/reducers/annotations';
import { useHotSpot } from 'redux/reducers/hotSpot/hooks';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { ModalLoader } from 'shared/ModalLoader';
import { customEvents, Project, CogniteAnnotation } from 'types';
import { actions as projectActions } from 'redux/reducers/project/projectReducer';
import { useDispatch } from 'react-redux';
import { actions as filesActions } from 'redux/reducers/files';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { useHistory } from 'react-router-dom';
import {
  SubHeaderAtom,
  SubHeaderContainer,
  SubHeaderItem,
  SubHeaderViewOnlyNotification,
} from './elements';

const GenerateDeIsolationProcedureModal = lazy(
  () => import('./GenerateDeIsolationProcedureModal')
);
const HotspotDeleteModal = lazy(() => import('./HotspotDeleteModal'));
const CompleteMigrationModal = lazy(() => import('./CompleteMigrationModal'));

export type Props = {
  project: Project;
  viewOnly: boolean;
  workStepsFileIds: number[];
  showWorkSteps?: boolean;
  setShowWorkSteps: Function;
};

export const SubHeader = ({
  project,
  viewOnly,
  workStepsFileIds,
  showWorkSteps,
  setShowWorkSteps,
}: Props) => {
  const {
    deIsolationMode,
    hasDeIsolationPhases,
    phases,
    setDeIsolationMode,
    createDeIsolationWorkSteps,
    updateAnnotationOfWorkStepsInvalid,
  } = useWorkSteps();
  const { activeHotSpot, clearActiveHotSpot } = useHotSpot();
  const [modalVisible, setModalVisible] = useState(false);
  const [completeMigrationModalVisible, setCompleteMigrationModalVisible] =
    useState(false);
  const [generateProcedureModalVisible, setGenerateProcedureModalVisible] =
    useState(false);
  const { editMode, deleteAnnotation, editAnnotation } = useAnnotations();

  const { saveHandler } = useSave();
  const dispatch = useDispatch();
  const metrics = useMetrics(METRICS_AREA.PROJECT_MIGRATE);
  const history = useHistory();

  const createDeIsolation = useCallback(() => {
    createDeIsolationWorkSteps();
    setGenerateProcedureModalVisible(false);
    window.dispatchEvent(new CustomEvent(customEvents.deIsolationStepsCreated));
    // eslint-disable-next-line
  }, []);

  const completeMigration = useCallback(() => {
    dispatch(filesActions.removeUnusedFiles(workStepsFileIds));
    dispatch(projectActions.setProjectMigrationCompleted());
    metrics.track(METRICS.COMPLETE_MIGRATION, {
      projectId: project.id,
      date: new Date().toISOString(),
      timestamp: new Date().getTime(),
    });
  }, [dispatch, workStepsFileIds, metrics, project.id]);

  const onDeleteAnnotation = useCallback(
    (annotation: CogniteAnnotation, needsMigration: boolean) => {
      deleteAnnotation(annotation, needsMigration);
      if (annotation && annotation.id) {
        updateAnnotationOfWorkStepsInvalid(annotation, annotation);
      }
    },
    [deleteAnnotation, updateAnnotationOfWorkStepsInvalid]
  );

  const isGenerateBtnDisabled = !hasDeIsolationPhases && !phases.length;

  return (
    <SubHeaderContainer>
      <SubHeaderItem>
        <Button
          data-testid="to-dashboard-btn"
          size="small"
          type="secondary"
          style={{ fontSize: '11px' }}
          onClick={() => history.push(`/project/${project.id}`)}
        >
          <Icon type="ArrowLeft" size={11} style={{ marginRight: '5px' }} />
          To dashboard
        </Button>
      </SubHeaderItem>
      {viewOnly && (
        <SubHeaderItem>
          <SubHeaderViewOnlyNotification data-testid="submenu-view-only-msg">
            <Icon
              type="EyeShow"
              size={14}
              style={{
                marginRight: '5px',
              }}
            />
            <div>You are in view only mode</div>
          </SubHeaderViewOnlyNotification>
        </SubHeaderItem>
      )}
      {project.status === 'compilation' && !viewOnly && (
        <>
          <SubHeaderItem>
            <SubHeaderAtom>
              <Micro>Procedure</Micro>
            </SubHeaderAtom>
            <SubHeaderAtom>
              <Flex
                direction="row"
                style={{ gap: '5px', alignItems: 'center' }}
              >
                <Button
                  data-testid="save-bttn"
                  size="small"
                  type="secondary"
                  style={{ fontSize: '11px' }}
                  onClick={() => saveHandler('test')}
                >
                  Save procedure
                </Button>
                {project.needsMigration && (
                  <Button
                    data-testid="migrate-procedure-btn"
                    size="small"
                    type="primary"
                    style={{ fontSize: '11px' }}
                    onClick={() => setCompleteMigrationModalVisible(true)}
                  >
                    Complete update
                  </Button>
                )}
              </Flex>
              {project.needsMigration && completeMigrationModalVisible && (
                <Suspense fallback={<ModalLoader />}>
                  <CompleteMigrationModal
                    isModalOpen={completeMigrationModalVisible}
                    setModalOpen={setCompleteMigrationModalVisible}
                    onCompleteMigration={completeMigration}
                  />
                </Suspense>
              )}
            </SubHeaderAtom>
          </SubHeaderItem>
          <SubHeaderItem>
            <SubHeaderAtom title="Select which set of actions you see in the context menu on the P&ID">
              <Micro>Actions</Micro>
              <Body level={3} style={{ marginLeft: '10px' }}>
                Isolation
              </Body>
              <Switch
                name="isolationMode"
                checked={deIsolationMode}
                data-testid="isolation-deisolation-actions-switch"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  nextValue?: boolean
                ) => {
                  setDeIsolationMode(nextValue || false);
                }}
              />
              <Body level={3} style={{ marginLeft: '8px' }}>
                De-isolation
              </Body>
            </SubHeaderAtom>
          </SubHeaderItem>
          <SubHeaderItem>
            <SubHeaderAtom>
              <Micro>Workflow</Micro>
            </SubHeaderAtom>
            <SubHeaderAtom>
              <Button
                data-testid="create-deisolation-procedure-action-btn"
                size="small"
                type="secondary"
                style={{ fontSize: '11px' }}
                disabled={isGenerateBtnDisabled}
                onClick={() => setGenerateProcedureModalVisible(true)}
                title="Generate the de-isolation procedure"
              >
                Generate de-isolation
              </Button>
              {generateProcedureModalVisible && (
                <Suspense fallback={<ModalLoader />}>
                  <GenerateDeIsolationProcedureModal
                    isModalOpen={generateProcedureModalVisible}
                    setModalOpen={setGenerateProcedureModalVisible}
                    onGenerateProcedure={createDeIsolation}
                  />
                </Suspense>
              )}
            </SubHeaderAtom>
          </SubHeaderItem>
          <SubHeaderItem>
            <SubHeaderAtom>
              <Micro>Hotspots</Micro>
            </SubHeaderAtom>
            <SubHeaderAtom>
              <Flex
                direction="row"
                style={{ gap: '5px', alignItems: 'center' }}
              >
                <Button
                  size="small"
                  type="secondary"
                  data-testid="edit-annotation"
                  disabled={
                    !activeHotSpot || !activeHotSpot.annotation || editMode
                  }
                  onClick={() => {
                    if (
                      activeHotSpot &&
                      activeHotSpot.annotation &&
                      !editMode
                    ) {
                      editAnnotation(activeHotSpot.annotation);
                    }
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  type="ghost-destructive"
                  data-testid="delete-annotation"
                  disabled={
                    !activeHotSpot || !activeHotSpot.annotation || editMode
                  }
                  onClick={() => {
                    if (
                      activeHotSpot &&
                      activeHotSpot.annotation &&
                      !editMode
                    ) {
                      setModalVisible(true);
                    }
                  }}
                >
                  Delete
                </Button>
              </Flex>
              {modalVisible && (
                <Suspense fallback={<ModalLoader />}>
                  <HotspotDeleteModal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    activeHotSpot={activeHotSpot}
                    needsMigration={project.needsMigration === true}
                    deleteAnnotation={onDeleteAnnotation}
                    clearActiveHotSpot={clearActiveHotSpot}
                  />
                </Suspense>
              )}
            </SubHeaderAtom>
          </SubHeaderItem>
          <SubHeaderItem>
            <SubHeaderAtom>
              <Body size="xx-small">Worksteps</Body>
              <Body size="small" style={{ marginLeft: '10px' }}>
                Hide
              </Body>
              <Switch
                name="workstepVisibility"
                checked={showWorkSteps}
                data-testid="workstep-visibility-actions-switch"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  nextValue?: boolean
                ) => {
                  setShowWorkSteps(nextValue || false);
                }}
              />
              <Body size="small" style={{ marginLeft: '10px' }}>
                Show
              </Body>
            </SubHeaderAtom>
          </SubHeaderItem>
        </>
      )}
    </SubHeaderContainer>
  );
};
