import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { Status } from 'redux/types';
import { fetchAllProcedures, SearchState } from 'redux/reducers/search';
import { SearchBar, LibrarySearchFilters } from './SearchBar';
import SearchManager from '../../../components/SearchResults/SearchManager';
import { Project } from '../../../types';
import { useProcedureSearch } from './useProcedureSearch';

const SearchProcedure = () => {
  const { allProcedures, statusAllProcedures, selectedRootAsset } = useSelector<
    RootState,
    SearchState
  >((state) => {
    return state.search;
  });
  const [searchStatus, setSearchStatus] = useState<Status>(Status.idle);
  const [filters, setFilters] = useState<LibrarySearchFilters>({});
  const [filteredProcedures, setFilteredProcedures] = useState([] as Project[]);

  const dispatch = useDispatch<AppDispatch>();
  const { filterRelevantProjects } = useProcedureSearch();

  useEffect(() => {
    dispatch(fetchAllProcedures(selectedRootAsset));
  }, [dispatch, selectedRootAsset]);

  useEffect(() => {
    if (statusAllProcedures === Status.success) {
      setFilteredProcedures(filterRelevantProjects(filters, allProcedures));
    }
    if (statusAllProcedures === Status.idle) {
      setFilteredProcedures([]);
    }
  }, [filters, statusAllProcedures, allProcedures, filterRelevantProjects]);

  const onSearch = (inputFilters: LibrarySearchFilters) => {
    setFilters(inputFilters);
    setSearchStatus(Status.success);
  };

  const onClear = () => {
    setFilters({});
    setSearchStatus(Status.idle);
  };

  return (
    <>
      <SearchBar onSearch={onSearch} onClear={onClear} />
      <SearchManager
        showCreateButton={false}
        procedures={filteredProcedures}
        isError={statusAllProcedures === Status.failed}
        isLoading={searchStatus === Status.loading}
        isSuccess={searchStatus === Status.success}
        noResultsMessage="No existing procedures found for that search request"
      />
    </>
  );
};

export default SearchProcedure;
