import React from 'react';
import { WorkStep, WorkstepPairs } from 'types';
import { getTextColor } from 'utils/colors';
import { StepBoxContainer, StepBoxInnerDiv } from './elements';

interface StepBoxItemProps {
  isActive?: boolean;
  workstepsPair: WorkstepPairs;
  activeWorkstep?: WorkStep;
  onSelected: Function;
}

export const StepBoxItem = React.memo((props: StepBoxItemProps) => {
  const { isActive, workstepsPair, activeWorkstep, onSelected } = props;

  const isolationTextColor = getTextColor(workstepsPair.color);
  const deIsolationTextColor = getTextColor(workstepsPair.deisolationColor);

  return (
    <StepBoxContainer
      className={isActive ? 'active' : undefined}
      data-testid={`stepbox-${workstepsPair.id}`}
      data-active={isActive}
      onClick={() => {
        if (activeWorkstep) {
          onSelected(activeWorkstep);
        }
      }}
    >
      <StepBoxInnerDiv
        className="step-box-inner"
        color={workstepsPair.color}
        fontColor={isolationTextColor}
      >
        {workstepsPair.position}
      </StepBoxInnerDiv>
      {!!workstepsPair.deisolationPosition && (
        <StepBoxInnerDiv
          color={workstepsPair.deisolationColor || 'white'}
          fontColor={deIsolationTextColor}
        >
          {workstepsPair.deisolationPosition}
        </StepBoxInnerDiv>
      )}
    </StepBoxContainer>
  );
});
