export function sortArray(
  arr: any[],
  sortByPropertyName: string,
  sortOrder: 'asc' | 'desc'
): any[] {
  if (!arr) {
    return [];
  }
  const sortAsc = (a: any, b: any) =>
    a[sortByPropertyName] > b[sortByPropertyName] ? 1 : -1;
  const sortDesc = (a: any, b: any) =>
    a[sortByPropertyName] > b[sortByPropertyName] ? -1 : 1;
  return arr.sort(sortOrder === 'desc' ? sortDesc : sortAsc);
}

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}

export function uniqBy(collection: any[], key: string) {
  const seen = {} as any;
  return collection.filter((item) => {
    const k = item[key] as any;
    // eslint-disable-next-line
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}
