import { ProcedureFileAttachment } from 'types';
import { ATTACHMENTS_DATASET_ID } from 'config/apiConfig';
import { modelFactory } from 'factories';
import { FileInfo, FileUploadResponse } from '@cognite/sdk';
import { getClient } from '../config/cognitesdk';

export class ProcedureAttachmentsResourceService {
  async fetchAttachments(
    procedureId: string,
    client = getClient()
  ): Promise<ProcedureFileAttachment[]> {
    const rawAttachmentsList = await client.files
      .list({
        filter: {
          dataSetIds: [{ externalId: ATTACHMENTS_DATASET_ID }],
          source: procedureId,
          metadata: { removed: 'false' },
        },
      })
      .autoPagingToArray({ limit: -1 });

    return rawAttachmentsList.map((attachment) =>
      modelFactory.mapToProcedureFileAttachment(attachment)
    );
  }

  upload(
    procedureId: string,
    file: File,
    attachmentFile: ProcedureFileAttachment,
    fileContent: any,
    dataSetId?: number,
    client = getClient()
  ): Promise<FileInfo | FileUploadResponse> {
    return client.files.upload(
      {
        externalId: attachmentFile.id,
        dataSetId,
        name: attachmentFile.name,
        mimeType: file.type ? file.type : undefined,
        source: procedureId,
        metadata: {
          uploaded_by: attachmentFile.author,
          removed: 'false',
        },
      },
      fileContent,
      true
    );
  }

  softDelete(fileAttachment: ProcedureFileAttachment, client = getClient()) {
    return client.files.update([
      {
        externalId: fileAttachment.id,
        update: {
          metadata: {
            set: {
              uploaded_by: fileAttachment.author,
              removed: 'true',
            },
          },
        },
      },
    ]);
  }

  undoSoftDelete(
    fileAttachment: ProcedureFileAttachment,
    client = getClient()
  ) {
    return client.files.update([
      {
        externalId: fileAttachment.id,
        update: {
          metadata: {
            set: {
              uploaded_by: fileAttachment.author,
              removed: 'false',
            },
          },
        },
      },
    ]);
  }
}
