import { Icon } from '@cognite/cogs.js';
import React from 'react';
import {
  ExecuteViewOnlyNotification,
  ExecutionMenuBarWrapper,
} from './elements';
import { IsoPlanLogo } from './IsoPlanLogo';

export interface ExecutionMenuBarProps {
  onMenuBarClick: () => void;
  viewOnly: boolean;
}

export const ExecutionMenuBar = ({
  onMenuBarClick,
  viewOnly,
}: ExecutionMenuBarProps) => {
  return (
    <ExecutionMenuBarWrapper className="z-8" onClick={onMenuBarClick}>
      <div>
        <IsoPlanLogo />
        <div className="menu-bar-icon">
          <Icon type="HamburgerMenu" />
        </div>
      </div>
      {viewOnly && (
        <ExecuteViewOnlyNotification data-testid="execute-view-only-msg">
          <Icon
            type="EyeShow"
            size={14}
            style={{
              marginRight: '5px',
            }}
          />
          <div>You are in view only mode</div>
        </ExecuteViewOnlyNotification>
      )}
    </ExecutionMenuBarWrapper>
  );
};
