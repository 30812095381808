import React, { useState } from 'react';

import { ProcedureApprovalFile } from 'types';

import { openFileExternalIdInNewWindow } from 'services/services';
import { useApprovalFileUpload } from 'hooks/useApprovalFileUpload';
import {
  ApprovalFileWrapper,
  ApprovalFileContent,
  ApprovalFileName,
  ApprovalFileActions,
  ApprovalFileApprover,
  ApprovalFileEditWrapper,
} from './elements';
import ApprovalFileEdit from './ApprovalFileEdit';
import { LinkButton } from '../elements';

type ApprovalFileProps = {
  file: ProcedureApprovalFile;
  canEdit: boolean;
};

const ApprovalFile = ({ file, canEdit = false }: ApprovalFileProps) => {
  const {
    fileSoftDeleteHandler,
    undoFileSoftDeleteHandler,
    updateFileMetaData,
  } = useApprovalFileUpload();
  const [isEditMode, setIsEditMode] = useState(false);

  const fileName = (
    <ApprovalFileName
      key={`approval-file-link-${file.id}`}
      data-testid={`approval-file-link-${file.id}`}
      icon={file.uploading ? 'Loader' : undefined}
      iconPlacement="right"
      onClick={() => openFileExternalIdInNewWindow(file.id)}
    >
      {file.name}
    </ApprovalFileName>
  );

  if (isEditMode) {
    return (
      <ApprovalFileWrapper>
        <ApprovalFileEditWrapper>
          {fileName}
          <ApprovalFileEdit
            file={file}
            onCancel={() => setIsEditMode(false)}
            onSubmit={(description, approvedBy) => {
              updateFileMetaData({
                ...file,
                description,
                approvedBy,
              });
              setIsEditMode(false);
            }}
          />
        </ApprovalFileEditWrapper>
      </ApprovalFileWrapper>
    );
  }

  return (
    <ApprovalFileWrapper>
      <ApprovalFileContent data-testid={`approval-file-content-${file.id}`}>
        {!file.removed ? (
          <>
            {fileName}
            {file.approvedBy && (
              <ApprovalFileApprover>
                Approved by: {file.approvedBy}
              </ApprovalFileApprover>
            )}
            {file.description && <div>{file.description}</div>}
          </>
        ) : (
          <span className="removed">{file.name} deleted.</span>
        )}
      </ApprovalFileContent>
      {canEdit && !file.uploading && (
        <ApprovalFileActions>
          {file.removed ? (
            <LinkButton
              key={`approval-file-undo-${file.id}`}
              data-testid={`approval-file-undo-${file.id}`}
              onClick={() => undoFileSoftDeleteHandler(file)}
            >
              Undo
            </LinkButton>
          ) : (
            <>
              <LinkButton
                key={`approval-file-edit-${file.id}`}
                data-testid={`approval-file-edit-${file.id}`}
                onClick={() => setIsEditMode(true)}
              >
                Edit
              </LinkButton>
              <LinkButton
                key={`approval-file-remove-${file.id}`}
                data-testid={`approval-file-remove-${file.id}`}
                onClick={() => fileSoftDeleteHandler(file)}
              >
                Delete
              </LinkButton>
            </>
          )}
        </ApprovalFileActions>
      )}
    </ApprovalFileWrapper>
  );
};

export default ApprovalFile;
