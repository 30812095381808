import React from 'react';
import Modal from 'shared/Modal';

import { useHistory } from 'react-router-dom';

export interface BackToDashboardModalProps {
  isModalOpen: boolean;
  setModalOpen: (boolValue: boolean) => void;
  projectId: string;
}

export const BackToDashboardModal = (props: BackToDashboardModalProps) => {
  const history = useHistory();

  const onOkClick = () => {
    history.push(`/project/${props.projectId}`);
  };

  return (
    <Modal
      visible={props.isModalOpen}
      setVisible={props.setModalOpen}
      title="The Phase Completed"
      okText="To dashboard"
      cancelText="Continue"
      okHandler={onOkClick}
    >
      <div>
        The phase has been completed. Would you like to navigate to the
        dashboard?
      </div>
    </Modal>
  );
};
