import styled from 'styled-components/macro';

export const ListItem = styled.div`
  display: block;
  overflow: hidden;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
  padding-left: 20px;
  margin-left: -20px;
  font-size: 16px;
  line-height: 24px;
  position: relative;

  .checkmark-icon {
    width: 20px;
    position: absolute;
    left: 0;
  }
`;
