/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from 'redux/types';
import { globalResourceService } from 'resources';
import { withToastForErrorWithArgs } from 'redux/utils';
import { DataSet } from '@cognite/sdk';

export const fetchDataSet = createAsyncThunk(
  'global/setDataSet',
  withToastForErrorWithArgs((ids) => {
    return globalResourceService.fetchDataSetInfo(ids as string[]);
  })
);

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    dataSets: [] as DataSet[],
    statusDataset: Status.idle,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDataSet.pending, (state, _action) => {
      state.statusDataset = Status.loading;
    });
    builder.addCase(fetchDataSet.fulfilled, (state, action) => {
      state.statusDataset = Status.success;
      state.dataSets = action.payload;
    });
    builder.addCase(fetchDataSet.rejected, (state, _action) => {
      state.statusDataset = Status.failed;
    });
  },
});

export type GlobalState = ReturnType<typeof globalSlice.reducer>;
export const { actions } = globalSlice;
export default globalSlice;
