import { Icon } from '@cognite/cogs.js';
import React from 'react';
import { Project } from 'types';
import { LogoArea, ProjectContainer, TextDetail, Title } from '../elements';
import { LogoSpan } from '../Attachments/elements';
import { WorkordersInnerWrapper } from '../Workorders/WorkorderBlock/elements';

type AboutProps = {
  project: Project;
  viewOnly?: boolean;
};

const UserLogs = ({ project }: AboutProps) => {
  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Warning"
            style={{ color: '#333333', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>User Logs</Title>
      </LogoArea>

      <WorkordersInnerWrapper>
        {project?.logFile?.map((item) => (
          <div style={{ marginBottom: 10 }}>
            <TextDetail style={{ padding: 10, background: '#FDCED6' }}>
              <strong>User:</strong> {item.userId}
            </TextDetail>

            <div style={{ padding: 20, background: 'rgb(255, 220, 127)' }}>
              <h2>User activities </h2>
              {item.activities.map((ac, index) => (
                <div key={ac.timestamp}>
                  <h4>activity {index + 1}</h4>
                  <TextDetail>
                    <strong>Timestamp:</strong>{' '}
                    {new Date(Number(ac.timestamp)).toLocaleString()}
                  </TextDetail>
                  <TextDetail>
                    <strong>Action:</strong> {ac.action}
                  </TextDetail>
                  <TextDetail>
                    <strong>Details:</strong>
                    {ac.details}
                  </TextDetail>
                  <hr />
                </div>
              ))}
            </div>
            <hr />
          </div>
        ))}
      </WorkordersInnerWrapper>
    </ProjectContainer>
  );
};

export default UserLogs;
