import React from 'react';
import { TagIcon } from 'shared/Icons/Tag';
import { Wrapper } from './elements';

export type Props = { color: string; size: string; children: React.ReactNode };

const Tag = ({ color, size, children }: Props) => {
  return (
    <Wrapper height={size}>
      <div
        style={{
          backgroundColor: color,
          padding: '0 3px',
          fontSize: '12px',
          lineHeight: '16px',
          marginRight: +size < 16 ? '-5px' : '-7px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {children}
      </div>
      <TagIcon color={color} width={size} height={size} />
    </Wrapper>
  );
};

export default Tag;
