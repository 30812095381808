import React from 'react';
import { PassValve } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { ReactComponent as Icon } from './pvIcon.svg';
import {
  Title,
  ProjectContainer,
  LogoArea,
  HorizontalLine,
  TextDetailExtraSmall,
} from '../elements';
import PassingValveBlock from './PassingValveBlock/PassingValveBlock';
import { TextDetail } from '../Workorders/WorkorderBlock/elements';

type PassingValvesCardProps = {
  isForDrawer?: boolean;
  showLoading?: boolean;
  editMode: boolean;
  valves: PassValve[];
  checkedValvesArray: string[] | undefined;
};

const PassingValves = ({
  isForDrawer = false,
  showLoading = false,
  editMode,
  valves,
  checkedValvesArray,
}: PassingValvesCardProps) => {
  const requiredValves = editMode
    ? valves
    : valves.filter((valve) => checkedValvesArray?.includes(valve.id));
  return (
    <ProjectContainer>
      {!isForDrawer && (
        <LogoArea>
          <Icon style={{ marginRight: '6px' }} />
          <Title>Passing valves</Title>
        </LogoArea>
      )}

      {showLoading && (
        <div style={{ display: 'block', overflow: 'hidden', height: '150px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}

      {requiredValves.length > 0 && (
        <>
          <div>
            {requiredValves.map((valve) => {
              return (
                <PassingValveBlock
                  key={`pass-valve-${valve.id}`}
                  editMode={editMode}
                  valve={valve}
                  checkedValvesArray={checkedValvesArray}
                />
              );
            })}
          </div>
          {editMode && (
            <>
              <HorizontalLine />
              <TextDetailExtraSmall style={{ marginTop: '6px' }}>
                Checking the box means the passing valve has been taken into
                account in the planning
              </TextDetailExtraSmall>
            </>
          )}
        </>
      )}

      {!showLoading && !requiredValves.length && (
        <TextDetail
          data-testid="no-passing-valves-text"
          style={{ marginLeft: '3px' }}
        >
          No relevant passing valves found
        </TextDetail>
      )}
    </ProjectContainer>
  );
};

export default React.memo(PassingValves);
