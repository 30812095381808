import { Project } from 'types';
import {
  PROCEDURES_DATASET_ID,
  PROCEDURES_FILE_TYPE,
  ROOT_ASSET,
} from 'config/apiConfig';

import { modelFactory } from 'factories';
import { FileFilterProps, FileRequestFilter } from '@cognite/sdk';
import { getClient } from '../config/cognitesdk';

export class ProjectsResourceService {
  async fetchProjects(
    rootAsset: string,
    filters?: FileFilterProps,
    limit?: number,
    client = getClient()
  ): Promise<Project[]> {
    const requestFilters = filters || {
      rootAssetIds: [{ externalId: ROOT_ASSET }],
      metadata: { rootAssetExternalId: rootAsset },
    };

    const scope: FileRequestFilter = {
      filter: {
        dataSetIds: [{ externalId: PROCEDURES_DATASET_ID }],
        mimeType: PROCEDURES_FILE_TYPE,
        ...requestFilters,
      },
    };

    if (limit) {
      scope.limit = limit;
    }

    const rawAllProjects = await client.files
      .list(scope)
      .autoPagingToArray({ limit: -1 });

    return rawAllProjects.map((fileEvent) =>
      modelFactory.createProject(fileEvent)
    );
  }

  async fetchAllProcedures(
    rootAsset: string,
    limit?: number
  ): Promise<Project[]> {
    const requestFilters = {
      rootAssetIds: [{ externalId: ROOT_ASSET }],
      metadata: {
        rootAssetExternalId: rootAsset,
      },
    };

    const scope: FileRequestFilter = {
      filter: {
        dataSetIds: [{ externalId: PROCEDURES_DATASET_ID }],
        mimeType: PROCEDURES_FILE_TYPE,
        ...requestFilters,
      },
    };

    if (limit) {
      scope.limit = limit;
    }

    const rawAllProjects = await getClient()
      .files.list(scope)
      .autoPagingToArray({ limit: -1 });

    return rawAllProjects.map((fileEvent) => {
      return modelFactory.createProject(fileEvent);
    });
  }

  async fetchProject(
    projectId: string,
    client = getClient()
  ): Promise<Project> {
    const response = await client.files.retrieve([{ externalId: projectId }]);
    return response.map((fileEvent) =>
      modelFactory.createProject(fileEvent)
    )[0];
  }
}
