import { createLayers } from '@cognite/z-index';

const LAYERS = [
  'MAXIMUM',
  'WARNING',
  'POPUP',
  'WS_EDIT',
  'WS_OVERLAY',
  'WS_EXECUTE',
  'MINIMUM',
] as const;

export default createLayers<(typeof LAYERS)[number]>(LAYERS);
