import React, { useLayoutEffect } from 'react';
import { Tabs } from '@cognite/cogs.js';
import { SearchPage } from 'containers/SearchPage/SearchPage';
import { useDispatch } from 'react-redux';
import SearchAsset from './SearchAsset/SearchAsset';
import SearchProcedure from './SearchProcedure/SearchProcedure';
import searchSlice from '../../redux/reducers/search';

const SearchPages = () => {
  const dispatch = useDispatch();

  const clearSearch = () => {
    dispatch(searchSlice.actions.setClearSearch());
  };

  useLayoutEffect(() => {
    return () => {
      dispatch(searchSlice.actions.setClearSearch());
    };
  }, [dispatch]);

  return (
    <SearchPage>
      <Tabs
        defaultActiveKey="assets"
        className="search-page-tabs"
        onChange={clearSearch}
      >
        <Tabs.Tab tabKey="assets" label="Search asset">
          <SearchAsset />
        </Tabs.Tab>
        <Tabs.Tab tabKey="procedures" label="Search procedure">
          <SearchProcedure />
        </Tabs.Tab>
      </Tabs>
    </SearchPage>
  );
};

export default SearchPages;
