import { PV_DATASET_ID } from 'config/apiConfig';
import { modelFactory } from 'factories';
import { getClient } from '../config/cognitesdk';

export class PassValvesResourceService {
  async fetchPassValves(assetIds: number[], client = getClient()) {
    const rawPassValves = await client.events
      .list({
        filter: {
          assetIds,
          dataSetIds: [{ externalId: PV_DATASET_ID }],
          metadata: {
            COMPLETED: 'N',
          },
        },
      })
      .autoPagingToArray({ limit: -1 });

    return rawPassValves.map((rawPassValve) =>
      modelFactory.mapToPassValve(rawPassValve)
    );
  }
}
