import { KeyValuePair } from 'types';
import { getClient } from '../config/cognitesdk';

export class ConfigService {
  async fetchDynamicDatasetsIds(
    dynamicDataSets: KeyValuePair
  ): Promise<KeyValuePair> {
    const configValues = { ...dynamicDataSets };
    const extIds = Object.values(configValues);
    const dataSetIdKeyPairs = this.swapKeyValues(configValues);
    const reshapedIds = extIds.map((extId) => {
      return { externalId: extId };
    });
    const dataSets = await getClient().datasets.retrieve(reshapedIds);
    dataSets.forEach((dataset) => {
      if (dataset.externalId) {
        const dataSetKey = dataSetIdKeyPairs[dataset.externalId as string];
        configValues[dataSetKey] = dataset.id;
      }
    });

    return configValues;
  }

  private swapKeyValues(dynamicDataSets: KeyValuePair): KeyValuePair {
    return Object.assign(
      {},
      ...Object.entries(dynamicDataSets).map(([a, b]) => ({ [b]: a }))
    );
  }
}
