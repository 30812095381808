/* eslint-disable no-empty-function */
/* eslint-disable no-param-reassign */
import { ModelFactory } from 'factories/model.factory';
import {
  AddWorkStepActionDTO,
  ConfirmDraftWorkStepDTO,
  Phase,
  PhaseType,
  WorkStep,
} from 'types';
import { sortArray } from 'utils/collection';
import { BuildDraftWorkstepCommand } from './commands/build-draft-workstep.command';
import { WorkstepsService } from './worksteps.service';

export class WorkstepsBuilderService {
  private buildDraftWorkstepCommand: BuildDraftWorkstepCommand;
  constructor(
    private workstepsService: WorkstepsService,
    private modelFactory: ModelFactory
  ) {
    this.buildDraftWorkstepCommand = new BuildDraftWorkstepCommand(
      this.modelFactory
    );
  }

  previewDraftWorkStep(phases: Phase[], dto: ConfirmDraftWorkStepDTO): Phase[] {
    const { workStep, phaseId, workStepActionSlug } = dto;
    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }
    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          const updatedWorkStep = { ...current };
          updatedWorkStep.isIgnored = false;
          if (updatedWorkStep.draftStepOptions) {
            updatedWorkStep.stepAction = updatedWorkStep.draftStepOptions.find(
              (action) => action.slug === workStepActionSlug
            );
          }

          if (workStep.linkedWorkStepId) {
            const linkedWorkStep =
              this.workstepsService.getWorkstepWithPredicate(
                phases,
                (ws) => ws.id === workStep.linkedWorkStepId
              );
            if (linkedWorkStep && linkedWorkStep.stepItem) {
              updatedWorkStep.stepItem = { ...linkedWorkStep.stepItem };
            }
          }

          // if (workStep.workStepType === 'missing') {
          //   updatedWorkStep.workStepType = phase.phaseType;
          // }
          return updatedWorkStep;
        }
      ),
    };

    return phases;
  }

  ignoreDraftWorkStep(phases: Phase[], dto: ConfirmDraftWorkStepDTO): Phase[] {
    const { workStep, phaseId } = dto;
    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }
    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          const updatedWorkStep = { ...current };
          updatedWorkStep.workStepType = 'missing';
          updatedWorkStep.isIgnored = true;
          return updatedWorkStep;
        }
      ),
    };

    return phases;
  }

  confirmDraftWorkStep(phases: Phase[], dto: ConfirmDraftWorkStepDTO): Phase[] {
    const { workStep, phaseId } = dto;
    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }
    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          const updatedWorkStep = { ...current };
          updatedWorkStep.isDraft = false;
          updatedWorkStep.isIgnored = false;
          if (updatedWorkStep.draftStepOptions) {
            delete updatedWorkStep.draftStepOptions;
          }

          if (workStep.workStepType === 'missing') {
            updatedWorkStep.workStepType = phase.phaseType;

            if (workStep.linkedWorkStepId) {
              const linkedWorkStep =
                this.workstepsService.getWorkstepWithPredicate(
                  phases,
                  (ws) => ws.id === workStep.linkedWorkStepId
                );
              if (linkedWorkStep && linkedWorkStep.stepItem) {
                updatedWorkStep.stepItem = { ...linkedWorkStep.stepItem };
              }
            }
          }
          return updatedWorkStep;
        }
      ),
    };

    return phases;
  }

  createDeIsolationWorkSteps(statePhases: Phase[]): Phase[] {
    const phases = this.filterIsolationPhasesOnly(statePhases);

    if (!phases || !phases.length) {
      return statePhases;
    }

    const lastPhase = phases[phases.length - 1];
    let lastPhasePosition = lastPhase.position;
    let lastWorkStepPosition = this.workstepsService.getNextPosition(phases);

    this.reverseIterateIsoPhases(phases, (isolationPhase) => {
      // eslint-disable-next-line no-plusplus
      lastPhasePosition++;

      const deIsolationPhase = this.createDeIsolationPhase(
        isolationPhase,
        lastPhasePosition
      );

      this.reverseIterateIsoWorkSteps(
        isolationPhase.workSteps,
        (workStep: WorkStep) => {
          const deIsolationWorkStep = this.buildDeIsolationWorkStep(
            workStep,
            lastWorkStepPosition
          );

          if (!deIsolationWorkStep) {
            return;
          }

          deIsolationPhase.workSteps.push(deIsolationWorkStep);
          // eslint-disable-next-line no-plusplus
          lastWorkStepPosition++;
        }
      );

      phases.push(deIsolationPhase);
    });

    return phases;
  }

  private createDeIsolationPhase(
    isolationPhase: Phase,
    lastPhasePosition: number
  ): Phase {
    const phase = this.modelFactory.createPhase(
      isolationPhase.name,
      lastPhasePosition,
      'deisolation',
      true
    );
    phase.linkedPhaseId = isolationPhase.id;
    return phase;
  }

  buildDeIsolationWorkStep(
    workStep: WorkStep,
    position: number,
    existingWorkStep?: WorkStep
  ): WorkStep {
    const draftWorkStep = this.buildDraftWorkstepCommand.execute({
      position,
      workStep,
      existingWorkStep: existingWorkStep || undefined,
    });

    return draftWorkStep;
  }

  addMissingStep(phases: Phase[], dto: AddWorkStepActionDTO): Phase[] {
    let targetPhaseIdx = phases.findIndex(
      (p) => p.linkedPhaseId === dto.phase.id
    );

    if (dto.phase.phaseType === 'deisolation') {
      targetPhaseIdx = phases.findIndex(
        (p) => p.id === dto.phase.linkedPhaseId
      );
    }

    if (!phases[targetPhaseIdx]) {
      return phases;
    }

    const curPhaseIdx = this.getPhaseIndex(phases, dto.phase.id);
    let newPosition = 0;
    let prevTargetWorkStepIdx = this.findFirstPrevWorkStepIndex(
      phases[curPhaseIdx],
      phases[targetPhaseIdx],
      dto
    );

    if (prevTargetWorkStepIdx === -1) {
      prevTargetWorkStepIdx = 0;

      newPosition = 0;
    } else {
      const prevTargetWorkStep = phases[targetPhaseIdx].workSteps[
        prevTargetWorkStepIdx
      ] as WorkStep;

      newPosition = prevTargetWorkStep.position;
    }

    const missingWorkStep = this.buildDeIsolationWorkStep(
      dto.workStep,
      newPosition
    ) as WorkStep;

    if (prevTargetWorkStepIdx === 0) {
      phases[targetPhaseIdx].workSteps.unshift(missingWorkStep);
    } else {
      phases[targetPhaseIdx] = this.workstepsService.addWorkStep(
        phases[targetPhaseIdx],
        missingWorkStep,
        prevTargetWorkStepIdx
      );
    }

    if (dto.phase.phaseType === 'deisolation') {
      const phaseIdx = this.getPhaseIndex(phases, dto.phase.id);
      phases[phaseIdx].workSteps = phases[phaseIdx].workSteps.map(
        (workStep) => {
          if (workStep.id === dto.workStep.id) {
            workStep.linkedWorkStepId = missingWorkStep.id;
          }
          return workStep;
        }
      );
    }

    return phases;
  }

  addMissingPhase(
    phases: Phase[],
    newPhase: Phase,
    phaseType: PhaseType
  ): void {
    const newPhaseIdx = phases.length;
    const newPhaseType =
      phaseType === 'isolation' ? 'deisolation' : 'isolation';
    const newPhasePosition =
      newPhaseType === 'deisolation' ? newPhase.position : -1;
    const missingPhase = this.modelFactory.createPhase(
      newPhase.name,
      newPhasePosition,
      newPhaseType
    );

    if (newPhaseType === 'deisolation') {
      missingPhase.linkedPhaseId = newPhase.id;
      missingPhase.deIsolation = true;
      phases.push(missingPhase);
    } else {
      phases.unshift(missingPhase);
      phases[newPhaseIdx].linkedPhaseId = phases[0].id;
    }
  }

  private findFirstPrevWorkStepIndex(
    phaseWithAddedStep: Phase,
    phaseWithMisingStep: Phase,
    dto: AddWorkStepActionDTO
  ): number {
    const prevWorkStepsIds = phaseWithAddedStep.workSteps
      .filter((workStep, idx) => {
        return (
          idx <= (dto.positionWithinPhase as number) &&
          workStep.id !== dto.workStep.id
        );
      })
      .map((workStep) => {
        if (dto.phase.phaseType === 'deisolation') {
          return workStep.linkedWorkStepId;
        }
        return workStep.id;
      })
      .reverse();

    let prevTargetWorkStepIdx = -1;

    for (let i = 0; i < prevWorkStepsIds.length; i++) {
      const prevWorkStepId = prevWorkStepsIds[i];

      if (dto.phase.phaseType === 'deisolation') {
        prevTargetWorkStepIdx = phaseWithMisingStep.workSteps.findIndex(
          (ws) => ws.id === prevWorkStepId
        );
      } else {
        prevTargetWorkStepIdx = phaseWithMisingStep.workSteps.findIndex(
          (ws) => ws.linkedWorkStepId === prevWorkStepId
        );
      }

      if (prevTargetWorkStepIdx !== -1) {
        break;
      }
    }

    return prevTargetWorkStepIdx;
  }

  private reverseIterateIsoPhases(
    isolationPhases: Phase[],
    callback: (phase: Phase) => void
  ): void {
    for (let i = isolationPhases.length - 1; i >= 0; i--) {
      const isolationPhase = isolationPhases[i];
      callback(isolationPhase);
    }
  }

  private reverseIterateIsoWorkSteps(
    workSteps: WorkStep[],
    callback: (workStep: WorkStep) => void
  ): void {
    const isolationWorkSteps = sortArray([...workSteps], 'position', 'desc');

    // eslint-disable-next-line no-loop-func
    isolationWorkSteps.forEach(callback);
  }

  private filterIsolationPhasesOnly(phases: Phase[]): Phase[] {
    return phases.filter((phase) => phase.phaseType === 'isolation');
  }

  private getPhaseIndex(phases: Phase[], phaseId: string): number {
    return this.workstepsService.findPhaseIndex(phases, phaseId);
  }
}
