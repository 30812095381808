import { WO_DATASET_ID, WO_TYPE, WO_VALID_TYPES } from 'config/apiConfig';
import { modelFactory } from 'factories';
import { WorkOrder } from 'types';
import { getClient } from '../config/cognitesdk';

export class WorkOrdersResourceService {
  async fetchWorkordersForAssetIds(
    assetIds: number[],
    client = getClient()
  ): Promise<WorkOrder[]> {
    const rawWorkorders = await client.events
      .list({
        filter: {
          assetIds,
          type: WO_TYPE,
          dataSetIds: [{ externalId: WO_DATASET_ID }],
          metadata: {
            StatusSet: 'false',
            isClosed: 'false',
          },
        },
      })
      .autoPagingToArray({ limit: -1 });

    const workorders = rawWorkorders
      .filter((workOrder) => {
        let shouldBeDisplayed = true;
        if (WO_VALID_TYPES && workOrder.subtype) {
          shouldBeDisplayed =
            WO_VALID_TYPES && WO_VALID_TYPES.includes(workOrder.subtype);
        }

        return shouldBeDisplayed;
      })
      .map((rawWorkorder) => modelFactory.mapToWorkOrder(rawWorkorder));

    const workordersSorted = workorders.sort((a, b) => a.endDate - b.endDate);

    return workordersSorted;
  }
}
