import { CogniteClient } from '@cognite/sdk';
import { AssetMappingType } from 'types';
import { EXCLUDED_ROOT_ASSETS, PROJECT_ROOT_ASSETS } from 'config/apiConfig';
import { modelFactory } from 'factories';
import { getClient } from '../config/cognitesdk';

export interface FetchAssetsSearchParams {
  userSearchQuery: string;
  userLocationChoice: string;
  client?: CogniteClient;
}

export class AssetsResourceService {
  async fetchRootAssets(client = getClient()): Promise<AssetMappingType[]> {
    const rawRootAssetsContent = await client.assets
      .list({
        filter: {
          parentExternalIds: PROJECT_ROOT_ASSETS,
        },
        limit: 1000,
      })
      .autoPagingToArray({ limit: -1 });

    const rootAssetsContent = rawRootAssetsContent
      .filter(
        (asset) =>
          // eslint-disable-next-line
          EXCLUDED_ROOT_ASSETS.indexOf(asset.externalId as string) === -1
      )
      .sort((a, b) => a.name.localeCompare(b.name))

      .map((assetEvent) => modelFactory.mapAssetsToType(assetEvent));

    return rootAssetsContent;
  }

  async fetchAssets(
    params: FetchAssetsSearchParams
  ): Promise<AssetMappingType[]> {
    const {
      userSearchQuery,
      userLocationChoice,
      client = getClient(),
    } = params;

    const userSearchQueryCaps = userSearchQuery.toUpperCase();

    const rawAssets = await client.assets.search({
      filter: {
        assetSubtreeIds: [{ externalId: userLocationChoice }],
      },
      limit: 100,
      search: {
        query: userSearchQueryCaps,
      },
    });

    const assets = rawAssets
      .sort((a, b) => (a.externalId?.length || 0) - (b.externalId?.length || 0))
      .map((assetEvent) => modelFactory.mapAssetsToType(assetEvent));

    return assets;
  }
}
