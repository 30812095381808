import { showErrorNotification } from 'utils/errors';
import { logger } from 'utils/logger';

export function withToastForError<Returned>(
  payloadCreator: () => Promise<Returned>
) {
  return async () => {
    try {
      return await payloadCreator();
    } catch (error) {
      showErrorNotification({ message: '', error });
      throw error; // throw error so createAsyncThunk will dispatch '/rejected'-action
    }
  };
}

export function withToastForErrorWithArgs<Args, Returned>(
  payloadCreator: (args?: Args) => Promise<Returned>
) {
  return async (args?: Args) => {
    try {
      return await payloadCreator(args);
    } catch (error) {
      showErrorNotification({ message: '', error });
      throw error; // throw error so createAsyncThunk will dispatch '/rejected'-action
    }
  };
}
export function withErrorAndEmptyResponseHandler<Args, Returned>(
  payloadCreator: (args: Args) => Promise<Returned>
) {
  return async (args: Args) => {
    const errorMsg = `Received empty response for ${args}!`;
    try {
      const results = await payloadCreator(args);

      if (!results) {
        logger(errorMsg, errorMsg, 'warning');
        return Promise.reject(errorMsg);
      }

      return results;
    } catch (err) {
      logger(err, errorMsg, 'warning');
      return Promise.reject(errorMsg);
    }
  };
}
