import React from 'react';
import { Flex } from '@cognite/cogs.js';
import { PnId } from 'types';
import { ReactComponent as Icon } from './pnidsIcon.svg';
import { Title, ProjectContainer, LogoArea, TextDetail } from '../elements';
import PnidInfo from './PnidInfo/PnidInfo';

type LinkedPnidsCardProps = {
  pnids: PnId[];
  pnidsNamesToUpdate: string[];
};

const LinkedPnids = ({ pnids, pnidsNamesToUpdate }: LinkedPnidsCardProps) => {
  return (
    <ProjectContainer>
      <LogoArea>
        <Icon style={{ marginRight: '6px' }} />
        <Title>Linked P&IDs</Title>
      </LogoArea>
      {pnids.length ? (
        <Flex
          direction="row"
          style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
        >
          {pnids.map((pnid) => {
            const needsUpdate = pnid.nameInCdf
              ? pnidsNamesToUpdate.includes(pnid.nameInCdf)
              : false;
            return (
              <PnidInfo
                key={`pnid-info-${pnid.id}`}
                pnid={pnid}
                needsUpdate={needsUpdate}
              />
            );
          })}
        </Flex>
      ) : (
        <TextDetail data-testid="no-pnids-text" style={{ marginLeft: '3px' }}>
          No P&IDs are connected to this procedure
        </TextDetail>
      )}
    </ProjectContainer>
  );
};

export default React.memo(LinkedPnids);
