import styled from 'styled-components/macro';

export const FilterBar = styled.div`
  background: #ededed;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const FilterInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
  padding-right: 10px;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const FilterActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FilterLabel = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 4px;
`;

export const Filter = styled.div`
  flex: 1;

  p + .cogs-checkbox {
    margin-top: 10px;
  }

  input {
    width: 100%;
  }
`;

export const FilterInputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 1200px) {
    & + & {
      margin-top: 10px;
    }
  }
`;
