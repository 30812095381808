import { FileInfo } from '@cognite/sdk';
import {
  HIGHLIGHTER_DATASET_ID,
  HIGHLIGHTER_FILE_PREFIX,
  HIGHLIGHTER_FILE_TYPE,
} from 'config/apiConfig';
import features from 'config/features';
import { cacheProvider } from 'services';
import { DrawingDTO } from 'types';
import { logger } from 'utils/logger';
import { getClient } from '../config/cognitesdk';

export class DrawingsResourceService {
  private emptyDrawingContent: string;
  constructor() {
    this.emptyDrawingContent = '{"lines":[],"width":"100%","height":"100%"}';
  }

  getDrawingId(pnidId: number | string, projectId: string): string {
    return `${HIGHLIGHTER_FILE_PREFIX}${pnidId}_${projectId}`;
  }

  getPnIdFromDrawingId(drawingId: string): string {
    const [pnid] = drawingId.replace(HIGHLIGHTER_FILE_PREFIX, '').split('_');
    return pnid;
  }

  getEmptyDrawingContent() {
    return this.emptyDrawingContent;
  }

  async getDrawingFromCDF(extId: string) {
    const fileDownloadUrl = await getClient()
      .files.getDownloadUrls([{ externalId: extId }])
      .then((response) => {
        return response;
      })
      .catch(() => []);

    if (!fileDownloadUrl[0]?.downloadUrl) {
      return this.createDrawing(extId, this.emptyDrawingContent);
    }

    const drawingContent = await getClient()
      .get<string>(fileDownloadUrl[0].downloadUrl)
      .then((data) => JSON.stringify(data.data));

    const drawing = this.createDrawing(extId, drawingContent);

    if (features.CACHE_PNIDS) {
      cacheProvider.setItem(extId, drawing);
    }

    return drawing;
  }

  async fetchDrawing(extId: string): Promise<DrawingDTO> {
    if (features.CACHE_PNIDS) {
      try {
        const cacheResponse = await cacheProvider.getItem(extId);
        if (cacheResponse) {
          return cacheResponse;
        }
        return this.getDrawingFromCDF(extId);
      } catch (err) {
        logger(err);
        return this.createDrawing(extId, this.emptyDrawingContent);
      }
    }
    // if you don't use cache feature fetch directly from cdf
    return this.getDrawingFromCDF(extId);
  }

  saveDrawing(
    pnidId: number,
    projectId: string,
    drawing: string,
    dataSetId?: number
  ) {
    const extId = this.getDrawingId(pnidId, projectId);

    return getClient()
      .files.upload(
        {
          externalId: extId,
          dataSetId,
          name: `${extId}.json`,
          mimeType: HIGHLIGHTER_FILE_TYPE,
          source: projectId,
          metadata: {
            associatedPnid: `${pnidId}`,
          },
        },
        drawing,
        true
      )
      .then(() => {
        const fullResponse = this.createDrawing(extId, drawing);
        cacheProvider.setItem(extId, fullResponse);
        return fullResponse;
      });
  }

  async getAllDrawingIdsForProcedure(projectExtId: string): Promise<string[]> {
    if (!projectExtId) {
      return [];
    }
    const allDrawingFiles = await getClient()
      .files.list({
        filter: {
          mimeType: HIGHLIGHTER_FILE_TYPE,
          dataSetIds: [{ id: HIGHLIGHTER_DATASET_ID }],
          source: projectExtId,
        },
      })
      .autoPagingToArray();

    if (!allDrawingFiles.length) {
      return [];
    }

    const drawingFileExternalIds = allDrawingFiles.reduce(
      (arr: string[], drawing: FileInfo) => {
        return drawing.externalId ? [...arr, drawing.externalId] : [...arr];
      },
      [] as string[]
    );

    return drawingFileExternalIds;
  }

  private createDrawing(externalId: string, content: string): DrawingDTO {
    return { content, externalId } as DrawingDTO;
  }
}
