import React from 'react';
import { StepExecutionDetails, StepExecutonStatus } from 'types';
import { WorkStepItemProps, WorkStepItem } from '../../Worksteps/WorkStepItem';
import { ExecutionWorkStepItemWrapper } from './elements';
import { ExecutionWorkStepItemActions } from './ExecutionWorkStepItemActions';
import { WorkstepExecutedInfo } from './WorkstepExecutedInfo';

export interface ExecWorkStepItemProps extends WorkStepItemProps {
  isWorkstepActive: boolean;
  isEnabled: boolean;
  actionsButtonsDisabled: boolean;
  onStepDone: (phaseId: string) => void;
  onStepChecked: (phaseId: string) => void;
  onUndoStep: (phaseId: string) => void;
  onUncheckStep: (phaseId: string) => void;
  viewOnly: boolean;
}
export const ExecWorkStepItem = (props: ExecWorkStepItemProps) => {
  const workStepStatus = props.workStep.execution?.getStatus();

  const isPendingExecution = (workStepStatus ===
    StepExecutonStatus.NotStarted) as boolean;

  const isExecuted = (workStepStatus ===
    StepExecutonStatus.Executed) as boolean;

  const isStepExecutedAndChecked =
    workStepStatus === StepExecutonStatus.Checked;

  let shouldStepBeSetAsActive = isPendingExecution || isExecuted;

  if (isStepExecutedAndChecked) {
    shouldStepBeSetAsActive = false;
  }

  const getClassName = () => {
    let className = props.isWorkstepActive ? 'active ' : ' ';

    if (!props.isEnabled) {
      className += 'not-enabled ';
    }

    return className;
  };
  const isVisibleActionButtons =
    (isExecuted || isPendingExecution) &&
    props.isWorkstepActive &&
    props.isEnabled;
  return (
    <div data-testid="exec-work-step-wrapper" className={getClassName()}>
      <ExecutionWorkStepItemWrapper>
        <WorkStepItem
          {...props}
          showWorkstepBackgroundAsActive={shouldStepBeSetAsActive}
          editable={false}
        >
          {!props.viewOnly && props.isEnabled && (
            <div data-testid="execution-workstep-actions">
              <ExecutionWorkStepItemActions
                isWorkStepActive={props.isWorkstepActive}
                isVisibleActionButtons={isVisibleActionButtons}
                onUncheckStep={props.onUncheckStep}
                phaseId={props.phaseId}
                isWorkstepDone={isExecuted}
                isWorkstepPendingExecution={isPendingExecution}
                actionsButtonsDisabled={props.actionsButtonsDisabled}
                onUndoStep={props.onUndoStep}
                onStepChecked={props.onStepChecked}
                onStepDone={props.onStepDone}
              />
            </div>
          )}
          {!isPendingExecution && props.isWorkstepActive && (
            <div data-testid="execution-workstep-executors-info">
              <WorkstepExecutedInfo
                execution={props.workStep.execution as StepExecutionDetails}
              />
            </div>
          )}
          {props.children}
        </WorkStepItem>
      </ExecutionWorkStepItemWrapper>
    </div>
  );
};
