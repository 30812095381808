import { Button, Icon } from '@cognite/cogs.js';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from 'redux/reducers/auth';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { RootState } from 'redux/store';
import { Phase, ProjectStatus } from 'types';
import {
  PhaseEnd,
  PhaseStart,
  PhasePosition,
  PhaseName,
  PhaseBlock,
  PhaseDivider,
  PhaseWrapper,
  PhaseType,
  PhaseApproved,
  StatusTag,
  PhaseApprovalWrapper,
  StatusTable,
  PhaseDisapproved,
} from './elements';
import { ExecWorkStepItem } from './ExecutionWorkStepItem';
import { toDisplayDate } from '../../../../../utils/date';

export type ExecutionPhasesProps = {
  projectStatus?: ProjectStatus;
  phases: Phase[];
  buttonsDisabled: boolean;
  viewOnly: boolean;
  onUndoStep: (phaseId: string) => void;
  onUncheckStep: (phaseId: string) => void;
  setExecutionModalOpen: (state: boolean) => void;
  setApprovalModalOpen: (state: boolean) => void;
  setUnApprovalModalOpen: (modelState: {
    isOpenModel: boolean;
    phaseId: string;
  }) => void;
  setIsModalInChooseCheckedState: (state: boolean) => void;
  setCurrentPhaseId: (state: string) => void;
};

export const ExecutionPhases = ({
  projectStatus,
  phases,
  buttonsDisabled,
  viewOnly,
  onUndoStep,
  onUncheckStep,
  setExecutionModalOpen,
  setIsModalInChooseCheckedState,
  setApprovalModalOpen,
  setUnApprovalModalOpen,
  setCurrentPhaseId,
}: ExecutionPhasesProps) => {
  const {
    active,
    setWorkStepAsActive,
    editWorkStep,
    deleteWorkStep,
    saveWorkStep,
    getActivePhaseId,
    countAllWorkStepsPhase,
    countAllWorkStepsPhaseChecked,
    countAllWorkStepsPhaseExecuted,
  } = useWorkSteps();

  const firstDeIsolationPhase = useMemo(() => {
    return phases.find((phase) => phase.phaseType === 'deisolation');
  }, [phases]);

  const { user } = useSelector<RootState, AuthState>((state) => state.auth);

  const openModal = (phaseId: string) => {
    setCurrentPhaseId(phaseId);
    setExecutionModalOpen(true);
  };

  const onStepDone = (phaseId: string) => {
    setIsModalInChooseCheckedState(false);
    openModal(phaseId);
  };

  const onStepChecked = (phaseId: string) => {
    setIsModalInChooseCheckedState(true);
    openModal(phaseId);
  };

  const onUnApprovePhase = (phase: Phase) => {
    setWorkStepAsActive(phase);
    setUnApprovalModalOpen({ phaseId: phase.id, isOpenModel: true });
  };

  const getPhaseEndContent = (phase: Phase) => {
    const activePhaseId = getActivePhaseId();

    if (phase.approvedBy) {
      return (
        <PhaseApprovalWrapper data-testid="phase-approved">
          <PhaseApproved>
            <Icon type="Checkmark" data-testid="approved-icon" size={12} />
            {` Approved`}
          </PhaseApproved>
          <div>
            <StatusTag>{`Supervisor: ${phase.approvedBy}`}</StatusTag>
            <StatusTag>
              {`on ${toDisplayDate(
                phase.approvedTime,
                'DD.MM.YYYY'
              )} ${toDisplayDate(phase.approvedTime, 'h:mm a')}`}
            </StatusTag>
          </div>
          <Button
            data-testid="phase-disapprove"
            type="destructive"
            disabled={viewOnly}
            onClick={() => onUnApprovePhase(phase)}
          >
            Disapprove
          </Button>
        </PhaseApprovalWrapper>
      );
    }
    if (phase.id === activePhaseId || phase.disapprovedBy) {
      const areAllStepsDone =
        countAllWorkStepsPhaseExecuted(phase.id) ===
        countAllWorkStepsPhase(phase.id);
      const areAllStepsCompleted =
        countAllWorkStepsPhaseChecked(phase.id) ===
        countAllWorkStepsPhase(phase.id);
      return (
        <>
          {phase.approvedTime} {phase.approvedBy}
          <StatusTable data-testid="phase-status">
            <tbody>
              <tr className={areAllStepsDone ? 'all-success' : undefined}>
                <td>{`${countAllWorkStepsPhaseExecuted(
                  phase.id
                )}/${countAllWorkStepsPhase(phase.id)}`}</td>
                <td>done</td>
              </tr>
              <tr className={areAllStepsCompleted ? 'all-success' : undefined}>
                <td>{`${countAllWorkStepsPhaseChecked(
                  phase.id
                )}/${countAllWorkStepsPhase(phase.id)}`}</td>
                <td>checked</td>
              </tr>
            </tbody>
          </StatusTable>
          {phase.disapprovedBy && (
            <>
              <PhaseDisapproved>
                <Icon type="EyeShow" data-testid="approved-icon" size={12} />
                {` Disapproved`}
              </PhaseDisapproved>
              <div>
                <StatusTag>{phase.disapprovedBy}</StatusTag>
                <StatusTag>
                  {`on ${toDisplayDate(
                    phase.approvedTime,
                    'DD.MM.YYYY'
                  )} ${toDisplayDate(phase.approvedTime, 'h:mm a')}`}
                </StatusTag>
              </div>
            </>
          )}
          <Button
            data-testid="phase-approve"
            type="primary"
            disabled={!areAllStepsCompleted || viewOnly}
            onClick={() => setApprovalModalOpen(true)}
          >
            Approve
          </Button>
        </>
      );
    }

    const activePhaseIndex = phases?.findIndex((ph) => ph.id === activePhaseId);
    if (activePhaseIndex < phases?.indexOf(phase) && !phase.disapprovedBy) {
      return (
        <div>
          <StatusTag>Not started</StatusTag>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {phases.map((phase) => (
        <Fragment key={phase.id}>
          {phase.position === 0 ? (
            <PhaseType data-testid="isolation-indicator">Isolation</PhaseType>
          ) : null}
          {phase.id === firstDeIsolationPhase?.id ? (
            <PhaseType data-testid="deisolation-indicator">
              Deisolation
            </PhaseType>
          ) : null}
          <PhaseWrapper
            data-testid={`phase-${phase.position}`}
            className={phase.id === getActivePhaseId() ? 'active' : ''}
          >
            <PhaseBlock>
              <PhaseStart>
                <PhasePosition data-testid="phase-position-start">
                  Phase {phase.position + 1} - Start
                </PhasePosition>
                <PhaseName data-testid="phase-name-start">
                  {phase.name}
                </PhaseName>
              </PhaseStart>

              {phase.workSteps.map((workStep) => {
                if (
                  (projectStatus === 'execution' ||
                    projectStatus === 'executionReady') &&
                  workStep.isDraft
                ) {
                  return null;
                }
                return (
                  <ExecWorkStepItem
                    onUncheckStep={onUncheckStep}
                    key={workStep.id}
                    isWorkstepActive={workStep.id === active.workStep?.id}
                    isEnabled={phase.id === getActivePhaseId()}
                    user={user}
                    active={active}
                    workStep={workStep}
                    editable={false}
                    projectStatus={projectStatus}
                    phaseId={phase.id}
                    onSelectWorkstep={setWorkStepAsActive}
                    onStepDone={onStepDone}
                    onStepChecked={onStepChecked}
                    onUndoStep={onUndoStep}
                    onEditWorkstep={editWorkStep}
                    onDeleteWorkstep={deleteWorkStep}
                    onSaveWorkstep={saveWorkStep}
                    actionsButtonsDisabled={buttonsDisabled}
                    viewOnly={viewOnly}
                  />
                );
              })}
              <PhaseEnd>
                <PhasePosition data-testid="phase-position-end">
                  Phase {phase.position + 1} - End
                </PhasePosition>
                <PhaseName data-testid="phase-name-end">{phase.name}</PhaseName>
                {getPhaseEndContent(phase)}
              </PhaseEnd>
            </PhaseBlock>
          </PhaseWrapper>
          {
            // Don't show on deisolation and complete execution step
            firstDeIsolationPhase &&
              firstDeIsolationPhase.position - 1 !== phase.position &&
              phases.indexOf(phase) + 1 !== phases.length && <PhaseDivider />
          }
        </Fragment>
      ))}
    </>
  );
};
