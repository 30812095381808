import { Resolved, Role, WorkStep } from 'types';

export const hasReviewerCommentsAfterResolved = (workStep: WorkStep) => {
  const reviewerComments = workStep.review?.comments
    ?.filter((comment) => comment.role === 'reviewer')
    .sort((a, b) => b.date - a.date);
  return (
    reviewerComments?.length &&
    workStep.review?.resolved?.compiler?.date &&
    reviewerComments[0].date > workStep.review.resolved.compiler.date
  );
};

export const createResolvedComments = (resolved?: Resolved) => {
  const resolvedComments = [];
  if (resolved?.compiler) {
    resolvedComments.push({
      id: 'tempMessageCompiler',
      commentContent: 'Marked as resolved',
      author: resolved?.compiler.name,
      role: 'compiler' as Role,
      date: resolved?.compiler.date,
      metaData: {
        commentType: 'resolve',
      },
    });
  }
  if (resolved?.reviewer) {
    resolvedComments.push({
      id: 'tempMessageReviewer',
      commentContent: 'Confirmed resolution',
      author: resolved?.reviewer.name,
      role: 'reviewer' as Role,
      date: resolved?.reviewer.date,
      metaData: {
        commentType: 'resolve',
      },
    });
  }
  return resolvedComments;
};
