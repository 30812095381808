import styled from 'styled-components/macro';

export const SearchPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  padding-top: 15px;
  width: calc(100% - 50px);
  margin: auto;

  .cogs-menu-divider {
    width: 80%;
    background: var(--cogs-greyscale-grey3);
  }

  .search-page-tabs {
    background: transparent;
    .rc-tabs-tab:focus-within {
      box-shadow: none;
    }
  }
`;
