import styled from 'styled-components/macro';
import { Button as ButtonCogs } from '@cognite/cogs.js';

export const Button = styled(ButtonCogs)`
  display: inline-flex;
  align-items: center;
  color: var(--cogs-greyscale-grey10);
  font-size: 12px !important;
  padding: 0px !important;
  background: var(--cogs-surface--muted) !important;
  font-weight: 500;
  border: none;
  &.cogs-btn-unstyled,
  &.cogs-btn-unstyled:hover,
  &.cogs-btn-unstyled:focus,
  &.cogs-btn-unstyled:active {
    border: none;
    color: var(--cogs-greyscale-grey10);
    font-size: 12px !important;
    font-weight: 500;
    display: inline-flex;
    box-shadow: none;
  }
`;
