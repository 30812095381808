import React, { useCallback } from 'react';
import {
  ActiveWorkStep,
  ConfirmDraftWorkStepDTO,
  WorkStep,
  WorkStepAction,
} from 'types';
import { MultiChoiceDraftWorkStepItemBox } from './elements';

export interface DraftWorkStepItemProps {
  active: ActiveWorkStep;
  phaseId: string;
  workStep: WorkStep;
  onWorkStepSelected: (workStep: WorkStep) => void;
  onPreviewWorkStep: (dto: ConfirmDraftWorkStepDTO) => void;
  children?: any;
  disabled?: boolean;
}

export const MultiChoiceDraftWorkStepItem = React.memo(
  ({
    active,
    phaseId,
    workStep,
    onWorkStepSelected,
    onPreviewWorkStep,
    children,
    disabled,
  }: DraftWorkStepItemProps) => {
    const onSelect = useCallback(
      () => {
        onWorkStepSelected(workStep);
      },
      // eslint-disable-next-line
      [workStep]
    );

    const previewWorkStep = useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        onPreviewWorkStep({
          phaseId,
          workStep,
          workStepActionSlug: event.currentTarget.value,
        });
      },
      // eslint-disable-next-line
      [workStep]
    );

    const isActive = active.workStep?.id === workStep.id;

    const draftStepOptions = workStep.draftStepOptions as WorkStepAction[];

    const missingStepClass =
      workStep.workStepType === 'missing' ? 'border-less' : '';

    return (
      <MultiChoiceDraftWorkStepItemBox
        className={isActive ? `active ${missingStepClass}` : missingStepClass}
        data-wsid={`work-step-item-${workStep.id}`}
        data-testid={`multi-choice-workstep-${workStep.id}`}
        onClick={onSelect}
      >
        <select
          data-testid="workstep-multiple-choices"
          className="cogs-input cogs-input-no-border cogs-input-tiny workstep-multiple-choices-select"
          title="Several alternatives"
          onChange={previewWorkStep}
          disabled={disabled}
        >
          <option key="option-default" hidden value="">
            Several alternatives
          </option>
          {draftStepOptions.map((item) => {
            return (
              <option
                key={item.slug}
                data-testid={`type-option-${item.slug}`}
                value={item.slug}
              >
                {item.text}
              </option>
            );
          })}
        </select>
        <div>{children}</div>
      </MultiChoiceDraftWorkStepItemBox>
    );
  }
);
