/* eslint-disable no-empty-function */
/* eslint-disable no-param-reassign */
import { ModelFactory } from 'factories/model.factory';
import { WorkStep, WorkStepAction } from 'types';
import { ActionsList, actionsMappings } from 'utils/test/actions';

export interface BuildDraftWorkstepCommandDTO {
  workStep: WorkStep;
  position: number;
  existingWorkStep?: WorkStep;
}

/**
 * Remove Workstep Command
 * Extracted logic for deleting worksteps used in workstep reducer
 */
export class BuildDraftWorkstepCommand {
  private isolationDeIsolationMapping: any = actionsMappings;

  constructor(private modelFactory: ModelFactory) {}

  execute(dto: BuildDraftWorkstepCommandDTO): WorkStep {
    const { workStep, position } = dto;
    const deIsolationWorkStep =
      dto.existingWorkStep ||
      this.modelFactory.createNewFromExistingWorkStep(workStep);

    deIsolationWorkStep.position = position;
    deIsolationWorkStep.workStepType = 'deisolation';
    deIsolationWorkStep.isDraft = true;
    deIsolationWorkStep.linkedWorkStepId = workStep.id;

    if (!deIsolationWorkStep.stepAction) {
      deIsolationWorkStep.workStepType = 'missing';
      return deIsolationWorkStep;
    }

    let stepActionSlug = deIsolationWorkStep.stepAction?.slug as string;

    if (!stepActionSlug) {
      stepActionSlug = this.createSlug(
        deIsolationWorkStep.stepAction?.template as string
      );
    }

    deIsolationWorkStep.linkedWorkStepSlug = stepActionSlug;

    const subItemType = deIsolationWorkStep.stepItem?.type?.type as string;

    if (this.hasNoStepItemOrActionMatched(subItemType, stepActionSlug)) {
      // try looking the oposite, maybe it is de-isolation step
      if (this.isolationDeIsolationMapping[subItemType]) {
        return this.mapStepActionFromDeIsolationStep(
          deIsolationWorkStep,
          subItemType,
          stepActionSlug
        );
      }
      deIsolationWorkStep.workStepType = 'missing';
      // eslint-disable-next-line
      return deIsolationWorkStep;
    }

    return this.findMapDeIsolationStepAction(
      deIsolationWorkStep,
      subItemType,
      stepActionSlug
    );
  }

  private findMapDeIsolationStepAction(
    deIsolationWorkStep: WorkStep,
    subItemType: string,
    stepActionSlug: string
  ): WorkStep {
    const deIsolationAction =
      this.isolationDeIsolationMapping[subItemType][stepActionSlug];

    if (deIsolationAction.length === 1) {
      deIsolationWorkStep.stepAction = this.findWorkStepAction(
        ActionsList,
        deIsolationAction[0]
      );
    } else {
      delete deIsolationWorkStep.stepAction;
      deIsolationWorkStep.draftStepOptions = deIsolationAction.map(
        (slug: string) => {
          return this.findWorkStepAction(ActionsList, slug);
        }
      );
    }

    return deIsolationWorkStep;
  }

  private mapStepActionFromDeIsolationStep(
    deIsolationWorkStep: WorkStep,
    subItemType: string,
    stepActionSlug: string
  ): WorkStep {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.isolationDeIsolationMapping[subItemType]) {
      if (
        // eslint-disable-next-line
        this.isolationDeIsolationMapping[subItemType][key].indexOf(
          stepActionSlug
        ) !== -1
      ) {
        stepActionSlug = key;
        deIsolationWorkStep.workStepType = 'missing';
        deIsolationWorkStep.stepAction = this.findWorkStepAction(
          ActionsList,
          stepActionSlug
        );
      }
    }
    return deIsolationWorkStep;
  }

  private hasNoStepItemOrActionMatched(
    subItemType: string,
    stepActionSlug: string
  ): boolean {
    return (
      !subItemType ||
      !this.isolationDeIsolationMapping[subItemType] ||
      !this.isolationDeIsolationMapping[subItemType][stepActionSlug]
    );
  }

  private findWorkStepAction(
    actionsList: WorkStepAction[],
    slug: string
  ): WorkStepAction {
    return actionsList.find((action) => action.slug === slug) as WorkStepAction;
  }

  private createSlug(template: string, separator = '-') {
    // eslint-disable-next-line
    // prettier-ignore
    const search = /\[item\]|\[class\]|\[type\]|\[floc\]|\[line\]/gi;
    const slug = template.replace(search, '');

    return slug
      .toString()
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, separator);
  }
}
