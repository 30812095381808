import React from 'react';
import QRCode from 'react-qr-code';

import { getQrCodeLink } from '../Qrcodes.utils';

export type ProcedureTemplateProps = {
  certificateNumber?: string;
};
const ProcedureTemplate = (
  { certificateNumber }: ProcedureTemplateProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div className="page-template" ref={ref}>
      <QRCode value={getQrCodeLink()} viewBox="0 0 256 256" />
      {certificateNumber && <span>{`IC : ${certificateNumber}`}</span>}
    </div>
  );
};

export default React.forwardRef(ProcedureTemplate);
