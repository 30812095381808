import React from 'react';
import { Icon } from '@cognite/cogs.js';
import { Project } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { toDisplayDate } from 'utils/date';
import {
  Title,
  TextDetail,
  ProjectContainer,
  LogoArea,
  LinkButton,
} from '../elements';
import { LogoSpan } from './elements';
import LinkedApprovedProcedure from './LinkedApprovedProcedure';

type AboutProps = {
  project: Project;
  viewOnly?: boolean;
};

const About = ({ project, viewOnly }: AboutProps) => {
  const procedureUrl = window?.location?.href.split('?')[0];
  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Warning"
            style={{ color: '#333333', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>About</Title>
      </LogoArea>
      {!project.id && (
        <div style={{ display: 'block', overflow: 'hidden', height: '180px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}

      {project.id && (
        <div style={{ width: '100%' }}>
          <TextDetail>
            Procedure ID:{' '}
            <LinkButton
              unstyled
              onClick={() => {
                navigator.clipboard.writeText(project.id || ' ');
              }}
            >
              {project.id?.split('-')[0]}&hellip; [click to copy]
            </LinkButton>
          </TextDetail>
          <TextDetail>
            Procedure URL:{' '}
            <LinkButton
              unstyled
              onClick={() => {
                navigator.clipboard.writeText(`${procedureUrl}?viewOnly=true`);
              }}
            >
              [click to copy]
            </LinkButton>
          </TextDetail>
          <TextDetail>
            Asset: {project.asset?.externalId?.split('.')[2]}
          </TextDetail>
          <TextDetail>
            FLOC: {project.asset?.externalId?.split('.').slice(-1)[0]}
          </TextDetail>
          <TextDetail>Compiler: {project.compiler}</TextDetail>

          <TextDetail>Due date: {project.due}</TextDetail>
          <TextDetail>
            Date planning started:{' '}
            {toDisplayDate(project.created as number, 'DD.MM.YYYY')}
          </TextDetail>
          {project.status === 'execution' ? (
            <>
              {project.isolated?.name && (
                <TextDetail>Isolator: {project.isolated?.name}</TextDetail>
              )}
              {project.isolated?.date && (
                <TextDetail>
                  Last Isolation date:
                  {project.isolated?.date &&
                    ` ${toDisplayDate(
                      project.isolated?.date,
                      'DD.MM.YYYY'
                    )}`}{' '}
                </TextDetail>
              )}
            </>
          ) : (
            <>
              {project.reviewed?.name && (
                <TextDetail>reviewer: {project.reviewed?.name}</TextDetail>
              )}
              {project.reviewed?.date && (
                <TextDetail>
                  Reviewed date:
                  {project.reviewed?.date &&
                    ` ${toDisplayDate(
                      project.isolated?.date,
                      'DD.MM.YYYY'
                    )}`}{' '}
                </TextDetail>
              )}
            </>
          )}
          <TextDetail>
            Date planning completed:
            {project.reviewed?.date &&
              ` ${toDisplayDate(project.reviewed?.date, 'DD.MM.YYYY')}`}
          </TextDetail>
          <TextDetail>
            Date execution started:
            {!!project.executionStarted &&
              ` ${toDisplayDate(project.executionStarted, 'DD.MM.YYYY')}`}
          </TextDetail>
          <TextDetail>Date execution completed: {project.completed}</TextDetail>
          <LinkedApprovedProcedure
            copyOfApprovedProcedure={project.copyOfApprovedProcedure}
            viewOnly={viewOnly}
          />
        </div>
      )}
    </ProjectContainer>
  );
};

export default React.memo(About, (propsOld, propsNew) => {
  /* eslint-disable */
  return (
    propsOld.project.id === propsNew.project.id &&
    propsOld.project.compiler === propsNew.project.compiler &&
    propsOld.project.created === propsNew.project.created &&
    propsOld.project.copyOfApprovedProcedure ===
      propsNew.project.copyOfApprovedProcedure
  );
});
