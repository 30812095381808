import { Colors, Icon } from '@cognite/cogs.js';
import styled from 'styled-components/macro';

export const PhaseContainerWrapper = styled.div<{
  deisolation?: boolean;
}>`
  text-align: start;
  margin-bottom: 14px;
  width: 100%;
  padding: ${(props) =>
    props.deisolation ? '12px 20px 12px 8px' : '12px 8px 12px 20px'};
  color: var(--cogs-greyscale-grey9);
  background-color: var(--cogs-greyscale-grey1);
  border-radius: 6px;

  .phase-wrapper-outer {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
  }

  .phase-name-wrapper {
    display: flex;
    align-items: center;
  }

  .phase-name-label {
    flex-grow: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-left: 8px;
  }

  .edit-phase-name-btn {
    outline: none;
  }

  .phase-wrapper-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .delete-phase-icon {
    margin-left: 5px;
    color: var(--cogs-greyscale-grey5);

    &:hover {
      color: var(--cogs-greyscale-grey6);
    }
  }
`;

export const PhaseToggleIcon = styled(Icon)`
  display: flex;
  color: ${Colors['text-icon--muted']};
  margin-left: 14px;
`;
