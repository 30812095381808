import React, { useRef } from 'react';
import { Flex, Icon } from '@cognite/cogs.js';

import {
  ProjectContainer,
  Title,
  LogoArea,
} from 'pages/Project/components/elements';
import { Phase } from '../../../../types';

import ProcedureTemplate from './procedureTemplate';
import WorkStepTemplate from './workstepTemplate';
import PrintButton from './PrintButton';
import { LogoSpan } from './elements';

export type QrCodesProps = {
  phases: Phase[];
  projectName: string;
  certificateNumber?: string;
};

const QrCodes = ({ phases, projectName, certificateNumber }: QrCodesProps) => {
  const qrCodeRef = useRef(null);
  const qrCodeWorkStepOnlyTagRef = useRef(null);

  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Print"
            style={{ color: 'var(--cogs-yellow-grey9)', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>Print QR-codes</Title>
      </LogoArea>

      <div>Print here you needed qr-codes</div>

      <Flex justifyContent="end" style={{ width: '100%' }}>
        <Flex gap={5}>
          <PrintButton
            contentRef={qrCodeRef}
            label="Print procedure"
            buttonDataTestId="print-qrcode-procedure"
            projectTitle={projectName}
          />
          <PrintButton
            contentRef={qrCodeWorkStepOnlyTagRef}
            label="Print only tags"
            buttonDataTestId="print-qrcode-tags"
            projectTitle={projectName}
            disabled={phases.length === 0}
          />
        </Flex>
      </Flex>

      {/* templates for qr codes, need to be rendered */}
      <div style={{ display: 'none' }}>
        <ProcedureTemplate
          ref={qrCodeRef}
          certificateNumber={certificateNumber}
        />
        <WorkStepTemplate
          ref={qrCodeWorkStepOnlyTagRef}
          phases={phases}
          certificateNumber={certificateNumber}
          onlyTags
        />
      </div>
    </ProjectContainer>
  );
};

export default QrCodes;
