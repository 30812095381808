/* eslint-disable no-param-reassign */
import {
  BaseWorkstepsActionDTO,
  Phase,
  SetWorkstepAsCheckedActionDTO,
  SetWorkstepAsDoneActionDTO,
  SetWorkstepStoppedExecutionActionDTO,
  StepExecutionDetails,
} from 'types';
import { WorkstepsService } from './worksteps.service';

export class ProjectExecutionService {
  // eslint-disable-next-line no-empty-function
  constructor(private workstepsService: WorkstepsService) {}

  setWorkstepAsDone(phases: Phase[], dto: SetWorkstepAsDoneActionDTO): Phase[] {
    const { workStep, phaseId, doneBy, doneTime } = dto;
    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }

    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          return {
            ...current,
            execution: Object.assign(
              Object.create(StepExecutionDetails.prototype),
              current.execution,
              {
                doneBy,
                doneTime,
              }
            ),
          };
        }
      ),
    };

    return phases;
  }

  undoWorkstepAsDone(phases: Phase[], dto: BaseWorkstepsActionDTO): Phase[] {
    const { workStep, phaseId } = dto;

    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }

    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          return {
            ...current,
            execution: Object.assign(
              Object.create(StepExecutionDetails.prototype),
              StepExecutionDetails.notStartedWithExecution()
            ),
          };
        }
      ),
    };

    return phases;
  }

  undoCheckedWorkstepAsUncheck(
    phases: Phase[],
    dto: BaseWorkstepsActionDTO
  ): Phase[] {
    const { workStep, phaseId } = dto;

    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }

    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          return {
            ...current,
            execution: Object.assign(
              Object.create(StepExecutionDetails.prototype),
              StepExecutionDetails.uncheckedStepWithExecution(workStep)
            ),
          };
        }
      ),
    };

    return phases;
  }

  setWorkstepAsChecked(
    phases: Phase[],
    dto: SetWorkstepAsCheckedActionDTO
  ): Phase[] {
    const { workStep, phaseId, checkedBy, checkedTime } = dto;
    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }

    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          return {
            ...current,
            execution: Object.assign(
              Object.create(StepExecutionDetails.prototype),
              current.execution,
              {
                checkedBy,
                checkedTime,
              }
            ),
          };
        }
      ),
    };

    return phases;
  }

  setWorkstepStoppedExecution(
    phases: Phase[],
    dto: SetWorkstepStoppedExecutionActionDTO
  ): Phase[] {
    const { workStep, phaseId, executionStoppedTime } = dto;

    const phaseIndex = this.getPhaseIndex(phases, phaseId);

    if (phaseIndex === -1) {
      return phases;
    }

    const phase = phases[phaseIndex];
    phases[phaseIndex] = {
      ...phase,
      workSteps: this.workstepsService.updateWorkstepMatchIdPosition(
        phase.workSteps,
        workStep,
        (current) => {
          return {
            ...current,
            execution: Object.assign(
              Object.create(StepExecutionDetails.prototype),
              current.execution,
              {
                executionStopped: true,
                executionStoppedTime,
              } as StepExecutionDetails
            ),
          };
        }
      ),
    };

    return phases;
  }

  private getPhaseIndex(phases: Phase[], phaseId: string): number {
    return this.workstepsService.findPhaseIndex(phases, phaseId);
  }
}
