import { Button, Popconfirm } from '@cognite/cogs.js';
import React from 'react';
import { Formik } from 'formik';
import { Project } from 'types';
import TextArea from 'shared/TextArea/TextArea';
import { Row } from 'containers/Grid';

export type Props = {
  project: Project;
  saveProject: Function;
};

export const ProjectInfo = ({ project, saveProject }: Props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        objectives: project.objectives || '',
        method: project.method || '',
      }}
      onSubmit={(values) => {
        saveProject({ ...project, ...values });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Row direction="column" margin="10px 0px">
              <TextArea
                id="objectives"
                name="objectives"
                title="Objectives"
                data-testid="projectObjectives"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.objectives}
                error={undefined}
              />
            </Row>
            <Row direction="column" margin="10px 0px">
              <TextArea
                id="method"
                name="method"
                title="Method"
                data-testid="projectMethod"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.method}
                error={undefined}
              />
            </Row>
            <Row display="flex" direction="row" justify-content="flex-end">
              <Popconfirm
                onConfirm={() => {
                  resetForm({
                    values: {
                      objectives: '',
                      method: '',
                    },
                  });
                }}
                content="You cannot undo this. Are you sure?"
                okText="Yes, clear"
              >
                <Button
                  type="tertiary"
                  data-testid="projectInfoClear"
                  htmlType="reset"
                  title="Save"
                  style={{ marginRight: '5px' }}
                >
                  Clear
                </Button>
              </Popconfirm>
              <button
                className="cogs-btn cogs-btn-secondary"
                type="submit"
                data-testid="projectInfoSave"
              >
                Save
              </button>
            </Row>
          </div>
        </form>
      )}
    </Formik>
  );
};
