/* eslint-disable no-unused-expressions */

import { PhaseType } from 'types';

export const useWorkStepsScrollAlign = () => {
  const SCROLL_OFFSET_TOP = 21;
  const TRESHOLD_TIME_TO_UPDATE_UI = 300;
  const PHASE_NAME_PANEL_HEIGHT = 40;

  return {
    scrollToWorkStep,
    scrollToFirstEl: scrollToFirstElement,
    hasWorkStepsInList,
    scrollAndClickWorkStep,
    scrollToEditableWorkStep,
  };

  function scrollToWorkStep(elIdToScroll: string, type: PhaseType) {
    // wait to update the workstep, activate...etc
    // this will update the UI
    setTimeout(() => {
      doTheScrolling(elIdToScroll, type);
    }, TRESHOLD_TIME_TO_UPDATE_UI);
  }

  function scrollToFirstElement(listName: string) {
    const workStepsNodes = getWorkStepsForList(listName);

    if (workStepsNodes.length) {
      const workStepId = getAttr(
        workStepsNodes[0] as HTMLDivElement,
        'data-workstep-id'
      );
      scrollToWorkStep(workStepId, 'isolation');
    }
  }

  function hasWorkStepsInList(listName: string): boolean {
    return getWorkStepsForList(listName).length > 0;
  }

  function scrollAndClickWorkStep(workStepId: string) {
    const workStepEl = elBy(workStepId, 'wsid');

    if (workStepEl) {
      setTimeout(() => {
        workStepEl.click();
      }, TRESHOLD_TIME_TO_UPDATE_UI);
    }
  }

  function scrollToEditableWorkStep() {
    setTimeout(() => {
      const activeEditableWorkStep = document.querySelector(
        '[data-editable="true"]'
      ) as HTMLDivElement;
      if (activeEditableWorkStep !== null) {
        const newWorkStepId = getAttr(activeEditableWorkStep, 'data-id');
        elBy(newWorkStepId, 'wsid').click();
      }
    }, TRESHOLD_TIME_TO_UPDATE_UI);
  }

  function doTheScrolling(elIdToScroll: string, type: PhaseType) {
    // This should work only if there are de-isolation worksteps
    // Find the de-isolation workstep first
    const deIsolationWorkstepEl = getDeIsolationWorkStepEl(elIdToScroll, type);
    if (!deIsolationWorkstepEl) {
      return;
    }

    const deIsolationWorkStepId = getAttr(deIsolationWorkstepEl, 'data-id');
    const isolationWorkStepId = getAttr(
      deIsolationWorkstepEl,
      'data-linked-id'
    );

    // Find the isolation workstep
    const isolationWorkStepEl = elBy(isolationWorkStepId, 'workstep-id');
    if (!isolationWorkStepEl) {
      return;
    }

    // Get the Isolation Sidebar Height and calculate the top offset
    const isolationSideBarHeight = elBy(
      'workstepList',
      'worksteps-list-container-id'
    ).offsetHeight;

    const deIsolationOffsetTop = calculateTopOffset(deIsolationWorkstepEl);
    const isolationOffsetTop = calculateTopOffset(isolationWorkStepEl);

    let scrollOffset = SCROLL_OFFSET_TOP;

    // takes in calculation the height of the phase name panel
    // in cases when the phase name is very long
    if (
      elBy(elIdToScroll, 'workstep-id').closest('.phase-container-wrapper') &&
      (
        elBy(elIdToScroll, 'workstep-id').closest(
          '.phase-container-wrapper'
        ) as HTMLDivElement
      ).querySelector('.phase-wrapper-outer')
    ) {
      const isolationPhaseContainer = elBy(
        isolationWorkStepId,
        'workstep-id'
      ).closest('.phase-container-wrapper') as HTMLDivElement;

      const deisolationPhaseContainer = elBy(
        deIsolationWorkStepId,
        'workstep-id'
      ).closest('.phase-container-wrapper') as HTMLDivElement;

      // @ts-ignore
      const isoPhaseNameHeight = isolationPhaseContainer.querySelector(
        '.phase-wrapper-outer'
      ).clientHeight;

      // @ts-ignore
      const deisoPhaseNameHeight = deisolationPhaseContainer.querySelector(
        '.phase-wrapper-outer'
      ).clientHeight;

      if (isoPhaseNameHeight !== deisoPhaseNameHeight) {
        scrollOffset =
          isoPhaseNameHeight - deisoPhaseNameHeight + SCROLL_OFFSET_TOP;
      }
    }

    // calculate the height needed to align the worksteps
    const height =
      isolationSideBarHeight +
      deIsolationOffsetTop -
      isolationOffsetTop -
      scrollOffset;

    const heightInPixels = `${height}px`;

    const deworkstepsList = elBy(
      'deisolationWorkstepList',
      'worksteps-list-container-id'
    )?.parentNode as HTMLDivElement;

    const scrollerPanel = document.querySelector(
      '.worksteps-sidebar-content-wrapper'
    ) as HTMLDivElement;

    const paddingTop =
      height > isolationSideBarHeight
        ? height - isolationSideBarHeight + PHASE_NAME_PANEL_HEIGHT
        : 0;

    scrollerPanel.style.transform = `translateY(${paddingTop}px)`;
    deworkstepsList.style.transform = `translateY(-${heightInPixels})`;

    document.querySelectorAll('.highlighted').forEach((node) => {
      node.classList.remove('highlighted');
    });

    // Find the inner (template) step box for linked step
    // and mark it highlighted
    const linkedWorkStepStepBox = elBy(
      type === 'deisolation' ? isolationWorkStepId : deIsolationWorkStepId,
      'wsid'
    );
    if (linkedWorkStepStepBox) {
      linkedWorkStepStepBox?.classList.add('highlighted');
    }

    // Finally align the worksteps
    setTimeout(() => {
      // The best way here would be to detect if the step is in the viewport or not
      deIsolationWorkstepEl.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }, 200);
  }

  function getDeIsolationWorkStepEl(elIdToScroll: string, type: PhaseType) {
    if (type === 'deisolation') {
      return elBy(elIdToScroll, 'workstep-id');
    }
    return elBy(elIdToScroll, 'linked-id');
  }

  function calculateTopOffset(workStepEl: HTMLDivElement): number {
    let { offsetTop } = workStepEl;
    let currentPhaseFound = false;

    // find parent phase element
    const currentPhase = workStepEl.closest(
      '.phase-container-wrapper'
    ) as HTMLDivElement;

    // iterate all elements and calculate the height of each one
    // eslint-disable-next-line
    (currentPhase.parentNode as HTMLDivElement)
      .querySelectorAll('.phase-container-wrapper')
      .forEach((phase) => {
        if (
          !currentPhaseFound &&
          phase.getAttribute('data-phaseid') !==
            currentPhase.getAttribute('data-phaseid')
        ) {
          // add margin bottom as well
          offsetTop += phase.clientHeight + 14;
        }

        if (
          phase.getAttribute('data-phaseid') ===
          currentPhase.getAttribute('data-phaseid')
        ) {
          currentPhaseFound = true;
        }
      });

    return offsetTop;
  }

  function elBy(
    elId: string,
    findBy:
      | 'worksteps-list-container-id'
      | 'workstep-id'
      | 'id'
      | 'linked-id'
      | 'wsid'
      | 'phaseid'
  ): HTMLDivElement {
    let selector;
    switch (findBy) {
      default:
      case 'worksteps-list-container-id':
        selector = `[data-worksteps-list-container-id="${elId}"]`;
        break;
      case 'workstep-id':
        selector = `[data-workstep-id="${elId}"]`;
        break;
      case 'id':
        selector = `[data-id="${elId}"]`;
        break;
      case 'linked-id':
        selector = `[data-linked-id="${elId}"]`;
        break;
      case 'wsid':
        selector = `[data-wsid="work-step-item-${elId}"]`;
        break;
      case 'phaseid':
        selector = `[data-phaseid="${elId}"]`;
        break;
    }
    return document.querySelector(selector) as HTMLDivElement;
  }

  function getAttr(element: HTMLDivElement, attributeName: string): string {
    return element.getAttribute(attributeName) as string;
  }

  function getWorkStepsForList(listName: string) {
    return Array.from(
      document.querySelectorAll(
        `[data-worksteps-list-container-id="${listName}"] [data-workstep-id]`
      )
    );
  }
};
