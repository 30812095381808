import { IMemoryProvider } from './types';

export class InMemoryCacheService implements IMemoryProvider {
  private store: any = {};

  hasKey(keyName: string): Promise<boolean> {
    // eslint-disable-next-line
    return Promise.resolve(this.store.hasOwnProperty(keyName));
  }

  removeItem(keyName: string): Promise<void> {
    return new Promise((resolve) => {
      if (this.storeHasKey(keyName)) {
        delete this.store[keyName];
        resolve();
      }
    });
  }

  setItem(keyName: string, value: any): Promise<void> {
    return new Promise((resolve) => {
      this.store[keyName] = value;
      resolve();
    });
  }

  getItem(keyName: string): Promise<any> {
    return new Promise((resolve) => {
      resolve(this.store[keyName]);
    });
  }

  clear(): void {
    this.store = {};
  }

  private storeHasKey(keyName: string): boolean {
    // eslint-disable-next-line
    return this.store.hasOwnProperty(keyName);
  }
}
