import { Body, Checkbox, Icon } from '@cognite/cogs.js';
import React from 'react';
import Tag from '../../Tag/Tag';
import { SafeguardingValue } from '../SafeguardingValve/elements';
import { StepInformationLine } from './elements';
import { SafeGuard, StepExecutonStatus } from '../../../../../types';
import {
  ExecutionWorkStepItemCompletedIcon,
  ExecutionWorkStepItemDoneCompletedIcon,
} from '../../ExecuteProcedure/ExecutionList/elements';

export interface WorkstepHeaderProps {
  editable: boolean;
  workstepId: string;
  wsPosition: number;
  wsTagNumber: string | undefined;
  wsTag: boolean | undefined;
  tag: boolean | undefined;
  setTag: Function;
  safeguarding: SafeGuard;
  status?: StepExecutonStatus;
}
export const WorkStepHeader = ({
  editable,
  workstepId,
  wsPosition,
  wsTagNumber,
  wsTag,
  tag,
  setTag,
  safeguarding,
  status,
}: WorkstepHeaderProps) => {
  const isExecuted = (status === StepExecutonStatus.Executed) as boolean;

  const isStepExecutedAndChecked = status === StepExecutonStatus.Checked;

  return (
    <StepInformationLine editable={editable}>
      <div
        className="stepNumber"
        data-testid={`position-workStep-${workstepId}`}
      >
        #{wsPosition}
        {isStepExecutedAndChecked && (
          <ExecutionWorkStepItemCompletedIcon>
            <Icon type="Checkmark" data-testid="checked-icon" size={21} />
          </ExecutionWorkStepItemCompletedIcon>
        )}
        {isExecuted && (
          <ExecutionWorkStepItemDoneCompletedIcon>
            <Icon type="CheckmarkDotted" data-testid="done-icon" size={21} />
          </ExecutionWorkStepItemDoneCompletedIcon>
        )}
      </div>
      <div className="tagAndKeyContainer">
        {editable && (
          <>
            <Body level={3} style={{ paddingRight: '4px' }}>
              Tag
            </Body>
            <Tag color="var(--cogs-greyscale-grey5)" size="24">
              <Checkbox
                data-testid="tag-item"
                name="tag"
                checked={tag}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const nextState = event.target.checked;
                  setTag(nextState);
                }}
              />
            </Tag>
          </>
        )}
        {safeguarding && safeguarding !== 'NO' && !editable && (
          <div className="safeguard-key" data-testid="deselected-safeguard">
            <img
              src={`${process.env.PUBLIC_URL}/images/KeyIcon.svg`}
              alt="Key"
            />
            <SafeguardingValue>{safeguarding}</SafeguardingValue>
          </div>
        )}
        {wsTag && !editable && (
          <Tag size="15" color="var(--cogs-greyscale-grey5)">
            {wsTagNumber || <Icon type="Checkmark" style={{ width: '14px' }} />}
          </Tag>
        )}
      </div>
    </StepInformationLine>
  );
};
