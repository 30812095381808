import React from 'react';
import { WorkStep } from 'types';

export const DeSelectedBox = ({
  color,
  children,
}: {
  color: string;
  children: React.ReactElement;
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        padding: '12px',
        width: '95%',
        borderRadius: '4px',
        marginBottom: '10px',
      }}
      className="cogs-body-3"
    >
      {children}
    </div>
  );
};

export type Props = {
  workStep: WorkStep;
  hasReviewerCommentsAfterResolved: Function;
  user: string;
};

export const ReviewDeselected = ({
  workStep,
  hasReviewerCommentsAfterResolved,
  user,
}: Props) => {
  if (workStep.review?.resolved) {
    if (
      workStep.review?.resolved.compiler &&
      !workStep.review?.resolved.reviewer
    ) {
      if (hasReviewerCommentsAfterResolved(workStep)) {
        return (
          <DeSelectedBox color="var(--cogs-pink-7)">
            <span data-testid="reviewer-has-rejected-the-resolved-solution">
              Reviewer rejected resolution
            </span>
          </DeSelectedBox>
        );
      }
      return (
        <DeSelectedBox color="#C8F4E7">
          <span data-testid="compiler-has-resolved">
            {workStep.review?.resolved.compiler.name === user
              ? 'You'
              : 'Compiler'}{' '}
            set to resolved
          </span>
        </DeSelectedBox>
      );
    }
    if (
      workStep.review?.resolved.compiler &&
      workStep.review?.resolved.reviewer
    ) {
      return (
        <DeSelectedBox color="#C8F4E7">
          <span data-testid="reviewer-has-confirmed">
            {workStep.review?.resolved.reviewer.name === user
              ? 'You'
              : 'Reviewer'}{' '}
            confirmed resolution
          </span>
        </DeSelectedBox>
      );
    }
  }
  if (workStep.review?.comments?.length) {
    return (
      <DeSelectedBox color="var(--cogs-pink-7)">
        <span data-testid="review-comments-unresolved">Review comments</span>
      </DeSelectedBox>
    );
  }
  return null;
};
