import Cookies, { CookieAttributes } from 'js-cookie';
import { AssetMappingType } from '../types';
import { FileInfoWithConnectedFiles } from '../redux/reducers/files';
import { ViewerQuality } from '../pages/EditProcedure/components/PnidPreview/hooks/usePnidZoom';

export interface SelectedRootAsset {
  selectedRootAsset: string;
  selectedRootAssetName: string;
}

export class CookiesService {
  private setCookie = (
    name: string,
    value: string,
    attributes?: CookieAttributes
  ): void => {
    Cookies.set(name, value, attributes);
  };

  private getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
  };

  getDefaultRootAsset = (
    assets: AssetMappingType[]
  ): AssetMappingType | undefined => {
    const cookieValue = this.getCookie('defaultRootAsset');

    if (cookieValue && assets.length > 0) {
      const result = assets.find((asset) => asset.assetFloc === cookieValue);

      if (result) {
        return {
          ...result,
        };
      }
    }
    return undefined;
  };

  getStartPageViewTab = (): string | undefined => {
    return this.getCookie('startPageViewTab');
  };

  setDefaultRootAsset = (value: string): void => {
    this.setCookie('defaultRootAsset', value, { expires: 30 });
  };

  setStartPageViewTab = (value: string): void => {
    this.setCookie('startPageViewTab', value, { expires: 30 });
  };

  setFilePreviewInfo = (value: FileInfoWithConnectedFiles): void => {
    const expireTime = new Date(new Date().getTime() + 1 * 60 * 1000);
    this.setCookie('filePreviewInfo', JSON.stringify(value), {
      expires: expireTime,
    });
  };

  removeFilePreviewInfo = (): void => {
    Cookies.remove('filePreviewInfo');
  };

  getFilePreviewInfo = (): FileInfoWithConnectedFiles | undefined => {
    const value = this.getCookie('filePreviewInfo');
    return value ? JSON.parse(value) : undefined;
  };

  getFilePreviewQuality = (): ViewerQuality | undefined =>
    this.getCookie('filePreviewQuality') as ViewerQuality;

  setFilePreviewQuality = (quality: ViewerQuality): void =>
    this.setCookie('filePreviewQuality', quality, { expires: 30 });
}
