import React from 'react';
import { Icon } from '@cognite/cogs.js';

import {
  ProjectContainer,
  Title,
  LogoArea,
  TextDetail,
} from 'pages/Project/components/elements';

import { LogoSpan } from './elements';

import ExecutorItem from './ExecutorItem';
import ExecutionItemNew from './NewExecutorItem';

import { useSave } from '../../../../hooks/useSave';

export type ExecutorsListProps = {
  viewOnly?: boolean;
  executorsList?: string[];
};

const ExecutorsList = ({
  viewOnly = false,
  executorsList = [],
}: ExecutorsListProps) => {
  const { updateExecutionList } = useSave();

  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Users"
            style={{ color: 'var(--cogs-greyscale-grey9)', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>Execution team</Title>
      </LogoArea>
      {!executorsList.length && viewOnly && (
        <TextDetail
          data-testid="no-executors-text"
          style={{ marginLeft: '3px' }}
        >
          No users were added
        </TextDetail>
      )}
      {executorsList?.map((value) => (
        <ExecutorItem
          key={value}
          executor={value}
          viewOnly={viewOnly}
          handleDelete={() => {
            updateExecutionList(
              executorsList?.filter((currentValue) => value !== currentValue)
            );
          }}
        />
      ))}
      {!viewOnly && (
        <ExecutionItemNew
          executorsList={executorsList}
          handleNew={(value) => {
            updateExecutionList([...executorsList, value]);
          }}
        />
      )}
    </ProjectContainer>
  );
};

export default React.memo(ExecutorsList);
