/* eslint-disable no-plusplus */
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import projectSlice from 'redux/reducers/project';
import { Phase, WorkStep } from 'types';
import { TagNumberPosition } from './types';

export const useWorkstepsTagsNumbers = () => {
  const [tagsList, setTagsList] = useState<TagNumberPosition[]>([]);
  const [totalProcessTags, setTotalProcessTags] = useState<string[]>([]);
  const [totalElectricTags, setTotalElectricTags] = useState(0);
  const dispatch = useDispatch();

  const updateLinkedTag = (
    allTags: TagNumberPosition[],
    linkedWorkstepId: string,
    phaseId: string,
    workstepId: string,
    workstepPosition: number
  ): TagNumberPosition[] => {
    const updatedTags = allTags.map((eachTag) => {
      if (eachTag.workStepId === linkedWorkstepId) {
        return {
          ...eachTag,
          connectedTagPhaseId: phaseId,
          connectedTagId: workstepId,
          connectedTagPosition: workstepPosition,
        };
      }
      return eachTag;
    });
    return updatedTags;
  };

  const isTagEqual = useCallback(
    (tagPosition: TagNumberPosition, updateTag: TagNumberPosition) => {
      return (
        // eslint-disable-next-line
        updateTag.phaseId === tagPosition.phaseId &&
        updateTag.workStepId === tagPosition.workStepId &&
        updateTag.position === tagPosition.position
      );
    },
    []
  );

  const updateWorkstepTag = useCallback(
    (tagPosition: TagNumberPosition) => {
      setTagsList((prev) =>
        prev.map((tagItem) => {
          if (isTagEqual(tagPosition, tagItem)) {
            return tagPosition;
          }
          return tagItem;
        })
      );
    },
    [setTagsList, isTagEqual]
  );

  const updateWorkstepTags = useCallback(
    (tagPositions: TagNumberPosition[]) => {
      setTagsList((prev) =>
        prev.map((tagItem) => {
          const tagToUpdate = tagPositions.find((updateTag) =>
            isTagEqual(tagItem, updateTag)
          );
          return tagToUpdate || tagItem;
        })
      );
    },
    [setTagsList, isTagEqual]
  );

  const checkForEmptyTagNumbers = useCallback(
    (tagsPositionsList: TagNumberPosition[]) => {
      const hasEmptyWorkstepsTagNumbers = tagsPositionsList.some(
        (tagPositionItem) => {
          return !tagPositionItem.tagNumber;
        }
      );
      const hasEnteredProcessTagNumbers = tagsPositionsList
        .filter((tag) => tag.type === 'process')
        .some((tagPositionItem) => {
          return !!tagPositionItem.tagNumber;
        });
      const hasEnteredElectricTagNumbers = tagsPositionsList
        .filter((tag) => tag.type === 'electrical')
        .some((tagPositionItem) => {
          return !!tagPositionItem.tagNumber;
        });
      dispatch(
        projectSlice.actions.setHasEmptyTagNumbers({
          allTags: hasEmptyWorkstepsTagNumbers,
          processTags: hasEnteredProcessTagNumbers,
          electricTags: hasEnteredElectricTagNumbers,
        })
      );
    },
    [dispatch]
  );

  const filterAndCountWorkstepsWithTags = useCallback(
    (phases: Phase[], shouldCountDraftSteps: boolean) => {
      let workStepsTags: TagNumberPosition[] = [];
      let electricTagsCount = 0;
      phases.forEach((phase) => {
        phase.workSteps
          .filter(
            (ws) =>
              !(
                shouldCountDraftSteps &&
                (ws.isDraft || ws.workStepType === 'missing')
              )
          )
          .forEach((workStep: WorkStep) => {
            if (workStep.tag && workStep.stepAction) {
              const tagPosition: TagNumberPosition = {
                phaseId: phase.id,
                workStepId: workStep.id,
                position: workStep.position,
                tagNumber: workStep.tagNumber || '',
                type: 'process',
                phaseType: phase.phaseType,
              };
              if (
                workStep.stepAction.text === 'Electrically isolate' ||
                workStep.stepAction.text === 'Electrically reinstate'
              ) {
                tagPosition.type = 'electrical';
                if (phase.phaseType === 'isolation') {
                  workStepsTags.push(tagPosition);
                  electricTagsCount++;
                }
              } else if (phase.phaseType === 'isolation') {
                workStepsTags.push(tagPosition);
              }

              if (
                phase.phaseType === 'deisolation' &&
                workStep.linkedWorkStepId
              ) {
                workStepsTags.push(tagPosition);
              }

              if (
                phase.phaseType === 'deisolation' &&
                workStep.linkedWorkStepId
              ) {
                workStepsTags = updateLinkedTag(
                  workStepsTags,
                  workStep.linkedWorkStepId,
                  phase.id,
                  workStep.id,
                  workStep.position
                );
              }
            }
          });
      });

      const workStepIdsToRemove: string[] = [];

      workStepsTags.forEach((item) => {
        if (item.phaseType === 'deisolation') {
          const isolationPhaseItem = workStepsTags.find(
            (phaseItem) =>
              phaseItem.connectedTagId === item.workStepId &&
              phaseItem.phaseType === 'isolation'
          );

          if (
            isolationPhaseItem &&
            isolationPhaseItem.connectedTagId === item.workStepId
          ) {
            workStepIdsToRemove.push(item.workStepId);
          }
        }
      });

      workStepsTags = workStepsTags.filter(
        (item) => !workStepIdsToRemove.includes(item.workStepId)
      );

      const tagNumbers = workStepsTags
        .filter((ws) => ws.type !== 'electrical')
        .map((item) => item.tagNumber);
      const uniqueTagNumbers = [...new Set(tagNumbers)];

      setTotalElectricTags(electricTagsCount);
      setTotalProcessTags(uniqueTagNumbers);
      setTagsList(workStepsTags);
      checkForEmptyTagNumbers(workStepsTags);
      // eslint-disable-next-line
    },
    [checkForEmptyTagNumbers]
  );

  const generateTagNumbers = useCallback(
    (selectedTag: TagNumberPosition) => {
      const currentValue = selectedTag.tagNumber;
      let tagNumber = 0;
      setTagsList((prev) => {
        // SSE-318
        // const currentIndex = prev.indexOf(selectedTag);

        return prev.map((tag) => {
          if (
            tag.type === selectedTag.type &&
            tag.position > selectedTag.position
          ) {
            tagNumber += 1;
            return {
              ...tag,
              tagNumber: (parseInt(currentValue, 10) + tagNumber).toString(),
            };
          }

          return tag;
        });
      });
    },
    [setTagsList]
  );

  return {
    tagsList,
    totalProcessTags,
    totalElectricTags,
    updateWorkstepTag,
    updateWorkstepTags,
    filterAndCountWorkstepsWithTags,
    generateTagNumbers,
  };
};
