import React from 'react';
import { Button } from '@cognite/cogs.js';
import { Project } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { ProjectContainer, Title } from '../../elements';
import { Details } from './elements';

type InfoSavedProps = {
  project: Project;
  editProject?: Function;
  viewOnly?: boolean;
};

const ProjectInfoSaved = ({
  project,
  editProject,
  viewOnly = false,
}: InfoSavedProps) => {
  return (
    <ProjectContainer>
      <Title>Objectives</Title>
      <Details data-testid="dashboard-objectives-saved">
        {project.objectives}
      </Details>
      {!project.id && (
        <div style={{ display: 'block', overflow: 'hidden', height: '80px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}
      <Title style={{ marginTop: '13px' }}>Method</Title>
      <Details data-testid="dashboard-method-saved">{project.method}</Details>
      {!project.id && (
        <div style={{ display: 'block', overflow: 'hidden', height: '80px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}
      {project.status === 'compilation' && editProject && !viewOnly && (
        <div style={{ marginTop: '33px', alignSelf: 'flex-end' }}>
          <Button
            type="tertiary"
            title="Edit"
            data-testid="projectInfoEdit"
            onClick={() => editProject()}
          >
            Edit
          </Button>
        </div>
      )}
    </ProjectContainer>
  );
};

export default React.memo(ProjectInfoSaved);
