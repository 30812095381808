import { FileInfo, FileUploadResponse } from '@cognite/sdk';
import { PreWorkItem } from 'types';
import { PROCEDURES_FILE_TYPE } from 'config/apiConfig';
import { getClient } from '../config/cognitesdk';

export class PreWorkItemsResourceService {
  async fetchPreWorkItems(
    fileId: string,
    client = getClient()
  ): Promise<PreWorkItem[]> {
    if (!fileId) {
      return [];
    }
    const fileDownloadUrl = await client.files.getDownloadUrls([
      { externalId: `iso_prework_${fileId}` },
    ]);

    const preworkContent = await client
      .get<{
        content: PreWorkItem[];
      }>(fileDownloadUrl[0].downloadUrl)
      .then((data) => data.data.content);

    return preworkContent;
  }

  async savePreWorkItems(
    projectId: string,
    preWorkItems: PreWorkItem[],
    dataSetId?: number,
    client = getClient()
  ): Promise<FileInfo | FileUploadResponse> {
    const jsonFileContent = {
      content: preWorkItems,
    };
    const savePreworkContent = await client.files.upload(
      {
        externalId: `iso_prework_${projectId}`,
        dataSetId,
        name: `isoplan_preworkitems_${projectId}`,
        mimeType: PROCEDURES_FILE_TYPE,
        source: 'isoplan_users',
      },
      jsonFileContent,
      true
    );

    return savePreworkContent;
  }
}
