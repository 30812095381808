/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classnames from 'classnames';

export interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  error?: string;
}

// eslint-disable-next-line react/prop-types
const TextArea = ({ error, name, title, ...rest }: Props) => {
  const classes = classnames('textArea', 'cogs-input', 'cogs-input-default', {
    'has-error': !!error,
  });

  return (
    <div className="cogs-input-container">
      {title && (
        <label
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 600,
            color: '#262626',
          }}
          data-testid="textarea-label"
          htmlFor={name}
        >
          {title}
        </label>
      )}
      <textarea
        className={classes}
        name={name}
        style={{ resize: 'vertical' }}
        {...rest}
      />
      <div className="error-space" data-testid={`${name}-error`}>
        {error}
      </div>
    </div>
  );
};

export default TextArea;
