import React from 'react';
import { toast } from '@cognite/cogs.js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import projectSlice, { ProjectState } from 'redux/reducers/project';
import { AuthState } from 'redux/reducers/auth';
import { ProcedureApprovalFile } from 'types';
import { procedureApprovalFilesResourceService } from '../resources';
import { isFileValid } from '../utils/file';
import { APPROVAL_FILES_DATASET_ID } from '../config/apiConfig';
import { useGlobal } from '../redux/reducers/global/hooks';

export const useApprovalFileUpload = () => {
  const dispatch = useDispatch();
  const { getCurrentDataSetId } = useGlobal();
  const { project } = useSelector<RootState, ProjectState>((state) => {
    return state.project;
  });
  const { user } = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  const fileUploadHandler = (file: File) => {
    // add more file validations here if necessary
    if (isFileValid(file)) {
      const reader = new FileReader();
      const approvalFile: ProcedureApprovalFile = {
        id: `${project.id}_${Date.now()}`,
        name: file.name,
        author: user,
        uploading: true,
        removed: false,
        approvedBy: '',
        description: '',
      };
      dispatch(projectSlice.actions.addNewApprovalFile(approvalFile));
      reader.readAsArrayBuffer(file);
      reader.onload = async (e) => {
        await procedureApprovalFilesResourceService
          .upload(
            `${project.id}`,
            file,
            approvalFile,
            e.target?.result,
            getCurrentDataSetId(APPROVAL_FILES_DATASET_ID)
          )
          .then(() => {
            dispatch(
              projectSlice.actions.setApprovalFileToUploaded(approvalFile)
            );
          })
          .catch(() => {
            dispatch(
              projectSlice.actions.removeFailedApprovalFile(approvalFile)
            );
            toast.error(
              <div>
                <h3>Error</h3> We couldn&apos;t upload {approvalFile.name}.
                <br />
                Try again
              </div>
            );
          });
      };
    } else {
      toast.warning(
        <div>
          <h3>You can&apos;t attach that</h3> The size limit is 1.2GB, and the
          following types are forbidden: .asp .css .swf .xhtml .rhtml .shtml .js
          .php
        </div>
      );
    }
  };

  const fileSoftDeleteHandler = async (fileToDelete: ProcedureApprovalFile) => {
    await procedureApprovalFilesResourceService
      .softDelete(fileToDelete)
      .then(() => {
        dispatch(
          projectSlice.actions.updateApprovalFileRemovedStatus(fileToDelete)
        );
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Oops</h3> We couldn’t delete {fileToDelete.name} for some
            reason. Try again.
          </div>
        );
      });
  };

  const undoFileSoftDeleteHandler = async (
    fileToUndoDelete: ProcedureApprovalFile
  ) => {
    await procedureApprovalFilesResourceService
      .undoSoftDelete(fileToUndoDelete)
      .then(() => {
        dispatch(
          projectSlice.actions.updateApprovalFileRemovedStatus(fileToUndoDelete)
        );
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Oops</h3> We couldn’t restore {fileToUndoDelete.name}. Try
            again.
          </div>
        );
      });
  };

  const updateFileMetaData = async (fileToUpdate: ProcedureApprovalFile) => {
    await procedureApprovalFilesResourceService
      .updateFileMetaData(fileToUpdate)
      .then(() => {
        dispatch(projectSlice.actions.updateApprovalFileMetaData(fileToUpdate));
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Oops</h3> We couldn’t update {fileToUpdate.name}. Try again.
          </div>
        );
      });
  };

  return {
    fileUploadHandler,
    fileSoftDeleteHandler,
    undoFileSoftDeleteHandler,
    updateFileMetaData,
  };
};
