import styled from 'styled-components/macro';
import { Button as CogsButton } from '@cognite/cogs.js';

export const ExecutorItemContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 8px 0;
  border-bottom: 1px var(--cogs-decorative--grayscale--300) solid;
`;

export const NewExecutorItemWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
`;

export const LogoSpan = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--cogs-purple-6);
  margin-right: 6px;
  text-align: center;
`;

export const LinkButton = styled(CogsButton).attrs({ unstyled: true })`
  font-size: 14px;
  line-height: 15.5px;
  cursor: pointer;
  color: var(--cogs-primary);
  margin-left: 5px;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  user-select: none;
  color: var(--cogs-red-2);
`;
