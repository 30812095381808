import React from 'react';
import { Icon } from '@cognite/cogs.js';

import {
  ProjectContainer,
  Title,
  LogoArea,
  TextDetail,
} from 'pages/Project/components/elements';

import { LogoSpan } from './elements';

import SupervisorItem from './SupervisorItem';
import SupervisorItemNew from './NewSupervisorItem';

import { useSave } from '../../../../hooks/useSave';

export type SupervisorsListProps = {
  viewOnly?: boolean;
  supervisorsList?: string[];
};

const SupervisorsList = ({
  viewOnly = false,
  supervisorsList = [],
}: SupervisorsListProps) => {
  const { updateSupervisionList } = useSave();

  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Users"
            style={{ color: 'var(--cogs-greyscale-grey9)', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>Supervision team</Title>
      </LogoArea>
      {!supervisorsList?.length && viewOnly && (
        <TextDetail
          data-testid="no-supervisors-text"
          style={{ marginLeft: '3px' }}
        >
          No users were added
        </TextDetail>
      )}
      {supervisorsList?.map((value) => (
        <SupervisorItem
          key={value}
          supervisor={value}
          viewOnly={viewOnly}
          handleDelete={() => {
            updateSupervisionList(
              supervisorsList?.filter((currentValue) => value !== currentValue)
            );
          }}
        />
      ))}
      {!viewOnly && (
        <SupervisorItemNew
          supervisorsList={supervisorsList}
          handleNew={(value) => {
            updateSupervisionList([...supervisorsList, value]);
          }}
        />
      )}
    </ProjectContainer>
  );
};

export default React.memo(SupervisorsList);
