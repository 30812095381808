import React, { useCallback } from 'react';
import { Button, Flex, Icon } from '@cognite/cogs.js';
import { Title, ProjectContainer, LogoArea } from '../elements';
import { LogoSpan } from './elements';
import { LoadingPlaceholder } from '../../../../shared/LoadingPlaceholder';
import { useSave } from '../../../../hooks/useSave';

type ApprovedProceduresProps = {
  isApprovedProcedure?: boolean;
  showLoading?: boolean;
  canEdit?: boolean;
};

const ApprovedProcedures = ({
  isApprovedProcedure = false,
  showLoading = false,
  canEdit = false,
}: ApprovedProceduresProps) => {
  const { updateIsApprovedProcedure } = useSave();

  const renderBody = useCallback((): JSX.Element | null => {
    const helptext = (
      <p>
        When a procedure is marked as approved procedure, it can easily be
        reused to start a new procedure. This has no impact on the current
        workflow of the procedure.
      </p>
    );
    if (isApprovedProcedure) {
      return (
        <>
          <p>
            Is approved procedure: <b>Yes</b>
          </p>
          {helptext}
          {canEdit && (
            <Flex justifyContent="end" style={{ width: '100%' }}>
              <Button
                type="ghost-destructive"
                data-testid="remove-as-approved-procedure"
                onClick={() => updateIsApprovedProcedure(false)}
              >
                Remove as approved procedure
              </Button>
            </Flex>
          )}
        </>
      );
    }

    return (
      <>
        <p>
          Is approved procedure: <b>No</b>
        </p>
        {helptext}
        {canEdit && (
          <Flex justifyContent="end" style={{ width: '100%' }}>
            <Button
              type="secondary"
              data-testid="add-as-approved-procedure"
              onClick={() => updateIsApprovedProcedure(true)}
            >
              Add as approved procedure
            </Button>
          </Flex>
        )}
      </>
    );
  }, [isApprovedProcedure, canEdit, updateIsApprovedProcedure]);

  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="CheckmarkAlternative"
            style={{ color: 'var(--cogs-grey9)', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>Approved procedures</Title>
      </LogoArea>
      {showLoading ? (
        <div style={{ display: 'block', overflow: 'hidden', height: '150px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      ) : (
        renderBody()
      )}
    </ProjectContainer>
  );
};

export default ApprovedProcedures;
