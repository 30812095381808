import { useResizeObserver } from 'hooks/useResizeObserver';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { customEvents, PhaseType } from 'types';
import features from 'config/features';
import { useWorkStepsScrollAlign } from './useWorkstepsScrollAlign';

/**
 * Used in WorkstepList
 * If using in other places, use with caution
 */
export const useScrollIntoView = (
  workstepsWrapperRef: RefObject<HTMLElement>,
  phasesType: PhaseType
) => {
  const {
    scrollToWorkStep,
    scrollToFirstEl,
    hasWorkStepsInList,
    scrollAndClickWorkStep,
    scrollToEditableWorkStep,
  } = useWorkStepsScrollAlign();
  const [isInitialized, setIsInitialized] = useState(false);
  const SCROLL_TO_FIRST_EL_TIMEOUT = 1000;
  const DEISOLATION_BLANK_SPACE_BOX_ID = 'deIsolationBlankSpaceBox';
  const DEISOLATION_WORKSTEPS_LIST = 'deisolationWorkstepList';

  // Optional callback to access the full DOMRect object if required.
  const optionalCallback = useCallback(
    (entry: DOMRectReadOnly) => {
      if (phasesType === 'isolation' && features.WORKSTEPS_SCROLL_ALIGN) {
        window.dispatchEvent(
          new CustomEvent(customEvents.workStepsHeightChanged, {
            detail: entry,
          })
        );
      }
    },
    [phasesType]
  );

  const workStepsHeightChanged = useCallback((e: any): void => {
    const info = e.detail;
    const deIsolationBlankSpaceBox = document.getElementById(
      DEISOLATION_BLANK_SPACE_BOX_ID
    ) as HTMLDivElement;
    deIsolationBlankSpaceBox.style.height = `${info.height}px`;
    // eslint-disable-next-line
  }, []);

  const scrollToFirstWorkStep = useCallback(() => {
    setTimeout(() => {
      scrollToFirstEl(DEISOLATION_WORKSTEPS_LIST);
    }, SCROLL_TO_FIRST_EL_TIMEOUT);
  }, [scrollToFirstEl]);

  // Access the width and the height returned from the observed element.
  useResizeObserver(workstepsWrapperRef, optionalCallback);

  useEffect(() => {
    if (phasesType === 'deisolation' && features.WORKSTEPS_SCROLL_ALIGN) {
      // Sync the height between isolation and deisolation lists
      window.addEventListener(
        customEvents.workStepsHeightChanged,
        workStepsHeightChanged,
        {
          passive: true,
        }
      );

      // Scroll to first step when the de-isolation is created
      window.addEventListener(
        customEvents.deIsolationStepsCreated,
        scrollToFirstWorkStep,
        {
          passive: true,
        }
      );
    }

    // If initial load, scroll to first work step
    if (
      phasesType === 'deisolation' &&
      !isInitialized &&
      features.WORKSTEPS_SCROLL_ALIGN &&
      hasWorkStepsInList(DEISOLATION_WORKSTEPS_LIST)
    ) {
      setIsInitialized(true);
      scrollToFirstWorkStep();
    }

    return () => {
      if (phasesType === 'deisolation' && features.WORKSTEPS_SCROLL_ALIGN) {
        window.removeEventListener(
          customEvents.workStepsHeightChanged,
          workStepsHeightChanged
        );

        window.removeEventListener(
          customEvents.deIsolationStepsCreated,
          scrollToFirstWorkStep
        );
      }
    };
    // eslint-disable-next-line
  }, []);

  return {
    scrollToWorkStep,
    scrollToEditableWorkStep,
    scrollAndClickWorkStep,
  };
};
