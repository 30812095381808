import { InMemoryCacheService } from './in-memory-cache.service';
import { IndexedDbCacheService } from './indexed-db-cache.service';
import { IMemoryProvider } from './types';

export class CacheProvider implements IMemoryProvider {
  private driver: IMemoryProvider;
  constructor() {
    if (!('indexedDB' in window)) {
      this.driver = new InMemoryCacheService();
    }
    this.driver = new IndexedDbCacheService();
  }
  hasKey(keyName: string): Promise<boolean> {
    return this.driver.hasKey(keyName);
  }
  removeItem(keyName: string): Promise<void> {
    return this.driver.removeItem(keyName);
  }
  setItem(keyName: string, value: any): Promise<void> {
    return this.driver.setItem(keyName, value);
  }
  getItem(keyName: string): Promise<any> {
    return this.driver.getItem(keyName);
  }
  clear(): void {
    this.driver.clear();
  }
}
