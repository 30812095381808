import React, { useState } from 'react';
import { Button, Input } from '@cognite/cogs.js';

import { ErrorMessage, NewSupervisorItemWrapper } from '../elements';

type NewSupervisorItemProps = {
  handleNew: (supervisor: string) => void;
  supervisorsList: string[];
};

const NewSupervisorItem = ({
  handleNew,
  supervisorsList,
}: NewSupervisorItemProps) => {
  const [updatedSupervisor, setUpdateSupervisor] = useState<string>();
  const [fieldError, setFieldError] = useState<boolean>(false);

  const newSupervisorHandler = () => {
    if (updatedSupervisor) {
      const hasDuplicate = supervisorsList?.find(
        (element) => element === updatedSupervisor
      );

      if (!hasDuplicate) {
        handleNew(updatedSupervisor);
        setUpdateSupervisor('');
      } else {
        setFieldError(true);
      }
    }
  };

  return (
    <>
      <NewSupervisorItemWrapper>
        <Input
          data-testid="supervisor-text-field-new"
          fullWidth
          style={{
            flexGrow: 1,
            alignSelf: 'stretch',
            padding: '0px 10px',
            height: '30px',
            minWidth: '80px',
            marginRight: '8px',
          }}
          value={updatedSupervisor}
          onChange={({ target }) => {
            setFieldError(false);
            setUpdateSupervisor(target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setUpdateSupervisor(e.currentTarget.value);
            }
          }}
        />
        <Button
          key="supervisor-add-new"
          data-testid="supervisor-add-new"
          type="secondary"
          size="small"
          onClick={newSupervisorHandler}
        >
          Add
        </Button>
      </NewSupervisorItemWrapper>

      {fieldError && (
        <ErrorMessage data-testid="supervisor-field-error">
          <span>Username already in list</span>
        </ErrorMessage>
      )}
    </>
  );
};

export default NewSupervisorItem;
