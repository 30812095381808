import styled from 'styled-components/macro';

export const LogoSpan = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #f5f5f5;
  margin-right: 6px;
  text-align: center;
`;

export const LinkedApprovedProcedureWrapper = styled.div`
  padding: 16px;
  background-color: #f5f5f5;
  margin-top: 16px;
`;
