import { modelFactory } from 'factories';
import { ProjectsService } from './projects.service';
import { ProjectStateService } from './project-state.service';
import { WorkstepsService } from './worksteps.service';
import { WorkstepsStateService } from './worksteps-state.service';
import { PhasesService } from './phases.service';
import { ProjectExecutionService } from './project-execution.service';
import { WorkstepsBuilderService } from './worksteps-builder.service';
import { AnnotationsService } from './annotations.service';
import { AnnotationsColorsService } from './annotations-colors.service';
import { CacheProvider } from './data';
import { ConfigService } from './config.service';
import { WorkStepsMigrationService } from './worksteps-migration.service';
import { CookiesService } from './cookies.service';

export * from './services';
export const configService = new ConfigService();
export const projectsService = new ProjectsService();
export const projectStateService = new ProjectStateService();
export const workstepsService = new WorkstepsService();
export const phasesService = new PhasesService();
export const annotationsService = new AnnotationsService();
export const annotationsColorsService = new AnnotationsColorsService();

export const cacheProvider = new CacheProvider();

export const projectExecutionService = new ProjectExecutionService(
  workstepsService
);

export const workstepsBuilderService = new WorkstepsBuilderService(
  workstepsService,
  modelFactory
);
export const workstepsStateService = new WorkstepsStateService(
  workstepsService,
  phasesService,
  projectExecutionService,
  workstepsBuilderService,
  modelFactory
);

export const workstepsMigrationService = new WorkStepsMigrationService();
export const cookieServices = new CookiesService();
