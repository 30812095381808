import React, { useEffect } from 'react';

import { Loader } from '@cognite/cogs.js';
import { ErrorBoundary } from '@cognite/react-errors';

import { useDispatch, useSelector } from 'react-redux';
import { fetchRootAssetsContent, SearchState } from '../redux/reducers/search';

import { Status } from '../redux/types';
import { RootState, AppDispatch } from '../redux/store';

import MainWrapper from './MainWrapper';
import { fetchDataSet, GlobalState } from '../redux/reducers/global';
import { dynamicConfig } from '../config/apiConfig';

type MainContainerProps = {
  children: React.ReactNode;
};

const MainContainer = ({ children }: MainContainerProps) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchRootAssetsContent());
    dispatch(fetchDataSet(Object.values(dynamicConfig)));
  }, [dispatch]);

  const { statusRootAssets } = useSelector<RootState, SearchState>((state) => {
    return state.search;
  });

  const { statusDataset } = useSelector<RootState, GlobalState>((state) => {
    return state.global;
  });

  const loadingSuccess =
    statusDataset === Status.success && statusRootAssets === Status.success;

  return (
    <MainWrapper>
      <ErrorBoundary instanceId="app-root">
        {loadingSuccess ? children : <Loader />}
      </ErrorBoundary>
    </MainWrapper>
  );
};

export default MainContainer;
