import { Status } from 'redux/types';
import {
  AppMode,
  PassValve,
  Project,
  ProjectStateVM,
  WorkOrder,
  Comment,
  PnId,
  PreWorkItem,
  ProcedureFileAttachment,
  AssetMappingType,
  ProcedureApprovalFile,
} from 'types';
import { modelFactory } from 'factories';

export class ProjectStateService {
  getInitialState(): ProjectStateVM {
    return {
      project: {} as Project,
      status: Status.idle,
      newProject: false as boolean,
      appMode: undefined as AppMode,
      editMethod: false as boolean,
      error: undefined as string | undefined,
      workorders: [] as WorkOrder[],
      woStatus: Status.idle,
      passValves: [] as PassValve[],
      pvStatus: Status.idle,
      reviewComments: [] as Comment[],
      commentStatus: Status.idle,
      connectedPnids: [] as PnId[],
      preWorkItems: [] as PreWorkItem[],
      preWorkItemsStatus: Status.idle,
      attachmentsList: [] as ProcedureFileAttachment[],
      attachmentStatus: Status.idle,
      tagNumbersUpdated: false as boolean,
      hasEmptyTagNumbers: false as boolean,
      hasEnteredProcessTagNumbers: false as boolean,
      hasEnteredElectricTagNumbers: false as boolean,
      isProcedureLoaded: false as boolean,
      approvalFilesStatus: Status.idle,
      approvalFilesList: [] as ProcedureApprovalFile[],
      executorsList: [] as string[],
      supervisorsList: [] as string[],
      isApprovedProcedure: false as boolean,
    };
  }

  setProject(project: Project): ProjectStateVM {
    return { ...this.getInitialState(), project, status: Status.success };
  }

  createProject(
    asset: AssetMappingType,
    user: string,
    newId: string,
    selectedRootAsset: string
  ): ProjectStateVM {
    return {
      ...this.getInitialState(),
      project: modelFactory.createNewProject(
        asset,
        user,
        selectedRootAsset,
        newId
      ),
      status: Status.success,
      attachmentStatus: Status.success,
      approvalFilesStatus: Status.success,
      commentStatus: Status.success,
      pvStatus: Status.success,
      woStatus: Status.success,
      preWorkItemsStatus: Status.success,
      newProject: true,
      editMethod: true,
    };
  }

  loadAndCreateProject(
    project: Project,
    user: string,
    newId: string
  ): ProjectStateVM {
    return {
      ...this.getInitialState(),
      project: modelFactory.copyProject(project, user, newId),
      status: Status.success,
      attachmentStatus: Status.success,
      approvalFilesStatus: Status.success,
      commentStatus: Status.success,
      preWorkItemsStatus: Status.success,
      newProject: true,
    };
  }

  setPnidDrawing(
    state: ProjectStateVM,
    pnid: string,
    drawingId: string
  ): ProjectStateVM {
    return {
      ...state,
      project: {
        ...state.project,
        pnIdsDrawings: {
          ...state.project.pnIdsDrawings,
          [pnid]: drawingId,
        },
      },
    };
  }
}
