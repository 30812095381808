import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getClient, setClient } from 'config/cognitesdk';
import authReducer from 'redux/reducers/auth';
import { CogniteClient } from '@cognite/sdk';
import * as Sentry from '@sentry/browser';

import { AuthProvider } from '@cognite/react-container';
import sidecar from '../config/sidecar';
import { getTenant } from '../utils/tenancy';

import useMetrics from '../hooks/useMetrics';
import { METRICS_AREA } from '../config/mixpanelConfig';

type Props = {
  children: React.ReactNode;
};

const AuthContainer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const tenant = getTenant();
  const [loaded, setLoaded] = useState(false);
  const { authState, client: authProviderClient } = useContext(AuthProvider);

  const project = authProviderClient?.project || '';
  const { cdfApiBaseUrl } = sidecar;
  const metrics = useMetrics(METRICS_AREA.LOGIN);

  useEffect(() => {
    if (tenant === 'testcafe' || tenant === 'mock') {
      const cogniteClient: CogniteClient = new CogniteClient({
        appId: 'isoplan-testing',
        baseUrl: cdfApiBaseUrl,
        project: '',
        getToken: () => Promise.resolve('2233'),
      });
      setClient(cogniteClient);

      // @ts-ignore;
      getClient().initAPIs();

      setLoaded(true);

      dispatch(
        authReducer.actions.login({
          projectId: -1,
          user: 'testcafe@cognite.com',
          project,
        })
      );
    }
  }, [cdfApiBaseUrl, dispatch, project, tenant]);

  useEffect(() => {
    if (authProviderClient && authState) {
      // @ts-ignore
      setClient(authProviderClient);
      setLoaded(true);

      metrics.initialize(authState.email || '', project);
      Sentry.setUser({ id: authState.email || '' });

      if (authState.authenticated) {
        dispatch(
          authReducer.actions.login({
            projectId: -1,
            user: authState?.email || '',
            project,
          })
        );
      }
    }
  }, [dispatch, authProviderClient, authState, project, metrics]);

  return <>{loaded && children}</>;
};

export default AuthContainer;
