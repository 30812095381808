import React from 'react';
import { Button } from '@cognite/cogs.js';

type Props = {
  cannotStartExecution: boolean;
  handleExecutionStart: Function;
  viewOnly: boolean;
  executorsList: string[];
  supervisorsList: string[];
};

const StartExecution = ({
  cannotStartExecution,
  handleExecutionStart,
  viewOnly,
  executorsList = [],
  supervisorsList = [],
}: Props) => {
  const renderActionButton = () => {
    if (!viewOnly) {
      return (
        <Button
          disabled={cannotStartExecution}
          type="primary"
          style={{ width: 'max-content', marginBottom: '8px' }}
          data-testid="proceed-to-execution-button"
          onClick={() => handleExecutionStart(executorsList)}
        >
          Start execution
        </Button>
      );
    }
    return null;
  };

  const renderErrorMessages = () => (
    <>
      {executorsList.length <= 1 && (
        <div
          style={{
            fontSize: '14px',
            color: '#B30539',
            width: '270px',
          }}
          data-testid="incomplete-execution-team-warning-dashboard"
        >
          You should have at least 2 users on the execution team
        </div>
      )}

      {supervisorsList?.length <= 0 && (
        <div
          style={{
            fontSize: '14px',
            color: '#B30539',
            width: '270px',
          }}
          data-testid="incomplete-supervision-team-warning-dashboard"
        >
          You should have at least 1 user on the supervision team
        </div>
      )}

      {cannotStartExecution ? (
        <div
          style={{
            fontSize: '14px',
            color: '#B30539',
            width: '270px',
          }}
          data-testid="no-cert-number-warning-dashboard"
        >
          You must resolve any issues marked in red before proceeding
        </div>
      ) : (
        <div
          style={{
            fontSize: '14px',
            color: '#333333',
            width: '270px',
          }}
          data-testid="execution-tablet-warning-dashboard"
        >
          The execution mode is designed for tablet. Please only press this when
          you’re ready to go out in the field
        </div>
      )}
    </>
  );

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        {renderActionButton()}
        {renderErrorMessages()}
      </div>
    </>
  );
};

export default StartExecution;
