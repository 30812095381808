import React, { useState } from 'react';
import { Icon, Input } from '@cognite/cogs.js';
import { useDispatch } from 'react-redux';
import projectSlice from 'redux/reducers/project';
import { DescriptionDiv, InputDiv } from './elements';

type Props = {
  description: string | undefined;
  newProject: boolean;
  editable: boolean;
};

const Description = ({ description, newProject, editable }: Props) => {
  const dispatch = useDispatch();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(description || '');

  return (
    <>
      {showInput ? (
        <InputDiv>
          <Input
            key="description-dashboard"
            variant="noBorder"
            style={{
              padding: '0px 2px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '30px',
              color: newProject ? '#8C8C8C' : '#262626',
              width: `${description?.length}ch`,
            }}
            placeholder="Add a new name"
            data-testid="projectNameEditMode"
            value={inputValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(projectSlice.actions.updateDescription(inputValue));
                setShowInput(false);
              }
            }}
            onBlur={() => {
              dispatch(projectSlice.actions.updateDescription(inputValue));
              setShowInput(false);
            }}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </InputDiv>
      ) : (
        <DescriptionDiv
          data-testid="projectName"
          title={editable ? 'Double click to edit title' : ''}
          style={{ color: newProject ? '#8C8C8C' : '#262626' }}
          onDoubleClick={() => {
            if (editable) {
              setShowInput(true);
            }
          }}
        >
          <span>{description}</span>
          {newProject && (
            <Icon
              type="Edit"
              data-testid="pencilIconDescription"
              style={{ marginLeft: '8px' }}
            />
          )}
        </DescriptionDiv>
      )}
    </>
  );
};

export default Description;
