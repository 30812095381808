// temporary eslint disable
/* eslint-disable */
import { assetColor, nextColorFromList } from 'utils/colors';
import { CogniteAnnotation, CogniteAnnotationSpec, ColorFiles } from 'types';

export class AnnotationsColorsService {
  updatedAnnotationsColors(
    state: any,
    fileAnnotations: Array<CogniteAnnotation | CogniteAnnotationSpec>
  ) {
    // const colors = current(state).fileColors;
    const colors = JSON.parse(JSON.stringify(state.fileColors));

    const filteredFileIds = fileAnnotations
      .map((fileAnnotation) => {
        if (
          'linkedResourceInternalId' in fileAnnotation.data &&
          fileAnnotation.data.linkedResourceInternalId
        )
          return fileAnnotation.data.linkedResourceInternalId;
      })
      .filter((id) => !colors.find((color: ColorFiles) => color.id === id));

    const uniqueFileColors = [...new Set(filteredFileIds)].map((id, index) => ({
      id,
      color: nextColorFromList(colors.length + index),
    }));

    return [...colors, ...uniqueFileColors];
  }

  // TOBEDONE: code refactor needed once the logic is in place
  colorAnnotations(
    state: any,
    rawAnnotations: Array<CogniteAnnotation | CogniteAnnotationSpec>
  ) {
    const fileAnnotations = rawAnnotations.filter((annotation) => {
      if (
        annotation &&
        'textRegion' in annotation.data &&
        'linkedResourceInternalId' in annotation.data &&
        'fileRef' in annotation.data
      )
        return (
          annotation.data.fileRef &&
          'id' in annotation.data.fileRef &&
          annotation.data.fileRef.id &&
          annotation.data.textRegion &&
          annotation.data.linkedResourceInternalId
        );
    });
    const colors = this.updatedAnnotationsColors(state, fileAnnotations);
    const annotations = rawAnnotations
      .map((annotation: CogniteAnnotation | CogniteAnnotationSpec) => {
        if (annotation && 'textRegion' in annotation.data) {
          let annotationId: number | undefined = undefined;

          if ('linkedResourceInternalId' in annotation.data) {
            annotationId = annotation.data?.linkedResourceInternalId;
          } else if ('id' in annotation.data) {
            annotationId = Number(annotation.data?.id);
          }

          const annotationColor = colors.find(
            (color: ColorFiles) => color.id === annotationId
          ) ?? { color: '#1c1c1c' };

          let detailValue: string | null | undefined = null;
          let typeValue: string | null | undefined = null;
          let sourceTypeData: string | null | undefined = null;
          let textValue: string | null | undefined = null;
          let oldAnnotationIdValue: string | null | undefined = null;

          if ('detail' in annotation.data) {
            detailValue = annotation.data.detail;
          }

          if ('text' in annotation.data) {
            textValue = annotation.data.text;
          }

          if ('type' in annotation.data) {
            typeValue = annotation.data.type;
          }

          if ('sourceType' in annotation.data) {
            sourceTypeData = annotation.data.sourceType;
          }

          if ('oldAnnotationId' in annotation.data) {
            oldAnnotationIdValue = annotation.data.oldAnnotationId;
          }

          const newAnnotation = {
            ...annotation,
            data: {
              textRegion: annotation.data.textRegion,
              detail: detailValue,
              type: typeValue,
              ...('relativePosition' in annotation.data && {
                relativePosition: annotation.data.relativePosition,
              }),
              ...('indirectRelation' in annotation.data && {
                indirectRelation: annotation.data.indirectRelation,
              }),
              ...('linkedResourceType' in annotation.data &&
                (annotation.data.linkedResourceType === 'asset' ||
                  annotation.data.linkedResourceType === 'file') && {
                  linkedResourceType: annotation.data.linkedResourceType,
                }),
              ...('linkedResourceExternalId' in annotation.data && {
                linkedResourceExternalId:
                  annotation.data.linkedResourceExternalId,
              }),
              ...('linkedResourceInternalId' in annotation.data && {
                linkedResourceInternalId:
                  annotation.data.linkedResourceInternalId,
              }),
              ...('indirectExternalId' in annotation.data && annotation.data?.indirectExternalId?.externalId && {
                indirectExternalId: annotation.data?.indirectExternalId,
              }),
              sourceType: sourceTypeData,
              text: textValue,
              oldAnnotationId: oldAnnotationIdValue,
            },
          } as CogniteAnnotation & { mark: any; color: string };

          if (
            'linkedResourceType' in annotation.data &&
            annotation.data.linkedResourceType === 'file'
          ) {
            newAnnotation.mark = {
              strokeWidth: 2,
              strokeColor: `${annotationColor.color}`,
              backgroundColor: `${annotationColor.color}`,
            };
            newAnnotation.color = annotationColor.color;
          } else {
            newAnnotation.mark = {
              strokeWidth: 0,
              backgroundColor: assetColor,
            };
            newAnnotation.color = assetColor;
          }
          return newAnnotation;
        } else if (
          annotation &&
          'type' in annotation.data &&
          'vertices' in annotation.data &&
          annotation.data.type === 'polylineAnnotation'
        ) {
          return annotation;
        }
      })
      .filter((element) => element !== undefined);
    return (annotations ?? []) as unknown as (CogniteAnnotation & {
      mark: any;
      color: string;
    })[];
  }

  saveFileColor(
    annotation: CogniteAnnotation | CogniteAnnotationSpec,
    color: string
  ): ColorFiles {
    if ('fileRef' in annotation.data && annotation.data.fileRef) {
      return { id: annotation.data.fileRef.id ?? 0, color };
    }
    return { id: 0, color };
  }
}
