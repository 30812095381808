/*!
 * Copyright 2022 Cognite AS
 */

import { CameraManager } from '../CameraManager';
import { CameraStopDelegate } from '../types';

import debounce from 'lodash/debounce';
import { EventTrigger } from '@reveal/utilities';

/**
 * Simple helper class to trigger a stop event whenever a camera manager's
 * camera hasn't changed for a little while.
 */

export class DebouncedCameraStopEventTrigger {
  private readonly _debouncedFireEvent: () => void;
  private readonly _cameraManager: CameraManager;
  private readonly _trigger = new EventTrigger<CameraStopDelegate>();

  constructor(cameraManager: CameraManager, debounceTimeMs: number = 100) {
    this._debouncedFireEvent = debounce(() => this._trigger.fire(), debounceTimeMs);
    this._cameraManager = cameraManager;

    this._cameraManager.on('cameraChange', this._debouncedFireEvent);
  }

  /**
   * Subscribe to the stop events generated by this trigger
   */
  subscribe(callback: CameraStopDelegate): void {
    this._trigger.subscribe(callback);
  }

  /**
   * Unsubscribe from the stop events
   */
  unsubscribe(callback: CameraStopDelegate): void {
    this._trigger.unsubscribe(callback);
  }

  /**
   * Deallocate resources associated with this object
   */
  dispose(): void {
    this._trigger.unsubscribeAll();
    this._cameraManager.off('cameraChange', this._debouncedFireEvent);
  }
}
