import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProjectPageLayoutWrapper } from './elements';

export interface ProjectLayoutProps {
  shouldUpdate: boolean;
  setShouldUpdate: Function;
  children: any;
}

export const ProjectLayout = React.memo(
  ({ shouldUpdate, setShouldUpdate, children }: ProjectLayoutProps) => {
    const [containerHeight, setContainerHeight] = useState('2000px');
    const pageLayoutWrapperEl = useRef<HTMLDivElement>(null);

    const tabletView =
      window && window.matchMedia
        ? window.matchMedia('(max-width: 1448px)')
        : null;
    const getHeightForCol = useCallback((colName: string): number => {
      const wrapper = pageLayoutWrapperEl.current as HTMLDivElement;

      const elements = [...wrapper.querySelectorAll(`.layout-item.${colName}`)];
      if (!elements.length) {
        return 2000; // something is not ok
      }
      return elements
        .map((component) => component.clientHeight)
        .reduce((sum, compHeight) => (sum as number) + compHeight);
      // eslint-disable-next-line
    }, []);

    const updateHeight = useCallback(() => {
      let newHeight = 300; // some extra buffer space
      const minHeight = 1500;
      const tabletMinHeight = 2500;

      if (tabletView && tabletView.matches) {
        const col1Height = getHeightForCol('col-md-1');
        const col2Height = getHeightForCol('col-md-2');

        newHeight += Math.max(tabletMinHeight, col1Height, col2Height);
      } else {
        const col1Height = getHeightForCol('col-lg-1');
        const col2Height = getHeightForCol('col-lg-2');
        const col3Height = getHeightForCol('col-lg-3');

        newHeight += Math.max(minHeight, col1Height, col2Height, col3Height);
      }

      setContainerHeight(`${newHeight}px`);
      setShouldUpdate(false);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      // wait for 1sec for ui to be ready
      setTimeout(() => {
        if (pageLayoutWrapperEl.current) {
          updateHeight();
        }
      }, 300);

      if (tabletView) {
        tabletView.addEventListener('change', updateHeight);
      }

      return () => {
        if (tabletView) {
          tabletView.removeEventListener('change', updateHeight);
        }
      };
      // eslint-disable-next-line
    }, [shouldUpdate]);

    return (
      <ProjectPageLayoutWrapper
        ref={pageLayoutWrapperEl}
        style={{ height: containerHeight }}
      >
        {children}
      </ProjectPageLayoutWrapper>
    );
  }
);
