import { useLayoutEffect, useState, useCallback, RefObject } from 'react';
import debounce from 'lodash/debounce';
// import ResizeObserver from 'resize-observer-polyfill';

export interface ResizeObserverEntry {
  target: HTMLElement;
  contentRect: DOMRectReadOnly;
}

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void
) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(
    debounce((entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    }, 100),
    [callback]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    // supported by all major browsers
    // added check only for Unit tests
    if (!window.ResizeObserver) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      handleResize(entries as any);
    });
    resizeObserver.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };

    // eslint-disable-next-line
  }, [ref]);

  return [width, height];
};
