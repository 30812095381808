import { TagNumberPosition } from 'pages/Project/components/WorkstepsTags/types';
import { Phase, PhaseType, WorkStep, CogniteAnnotation } from 'types';
import { onlyUnique } from 'utils/collection';
import { AnnotationData } from '@cognite/sdk';

export class WorkstepsService {
  moveWorkstep(
    phases: Phase[],
    workStep: WorkStep,
    fromPhaseIndex: number,
    toPhaseIndex: number,
    fromWorkstepIndex: number,
    toWorkstepIndex: number
  ): Phase[] {
    phases[fromPhaseIndex].workSteps.splice(fromWorkstepIndex, 1);
    phases[toPhaseIndex].workSteps.splice(toWorkstepIndex, 0, workStep);
    return phases;
  }

  removeWorkStep(phase: Phase, workStepId: string): Phase {
    return {
      ...phase,
      workSteps: phase.workSteps.filter((it) => it.id !== workStepId),
    };
  }

  addWorkStep(phase: Phase, workStep: WorkStep, positionWithinPhase?: number) {
    const position = positionWithinPhase || phase.workSteps.length;

    return {
      ...phase,
      workSteps: [
        ...phase.workSteps.slice(0, position),
        workStep,
        ...phase.workSteps.slice(position),
      ],
    };
  }

  updateOrderOfWorkSteps(phases: Phase[]): Phase[] {
    const allSteps = phases.map((it) => it.workSteps).flat();
    return phases.map((phase) => {
      const newPhase = { ...phase };
      newPhase.workSteps = newPhase.workSteps
        .map((workStep) => {
          return {
            ...workStep,
            position: allSteps.findIndex((step) => step.id === workStep.id) + 1,
          };
        })
        .sort((w, next) => w.position - next.position);
      return newPhase;
    });
  }

  findPhaseIndex(phases: Phase[], phaseId: string): number {
    return phases.findIndex((phase) => phase.id === phaseId);
  }

  getNextPosition(phases: Phase[]): number {
    const positions = phases
      .map((phase) => phase.workSteps.map((workStep) => workStep.position))
      .flat()
      .sort((a: number, b: number) => +a - +b);
    if (positions.length > 0) {
      return positions[positions.length - 1] + 1;
    }
    return 1;
  }

  batchUpdateWorkstepsTagNumbers(
    phases: Phase[],
    tagNumbers: TagNumberPosition[]
  ): Phase[] {
    return phases.map((phase) => {
      return {
        ...phase,
        workSteps: phase.workSteps.map((workstep) => {
          if (workstep.tag) {
            if (phase.phaseType === 'deisolation') {
              const deIsoTagNumberIdxForIndividual = tagNumbers.findIndex(
                (tagNumber) =>
                  // eslint-disable-next-line
                  tagNumber.phaseId === phase.id &&
                  tagNumber.workStepId === workstep.id &&
                  tagNumber.position === workstep.position
              );

              const deIsoTagNumberIdx = tagNumbers.findIndex(
                (tagNumber) =>
                  // eslint-disable-next-line
                  tagNumber.connectedTagPhaseId === phase.id &&
                  tagNumber.connectedTagId === workstep.id &&
                  tagNumber.connectedTagPosition === workstep.position
              );

              if (
                deIsoTagNumberIdx === -1 &&
                deIsoTagNumberIdxForIndividual === -1
              ) {
                return workstep;
              }
              if (deIsoTagNumberIdx !== -1) {
                return {
                  ...workstep,
                  tagNumber: tagNumbers[deIsoTagNumberIdx].tagNumber,
                };
              }

              return {
                ...workstep,
                tagNumber: tagNumbers[deIsoTagNumberIdxForIndividual].tagNumber,
              };
            }
            const tagNumberIdx = tagNumbers.findIndex(
              (tagNumber) =>
                // eslint-disable-next-line
                tagNumber.phaseId === phase.id &&
                tagNumber.workStepId === workstep.id &&
                tagNumber.position === workstep.position
            );

            if (tagNumberIdx === -1) {
              return workstep;
            }

            return {
              ...workstep,
              tagNumber: tagNumbers[tagNumberIdx].tagNumber,
            };
          }
          return workstep;
        }),
      };
    });
  }

  updateWorkstepMatchIdPosition(
    workSteps: WorkStep[],
    updatedWorkStep: WorkStep,
    callback: (currentWorkstep: WorkStep) => WorkStep
  ): WorkStep[] {
    return workSteps.map((currentWorkstep) => {
      if (this.isRequiredWorkstep(currentWorkstep, updatedWorkStep)) {
        return callback(currentWorkstep);
      }
      return currentWorkstep;
    });
  }

  getWorkstepWithPredicate(
    phases: Phase[],
    predicate: (workStep: WorkStep) => boolean
  ): WorkStep | undefined {
    return phases
      .map((phase) => phase.workSteps)
      .reduce((a, b) => a.concat(b), [])
      .find((workStep) => predicate(workStep));
  }

  filterWorkstepsWithPredicate(
    phases: Phase[],
    predicate: (workStep: WorkStep) => boolean
  ): WorkStep[] {
    return phases
      .map((phase) => phase.workSteps)
      .reduce((a, b) => a.concat(b), [])
      .filter((workStep) => predicate(workStep));
  }

  /** Returns the workstep that is linked with the deleted one.
   * If isolation, finds the de-isolation step.
   * If de-isolation, finds the isolation step.
   */
  findLinkedWorkStep(
    phases: Phase[],
    currentPhaseIdx: number,
    workStepId: string,
    phaseType: PhaseType
  ): WorkStep | undefined {
    const currentWorkStep = phases[currentPhaseIdx].workSteps.find(
      (ws) => ws.id === workStepId
    ) as WorkStep;

    const filteredOpositePhases = phases.filter((phase) => {
      return phase.phaseType !== phaseType;
    });

    const linkedWorkStepId = this.getLinkedWorkstepId(
      currentWorkStep,
      phaseType
    );

    return this.getWorkstepWithPredicate(filteredOpositePhases, (workStep) => {
      if (phaseType === 'deisolation') {
        return workStep.id === linkedWorkStepId;
      }

      return workStep.linkedWorkStepId === linkedWorkStepId;
    });
  }

  /** Returns the linked workstep id
   * If workstep is deisolation, returns isolaiton step id
   * if is isolation, returns de-isolaiton steps linkedWorkStepId
   */
  getLinkedWorkstepId(workStep: WorkStep, phaseType: PhaseType): string {
    return (
      phaseType === 'deisolation' ? workStep?.linkedWorkStepId : workStep?.id
    ) as string;
  }

  isWorkstepOnlyComment(workStep: WorkStep): boolean {
    return (
      (!workStep.stepItem ||
        Object.keys(workStep.stepItem).length === 0 ||
        !workStep.stepItem.annotation?.id) &&
      !!workStep.comment
    );
  }

  getWorkstepsFileIds(phases: Phase[], pnIdsToMerge: number[] = []): number[] {
    const phasesFilesIds = phases
      .map((phase) => phase.workSteps)
      .reduce((a, b) => a.concat(b), [])
      .filter(
        (workStep) =>
          workStep.stepItem &&
          workStep.stepItem.annotation &&
          workStep.stepItem.annotation.annotatedResourceId
      )
      .map((ws) => ws.stepItem?.annotation?.annotatedResourceId)
      .filter(onlyUnique);

    return [...pnIdsToMerge, ...phasesFilesIds].filter(onlyUnique) as number[];
  }

  updateWorkStepAnnotationsProperty(
    workStep: WorkStep,
    property: keyof CogniteAnnotation['data'] | keyof AnnotationData | 'detail',
    value: any
  ): WorkStep {
    const { stepItem } = workStep;

    if (!stepItem) {
      return workStep;
    }

    const setAnnotationProperty = (
      annotation: CogniteAnnotation
    ): CogniteAnnotation => {
      return { ...annotation, data: { [property]: value } };
    };

    let updatedWorkStep = { ...stepItem };

    if (updatedWorkStep.annotation) {
      updatedWorkStep = {
        ...updatedWorkStep,
        annotation: setAnnotationProperty(updatedWorkStep.annotation),
      };
    }
    if (updatedWorkStep.line?.annotation) {
      updatedWorkStep = {
        ...updatedWorkStep,
        line: {
          ...updatedWorkStep.line,
          annotation: setAnnotationProperty(updatedWorkStep.line.annotation),
        },
      };
    }
    if (updatedWorkStep.relativeRef?.annotation) {
      updatedWorkStep = {
        ...updatedWorkStep,
        relativeRef: {
          ...updatedWorkStep.relativeRef,
          annotation: setAnnotationProperty(
            updatedWorkStep.relativeRef.annotation
          ),
        },
      };
    }

    return {
      ...workStep,
      stepItem: updatedWorkStep,
    };
  }

  private isRequiredWorkstep(
    currentWorkstep: WorkStep,
    modifiedWorkstep: WorkStep
  ): boolean {
    return (
      currentWorkstep.id === modifiedWorkstep.id &&
      currentWorkstep.position === modifiedWorkstep.position
    );
  }
}
