import styled from 'styled-components/macro';
import Layers from 'utils/zindex';

export const WorkStepContainer = styled.div<{
  stepColor?: string;
  editable?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${(props) =>
    props.editable
      ? '1px solid var(--cogs-greyscale-grey5)'
      : `6px solid ${props.stepColor || 'grey'}`};
  border-left: 1px solid var(--cogs-greyscale-grey5);
  border-right: 1px solid var(--cogs-greyscale-grey5);
  border-bottom: 1px solid var(--cogs-greyscale-grey5);
  border-radius: 0px 0px 3px 3px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StepInformationLine = styled.div<{
  editable: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  ${(props) =>
    !props.editable && 'border-bottom: 1px solid var(--cogs-greyscale-grey5);'}
  padding-bottom: ${(props) => (props.editable ? '8px' : '2px')};
  padding-right: 4px;
  position: relative;

  .tagAndKeyContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .stepNumber {
    color: var(--cogs-greyscale-grey7);
    font-size: 12px;
    line-height: 16px;
  }

  .safeguard-key {
    display: flex;
    flex-direction: row;
    align-items: inherit;
  }

  .cogs-checkbox .checkbox-ui {
    margin: 3px 0px;
  }
`;

export const WorkStepContainerInner = styled.div`
  width: 100%;
  position: relative;

  .drag-handle-box {
    left: -4px;
    position: relative;
    padding-right: 0;
  }

  .work-step-body-box {
    width: 100%;
  }

  .comment-non-active {
    white-space: normal;

    .inner {
      overflow: hidden;
      transition: max-height 0.2s ease;
    }

    .cogs-btn-unstyled {
      margin-top: 4px;
      color: var(--cogs-midblue-3);
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .comment-active {
    font-size: 10px;
    font-style: italic;
  }

  .comment-container-title {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .comment-container-body {
    color: var(--cogs-greyscale-grey10);
    line-height: 20px;
    white-space: normal;
  }
`;

export const WarningBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--cogs-yellow-6);
  padding: 6px 10px 6px 10px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const CommentField = styled.div`
  flex: 3 1 auto;
`;

export const Actions = styled.div`
  margin-right: 11px;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const Step = styled.div<{
  isDoneAndChecked?: boolean;
}>`
  box-sizing: border-box;
  background-color: var(--cogs-greyscale-grey3);
  padding: 5px 0px 8px ${(props) => (props.isDoneAndChecked ? '24px' : '8px')};
  width: 100%;
  min-height: 45px;
  text-align: start;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: var(--cogs-greyscale-grey2);
  }

  &.highlighted {
    background-color: #fff;
  }

  &.active {
    background-color: #ffffff;
  }

  &.active:hover {
    background-color: #ffffff;
  }
`;

export const StepTextArea = styled.div`
  width: 100%;
  &.sortable-ghost {
    background-color: aliceblue;
  }
  &.sortable-chosen {
    background-color: aliceblue;
  }
  &[contenteditable='true'] {
    background-color: aliceblue;
  }
`;

export const DeleteWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${Layers.WARNING};
`;

export const WorkStepNumberContainer = styled.div<{
  isTag: boolean;
}>`
  &&.work-step-aligned-right {
    min-width: 45px;
  }
  &&.work-step-aligned-top {
    width: 20px;
    display: block;
    top: 0;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: ${(props) => (props.isTag ? '-27px auto 0' : '-15px auto 0')};
  }
`;

export const MultiChoiceDraftWorkStepItemBox = styled.div`
  display: block;
  overflow: hidden;
  background: var(--cogs-greyscale-grey3);
  border: 1px solid var(--cogs-greyscale-grey5);
  border-radius: 0px 0px 3px 3px;

  &.border-less {
    border: 0;
  }

  &:hover {
    background: var(--cogs-greyscale-grey2);
  }

  &.active {
    background: white;
  }

  &.highlighted {
    background-color: #fff;
  }

  .workstep-multiple-choices-select,.workstep-multiple-choices-select: hover {
    background: var(--cogs-greyscale-grey5);
    border-color: var(--cogs-greyscale-grey5);
    margin-left: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
  }
  .workstep-multiple-choices-select:hover {
    border-color: var(--cogs-greyscale-grey6);
  }

  .workstep-multiple-choices-select:disabled {
    background: var(--cogs-greyscale-grey6);
  }
`;

export const MissingWorkStepItemBox = styled.div`
  display: block;
  overflow: hidden;
  background: var(--cogs-greyscale-grey3);
  padding-bottom: 10px;
  border: 1px dashed red;
  border-radius: 3px;

  &.padding-bottom-less {
    padding-bottom: 0;
  }

  &:hover {
    background: var(--cogs-greyscale-grey2);
  }

  &.ignored {
    height: 5px;
    padding-bottom: 0;
    border: 0;
    border-top: 1px dashed red;
  }

  &.active {
    background: white;
  }

  &.highlighted {
    background-color: #fff;
  }

  .step-missing-lbl {
    color: black;
    font-style: italic;
    display: block;
    padding-top: 10px;
    padding-left: 24px;
  }
`;

export const CheckedAndDoneArea = styled.div`
  padding: 4px 8px 8px 24px;
  background-color: var(--cogs-white);
  color: black;
  line-height: 20px;
`;
