/* eslint-disable no-param-reassign */
import { Phase, PhaseType } from 'types';

export class PhasesService {
  updatePhaseName(phase: Phase, name: string): Phase {
    phase.name = name;
    return phase;
  }

  setApproved(phase: Phase, user: string): Phase {
    phase.approvedBy = user;
    phase.disapprovedBy = '';
    phase.approvedTime = new Date().getTime();
    return phase;
  }

  setUnApproved(phase: Phase, user: string): Phase {
    phase.approvedBy = '';
    phase.disapprovedBy = user;
    phase.approvedTime = new Date().getTime();
    return phase;
  }

  updatePhaseMatchIdCallback(
    phases: Phase[],
    phaseId: string,
    updateCallback: (phase: Phase) => Phase
  ): Phase[] {
    return phases.map((phase) => {
      if (phase.id === phaseId) {
        return updateCallback(phase);
      }

      return phase;
    });
  }

  updateOrderOfPhases(phases: Phase[]): Phase[] {
    const sortByPosition = (arr: Phase[]): Phase[] => {
      if (!arr) {
        return [];
      }
      return arr.sort((a, b) => (a.position > b.position ? 1 : -1)) as Phase[];
    };

    return ([] as Phase[])
      .concat(sortByPosition(phases.filter((p) => p.phaseType === 'isolation')))
      .concat(
        sortByPosition(phases.filter((p) => p.phaseType === 'deisolation'))
      )
      .map((phase, idx) => {
        return { ...phase, position: idx };
      })
      .sort((w, next) => w.position - next.position);
  }

  hasDeIsolationPhases(phases: Phase[]): boolean {
    return phases.filter((p) => p.phaseType === 'deisolation').length > 0;
  }

  findPhaseIndex(phases: Phase[], phaseId: string): number {
    return phases.findIndex((phase) => phase.id === phaseId);
  }

  /** Returns Linked phase id. If isolation, returns the id of the deisolation phase
   * If Deisolation, returns the id of the isolaiton phase.
   */
  getLinkedPhaseId(phase: Phase, phaseType: PhaseType): string {
    return (
      phaseType === 'deisolation' ? phase.linkedPhaseId : phase.id
    ) as string;
  }

  /** Returns Linked phase id. If isolation, returns the id of the deisolation phase
   * If Deisolation, returns the id of the isolaiton phase.
   */
  getLinkedPhaseIndex(
    phases: Phase[],
    linkedPhaseId: string,
    phaseType: PhaseType
  ): number {
    return phases.findIndex((phase) =>
      phaseType === 'deisolation'
        ? phase.id === linkedPhaseId
        : phase.linkedPhaseId === linkedPhaseId
    );
  }
}
