import React from 'react';
import { Body, Flex, Input, Radio } from '@cognite/cogs.js';
import { SizeAndClassType } from 'types';
import { useActiveWorkStep } from 'redux/reducers/activeWorkStep/hooks';

export type Props = {
  sizeAndClass?: SizeAndClassType;
  setSizeAndClass: Function;
  editable: boolean;
};

export const SizeAndClass = ({
  sizeAndClass,
  setSizeAndClass,
  editable,
}: Props) => {
  const { classTypes } = useActiveWorkStep();

  if (editable) {
    return (
      <Flex direction="row" style={{ marginTop: '8px', flexFlow: 'wrap' }}>
        <Input
          title="Size"
          size="small"
          htmlSize={6}
          data-testid="setSize"
          value={sizeAndClass?.size || ''}
          onChange={({ target }) => {
            setSizeAndClass({ ...sizeAndClass, size: target.value });
          }}
        />
        <Radio
          id="sizeMM"
          name="size"
          value="mm"
          data-testid="sizeMM"
          checked={sizeAndClass?.unit === 'mm'}
          style={{ alignItems: 'flex-end', paddingBottom: '3px' }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            value?: string
          ) => {
            const val = value || event.target.value;
            setSizeAndClass({ ...sizeAndClass, unit: val });
          }}
          label="mm"
        />
        <Radio
          id="sizeInches"
          name="size"
          value="inches"
          checked={sizeAndClass?.unit === 'inches'}
          style={{
            alignItems: 'flex-end',
            paddingBottom: '3px',
            marginRight: '5px',
          }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            value?: string
          ) => {
            const val = value || event.target.value;
            setSizeAndClass({ ...sizeAndClass, unit: val });
          }}
          label="inches"
        />
        <div className="cogs-input-container">
          <Body level={2} style={{ marginBottom: '4px' }}>
            Class
          </Body>
          <select
            className="cogs-input-tiny"
            data-testid="class-options"
            style={{ width: '190px' }}
            value={sizeAndClass?.classType}
            onChange={({ target }) => {
              setSizeAndClass({ ...sizeAndClass, classType: target.value });
            }}
          >
            <option value="">Select</option>
            {classTypes.map((cl: string) => {
              return (
                <option key={cl} data-testid={`class-option-${cl}`}>
                  {cl}
                </option>
              );
            })}
          </select>
        </div>
      </Flex>
    );
  }
  return null;
};
