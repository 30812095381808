import React from 'react';

export const WarningIcon = ({ color }: { color?: string }) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0043 0.5C10.6194 -0.166667 9.65718 -0.166666 9.27228 0.5L0.612029 15.5C0.227129 16.1667 0.708254 17 1.47805 17H18.7986C19.5684 17 20.0495 16.1667 19.6646 15.5L11.0043 0.5ZM9.31633 4.81836H10.952L10.8227 12.0719H9.44559L9.31633 4.81836ZM11.126 14.1003C11.126 14.6522 10.6736 15.0946 10.1317 15.0946C9.58479 15.0946 9.13735 14.6522 9.13735 14.1003C9.13735 13.5584 9.58479 13.111 10.1317 13.111C10.6736 13.111 11.126 13.5584 11.126 14.1003Z"
      fill={color || '#FFBB00'}
    />
  </svg>
);
