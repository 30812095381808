export const METRICS_AREA = {
  ANNOTATIONS: 'Annotations',
  LOGIN: 'login',
  PROJECT_LOAD: 'ProjectLoad',
  PROJECT_SAVE: 'Procedure_Saving',
  PROJECT_EDIT_DRAWER: 'Edit_Procedure_Drawer',
  PROJECT_MIGRATE: 'Migrate_Procedure',
  PNID_LOAD: 'pnid_load',
  SEARCH: 'Search_Response',
  TOPBAR: 'Topbar',
};

export const METRICS = {
  PROJECT_LOAD: 'load_procedure',
  PROJECT_LOAD_TIME: 'loading_project',
  PROJECT_USE_HISTORICAL: 'use_hist_procedure',
  PROJECT_CREATE: 'create_procedure',
  PROJECT_SAVE: 'save_procedure',
  MIGRATE_PNID: 'migrate_pnid',
  COMPLETE_MIGRATION: 'migration_complete',
  MOVE_TO_REVIEW: 'move_to_review',
  MOVE_TO_READY_FOR_EXECUTION: 'move_to_ready_for_execution',
  MOVE_BACK_TO_COMPILATION: 'rejected_to_compilation',
  MOVE_TO_EXECUTION: 'move_to_execution',
  EDIT_EXECUTION_LIST: 'edit_execution_list',
  EDIT_SUPERVISION_LIST: 'edit_supervision_list',
  PROJECT_EDIT_DRAWER_OPEN: 'drawer_opened',
  STOP_EXECUTION: 'stop_execution',
  PNID_LOAD: 'pnid_load',
  ANNOTATION_EDIT: 'add_or_edit_annotation',
  ANNOTATION_DELETE: 'delete_annotation',
  SEARCH_NO_HISTORY_FOUND: 'no_historical_procedures',
  SEARCH_NO_ASSETS_FOUND: 'no_assets_found',
  TOPBAR_OPEN_SIDEBAR: 'open_sidebar',
};
