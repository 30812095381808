import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchActions,
  fetchClassTypes,
  fetchItemTypes,
  fetchReferenceTypes,
} from 'services';
import {
  Reference,
  SizeAndClassType,
  StepItem,
  WorkStep,
  WorkStepAction,
} from 'types';

import { actions, SelectionMode } from './activeWorkStepReducer';

export const useActiveWorkstepActions = () => {
  const dispatch = useDispatch();

  const cancelActive = useCallback(() => {
    dispatch(actions.cancelActive());
  }, [dispatch]);

  const getClassTypes = useCallback(async () => {
    const response = await fetchClassTypes();
    dispatch(actions.setClassTypes({ classTypes: response }));
  }, [dispatch]);

  const getItemTypes = useCallback(async () => {
    const response = await fetchItemTypes();
    dispatch(actions.setItemTypes({ itemTypes: response }));
  }, [dispatch]);

  const getReferenceTypes = useCallback(
    async (type: string) => {
      const response = await fetchReferenceTypes(type);
      dispatch(
        actions.setReferenceTypes({
          referenceTypes: response,
          type,
        })
      );
    },
    [dispatch]
  );

  const getTemplates = useCallback(
    async (type: string) => {
      const response = await fetchActions(type);
      dispatch(actions.setTemplates({ templates: response, type }));
    },
    [dispatch]
  );

  const selectAsset = useCallback(
    (item: StepItem) => {
      dispatch(actions.setItem({ item }));
    },
    [dispatch]
  );

  const selectLine = useCallback(
    (item: StepItem) => {
      dispatch(actions.setLine({ item }));
    },
    [dispatch]
  );

  const selectRelativeRef = useCallback(
    (item: StepItem, relation: Reference) => {
      dispatch(actions.setRelativeRef({ item, relation }));
    },
    [dispatch]
  );

  const selectTemplate = useCallback(
    (template: WorkStepAction) => {
      dispatch(actions.setTemplate({ template }));
    },
    [dispatch]
  );

  const setActive = useCallback(
    (workStep: WorkStep) => {
      dispatch(actions.setActive({ workStep }));
    },
    [dispatch]
  );

  const setActiveAfterSave = useCallback(
    (workStep: WorkStep) => {
      dispatch(actions.setActiveAfterSave({ workStep }));
    },
    [dispatch]
  );

  const setEditable = useCallback(
    (workStep: WorkStep) => {
      dispatch(actions.setEditable({ workStep }));
    },
    [dispatch]
  );

  const setSelectionMode = useCallback(
    (mode: SelectionMode) => {
      dispatch(actions.setSelectionMode({ mode }));
    },
    [dispatch]
  );

  const setSizeAndClass = useCallback(
    (sizeAndClass: SizeAndClassType) => {
      dispatch(actions.setSizeAndClass({ sizeAndClass }));
    },
    [dispatch]
  );
  const updateItemDetail = useCallback(
    (detail: string) => {
      dispatch(actions.updateItemDetail({ detail }));
    },
    [dispatch]
  );
  const updateItemOption = useCallback(
    ({ item, option }: { item: StepItem; option: Reference }) => {
      dispatch(actions.updateItemOption({ item, option }));
    },
    [dispatch]
  );

  const updateRelativePosition = useCallback(
    ({ item, position }: { item: StepItem; position: string }) => {
      dispatch(actions.updateRelativePosition({ item, position }));
    },
    [dispatch]
  );

  return {
    cancelActive,
    getClassTypes,
    getItemTypes,
    getReferenceTypes,
    getTemplates,
    selectAsset,
    selectLine,
    selectRelativeRef,
    selectTemplate,
    setActive,
    setActiveAfterSave,
    setEditable,
    setSelectionMode,
    setSizeAndClass,
    updateItemOption,
    updateRelativePosition,
    updateItemDetail,
  };
};
