import { toast } from '@cognite/cogs.js';
import { ExternalEvent, FileInfo } from '@cognite/sdk';
import {
  COMMENT_DATASET_ID,
  PREWORK_DATASET_ID,
  PROCEDURES_DATASET_ID,
  PROCEDURES_FILE_TYPE,
} from 'config/apiConfig';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { modelFactory } from 'factories';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthState } from 'redux/reducers/auth';
import { FilesState } from 'redux/reducers/files';
import projectSlice, { ProjectState } from 'redux/reducers/project';
import workStepsSlice, { WorkStepState } from 'redux/reducers/worksteps';
import { RootState } from 'redux/store';
import { preWorkItemsResourceService } from 'resources';
import {
  Activity,
  Comment,
  ExecutionSubStates,
  Phase,
  PreWorkItem,
  Project,
  ProjectStatus,
  UserActivity,
} from 'types';
import { showErrorNotification } from 'utils/errors';
import { logger } from 'utils/logger';
import { getClient } from '../config/cognitesdk';
import { getExecutionSubState } from '../pages/EditProcedure/components/ExecuteProcedure/ExecuteProcedure.utils';
import { useGlobal } from '../redux/reducers/global/hooks';
import useMetrics from './useMetrics';

type UploadFileData = {
  userInfo: string;
  fileInfo: Project;
  fileContent: Phase[];
  pnidsInfo: FileInfo[];
  fileId: string;
  executionSubState?: ExecutionSubStates;
  action?: string;
};

type UploadReviewCommentData = {
  userInfo: string;
  projectInfo: Project;
  comment: Comment;
};

export const useSave = () => {
  const { project, newProject, preWorkItems } = useSelector<
    RootState,
    ProjectState
  >((state) => {
    return state.project;
  });

  const { phases } = useSelector<RootState, WorkStepState>((state) => {
    return state.workSteps;
  });

  const { files } = useSelector<RootState, FilesState>((state) => {
    return state.files;
  });

  const { user } = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  const { getCurrentDataSetId } = useGlobal();

  const dispatch = useDispatch();
  const history = useHistory();

  const metrics = useMetrics(METRICS_AREA.PROJECT_SAVE);

  const commentEventUpload = async (
    uploadReviewCommentData: UploadReviewCommentData
  ) => {
    const { userInfo, projectInfo, comment } = uploadReviewCommentData;

    const commentEvent: ExternalEvent = {
      externalId: comment.id,
      dataSetId: getCurrentDataSetId(COMMENT_DATASET_ID),
      type: 'isoplan_review_comment',
      subtype: projectInfo.id,
      startTime: Date.now(),
      source: userInfo,
      metadata: {
        content: comment.commentContent,
        role: comment.role,
      },
    };
    return getClient().events.create([commentEvent]);
  };

  const preWorkItemsFileUpload = (preWorkItemsContent: PreWorkItem[]) => {
    return preWorkItemsResourceService.savePreWorkItems(
      project.id as string,
      preWorkItemsContent,
      getCurrentDataSetId(PREWORK_DATASET_ID)
    );
  };

  const createLogFile = (activity: Activity, projectInfo: Project) => {
    const MAX_ACTIVITIES_PER_USER = 5;
    const MAX_LOG_USERS = 2;
    // Check if projectInfo.logFile is an array
    if (Array.isArray(projectInfo.logFile)) {
      const userLogFile = projectInfo?.logFile?.find(
        (lg: UserActivity) => lg.userId === user
      );

      if (userLogFile) {
        // Make sure userLogFile.activities is an array
        if (!Array.isArray(userLogFile.activities)) {
          userLogFile.activities = [];
        }
        const newActivity = {
          timestamp: new Date().getTime().toString(),
          action: activity.action,
          details: activity.details,
        };

        const newActivityObj = [...userLogFile.activities, newActivity];
        const logsWithoutUser = projectInfo.logFile
          .filter((lg) => lg.userId !== user)
          ?.slice(0, MAX_LOG_USERS);
        const newUserLogFile: UserActivity[] = [
          ...logsWithoutUser,
          {
            userId: user,
            activities: newActivityObj.slice(0, MAX_ACTIVITIES_PER_USER),
          },
        ];

        return newUserLogFile;
      }

      const logsWithoutUser = projectInfo.logFile
        .filter((lg) => lg.userId !== user)
        ?.slice(0, MAX_LOG_USERS);
      const newUserLogFile: UserActivity[] = [
        ...logsWithoutUser,
        {
          userId: user,
          activities: [
            {
              timestamp: new Date().getTime().toString(),
              action: activity.action,
              details: activity.details,
            },
          ],
        },
      ];

      return newUserLogFile;
    }

    const newUserLogFile: UserActivity[] = [
      {
        userId: user,
        activities: [
          {
            timestamp: new Date().getTime().toString(),
            action: activity.action,
            details: activity.details,
          },
        ],
      },
    ];

    return newUserLogFile;
  };
  const fileUpload = async (uploadFileData: UploadFileData) => {
    const { userInfo, fileInfo, fileContent, pnidsInfo, fileId, action } =
      uploadFileData;

    let fileContentObject;
    try {
      const newData = JSON.parse(JSON.stringify(fileContent));
      const phasesDTO = modelFactory.mapPhasesToDto(newData);
      fileContentObject = {
        content: phasesDTO,
      };
    } catch (err) {
      logger(`[PROCEDURE_ID]=${fileId} Error while mapping phases to DTO`);
      logger(err);
    }

    const pnidDataAsArray = pnidsInfo.length
      ? pnidsInfo.map((pnid) => {
          return `"${pnid.id}"`;
        })
      : undefined;

    const pnidTitlesDataAsArray = pnidsInfo.length
      ? pnidsInfo.map((pnid) => {
          return `"${pnid?.metadata?.FILE_NAME} ${pnid?.metadata?.DOCUMENT_TITLE_2}"`;
        })
      : undefined;

    const passValvesAsArray = fileInfo.checkedValves
      ? fileInfo.checkedValves.map((valveId) => {
          return `"${valveId}"`;
        })
      : undefined;

    const executorEmailsAsArray = fileInfo.executorsList
      ? fileInfo.executorsList.map((executerEmail) => {
          return `"${executerEmail}"`;
        })
      : undefined;

    const supervisorEmailsAsArray = fileInfo.supervisorsList
      ? fileInfo.supervisorsList.map((supervisorEmail) => {
          return `"${supervisorEmail}"`;
        })
      : undefined;

    const pnidDataInRightFormat = pnidDataAsArray
      ? `[${pnidDataAsArray.toString()}]`
      : '';

    const pnidTitleInRightFormat = pnidTitlesDataAsArray
      ? `[${pnidTitlesDataAsArray.toString()}]`
      : '';

    const passValvesInRightFormat = passValvesAsArray
      ? `[${passValvesAsArray.toString()}]`
      : '';

    const executorEmailsInRightFormat = executorEmailsAsArray
      ? `[${executorEmailsAsArray.toString()}]`
      : '';

    const supervisorEmailsInRightFormat = supervisorEmailsAsArray
      ? `[${supervisorEmailsAsArray.toString()}]`
      : '';

    const pnIdsDrawingsInRightFormat = JSON.stringify(
      fileInfo.pnIdsDrawings ? fileInfo.pnIdsDrawings : {}
    );

    const updatedName =
      fileInfo.name || `Unnamed Procedure for ${fileInfo.asset?.externalId}`;
    const isInIsolation =
      fileInfo.status === 'executionReady' || fileInfo.status === 'execution';

    const isCompleted =
      fileInfo.status === 'done' || fileInfo.status === 'deleted';
    const isInIReview =
      fileInfo.status === 'review' || fileInfo.status === 'reviewReady';
    const fileInfoCompilerResolvedDate = fileInfo.resolved?.compiler?.date
      ? fileInfo.resolved?.compiler?.date.toFixed()
      : '';
    const projectCompilerResolvedDate = project.resolved?.compiler?.date
      ? project.resolved?.compiler?.date.toFixed()
      : '';

    const isolator = isInIsolation
      ? { name: user, date: String(Date.now()) }
      : {
          name: project.isolated?.name || '',
          date: project.isolated?.date || '',
        };

    const logDetails = `procedure in :${fileInfo.status}, compiler: ${
      project.compiler
    },  ${
      fileInfo.reviewed?.name ? `reviewer :${fileInfo.reviewed?.name}` : ''
    } 
    
    ${fileInfo.isolated?.name ? `isolator :${fileInfo.isolated?.name}` : ''}
    `;
    const logFile = createLogFile(
      { action: `user clicked ${action}` || '', details: logDetails },
      project
    );

    const compiler =
      !isInIsolation && !isInIReview && !isCompleted
        ? userInfo
        : project.compiler || userInfo;

    await getClient().files.upload(
      {
        externalId: fileId,
        assetIds: fileInfo.asset ? [Number(fileInfo.asset.id)] : undefined,
        dataSetId: getCurrentDataSetId(PROCEDURES_DATASET_ID),
        name: fileInfo.fileName,
        mimeType: PROCEDURES_FILE_TYPE,
        source: userInfo,
        metadata: {
          procedure_name: updatedName,
          due_date: fileInfo.due || '',
          method: fileInfo.method || '',
          objectives: fileInfo.objectives || '',
          pids: pnidDataInRightFormat,
          pidsTitles: pnidTitleInRightFormat,
          pnIdsDrawings: pnIdsDrawingsInRightFormat,
          passValves: passValvesInRightFormat,
          type: fileInfo.type || 'positive',
          status: fileInfo.status || 'compilation',
          rejectedName: fileInfo.rejected?.name || '',
          rejectedDate: fileInfo.rejected?.date || '',
          resubmittedName: fileInfo.resubmitted?.name || '',
          resubmittedDate: fileInfo.resubmitted?.date || '',
          reviewedName: fileInfo.reviewed?.name || '',
          reviewedDate: fileInfo.reviewed?.date || '',
          completed: fileInfo.completed ? fileInfo.completed.toFixed() : '',
          compilerResolvedName: !isInIsolation
            ? fileInfo.resolved?.compiler?.name || ''
            : project.reviewed?.name || '',

          compilerResolvedDate: !isInIsolation
            ? fileInfoCompilerResolvedDate
            : projectCompilerResolvedDate,

          isolatorName: isolator.name || '',
          isolatedDate: isolator.date || '',
          reviewerResolvedName: fileInfo.resolved?.reviewer?.name || '',
          reviewerResolvedDate: fileInfo.resolved?.reviewer?.date
            ? fileInfo.resolved?.reviewer?.date.toFixed()
            : '',
          executionStoppedName: fileInfo.executionStopped?.name || '',
          executionStoppedDate: fileInfo.executionStopped?.date
            ? fileInfo.executionStopped?.date.toFixed()
            : '',

          procedureDeletedName: fileInfo.deletionRecord?.name || '',
          procedureDeletedDate: fileInfo.deletionRecord?.date
            ? fileInfo.deletionRecord?.date.toFixed()
            : '',

          isDeleted: String(fileInfo.isDeleted || ''),
          assetExternalId: fileInfo.asset?.externalId || '',
          rootAssetExternalId: fileInfo.rootAsset || '',
          executionStarted: String(fileInfo.executionStarted) || '',
          certificateNumber: fileInfo.certificateNumber || '',
          executorsList: executorEmailsInRightFormat,
          supervisorsList: supervisorEmailsInRightFormat,
          updatedSource: compiler,
          needsMigration: String(fileInfo.needsMigration) || '',
          deletionRecord: String(fileInfo.deletionRecord),
          executionState:
            getExecutionSubState(project, phases)?.toString() || '',
          isApprovedProcedure: String(fileInfo.isApprovedProcedure) || '',
          copyOfApprovedProcedure: fileInfo.copyOfApprovedProcedure || '',
          logFile: JSON.stringify(logFile),
        },
      },
      fileContentObject,
      true
    );
  };

  const saveHandler = (action: string) => {
    if (
      project.status === 'compilation' ||
      project.status === 'review' ||
      project.status === 'executionReady'
    ) {
      const savingTime = metrics.start(METRICS.PROJECT_SAVE, {
        project: project.id,
        status: project.status,
      });
      const requiredFileId = String(project.id);
      const inputFile = {
        userInfo: user,
        fileInfo: project,
        fileContent: phases,
        pnidsInfo: files,
        fileId: requiredFileId,
        action: action || 'saveHandler',
      };

      fileUpload(inputFile)
        .then(() => {
          if (newProject) {
            toast.success(
              <div data-testid="new-procedure-saved-msg">
                Your procedure was saved
              </div>
            );

            const pnidDataAsArray = files.length
              ? files.map((pnid) => {
                  return pnid.id.toString();
                })
              : [];
            dispatch(
              projectSlice.actions.makeProjectNotNew({ pnids: pnidDataAsArray })
            );
          }
          metrics.stop(savingTime);
        })
        .catch((error) => {
          const errorMsg = (
            <div data-testid="save-handler-error-msg">
              <h3>Something went wrong</h3> And we’re not sure what. Try again
            </div>
          );
          showErrorNotification({ message: errorMsg, error });
          metrics.stop(savingTime);
        });
    }
  };

  const submitReviewReadyHandler = (action: string) => {
    if (project.status === 'compilation') {
      const requiredFileId = String(project.id);
      const projectStatusReviewReady = {
        ...project,
        status: 'reviewReady' as ProjectStatus,
        resubmitted: {
          name: user,
          date: String(Date.now()),
        },
      };
      const inputFile = {
        userInfo: project.compiler || user,
        fileInfo: projectStatusReviewReady,
        fileContent: phases,
        pnidsInfo: files,
        fileId: requiredFileId,
        action,
      };

      fileUpload(inputFile)
        .then(() => {
          history.push('/');
          dispatch(projectSlice.actions.clearProject());
          dispatch(workStepsSlice.actions.clearWorkSteps());
          const message = `Procedure ${project.name} has been submitted for review.`;
          toast.success(
            <div data-testid="procedure-submited-for-review-msg">{message}</div>
          );
          metrics.track(METRICS.MOVE_TO_REVIEW, {
            site: project.rootAsset,
            status: 'compilation',
          });
        })
        .catch(() => {
          toast.error(
            <div>
              <h3>Something went wrong</h3> And we’re not sure what. Try again
            </div>
          );
        });
    }
  };

  const pushToReviewHandler = () => {
    if (project.status === 'reviewReady') {
      const requiredFileId = String(project.id);
      const projectStatusReview = {
        ...project,
        status: 'review' as ProjectStatus,
        reviewed: {
          name: user,
          date: '',
        },
      };
      const inputFile = {
        userInfo: project.compiler || user,
        fileInfo: projectStatusReview,
        fileContent: phases,
        pnidsInfo: files,
        fileId: requiredFileId,
        action: 'push to review',
      };

      fileUpload(inputFile)
        .then(() => {
          dispatch(projectSlice.actions.updateProjectStatus('review'));
          const message = `Procedure ${project.name} is now in review.`;
          toast.success(<div>{message}</div>);
        })
        .catch(() => {
          toast.error(
            <div>
              <h3>Something went wrong</h3> And we’re not sure what.
            </div>
          );
        });
    }
  };

  const submitExecutionHandler = () => {
    if (project.status === 'review') {
      const requiredFileId = String(project.id);
      const projectStatusExecutionReady = {
        ...project,
        status: 'executionReady' as ProjectStatus,
        reviewed: {
          name: user,
          date: String(Date.now()),
        },
      };
      const inputFile = {
        userInfo: project.compiler || user,
        fileInfo: projectStatusExecutionReady,
        fileContent: phases,
        pnidsInfo: files,
        fileId: requiredFileId,
        action: 'submit execution',
      };

      fileUpload(inputFile)
        .then(() => {
          history.push('/');
          dispatch(projectSlice.actions.clearProject());
          dispatch(workStepsSlice.actions.clearWorkSteps());
          const message = `Procedure ${project.name} is now ready for execution!`;
          toast.success(<div>{message}</div>);
          metrics.track(METRICS.MOVE_TO_READY_FOR_EXECUTION, {
            site: project.rootAsset,
            status: 'review',
          });
        })
        .catch(() => {
          toast.error(
            <div>
              <h3>Something went wrong</h3> And we’re not sure what. Try again
            </div>
          );
        });
    }
  };

  const rejectCompilationHandler = () => {
    if (project.status === 'review') {
      const requiredFileId = String(project.id);
      const projectStatusCompilation = {
        ...project,
        status: 'compilation' as ProjectStatus,
        rejected: {
          name: user,
          date: String(Date.now()),
        },
      };
      const inputFile = {
        userInfo: project.compiler || user,
        fileInfo: projectStatusCompilation,
        fileContent: phases,
        pnidsInfo: files,
        fileId: requiredFileId,
        action: 'reject compilation',
      };

      fileUpload(inputFile)
        .then(() => {
          history.push('/');
          dispatch(projectSlice.actions.clearProject());
          dispatch(workStepsSlice.actions.clearWorkSteps());
          const message = `Procedure ${project.name} is rejected and returned to its compiler.`;
          toast.success(<div>{message}</div>);
          metrics.track(METRICS.MOVE_BACK_TO_COMPILATION, {
            site: project.rootAsset,
            status: 'review',
          });
        })
        .catch(() => {
          toast.error(
            <div>
              <h3>Something went wrong</h3> And we’re not sure what.
            </div>
          );
        });
    }
  };

  const saveProjectChanges = (
    inputFileOverrides?: Partial<UploadFileData>
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const requiredFileId = String(project.id);
      const inputFile = {
        userInfo: user,
        fileInfo: project,
        fileContent: phases,
        pnidsInfo: files,

        fileId: requiredFileId,
        action: inputFileOverrides?.action || 'saveProjectChanges',
        ...(inputFileOverrides || {}),
      };
      fileUpload(inputFile)
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          reject(err);
        });
    });
  };

  const stopProjectExecution = (): Promise<boolean> => {
    const emptyNameAndDate = {
      name: '',
      date: '',
    };

    const reqDTO = {
      ...project,
      status: 'compilation' as ProjectStatus,
      rejected: emptyNameAndDate,
      resubmitted: emptyNameAndDate,
      reviewed: emptyNameAndDate,
      executionStopped: {
        name: user,
        date: Date.now(),
      },
    };

    return saveProjectChanges({
      fileInfo: reqDTO,
      action: 'stop project execution button',
    });
  };

  const completeProjectExecution = (): Promise<boolean> => {
    if (project.status !== 'execution') {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(false);
    }

    const reqDTO = {
      ...project,
      status: 'done' as ProjectStatus,
      completed: Date.now(),
    };

    return saveProjectChanges({
      fileInfo: reqDTO,
      action: 'complete project execution button',
    });
  };

  const completeProjectExecutionForDelete = (): Promise<boolean> => {
    const reqDTO = {
      ...project,
      status: 'deleted' as ProjectStatus,
      isDeleted: true,
      completed: Date.now(),
      deletionRecord: {
        name: user,
        date: Date.now(),
      },
    };

    return saveProjectChanges({
      fileInfo: reqDTO,
      action: 'procedure delete button',
    });
  };

  const executionStartHandler = () => {
    if (project.status === 'executionReady') {
      const requiredFileId = String(project.id);
      const projectStatusExecution = {
        ...project,
        status: 'execution' as ProjectStatus,
        executionStarted: Date.now(),
      };
      const inputFile = {
        userInfo: project.compiler || user,
        fileInfo: projectStatusExecution,
        fileContent: phases,
        pnidsInfo: files,
        fileId: requiredFileId,
        action: 'execution Start button',
      };

      fileUpload(inputFile)
        .then(() => {
          dispatch(projectSlice.actions.updateProjectStatus('execution'));
          history.push(`/project/edit/${project.id}`);
          const message = `Procedure ${project.name} is now in execution!`;
          toast.success(
            <div>
              <h3>Success!</h3> {message}
            </div>
          );
          metrics.track(METRICS.MOVE_TO_EXECUTION, {
            site: project.rootAsset,
            status: 'executionReady',
          });
        })
        .catch(() => {
          toast.error(
            <div>
              <h3>Something went wrong</h3> And we’re not sure what. Try again
            </div>
          );
        });
    }
  };

  const updateExecutorsList = (userEmails: string[]) => {
    const requiredFileId = String(project.id);
    const projectExecutorsList = {
      ...project,
      executorsList: userEmails,
    };

    const inputFile = {
      userInfo: project.compiler || user,
      fileInfo: projectExecutorsList,
      fileContent: phases,
      pnidsInfo: files,
      fileId: requiredFileId,
      action: 'Update Executors List',
    };

    fileUpload(inputFile)
      .then(() => {
        dispatch(projectSlice.actions.updateExecutorsList(userEmails));
        metrics.track(METRICS.EDIT_EXECUTION_LIST, {
          site: project.rootAsset,
          status: project.status,
        });
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Something went wrong</h3> And we’re not sure what. Try again
          </div>
        );
      });
  };

  const updateSupervisorsList = (userEmails: string[]) => {
    const requiredFileId = String(project.id);
    const projectSupervisorsList = {
      ...project,
      supervisorsList: userEmails,
    };

    const inputFile = {
      userInfo: project.compiler || user,
      fileInfo: projectSupervisorsList,
      fileContent: phases,
      pnidsInfo: files,
      fileId: requiredFileId,
    };

    fileUpload(inputFile)
      .then(() => {
        dispatch(projectSlice.actions.updateSupervisorsList(userEmails));
        metrics.track(METRICS.EDIT_SUPERVISION_LIST, {
          site: project.rootAsset,
          status: project.status,
        });
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Something went wrong</h3> And we’re not sure what. Try again
          </div>
        );
      });
  };

  const saveReviewComment = async (comment: Comment) => {
    const inputFile = {
      userInfo: user,
      projectInfo: project,
      comment,
    };

    try {
      const response = await commentEventUpload(inputFile);
      if (response) {
        dispatch(
          projectSlice.actions.addNewReviewComment({
            comment,
          })
        );
        return true;
      }
      return false;
    } catch (error) {
      toast.error(
        <div>
          <h3>Sorry</h3> Your comment wasn’t saved. Try again
        </div>
      );
      return false;
    }
  };

  const savePreWorkItems = () => {
    if (
      project.status === 'compilation' ||
      project.status === 'executionReady'
    ) {
      preWorkItemsFileUpload(preWorkItems).catch(() => {
        toast.error(
          <div>
            <h3>Sorry</h3> Your action item wasn’t saved. Try again
          </div>
        );
      });
    }
  };

  const updateIsApprovedProcedure = (isApprovedProcedure: boolean) => {
    const savingTime = metrics.start(METRICS.PROJECT_SAVE, {
      project: project.id,
      status: project.status,
    });
    const inputFile = {
      userInfo: project.compiler || user,
      fileInfo: {
        ...project,
        isApprovedProcedure,
      },
      action: 'update IsApproved Procedure',
      fileContent: phases,
      pnidsInfo: files,
      fileId: String(project.id),
    };
    fileUpload(inputFile)
      .then(() => {
        dispatch(
          projectSlice.actions.updateProjectApprovedProcedure(
            isApprovedProcedure
          )
        );
        toast.success(
          <div data-testid="new-procedure-saved-msg">
            Your procedure was saved
          </div>
        );
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Something went wrong</h3> And we’re not sure what.
          </div>
        );
      })
      .finally(() => {
        metrics.stop(savingTime);
      });
  };

  const clearCopyOfApprovedProcedure = () => {
    const savingTime = metrics.start(METRICS.PROJECT_SAVE, {
      project: project.id,
      status: project.status,
    });
    const inputFile = {
      userInfo: project.compiler || user,
      fileInfo: {
        ...project,
        copyOfApprovedProcedure: undefined,
      },
      fileContent: phases,
      pnidsInfo: files,
      fileId: String(project.id),
      action: 'clear Copy Of Approved Procedure',
    };

    fileUpload(inputFile)
      .then(() => {
        dispatch(projectSlice.actions.clearCopyOfApprovedProcedure());
        toast.success(
          <div data-testid="new-procedure-saved-msg">
            Your procedure was saved
          </div>
        );
      })
      .catch(() => {
        toast.error(
          <div>
            <h3>Something went wrong</h3> And we’re not sure what.
          </div>
        );
      })
      .finally(() => {
        metrics.stop(savingTime);
      });
  };

  return {
    saveHandler,
    submitReviewReadyHandler,
    pushToReviewHandler,
    submitExecutionHandler,
    rejectCompilationHandler,
    executionStartHandler,
    saveReviewComment,
    saveProjectChanges,
    savePreWorkItems,
    stopProjectExecution,
    completeProjectExecution,
    updateExecutionList: updateExecutorsList,
    updateSupervisionList: updateSupervisorsList,
    updateIsApprovedProcedure,
    clearCopyOfApprovedProcedure,
    completeProjectExecutionForDelete,
  };
};
