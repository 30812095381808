import React from 'react';
import StartPageCard from '../StartPageCard';
import { CardLine } from '../../elements';
import { Project } from '../../../../types';
import { useStartPageActions } from '../../hooks/StartPageActions';

type ExecutionViewProps = {
  setClickedProcedure: Function;
  setDashboardModalVisible: Function;
  setIsOnlyMyProjects: Function;
  projects: Project[];
  isOnlyMyProjects: boolean;
  selectedRootAsset: string;
};

const ExecutionView = ({
  setClickedProcedure,
  setDashboardModalVisible,
  projects,
}: ExecutionViewProps) => {
  const { filterProjectsAndGroupByExecution } = useStartPageActions();

  const ExecutionViewVM = filterProjectsAndGroupByExecution(projects);

  return (
    <CardLine data-testid="execution-overview">
      <StartPageCard
        title="NOT STARTED"
        textColor="#000000"
        borderColor="#FFDC7F"
        backgroundColor="#FFDC7F"
        projects={ExecutionViewVM.NotStarted}
        selectProject={setClickedProcedure}
        setModalVisible={setDashboardModalVisible}
        data-testid="not-started"
      />
      <StartPageCard
        title="ISOLATION PHASE"
        textColor="#00665C"
        borderColor="#6ED8BE"
        backgroundColor="#FFFFFF"
        projects={ExecutionViewVM.IsolationStarted}
        selectProject={setClickedProcedure}
        setModalVisible={setDashboardModalVisible}
        data-testid="isolation-phase"
      />
      <StartPageCard
        title="ISOLATION IN PLACE"
        textColor="#333333"
        borderColor="#FFDC7F"
        backgroundColor="#FFFFFF"
        projects={ExecutionViewVM.IsolationDone}
        selectProject={setClickedProcedure}
        setModalVisible={setDashboardModalVisible}
        data-testid="isolation-in-place"
      />
      <StartPageCard
        title="DEISOLATION PHASE"
        textColor="#00665C"
        borderColor="#6ED8BE"
        backgroundColor="#FFFFFF"
        projects={ExecutionViewVM.DeIsolationStarted}
        selectProject={setClickedProcedure}
        setModalVisible={setDashboardModalVisible}
        data-testid="deisolation-phase"
      />
      <StartPageCard
        title="DEISOLATION DONE"
        textColor="#00665C"
        borderColor="#C8F4E7"
        backgroundColor="#C8F4E7"
        projects={ExecutionViewVM.DeIsolationDone}
        selectProject={setClickedProcedure}
        setModalVisible={setDashboardModalVisible}
        data-testid="deisolation-done"
      />
    </CardLine>
  );
};

export default ExecutionView;
