import React from 'react';
import { PnId } from 'types';
import { openFileIdInNewWindow } from 'services/services';
import { TextDetailSmall, TextDetailRed, LinkButton } from '../../elements';

type PnidInfoProps = {
  pnid: PnId;
  needsUpdate: boolean;
};

const PnidInfo = ({ pnid, needsUpdate }: PnidInfoProps) => {
  return (
    <div
      style={{
        textAlign: 'left',
        margin: '0px 27px 15px 0px',
        width: '210px',
      }}
    >
      <TextDetailSmall>{pnid.filename}</TextDetailSmall>
      {pnid.version && !needsUpdate && (
        <TextDetailSmall>Revision: {pnid.version}</TextDetailSmall>
      )}
      {pnid.version && needsUpdate && (
        <TextDetailRed>Revision: {pnid.version} (outdated)</TextDetailRed>
      )}
      <TextDetailSmall>{pnid.extendedDescription}</TextDetailSmall>
      <LinkButton
        style={{
          textAlign: 'left',
          wordBreak: 'keep-all',
          marginTop: '5px',
        }}
        onClick={() => openFileIdInNewWindow(Number(pnid.id))}
      >
        {pnid.description}
      </LinkButton>
    </div>
  );
};

export default PnidInfo;
