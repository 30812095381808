import styled from 'styled-components/macro';
import React from 'react';
import { LinkButton } from '../elements';

export const LogoSpan = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--cogs-greyscale-grey2);
  margin-right: 6px;
  text-align: center;
`;

export const AttachmentName = styled(LinkButton)`
  text-align: left;
  word-break: break-all;
  font-weight: 500 !important;
`;

export const AttachmentsWrapper = React.memo(styled.div`
  width: 100%;
  div.topFlex {
    margin-top: 11px;
    justify-content: space-between;
  }
  span.removed {
    word-break: break-all;
  }
`);
