import React from 'react';
import { LoadingPlaceholderWrapper } from './elements';

export interface LoadingPlaceholderProps {
  useCompactMode?: boolean;
}
export const LoadingPlaceholder = React.memo(
  ({ useCompactMode = false }: LoadingPlaceholderProps) => {
    return (
      <LoadingPlaceholderWrapper>
        <div className={useCompactMode ? 'wrapper compact' : 'wrapper'}>
          <div className="wrapper-cell">
            <div className="image" />
            <div className="text">
              <div className="text-line"> </div>
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
            </div>
          </div>
          <div className="wrapper-cell">
            <div className="image" />
            <div className="text">
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line"> </div>
            </div>
          </div>
          <div className="wrapper-cell">
            <div className="image" />
            <div className="text">
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
            </div>
          </div>
          <div className="wrapper-cell">
            <div className="image" />
            <div className="text">
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
            </div>
          </div>
          <div className="wrapper-cell">
            <div className="image" />
            <div className="text">
              <div className="text-line"> </div>
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
            </div>
          </div>
          <div className="wrapper-cell">
            <div className="image" />
            <div className="text">
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line" />
              <div className="text-line"> </div>
            </div>
          </div>
        </div>
      </LoadingPlaceholderWrapper>
    );
  }
);
