import React, { useState } from 'react';
import { Flex, Input, Button } from '@cognite/cogs.js';
import { ProjectStatus } from 'types';
import { useDispatch } from 'react-redux';
import projectSlice from 'redux/reducers/project';
import { ReactComponent as Icon } from './cnIcon.svg';
import { Title, ProjectContainer, LogoArea, TextDetail } from '../elements';

type CertificateNumberCardProps = {
  projectStatus: ProjectStatus;
  certificateNumber?: string;
  viewOnly?: boolean;
};

const CertificateNumber = ({
  projectStatus,
  certificateNumber,
  viewOnly = false,
}: CertificateNumberCardProps) => {
  const dispatch = useDispatch();
  const [editableCertificate, setEditableCertificate] = useState<boolean>(
    !viewOnly && !certificateNumber
  );
  const [updatedNumber, setUpdatedNumber] = useState<string>(
    certificateNumber || ''
  );
  const onSaveHandler = () => {
    setEditableCertificate(false);
    dispatch(projectSlice.actions.updateCertificateNumber(updatedNumber));
  };

  return (
    <ProjectContainer>
      <LogoArea>
        <Icon style={{ marginRight: '6px' }} />
        <Title>Isolation certificate number</Title>
      </LogoArea>
      {editableCertificate && projectStatus === 'executionReady' ? (
        <Flex
          direction="row"
          style={{
            marginTop: '10px',
            width: '100%',
          }}
        >
          <Input
            fullWidth
            style={{ margin: '0px 11px' }}
            value={updatedNumber}
            onChange={({ target }) => {
              setUpdatedNumber(target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSaveHandler();
              }
            }}
          />
          <Button type="secondary" disabled={viewOnly} onClick={onSaveHandler}>
            Save
          </Button>
        </Flex>
      ) : (
        <Flex
          direction="row"
          style={{
            marginTop: '10px',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextDetail style={{ marginLeft: '10px' }}>
            {certificateNumber}
          </TextDetail>

          {projectStatus === 'executionReady' && !viewOnly && (
            <Button
              type="secondary"
              onClick={() => setEditableCertificate(true)}
            >
              Edit
            </Button>
          )}
        </Flex>
      )}
    </ProjectContainer>
  );
};

export default CertificateNumber;
