import React from 'react';
import { WorkStep, WorkstepPairs } from 'types';
import { v4 as uuidv4 } from 'uuid';
import { StepBoxesWrapper } from './elements';
import { StepBoxItem } from './StepBoxItem';

export const StepBox = ({
  active,
  workStepsPairs,
  workSteps,
  onSelected,
}: {
  active?: WorkStep;
  workStepsPairs: Array<WorkstepPairs>;
  workSteps: Array<WorkStep>;
  onSelected: Function;
}) => {
  const onlyOnePair = workStepsPairs.length === 1;
  return (
    <StepBoxesWrapper removePadding={onlyOnePair} key={uuidv4()}>
      {workStepsPairs.map((workStepPair) => {
        const activeWorkstep = workSteps.find(
          (ws) => ws.id === workStepPair.id
        );
        const isActiveBox =
          active &&
          (active.id === workStepPair.id ||
            active.id === workStepPair.deisolationId);
        return (
          <StepBoxItem
            activeWorkstep={activeWorkstep}
            onSelected={onSelected}
            isActive={isActiveBox}
            workstepsPair={workStepPair}
            key={`stepbox-${workStepPair.id}`}
          />
        );
      })}
    </StepBoxesWrapper>
  );
};
