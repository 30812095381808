import styled from 'styled-components/macro';
import zindex from 'utils/zindex';

export const WorkstepWrapper = styled.div<{
  lockIconPresent: boolean;
  editable?: boolean;
  wasExecutionStoppedHere?: boolean;
}>`
  position: relative;
  height: 100%;
  margin-bottom: 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 3px 3px;
  ${(props) =>
    props.lockIconPresent
      ? 'display: flex; flex-direction: row-reverse; align-items: center;'
      : ''}

  ${(props) => props.editable && `z-index: ${zindex.WS_EDIT};`}
  ${(props) => props.wasExecutionStoppedHere && `padding-top: 33px;`}

  &:hover {
    box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.08),
      0px 2px 10px rgba(0, 0, 0, 0.06);
  }
`;

export const WorkstepsOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${zindex.WS_OVERLAY};
`;

export const WorkstepsSidebarContainer = styled.div<{
  hasDeisolation?: boolean;
}>`
  min-width: ${(props) => (props.hasDeisolation ? '664' : '334')}px;
  display: block;
  height: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;

  .worksteps-sidebar-content {
    background: transparent;
    position: absolute;
    top: 33px;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 35px;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;
  }

  .worksteps-sidebar-content-wrapper {
    display: block;
    overflow: visible;
    height: fit-content;
    position: relative;
    padding-top: 0;
    transform: scale(1);
    transition: 0.2s transform ease;
  }

  .worksteps-sidebar-panel {
    padding-bottom: 20px;
    padding-left: 12px;
    display: block;
    width: 50%;
    float: left;
    box-sizing: border-box;
    height: auto;
    display: block;
    overflow: hidden;
    position: relative;
    transform: scale(1);
    transition: 0.2s transform ease;

    &:last-child {
      padding-left: 4px;
      padding-right: 12px;
    }

    &:only-child {
      padding-left: 12px;
    }

    &.compact {
      padding: 0;
    }

    &.full-width {
      width: 100%;
    }
  }

  .worksteps-list-header {
    background: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    height: 33px;
    box-sizing: border-box;
    color: var(--cogs-greyscale-grey6);
    text-transform: uppercase;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.14px;
  }

  .worksteps-list-header > .worksteps-sidebar-panel {
    padding-top: 11px;
    padding-left: 24px;

    &:last-child {
      padding-left: 14px;
    }
  }

  .worksteps-list-footer {
    background: transparent;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    padding: 0 20px;
    user-select: none;
  }
`;

export const DeIsolationBlankSpaceBox = styled.div`
  display: block;
  overflow: hidden;
  height: 0px;
`;

export const WorkstepsListFooter = styled.div`
  background: transparent;
  position: fixed !important;
  bottom: 0;
  width: 100%;
  height: 35px;
`;

export const WorkStepListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-bottom: 20px;

  .add-workstep-panel {
    margin-top: 24px;
    width: 100%;
    text-align: right;
  }
`;

export const AddWorkstepArea = styled.div<{
  deisolation?: boolean;
}>`
  position: absolute;
  bottom: -17px;
  ${(props) => (props.deisolation ? 'right' : 'left')}: -16px;
`;

export const LockIconArea = styled.div<{
  deisolation?: boolean;
}>`
  position: absolute;
  ${(props) => (props.deisolation ? 'right' : 'left')}: -16px;
`;

export const HelpIconArea = styled.div<{
  deisolation?: boolean;
}>`
  position: absolute;
  display: block;
  overflow: hidden;
  width: 14px;
  height: 14px;
  top: 40%;
  ${(props) => (props.deisolation ? 'right' : 'left')}: -16px;

  > img {
    vertical-align: top;
  }
`;

export const ExecutionStoppedMessage = styled.div`
  background-color: #b30539;
  border-radius: 3px;
  color: var(--cogs-white);
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20px;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 5px;
  width: 100%;
  position: absolute;
  top: 0;
`;
