import React from 'react';
import { Button, Checkbox } from '@cognite/cogs.js';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/project';

import { PassValve } from 'types';
import { SingleValveDiv } from './elements';
import {
  TextDetail,
  TextDetailBald,
} from '../../Workorders/WorkorderBlock/elements';

type PassingValveBlockProps = {
  editMode: boolean;
  valve: PassValve;
  checkedValvesArray: string[] | undefined;
};

const PassingValveBlock = ({
  editMode,
  valve,
  checkedValvesArray,
}: PassingValveBlockProps) => {
  const dispatch = useDispatch();

  return (
    <SingleValveDiv data-testid={`pass-valve-${valve.id}`}>
      {editMode && (
        <Checkbox
          style={{ marginTop: '6px', marginRight: '12px' }}
          name={valve.id}
          checked={
            checkedValvesArray ? checkedValvesArray.includes(valve.id) : false
          }
          onChange={() => {
            dispatch(actions.addOrRemoveCheckedValve(valve.id));
          }}
        />
      )}
      <TextDetailBald style={{ margin: '6px 12px 0px 0px' }}>
        {valve.floc.split('.').pop()}
      </TextDetailBald>
      <TextDetail style={{ marginRight: '12px', marginTop: '6px' }}>
        {valve.tag}
      </TextDetail>
      <Button
        icon="ExternalLink"
        aria-label="Pass Valves"
        iconPlacement="right"
        style={{
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '-0.008em',
          fontWeight: 400,
          color: '#4A67FB',
          display: 'inline-flex',
          marginTop: '6px',
        }}
        onClick={() => window.open(valve.url, '_blank')}
      >
        Open in PV registry
      </Button>
    </SingleValveDiv>
  );
};

export default PassingValveBlock;
