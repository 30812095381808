import { CogniteClient } from '@cognite/sdk';
import { cogniteSdkExtensions } from './cognitesdk-extensions';
import features from './features';

let client: CogniteClient;

export const setClient = (newClient: CogniteClient) => {
  client = features.CACHE_PNIDS ? cogniteSdkExtensions(newClient) : newClient;
};

export const getClient = () => {
  return client;
};
