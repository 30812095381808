import { IconType } from '@cognite/cogs.js';
// eslint-disable-next-line
import { CogniteClient } from '@cognite/sdk/dist/src';
import ReactUnifiedViewer, {
  ToolType,
  ToolbarComponent,
  ToolConfig,
  TooltipConfig,
  UnifiedViewerEventListenerMap,
  UnifiedViewerEventType,
  UnifiedViewerNode,
} from '@cognite/unified-file-viewer';
import { UnifiedViewerPointerEvent } from '@cognite/unified-file-viewer/dist/core/UnifiedViewerRenderer/UnifiedEventHandler';
import { OptionProperty } from '@cognite/unified-file-viewer/dist/core/tools/Tool';
import { ToolConfigByToolType } from '@cognite/unified-file-viewer/dist/core/tools/types';
import React, { LegacyRef } from 'react';
import { getClient } from 'config/cognitesdk';

type ToolbarItemConfiguration = {
  type: ToolType;
  icon: IconType;
  properties?: OptionProperty[];
};

type IdsByType = {
  containerIds: string[];
  annotationIds: string[];
};

type UnifiedFileViewerProps = {
  applicationId: string;
  shouldUseAdaptiveRendering: boolean;
  nodes: UnifiedViewerNode[];
  tool?: ToolConfig;
  tooltips?: TooltipConfig[];
  onClick?: (event: UnifiedViewerPointerEvent) => void;
  cogniteClient?: CogniteClient;
  shouldAllowDragDrop?: boolean;
  onUpdateRequest?: UnifiedViewerEventListenerMap[UnifiedViewerEventType.ON_UPDATE_REQUEST];
  activeTool: ToolConfig;
  onToolChange: (tool: ToolConfig) => void;
  toolConfigByToolType: ToolConfigByToolType;
  style?: React.CSSProperties;
  tools: any;
  renderContextMenu?: (ids: IdsByType) => React.ReactElement | null;
  onSelect?: UnifiedViewerEventListenerMap[UnifiedViewerEventType.ON_SELECT];
  innerRef?: LegacyRef<HTMLDivElement>;
};

const UnifiedFileViewer = ({
  applicationId,
  shouldUseAdaptiveRendering,
  nodes,
  tool,
  tooltips,
  onClick,
  shouldAllowDragDrop,
  onUpdateRequest,
  activeTool,
  onToolChange,
  toolConfigByToolType,
  style,
  tools,
  renderContextMenu,
  onSelect,
  innerRef,
}: UnifiedFileViewerProps) => {
  return (
    <>
      <ReactUnifiedViewer
        id="container"
        applicationId={applicationId}
        shouldUseAdaptiveRendering={shouldUseAdaptiveRendering}
        nodes={nodes}
        tool={tool}
        tooltips={tooltips}
        onClick={onClick}
        cogniteClient={getClient() as any}
        shouldAllowDragDrop={shouldAllowDragDrop}
        onUpdateRequest={onUpdateRequest}
        renderContextMenu={renderContextMenu}
        onSelect={onSelect}
      />
      <div className="toolbar-wrapper" ref={innerRef}>
        <ToolbarComponent
          activeTool={activeTool}
          onToolChange={onToolChange}
          toolConfigByToolType={toolConfigByToolType}
          style={style}
          tools={tools}
        />
      </div>
    </>
  );
};

export default UnifiedFileViewer;
