import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProjectPage from '../Project';
import EditProcedure from '../EditProcedure';

import {
  RouterContext,
  RouterContextData,
} from '../../containers/RouterProviders';

export const isViewOnlyRedirect = (route: RouterContextData) =>
  (route.location.from?.search.includes('viewOnly') &&
    !route.location.to?.search.includes('viewOnly') &&
    route.location.to?.pathname.includes('project')) ||
  false;

const ProjectRoutes = () => {
  const route = useContext(RouterContext);

  return (
    <Switch>
      {isViewOnlyRedirect(route) && (
        <Redirect to={`${route.location.to?.pathname}?viewOnly=true`} />
      )}

      <Route path="/project/:id" exact component={ProjectPage} />
      <Route path="/project/edit/:id" exact component={EditProcedure} />
    </Switch>
  );
};

export default ProjectRoutes;
