import { useCallback, useState } from 'react';
import {
  ToolConfigByToolType,
  ToolType,
  ToolConfig,
} from '@cognite/unified-file-viewer/dist/core/tools/types';
import isString from 'lodash/isString';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { actions } from '../../../redux/reducers/annotations/annotationsReducer';

const isToolType = (tool: ToolType | ToolConfig): tool is ToolType =>
  isString(tool);

type ToolState = {
  tool: ToolConfig;
  toolConfigByToolType: ToolConfigByToolType;
};

type UseManagedToolsReturnType = {
  tool: ToolConfig;
  toolConfigByToolType: ToolConfigByToolType;
  setTool: (nextTool: ToolType | ToolConfig) => void;
};

const DEFAULT_STYLE = {
  fill: 'rgba(0, 179, 230, 0.5)',
  stroke: 'black',
  strokeWidth: 5,
};

const DEFAULT_TOOL_CONFIGS_BY_TOOL_TYPE: ToolConfigByToolType = {
  [ToolType.RECTANGLE]: { type: ToolType.RECTANGLE, ...DEFAULT_STYLE },
  [ToolType.ELLIPSE]: { type: ToolType.ELLIPSE, ...DEFAULT_STYLE },
  [ToolType.POLYLINE]: { type: ToolType.POLYLINE, ...DEFAULT_STYLE },
  [ToolType.TEXT]: {
    type: ToolType.TEXT,
    fontSize: 2,
    fill: 'black',
  },
  [ToolType.STICKY]: {
    type: ToolType.STICKY,
    width: 200,
    height: 200,
  },
  [ToolType.LINE]: {
    type: ToolType.LINE,
    ...DEFAULT_STYLE,
    isWorkspaceAnnotation: true,
    shouldGenerateConnections: true,
  },
  [ToolType.IMAGE]: {
    type: ToolType.IMAGE,
    imageUrl: '',
    imageSize: 200,
  },
  [ToolType.SELECT]: {
    type: ToolType.SELECT,
    shouldGenerateConnections: true,
  },
  [ToolType.PAN]: { type: ToolType.PAN },
};

const useManagedTools = (
  initialTool: ToolType | ToolConfig,
  initialToolsConfigByToolType?: Partial<ToolConfigByToolType>
): UseManagedToolsReturnType => {
  const dispatch = useDispatch<AppDispatch>();

  const [{ tool, toolConfigByToolType }, setToolState] = useState<ToolState>(
    () => {
      if (isToolType(initialTool)) {
        const toolConfigByToolType = {
          ...DEFAULT_TOOL_CONFIGS_BY_TOOL_TYPE,
          ...initialToolsConfigByToolType,
        };

        return {
          tool: toolConfigByToolType[initialTool],
          toolConfigByToolType,
        };
      }

      return {
        tool: initialTool,
        toolConfigByToolType: {
          ...DEFAULT_TOOL_CONFIGS_BY_TOOL_TYPE,
          ...initialToolsConfigByToolType,
          [initialTool.type]: initialTool,
        },
      };
    }
  );

  const findToolByType = (nextTool: ToolConfig) => {
    let selectedTool: string;
    switch (nextTool.type) {
      case ToolType.PAN:
        selectedTool = ToolType.PAN;
        break;
      case ToolType.SELECT:
        selectedTool = ToolType.SELECT;
        break;
      case ToolType.RECTANGLE:
        selectedTool = ToolType.RECTANGLE;
        break;
      case ToolType.POLYLINE:
        selectedTool = ToolType.POLYLINE;
        break;
      default:
        selectedTool = ToolType.SELECT;
        break;
    }

    return selectedTool;
  };

  const setTool: UseManagedToolsReturnType['setTool'] = useCallback(
    (nextTool) => {
      // dispatch(actions.cleanUpNewAnnotation()); commented temporarily
      if (isToolType(nextTool)) {
        setToolState((prevState) => ({
          ...prevState,
          tool: prevState.toolConfigByToolType[nextTool],
        }));
        return;
      }

      setToolState((prevState) => ({
        tool: nextTool,
        toolConfigByToolType: {
          ...prevState.toolConfigByToolType,
          [tool.type]: tool,
        },
      }));

      const selectedTool = findToolByType(nextTool);
      dispatch(actions.setCurrentTool({ tool: selectedTool }));
    },
    [tool.type]
  );

  return {
    tool,
    toolConfigByToolType,
    setTool,
  };
};

export default useManagedTools;
