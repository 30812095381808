import styled from 'styled-components/macro';

export const PreWorkItemContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  box-sizing: border-box;

  .cogs-checkbox .checkbox-ui {
    margin: 2px 0px 0px 0px;
  }
`;
