/* eslint-disable  */
import { CogniteClient } from '@cognite/sdk';

import { cacheRequest } from 'utils/cache';
import { HIGHLIGHTER_FILE_PREFIX } from './apiConfig';

export const cogniteSdkExtensions = (
  cogniteClient: CogniteClient
): CogniteClient => {
  const proxyMethod = (method: any, callback: any) => {
    return new Proxy(method, {
      apply: (target, that, args) => {
        return callback(target, that, args);
      },
    });
  };

  // @ts-ignore
  cogniteClient.extendSdkApis = () => {
    // some of the API's are available only if the user is logged in
    cogniteClient.login.status().then((res) => {
      cogniteClient.files.getDownloadUrls = proxyMethod(
        cogniteClient.files.getDownloadUrls,
        (target: any, that: any, args: any) => {
          const argsFlat = args.flat();
          if (
            Array.isArray(argsFlat) &&
            argsFlat.length === 2 &&
            argsFlat[1].cache === false
          ) {
            return target.apply(that, args);
          }
          if (
            Array.isArray(argsFlat) &&
            ((argsFlat[0] as any).id || (argsFlat[0] as any).externalId)
          ) {
            if ((argsFlat[0] as any).id) {
              const fileId = (argsFlat[0] as any).id;
              const cacheKey = `downloadlink${fileId}`;
              return cacheRequest(cacheKey, target, that, args, true);
            }
            const fileId: string = (argsFlat[0] as any).externalId;
            if (fileId.includes(HIGHLIGHTER_FILE_PREFIX)) {
              return target.apply(that, args);
            }
          }
          return target.apply(that, args);
        }
      );
    });
  };

  return cogniteClient;
};
