import { Colors } from '@cognite/cogs.js';

export const assetColor = `${Colors['decorative--yellow--400']}`;
export const userAssetColors = `${Colors['decorative--lightBlue--400']}`;
export const defaultColors = [
  '#D46AE2',
  '#6ED8BE',
  '#A4B2FC',
  '#FFB38B',
  '#FDCED6',
  '#BFBFBF',
];
const backgroundsThatGiveWhiteText = ['#923FA7', '#4A67FB', '#008000'];

/* eslint-disable no-param-reassign */
export const rgbToHex = (rgb: string): string => {
  rgb = rgb.trim();
  if (!rgb.startsWith('rgb(') || !rgb.endsWith(')')) {
    // console.log('Invalid RGB format.');
  }
  const values = rgb.slice(4, -1).split(',');
  if (values.length !== 3) {
    // console.log(
    //   'Invalid RGB format. Please provide a string with three comma-separated values.'
    // );
  }
  const rgbValues = values.map((value) => parseInt(value, 10));

  rgbValues.forEach((value) => {
    if (Number.isNaN(value) || value < 0 || value > 255) {
      // console.log('Invalid RGB value. Each value must be between 0 and 255.');
    }
  });
  const hexValues = rgbValues.map((value) =>
    value.toString(16).padStart(2, '0')
  );

  return `#${hexValues.join('')}`;
};

export const hexToRgb = (hex: string): string => {
  hex = hex.replace(/^#/, '');
  if (!hex.match(/^([A-Fa-f0-9]{3}){1,2}$/)) {
    // console.log('Invalid hex color code.');
  }

  hex = hex.length === 3 ? hex.repeat(2) : hex;
  const rgbValues = hex.match(/.{1,2}/g)!.map((value) => parseInt(value, 16));

  return `rgb(${rgbValues.join(', ')})`;
};

// this gives us more contrasting light levels if we use the colors' array in order
export const cogsColors = [100, 500, 200, 400, 300]
  .map((number: number) =>
    [
      'decorative--purple',
      'decorative--orange',
      'decorative--blue',
      'decorative--pink',
    ].map(
      (color: string) =>
        (Colors as { [key: string]: string })[`${color}--${number}`]
    )
  )
  .flat()
  .map((colour) => rgbToHex(colour).toUpperCase());

export const randomColor = (colors: string[]) => {
  const max = colors.length;
  const randomNr = Math.floor(Math.random() * Math.floor(max));
  return colors[randomNr];
};

export const nextColorFromList = (index: number) => {
  const colors = [...defaultColors, ...cogsColors];
  const { length } = colors;
  const adjustedIndex = index >= length ? index % length : index;
  return colors[adjustedIndex];
};

export const getTextColor = (backgroundColor?: string): string => {
  if (
    !!backgroundColor &&
    backgroundsThatGiveWhiteText.includes(backgroundColor)
  ) {
    return 'white';
  }
  return 'black';
};

/* eslint-disable consistent-return */
export const rgbToRgba = (rgb: string, opacity: number) => {
  let annotationColour;

  if (rgb) {
    if (rgb?.startsWith('rgb(') && opacity > 0 && opacity < 1) {
      annotationColour = rgb;
    } else {
      annotationColour = hexToRgb(rgb);
    }

    const match = annotationColour.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (match) {
      const red = parseInt(match[1], 10);
      const green = parseInt(match[2], 10);
      const blue = parseInt(match[3], 10);

      return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }
  }
};
