import styled from 'styled-components/macro';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  word-break: break-all;
  padding: 0px 15px 24px 15px;
  align-items: start;
  text-align: left;
`;

export const TitleBox = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const BoxItem = styled.div`
  background: white;
  box-shadow: 0px 2px 3px 0px #00000014;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border-color: var(--cogs-greyscale-grey3);
  }

  h2 {
    font-size: 17px;
    color: var(--cogs-midblue-2);
    word-break: keep-all;
  }
`;
