import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { GlobalState } from './globalReducer';

export const useGlobal = () => {
  const { dataSets } = useSelector<RootState, GlobalState>((state) => {
    return state.global;
  });

  const getCurrentDataSetId = (externalId: string): number | undefined => {
    return dataSets.find((value) => value.externalId === externalId)?.id;
  };

  return { getCurrentDataSetId };
};
