import { Button } from '@cognite/cogs.js';
import features from 'config/features';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable, Sortable } from 'react-sortablejs';
import { AuthState } from 'redux/reducers/auth';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { RootState } from 'redux/store';
import { workstepsService } from 'services';
import {
  ConfirmDraftWorkStepDTO,
  CreateNewWorkStepDTO,
  customEvents,
  PhaseType,
  ProjectStatus,
  StepExecutonStatus,
  WorkStep,
} from 'types';
import { PhaseContainer } from '../PhaseContainer';
import { WorkStepTypeSelector } from '../WorkStepItem/WorkStepTypeSelector';
import { AddNextWorkStep } from './AddNextWorkStep';
import {
  DeIsolationBlankSpaceBox,
  ExecutionStoppedMessage,
  LockIconArea,
  HelpIconArea,
  WorkStepListContainer,
  WorkstepWrapper,
} from './elements';
import { useScrollIntoView } from './hooks/useScrollIntoView';
import {
  deIsolationPhasesSelector,
  isolationPhasesSelector,
} from './selectors';

export type WorkstepsListProps = {
  projectStatus?: ProjectStatus;
  phasesType: PhaseType;
  onOverlayClicked: () => void;
  viewOnly: boolean;
};

export const WorkstepsList = ({
  projectStatus,
  phasesType,
  onOverlayClicked,
  viewOnly,
}: WorkstepsListProps) => {
  const {
    active,
    saving,
    workStepsLoaded,
    newWorkStep,
    moveWorkStep,
    getNextPosition,
    editWorkStep,
    deleteWorkStep,
    saveWorkStep,
    setWorkStepAsActive,
    confirmDraftWorkStep,
    previewDraftWorkStep,
    ignoreDraftWorkStep,
  } = useWorkSteps();

  const { user } = useSelector<RootState, AuthState>((state) => state.auth);
  const workstepsWrapperRef = useRef<HTMLDivElement>(null);

  const { scrollToWorkStep, scrollToEditableWorkStep, scrollAndClickWorkStep } =
    useScrollIntoView(workstepsWrapperRef, phasesType);

  const phases = useSelector((state) =>
    phasesType === 'isolation'
      ? isolationPhasesSelector(state)
      : deIsolationPhasesSelector(state)
  );
  const lastPhase = phases?.[phases.length - 1];

  const createNewWorkStep = ({
    phase,
    position,
    workStepIndex,
  }: CreateNewWorkStepDTO) => {
    let newPosition = position;

    if (phase) {
      const phaseType = phase.phaseType || 'isolation';
      newPosition = phaseType === 'deisolation' ? position + 1 : position;
    }

    newWorkStep({
      phase,
      position: newPosition,
      positionWithinPhase: workStepIndex + 1,
      phasesType,
    });

    const shouldScrollIntoView = !phases.length
      ? false
      : lastPhase.id === phase.id &&
        workStepIndex >= lastPhase.workSteps.length;
    if (shouldScrollIntoView) {
      window.dispatchEvent(
        new CustomEvent(customEvents.expandPhase, {
          detail: { id: phase.id, position: phase.position },
        })
      );

      setTimeout(() => {
        if (workstepsWrapperRef.current) {
          workstepsWrapperRef.current.scrollTop =
            workstepsWrapperRef.current.scrollHeight;
        }
      });
    }

    if (features.WORKSTEPS_SCROLL_ALIGN) {
      scrollToEditableWorkStep();
    }
  };

  const onWorkStepReorder = useCallback(
    (evt: Sortable.SortableEvent) => {
      const workStepId = evt.item.getAttribute('data-workstep-id');
      const fromPhaseId = evt.from
        .closest('[data-phase-id]')
        ?.getAttribute('data-phase-id');
      const toPhaseId = evt.to
        .closest('[data-phase-id]')
        ?.getAttribute('data-phase-id');

      if (!workStepId || !fromPhaseId) {
        return;
      }
      const movedWorkStep = phases
        .find((it) => it.id === fromPhaseId)
        ?.workSteps.find((ws) => ws.id === workStepId);
      if (
        movedWorkStep?.execution?.getStatus() === StepExecutonStatus.Checked
      ) {
        return;
      }
      if (
        movedWorkStep &&
        toPhaseId &&
        !isUndefined(evt.oldIndex) &&
        !isUndefined(evt.newIndex)
      ) {
        moveWorkStep({
          workStep: movedWorkStep,
          toPhase: toPhaseId,
          fromPhase: fromPhaseId,
          oldIndex: evt.oldIndex,
          newIndex: evt.newIndex,
        });
      }
    },
    // eslint-disable-next-line
    [phases]
  );

  const onWorkStepSelected = useCallback(
    (workStep: WorkStep) => {
      setWorkStepAsActive(workStep);
      if (features.WORKSTEPS_SCROLL_ALIGN) {
        scrollToWorkStep(workStep.id, phasesType);
      }
    },
    // eslint-disable-next-line
    [setWorkStepAsActive]
  );

  const onConfirmDraftWorkStep = useCallback(
    (dto: ConfirmDraftWorkStepDTO) => {
      confirmDraftWorkStep(dto);

      const predicate = (workStep: WorkStep) =>
        (workStep.isDraft &&
          workStep.position > dto.workStep.position) as boolean;
      const nextDrafWorkStep = workstepsService.getWorkstepWithPredicate(
        phases,
        predicate
      );

      if (nextDrafWorkStep && nextDrafWorkStep.id) {
        scrollAndClickWorkStep(nextDrafWorkStep.id);
      }
    },
    // eslint-disable-next-line
    [confirmDraftWorkStep, phases]
  );

  const workstepContainerId =
    phasesType === 'deisolation' ? 'deisolationWorkstepList' : 'workstepList';

  return (
    <WorkStepListContainer
      ref={workstepsWrapperRef}
      data-testid={workstepContainerId}
      data-worksteps-list-container-id={workstepContainerId}
    >
      {phasesType === 'deisolation' && (
        <DeIsolationBlankSpaceBox id="deIsolationBlankSpaceBox" />
      )}
      <ReactSortable
        group="phases"
        animation={250}
        swapThreshold={0.9}
        list={phases.map((x) => ({ ...x, chosen: true }))}
        setList={noop}
        disabled
      >
        {phases.map((phase) => {
          const isPhaseViewOnly = viewOnly || !!phase.approvedBy;
          return (
            <PhaseContainer
              key={`phase-${phase.id}`}
              phase={phase}
              projectStatus={projectStatus}
              viewOnly={isPhaseViewOnly}
            >
              <div style={{ marginTop: '8px', position: 'relative' }}>
                <ReactSortable
                  group={
                    phasesType === 'deisolation'
                      ? 'deisolation-worksteps'
                      : 'worksteps'
                  }
                  animation={250}
                  removeCloneOnHide={false}
                  list={phase.workSteps.map((x) => ({ ...x, chosen: true }))}
                  setList={noop}
                  disabled={isPhaseViewOnly || projectStatus !== 'compilation'}
                  onEnd={onWorkStepReorder}
                >
                  {phase.workSteps
                    .filter(
                      (ws) =>
                        !(
                          (projectStatus === 'execution' ||
                            projectStatus === 'executionReady') &&
                          ws.isDraft
                        )
                    )
                    .map((workStep, workStepIndex) => {
                      const wasExecutionStoppedHere =
                        workStep.execution?.getWasExecutionStopped();
                      const isDoneAndChecked =
                        workStep.execution?.getStatus() ===
                        StepExecutonStatus.Checked;
                      const isWorkstepLocked =
                        isPhaseViewOnly ||
                        projectStatus !== 'compilation' ||
                        (projectStatus === 'compilation' && isDoneAndChecked);
                      const isWorkstepEditable =
                        active.workStep?.id === workStep.id && active.editable;

                      return (
                        <WorkstepWrapper
                          data-workstep-id={workStep.id}
                          data-testid={`workStepItem-${workStep.id}`}
                          data-active={active.workStep?.id === workStep.id}
                          data-editable={isWorkstepEditable}
                          editable={isWorkstepEditable}
                          key={workStep.id}
                          data-linked-id={workStep.linkedWorkStepId}
                          lockIconPresent={isDoneAndChecked}
                          wasExecutionStoppedHere={
                            wasExecutionStoppedHere && !isDoneAndChecked
                          }
                        >
                          {wasExecutionStoppedHere &&
                            !isDoneAndChecked &&
                            projectStatus === 'compilation' && (
                              <ExecutionStoppedMessage>
                                Execution interrupted
                              </ExecutionStoppedMessage>
                            )}
                          <WorkStepTypeSelector
                            user={user}
                            active={active}
                            workStep={workStep}
                            phase={phase}
                            saving={saving}
                            projectStatus={projectStatus}
                            isDoneAndChecked={isDoneAndChecked}
                            onWorkStepSelected={onWorkStepSelected}
                            onEditWorkStep={editWorkStep}
                            onDeleteWorkstep={deleteWorkStep}
                            onSaveWorkstep={saveWorkStep}
                            onConfirmDraftWorkStep={onConfirmDraftWorkStep}
                            onPreviewDraftWorkStep={previewDraftWorkStep}
                            onIgnoreDraftWorkStep={ignoreDraftWorkStep}
                            viewOnly={viewOnly}
                          />
                          {(!isWorkstepLocked ||
                            (!phase.approvedBy && phase.disapprovedBy)) && (
                            <AddNextWorkStep
                              phase={phase}
                              phasesType={phasesType}
                              workStepId={workStep.id}
                              workStepPosition={workStep.position}
                              workStepIndex={workStepIndex}
                              isWorkstepEditable={isWorkstepEditable}
                              onCreateNewWorkStep={createNewWorkStep}
                              onOverlayClicked={onOverlayClicked}
                            />
                          )}
                          {isDoneAndChecked && (
                            <LockIconArea
                              deisolation={phasesType === 'deisolation'}
                              data-testid={`lock-icon-for-${workStep.id}`}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/images/Lock.svg`}
                                alt="lock"
                              />
                            </LockIconArea>
                          )}
                          {workStep.isDraft &&
                            !isDoneAndChecked &&
                            !workStep.isIgnored && (
                              <HelpIconArea
                                deisolation={phasesType === 'deisolation'}
                                data-testid={`draft-icon-for-${workStep.id}`}
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/HelpIcon.svg`}
                                  width="14"
                                  title="The workstep is in Draft mode"
                                  alt="draft"
                                />
                              </HelpIconArea>
                            )}
                        </WorkstepWrapper>
                      );
                    })}
                </ReactSortable>
              </div>
            </PhaseContainer>
          );
        })}
      </ReactSortable>
      {!viewOnly &&
        projectStatus === 'compilation' &&
        workStepsLoaded &&
        !lastPhase?.approvedBy && (
          <div className="add-workstep-panel">
            <Button
              data-testid="add-new-workstep-at-the-end"
              type="secondary"
              style={{
                marginRight: phasesType === 'deisolation' ? '32px' : '20px',
              }}
              onClick={() =>
                createNewWorkStep({
                  phase: lastPhase,
                  position: getNextPosition(phases),
                  workStepIndex: lastPhase ? lastPhase.workSteps.length : 0,
                })
              }
            >
              Add step
            </Button>
          </div>
        )}
    </WorkStepListContainer>
  );
};
