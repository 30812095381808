import { createSelector } from '@reduxjs/toolkit';
import { Phase } from 'types';

export const isolationPhasesSelector = createSelector(
  (state: any) => state.workSteps.phases,
  (allPhases) => {
    if (!allPhases || !allPhases.length) return [];

    return allPhases.filter(
      (phase: Phase) => phase.phaseType === 'isolation'
    ) as Phase[];
  }
);

export const deIsolationPhasesSelector = createSelector(
  (state: any) => state.workSteps.phases,
  (allPhases) => {
    if (!allPhases || !allPhases.length) return [];

    return allPhases.filter(
      (phase: Phase) => phase.phaseType === 'deisolation'
    ) as Phase[];
  }
);
