import { hasReviewerCommentsAfterResolved } from 'pages/EditProcedure/components/Worksteps/Review/Resolved';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectState } from 'redux/reducers/project';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { RootState } from 'redux/store';
import { WorkStep } from 'types';

export const useResolvedProject = () => {
  const { project, reviewComments } = useSelector<RootState, ProjectState>(
    (state) => state.project
  );

  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (project.status === 'compilation') {
      if (project.executionStopped?.date && !project.resolved?.reviewer?.date) {
        setIsResolved(true);
      } else if (reviewComments?.length && !project.resolved?.reviewer?.date) {
        setIsResolved(!!project.resolved?.compiler?.date);
      } else {
        setIsResolved(true);
      }
    } else if (project.status === 'review') {
      setIsResolved(true);
    }
  }, [project, reviewComments]);

  return isResolved;
};

export const useResolvedWorkSteps = () => {
  const { project } = useSelector<RootState, ProjectState>(
    (state) => state.project
  );

  const { revisedWorkSteps } = useWorkSteps();

  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (project.status === 'compilation') {
      setIsResolved(
        revisedWorkSteps().filter(
          (workStep: WorkStep) =>
            !workStep?.review?.resolved?.compiler?.date ||
            hasReviewerCommentsAfterResolved(workStep)
        ).length === 0
      );
    } else if (project.status === 'review') {
      setIsResolved(
        revisedWorkSteps().filter(
          (workStep: WorkStep) =>
            workStep?.review?.resolved?.compiler?.date &&
            !workStep?.review?.resolved?.reviewer?.date
        ).length === 0
      );
    }
  }, [project.status, revisedWorkSteps]);

  return isResolved;
};
