import React from 'react';
import { useDispatch } from 'react-redux';
import projectSlice from 'redux/reducers/project';
import {
  Comment,
  PassValve,
  Phase,
  PnId,
  PreWorkItem,
  ProcedureApprovalFile,
  ProcedureFileAttachment,
  Project,
  WorkOrder,
} from 'types';
import { Status } from 'redux/types';
import { useResolvedWorkSteps } from 'hooks/useResolved';
import PreWorkItems from './components/PreWorkItems';
import ProcedureBox from './components/ProcedureBox';
import Workorders from './components/Workorders';
import PassingValves from './components/PassingValves';
import CertificateNumber from './components/CertificateNumber';
import LinkedPnids from './components/LinkedPnids';
import Attachments from './components/Attachments';
import { WorkstepsTags } from './components/WorkstepsTags';
import About from './components/About';
import ProjectInfoSaved from './components/ProjectInfoSaved';
import { ProjectInfo } from './components/ProjectInfo';
import { ProjectCard } from './components/ProjectCard/ProjectCard';
import { ReviewComments } from './components/ReviewComments/ReviewComments';
import ApprovalFiles from './components/ApprovalFiles';
import ExecutorsList from './components/ExecutorsList';
import SupervisorsList from './components/SupervisorsList';
import QrCodes from './components/Qrcodes';
import ApprovedProcedures from './components/ApprovedProcedures';
import UserLogs from './components/UserLogs/UserLogs';

type ProjectPageComponentsProps = {
  project: Project;
  editMethod: boolean;
  viewOnly?: boolean;
  isolationWorkstepsNumber: number;
  deIsolationWorkstepsNumber: number;
  workorders: WorkOrder[];
  workOrdersStatus: Status;
  passValves: PassValve[];
  passValvesStatus: Status;
  user: string;
  comments: Comment[];
  pnids: PnId[];
  procedureBoxHighlighted: boolean;
  executionListHighlighted: boolean;
  SupervisionListHighlighted: boolean;
  preWorkItems: PreWorkItem[];
  preWorkItemsDone: boolean;
  preWorkItemsStatus: Status;
  passingValvesDone: boolean;
  attachmentsList: ProcedureFileAttachment[];
  approvalFilesList: ProcedureApprovalFile[];
  attachmentStatus: Status;
  approvalFilesStatus: Status;
  tagsBoxHighlighted: boolean;
  pnidsNamesToUpdate: string[];
  phases: Array<Phase>;
};

const viewLogsUserList = [
  'arun.shanmuganathan@cognitedata.com',
  'nishan.kossinna@cognitedata.com',
  'tharindu.ilangakoon@cognitedata.com',
];
const ProjectPageComponents = ({
  project,
  editMethod,
  viewOnly = false,
  isolationWorkstepsNumber,
  deIsolationWorkstepsNumber,
  workorders,
  passValves,
  passValvesStatus,
  user,
  comments,
  pnids,
  procedureBoxHighlighted,
  executionListHighlighted,
  SupervisionListHighlighted,
  preWorkItems,
  preWorkItemsDone,
  passingValvesDone,
  attachmentsList,
  approvalFilesList,
  tagsBoxHighlighted,
  pnidsNamesToUpdate,
  phases,
}: ProjectPageComponentsProps) => {
  const dispatch = useDispatch();
  const isWorkStepsResolved = useResolvedWorkSteps();

  const showLoading =
    !project.id ||
    passValvesStatus === Status.idle ||
    passValvesStatus === Status.loading;

  const projectInStatusDone = project.status === 'done';

  return (
    <>
      {(project.status === 'review' ||
        (project.status === 'compilation' &&
          (project.rejected?.name || !isWorkStepsResolved))) && (
        <div className="layout-item col-lg-1 col-md-1">
          <ReviewComments
            user={user}
            project={project}
            comments={comments}
            viewOnly={viewOnly || projectInStatusDone}
          />
        </div>
      )}

      <ProjectCard className="layout-item col-lg-1 col-md-1 md-pos-1">
        {editMethod ? (
          <ProjectInfo
            project={project}
            saveProject={(updatedProject: Project) => {
              dispatch(
                projectSlice.actions.updateProjectMethodAndObjectives({
                  method: updatedProject.method || '',
                  objectives: updatedProject.objectives || '',
                })
              );
              dispatch(projectSlice.actions.setEditMethod(false));
            }}
          />
        ) : (
          <ProjectInfoSaved
            project={project}
            viewOnly={viewOnly || projectInStatusDone}
            editProject={() => {
              dispatch(projectSlice.actions.setEditMethod(true));
            }}
          />
        )}
      </ProjectCard>

      {(project.status === 'executionReady' ||
        project.status === 'execution') && (
        <ProjectCard
          className="layout-item col-lg-2 col-md-2 md-pos-2"
          highlighted={!project.certificateNumber}
        >
          <CertificateNumber
            projectStatus={project.status}
            certificateNumber={project.certificateNumber}
            viewOnly={viewOnly || projectInStatusDone}
          />
        </ProjectCard>
      )}

      <ProjectCard className="layout-item col-lg-2 col-md-2 md-pos-3">
        <About project={project} viewOnly={viewOnly} />
      </ProjectCard>

      <ProjectCard
        className="layout-item col-lg-3 col-md-2 md-pos-2"
        highlighted={procedureBoxHighlighted}
      >
        <ProcedureBox
          project={project}
          isolationWorkstepsNumber={isolationWorkstepsNumber}
          deIsolationWorkstepsNumber={deIsolationWorkstepsNumber}
          viewOnly={viewOnly || projectInStatusDone}
        />
      </ProjectCard>

      <ProjectCard
        className="layout-item col-lg-1 col-md-1"
        highlighted={!preWorkItemsDone && project.status === 'executionReady'}
      >
        <PreWorkItems
          projectId={project.id}
          editMode={project.status === 'compilation' && !viewOnly}
          checkboxesMode={project.status === 'executionReady' && !viewOnly}
          preWorkItems={preWorkItems}
          showLoading={showLoading}
        />
      </ProjectCard>

      <ProjectCard
        className="layout-item col-lg-2 col-md-2 md-pos-4"
        highlighted={!passingValvesDone}
      >
        <PassingValves
          editMode={project.status === 'compilation' && !viewOnly}
          showLoading={showLoading}
          valves={passValves}
          checkedValvesArray={project.checkedValves}
        />
      </ProjectCard>
      <ProjectCard
        className="layout-item col-lg-3 col-md-2 md-pos-5"
        highlighted={tagsBoxHighlighted}
      >
        <WorkstepsTags
          projectStatus={project.status}
          editMode={
            (project.status === 'executionReady' ||
              project.status === 'execution') &&
            !viewOnly
          }
          showTagNumbers={
            project.status === 'executionReady' ||
            project.status === 'execution'
          }
        />
      </ProjectCard>
      <ProjectCard className="layout-item col-lg-1 col-md-1">
        <LinkedPnids pnids={pnids} pnidsNamesToUpdate={pnidsNamesToUpdate} />
      </ProjectCard>
      <ProjectCard className="layout-item col-lg-1 col-md-1">
        <QrCodes
          projectName={project.name || ''}
          phases={phases}
          certificateNumber={project.certificateNumber}
        />
      </ProjectCard>
      <ProjectCard className="layout-item col-lg-2 col-md-2">
        <Workorders
          workorders={workorders}
          timeToCompareWith={project.lastUpdated}
          projectStatus={project.status}
          showLoading={showLoading}
        />
      </ProjectCard>

      <ProjectCard className="layout-item col-lg-1 col-md-1">
        <ApprovedProcedures
          isApprovedProcedure={project.isApprovedProcedure}
          canEdit={!viewOnly}
          showLoading={showLoading}
        />
      </ProjectCard>
      {viewLogsUserList.includes(user?.toLowerCase()) && (
        <ProjectCard className="layout-item col-lg-1 col-md-1 md-pos-3">
          <UserLogs project={project} viewOnly={viewOnly} />
        </ProjectCard>
      )}
      <ProjectCard className="layout-item col-lg-3 col-md-2 md-pos-6">
        <Attachments
          attachmentsList={attachmentsList}
          canEdit={!viewOnly && !projectInStatusDone}
          showLoading={showLoading}
        />
      </ProjectCard>

      <ProjectCard className="layout-item col-lg-3 col-md-2 md-pos-6">
        <ApprovalFiles
          filesList={approvalFilesList}
          canEdit={!viewOnly && !projectInStatusDone}
          showLoading={showLoading}
        />
      </ProjectCard>
      <ProjectCard
        className="layout-item col-lg-3 col-md-2 md-pos-6"
        highlighted={executionListHighlighted}
      >
        <ExecutorsList
          executorsList={project.executorsList}
          viewOnly={viewOnly || projectInStatusDone}
        />
      </ProjectCard>
      <ProjectCard
        className="layout-item col-lg-3 col-md-2 md-pos-6"
        highlighted={SupervisionListHighlighted}
      >
        <SupervisorsList
          supervisorsList={project.supervisorsList}
          viewOnly={viewOnly || projectInStatusDone}
        />
      </ProjectCard>
    </>
  );
};

export default ProjectPageComponents;
