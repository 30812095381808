/* eslint-disable */
import styled from 'styled-components/macro';
import zIndex from 'utils/zindex';
import { Button } from '@cognite/cogs.js';
interface ButtonProps {
  position?: 'left' | 'right';
}
export const ExecutionWorkStepList = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  touch-action: pan-x;
  z-index: ${zIndex.WS_EDIT};

  display: flex;

  &.collapsed {
    transition: all 0.5s;
    opacity: 0;
    height: 0;
  }
`;
export const ExecutionWorkStepButton = styled(Button)<ButtonProps>`
  float: ${(props: ButtonProps) => props.position || 'none'};
`;
export const ExecutionListCollapseButton = styled.button`
  position: fixed;
  bottom: 8px;
  right: 8px;
  height: 50px;
  width: 50px;
  border-radius: 40px;
  border: 1px solid #e6e6e6 !important;
  z-index: ${zIndex.POPUP};
`;

export const PhaseStart = styled.div.attrs({ className: 'z-1' })`
  width: 130px;
  height: 150px;
  background: #fff;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  margin-right: 11px;
  padding: 8px;
  overflow-y: hidden;
  overflow-x: hidden;

  box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));
  border-radius: 4px 0px 0px 4px;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    border-top: 75px solid transparent;
    border-left: 11px solid white;
    border-bottom: 75px solid transparent;
    right: -11px;
  }
`;

export const PhaseBlock = styled.div`
  position: relative;
  height: auto;
  display: inline-flex;
  box-sizing: border-box;
  gap: 17px;
  align-items: end;
  border-bottom: 5px solid var(--cogs-greyscale-grey4);
  padding-bottom: 8px;
`;

export const PhaseWrapper = styled.div`
  padding: 12px 14px;
  display: flex;

  &.active {
    background: rgba(74, 103, 251, 0.25);

    ${PhaseBlock} {
      border-color: rgba(72, 97, 214, 0.4);
    }
  }
`;

export const ExecutionCompletion = styled.div`
  background: var(--cogs-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  width: 110px;

  div {
    color: white;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 500;
  }
`;

export const ExecutionWorkStepItemWrapper = styled.div`
  display: inline-block;
  width: 300px;
  height: auto;
  vertical-align: bottom;
  box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));
  border-radius: 4px 0px 0px 4px;
`;

export const ExecutionWorkStepItemActionsContainer = styled.div`
  background-color: white;
  display: block;
  overflow: hidden;
  padding: 8px 20px 10px 10px;
  box-sizing: border-box;
  color: var(--cogs-t4-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--cogs-greyscale-grey7);
  font-size: 12px;
`;

export const ExecutionWorkStepItemCompletedIcon = styled.div`
  left: 25px;
  top: -3px;
  position: absolute;
  color: var(--cogs-green-3);
`;

export const ExecutionWorkStepItemDoneCompletedIcon = styled.div`
  left: 25px;
  top: -3px;
  position: absolute;
  color: var(--cogs-midorange-3);
`;

export const PhasePosition = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--cogs-greyscale-grey6);
`;

export const PhaseName = styled.div`
  font-size: 16px;
  font-weight: 500;
  flex-grow: 1;
`;

export const PhaseDivider = styled.div`
  background-image: linear-gradient(
    var(--cogs-greyscale-grey6) 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: right;
  background-size: 2px 25px;
  background-repeat: repeat-y;
  flex: 0 0 2px;
  margin-right: -1px;
`;

export const PhaseType = styled.div`
  background: var(--cogs-greyscale-grey7);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: 6px 9px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  width: 45px;
  transform: rotate(180deg);
`;

export const StatusTag = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--cogs-greyscale-grey7);
  font-size: 12px;
`;

export const StatusTable = styled.table`
  width: min-content;
  color: var(--cogs-greyscale-grey7);
  font-size: 12px;
  margin-bottom: 5px;

  tr td:first-child {
    width: 45px;
    padding-right: 4px;
  }

  .all-success {
    color: var(--cogs-green-3);
  }
`;

export const PhaseDisapproved = styled.div`
  display: flex;
  align-items: center;
  color: var(--cogs-border--status-critical--strong);
  font-weight: 500;
  font-size: 14px;

  i {
    margin-right: 5px;
  }
`;

export const PhaseDisapproveDescription = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
`;

export const PhaseApproved = styled.div`
  display: flex;
  align-items: center;
  color: var(--cogs-green-3);
  font-weight: 500;
  font-size: 14px;

  i {
    margin-right: 5px;
  }
`;

export const PhaseApprovalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: 5px;
`;

export const PhaseEnd = styled.div.attrs({ className: 'z-1' })`
  width: 180px;
  height: 190px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  vertical-align: bottom;
  padding: 8px;
  overflow-y: hidden;
  overflow-x: hidden;

  box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));
  border-radius: 4px 0px 0px 4px;

  ${PhaseName} {
    overflow-y: hidden;
  }
`;
