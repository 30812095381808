import React from 'react';
import { useHistory } from 'react-router-dom';
import { TextDetail, LinkButton } from '../elements';
import { LinkedApprovedProcedureWrapper } from './elements';
import { useSave } from '../../../../hooks/useSave';

type LinkedApprovedProcedureProps = {
  copyOfApprovedProcedure?: string;
  viewOnly?: boolean;
};

const LinkedApprovedProcedure = ({
  copyOfApprovedProcedure,
  viewOnly = false,
}: LinkedApprovedProcedureProps) => {
  const history = useHistory();
  const { clearCopyOfApprovedProcedure } = useSave();

  return (
    <>
      {copyOfApprovedProcedure && (
        <LinkedApprovedProcedureWrapper>
          <TextDetail>
            Created from an approved procedure:{' '}
            <LinkButton
              data-testid="go-to-dashboard-linked-procedure"
              onClick={(e: any) => {
                e.preventDefault();

                if (!viewOnly) {
                  history.push(`/project/${copyOfApprovedProcedure}`);
                } else {
                  history.push(
                    `/project/${copyOfApprovedProcedure}?viewOnly=true`
                  );
                }

                // project page don't refresh automatically
                window.location.reload();
              }}
            >
              {copyOfApprovedProcedure?.split('-')[0]}&hellip; [click to open]
            </LinkButton>
          </TextDetail>
          If you make changes this procedure, see if the old one is still
          relevant as approved procedure.{' '}
          {!viewOnly && (
            <>
              <LinkButton
                onClick={() => {
                  clearCopyOfApprovedProcedure();
                }}
                data-testid="remove-link-approved-procedure"
              >
                Click here
              </LinkButton>
              {' to remove this link'}
            </>
          )}
        </LinkedApprovedProcedureWrapper>
      )}
    </>
  );
};

export default LinkedApprovedProcedure;
