import React from 'react';
import Badge from 'shared/Badge';
import CardContainer from 'containers/CardContainer';
import { Project } from 'types';
import { toDisplayDate } from 'utils/date';
import { TitleBox, Box, BoxItem } from './elements';

export type Props = {
  title: string;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  projects: Array<Project>;
  selectProject: Function;
  setModalVisible: Function;
};

type StartPageProps = Props & React.HTMLAttributes<HTMLDivElement>;

const StartPageCard: React.FC<StartPageProps> = ({
  title,
  textColor,
  borderColor,
  backgroundColor,
  projects,
  selectProject,
  setModalVisible,
  ...rest
}: StartPageProps) => {
  return (
    <CardContainer minHeight="380px">
      <TitleBox>
        <Badge
          data-testid="dashboard-card-title"
          style={{ margin: '0px' }}
          size={13}
          text={title}
          textColor={textColor}
          borderColor={borderColor}
          background={backgroundColor}
        />
      </TitleBox>
      <Box {...rest}>
        {projects.map((project) => {
          const compilerNameOnly = project.compiler?.split('@')[0];
          const reviewerNameOnly = project.reviewed?.name.split('@')[0];
          const onClickHandler = () => {
            selectProject(project);
            setModalVisible(true);
          };
          return (
            <BoxItem
              className="cogs-body-2"
              key={`dashboard-project-item-${project.id}`}
              onClick={onClickHandler}
            >
              <h2 data-testid={`dashboard-element-${project.id}`}>
                {project.name}
              </h2>
              <div style={{ margin: '4px 0px' }}>
                <div>
                  <span>Compiler: </span>
                  <span>{compilerNameOnly}</span>
                </div>

                {reviewerNameOnly ? (
                  <div>
                    <span>Reviewer: </span>
                    <span>{reviewerNameOnly}</span>
                  </div>
                ) : null}
              </div>
              <div>
                <span>Date started: </span>
                <span>
                  {toDisplayDate(project.created as number, 'DD.MM.YYYY')}
                </span>
              </div>
              {project.due ? (
                <div>
                  <span>Due date: </span>
                  <span>{project.due}</span>
                </div>
              ) : null}
              {project.rejected?.date && project.status === 'compilation' && (
                <div style={{ color: '#B30539', marginTop: '4px' }}>
                  <div data-testid={`rejected-${project.id}`}>
                    Returned by reviewer
                  </div>
                  <div>
                    {toDisplayDate(project.rejected?.date, 'DD.MM.YYYY')}
                  </div>
                </div>
              )}
              {project.resubmitted?.date &&
                project.rejected?.date &&
                (project.status === 'review' ||
                  project.status === 'reviewReady') && (
                  <div style={{ color: '#078D79', marginTop: '4px' }}>
                    <div data-testid={`resubmitted-${project.id}`}>
                      Resubmitted by compiler
                    </div>
                    <div>
                      {toDisplayDate(project.resubmitted?.date, 'DD.MM.YYYY')}
                    </div>
                  </div>
                )}
              {project.executionStopped?.date &&
                (project.status === 'compilation' ||
                  project.status === 'reviewReady' ||
                  project.status === 'review') && (
                  <div style={{ color: '#B30539', marginTop: '4px' }}>
                    <div data-testid={`retraced-${project.id}`}>
                      Retracted from execution
                    </div>
                    <div>
                      {toDisplayDate(
                        project.executionStopped?.date,
                        'DD.MM.YYYY'
                      )}
                    </div>
                  </div>
                )}
              {project.certificateNumber ? (
                <div>
                  <span>IC number: </span>
                  <span>{project.certificateNumber}</span>
                </div>
              ) : null}
            </BoxItem>
          );
        })}
      </Box>
    </CardContainer>
  );
};

export default React.memo(StartPageCard);
