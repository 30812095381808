import useMetrics from 'hooks/useMetrics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { assetsResourceService } from 'resources';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { showErrorNotification } from 'utils/errors';
import searchSlice, {
  fetchIsolationProcedures,
  SearchState,
  fetchProceduresForRootAsset,
} from './searchReducer';

export const useSearch = () => {
  const dispatch = useDispatch();

  const { selectedRootAsset, flocInput } = useSelector<RootState, SearchState>(
    (state) => {
      return state.search;
    }
  );

  const metrics = useMetrics(METRICS_AREA.SEARCH);

  const search = () => {
    if (!flocInput) {
      return;
    }

    dispatch(searchSlice.actions.startAssetSearch());

    assetsResourceService
      .fetchAssets({
        userSearchQuery: flocInput,
        userLocationChoice: selectedRootAsset,
      })
      .then((responseAssets) => {
        if (responseAssets.length) {
          dispatch(searchSlice.actions.searchAssetsSuccess(responseAssets));
          dispatch(searchSlice.actions.startProcedureSearch());
          fetchIsolationProcedures(
            {
              assetMapping: responseAssets,
            },
            selectedRootAsset
          )
            .then((responseProcedures) => {
              dispatch(
                searchSlice.actions.searchProceduresSuccess(responseProcedures)
              );
              if (!responseProcedures.length) {
                metrics.track(METRICS.SEARCH_NO_HISTORY_FOUND, {
                  searchQuery: flocInput,
                  site: selectedRootAsset,
                });
              }
            })
            .catch((error) => {
              showErrorNotification({
                message: `Failed to fetch procedures. Please try to
                refresh the page.`,
                error,
              });
              dispatch(searchSlice.actions.failedIsolationsSearch(error));
            });
        } else {
          dispatch(searchSlice.actions.failedAssetsSearch('No assets found'));
          metrics.track(METRICS.SEARCH_NO_ASSETS_FOUND, {
            searchQuery: flocInput,
            site: selectedRootAsset,
          });
        }
      })
      .catch((err: string) =>
        dispatch(searchSlice.actions.failedAssetsSearch(err))
      );
  };

  const searchProcedures = () => {
    return fetchProceduresForRootAsset(selectedRootAsset);
  };

  return { search, searchProcedures };
};
