import { KeyValuePair } from 'types';
/* eslint-disable */

export const dynamicConfig = {
  PROCEDURES_DATASET_ID: 'NZ_Isolation_Procedures',
  COMMENT_DATASET_ID: 'NZ_Isoplan_Review_Comments',
  ATTACHMENTS_DATASET_ID: 'NZ_Isoplan_Attachments',
  APPROVAL_FILES_DATASET_ID: 'DATASET:NZ:ISOPLAN_APPROVAL_FILES',
  PREWORK_DATASET_ID: 'NZ_Isoplan_PreWorkItems',
  HIGHLIGHTER_DATASET_ID: 'NZ_Isoplan_ManualDrawings',
};

export const WO_TYPE = 'workorder';
export const WO_DATASET_ID = 'NZ_sap_work_orders';
export const WO_VALID_TYPES = ['EP01', 'EP02'];

export const PV_DATASET_ID = 'NZ_Passing_Valves';

export let PREWORK_DATASET_ID = dynamicConfig.PREWORK_DATASET_ID as any;

export let HIGHLIGHTER_DATASET_ID = dynamicConfig.HIGHLIGHTER_DATASET_ID as any;
export const HIGHLIGHTER_FILE_TYPE = 'application/json';
export const HIGHLIGHTER_FILE_PREFIX = 'iso_drawing_';

export let ATTACHMENTS_DATASET_ID = dynamicConfig.ATTACHMENTS_DATASET_ID as any;
export let APPROVAL_FILES_DATASET_ID =
  dynamicConfig.APPROVAL_FILES_DATASET_ID as any;

export let COMMENT_DATASET_ID = dynamicConfig.COMMENT_DATASET_ID as any;
export const COMMENT_TYPE = 'isoplan_review_comment';

export const PNID_MIME_TYPE = 'application/pdf';
export const PNID_SOURCE = 'NZ_fileshare';
export const PNID_DATASET_ID = 'nz_fileshare';

export const PROJECT_ROOT_ASSETS = ['NZ.MAU', 'NZ.POH', 'NZ.EIL', 'NZ.TKF'];
export const EXCLUDED_ROOT_ASSETS = [
  'NZ.POH.WEL',
  'NZ.POH.XWE',
  'NZ.TKF.NKW',
  'NZ.TKF.MET',
];
export const ROOT_ASSET = 'EP.NZ';
export const DEFAULT_SITE = 'NZ.MAU.MPA';
export const DEFAULT_SITE_NAME = 'Maui A';

export const ASSETS_DATASET_ID = 'dataset:NZ:SAP_assets';
export const LINE_NUMBERS_DATASET_ID = 'NZ_SAP_linenumbers';

export const PROCEDURES_FILE_TYPE = 'application/json';
export let PROCEDURES_DATASET_ID = dynamicConfig.PROCEDURES_DATASET_ID as any;

export function initDynamicConfig(dynamicConfig: KeyValuePair) {
  PREWORK_DATASET_ID = dynamicConfig.PREWORK_DATASET_ID;
  ATTACHMENTS_DATASET_ID = dynamicConfig.ATTACHMENTS_DATASET_ID;
  APPROVAL_FILES_DATASET_ID = dynamicConfig.APPROVAL_FILES_DATASET_ID;
  COMMENT_DATASET_ID = dynamicConfig.COMMENT_DATASET_ID;
  PROCEDURES_DATASET_ID = dynamicConfig.PROCEDURES_DATASET_ID;
  HIGHLIGHTER_DATASET_ID = dynamicConfig.HIGHLIGHTER_DATASET_ID;
}
