/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Item, CogniteAnnotation } from 'types';

interface IActiveWorkHotSpotState {
  annotation?: CogniteAnnotation;
  asset?: Item;
}

const hotSpotStepSlice = createSlice({
  name: 'hotSpot',
  initialState: {
    activeHotSpot: {} as IActiveWorkHotSpotState,
  },
  reducers: {
    setActiveHotSpot: (
      state,
      action: PayloadAction<{ hotSpot: CogniteAnnotation }>
    ) => {
      state.activeHotSpot.annotation = action.payload.hotSpot;
    },
    setActiveAsset: (state, action: PayloadAction<{ asset: Item }>) => {
      state.activeHotSpot.asset = action.payload.asset;
    },
    clearActiveHotSpot: (state) => {
      state.activeHotSpot = {} as IActiveWorkHotSpotState;
    },
  },
});

export type HotSpotState = ReturnType<typeof hotSpotStepSlice.reducer>;
export const { actions } = hotSpotStepSlice;
export default hotSpotStepSlice;
