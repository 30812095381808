import { toast } from '@cognite/cogs.js';
import { logger } from './logger';

const generateErrorMessage = (errorCode: number): string => {
  switch (errorCode) {
    case 401:
      return 'Your account has insufficient access rights. Contact your project administrator.';
    case 404:
    case 403:
      return 'We could not find what you were looking for. Keep in mind that this may be due to insufficient access rights.';
    case 500:
    case 503:
      return 'Something went terribly wrong. You can try again in a bit.';
    case undefined:
      return 'We experienced a network issue while handling your request. Please make sure you are connected to the internet and try again.';
    default:
      return `Something went wrong. Please contact Cognite support if the error persists. Error code: ${errorCode}`;
  }
};

interface ErrorNotificationProps {
  message: string | React.ReactNode;
  error?: any;
  duration?: number;
}

export const showErrorNotification = (props: ErrorNotificationProps): void => {
  const defaultErrorMessage = `We’re sorry, we weren’t able to do that. Try again`;

  const { duration = 4000, error = undefined } = props;

  let displayErrorMessage = props.message;

  if (error) {
    if (error && 'status' in error && !props.message) {
      displayErrorMessage = generateErrorMessage(error.status);
    } else if (!props.message) {
      displayErrorMessage = defaultErrorMessage;
    }
    logger(error);
  }

  toast.error(displayErrorMessage, {
    autoClose: duration,
  });
};
