import { AnnotationDuplicatesService } from 'services/annotation-duplicates.service';
import {
  FetchAnnotationsForFileDTO,
  FileAnnotationsDTO,
  CogniteAnnotation,
} from 'types';
import { getClient } from '../config/cognitesdk';

export class AnnotationsResourceService {
  private annotationDuplicatesService: AnnotationDuplicatesService;
  constructor() {
    this.annotationDuplicatesService = new AnnotationDuplicatesService();
  }
  async fetchAnnotationsForFileId(
    dto: FetchAnnotationsForFileDTO,
    phases: any[]
  ): Promise<FileAnnotationsDTO> {
    const { fileId, projectAnnotations } = dto;
    const filter = {
      annotatedResourceIds: [{ id: fileId }],
      annotatedResourceType: 'file',
      annotationType: 'isoplan.IsoPlanAnnotation',
    } as any;

    const listResponseTemp = await getClient().annotations.list({
      filter,
      limit: 800,
    });

    const listResponse = listResponseTemp.items.filter(
      (annotation) => annotation.annotationType === 'isoplan.IsoPlanAnnotation'
    );

    const fileAnnotations = listResponse;
    let filteredAnnotations;
    if (fileAnnotations) {
      filteredAnnotations = dto.procedureNeedsMigration
        ? this.mergeProjectAndFileAnnotationsForMigration(
            fileAnnotations,
            projectAnnotations,
            phases
          )
        : this.filterOutDuplicatesMergeWithProjectAnnotations(
            fileAnnotations,
            projectAnnotations,
            phases
          );
    }
    const val = {
      fileId,
      annotations: filteredAnnotations ?? [],
    };
    return val;
  }

  deleteAnnotation(id: number): Promise<{}> {
    return getClient().annotations.delete([{ id }]);
  }

  createAnnotation(
    annotation: CogniteAnnotation
  ): Promise<CogniteAnnotation[]> {
    return getClient().annotations.create([annotation]);
  }

  private filterOutDuplicatesMergeWithProjectAnnotations(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[],
    phases: any[]
  ) {
    return this.annotationDuplicatesService.filterOutDuplicateProjectAnnotations(
      this.annotationDuplicatesService.filterOutDuplicatePipelineAnnotations(
        fileAnnotations,
        phases
      ),
      projectAnnotations
    );
  }

  private mergeProjectAndFileAnnotationsForMigration(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[],
    phases: any[]
  ) {
    const markedProjectAnnotations = projectAnnotations.map((annotation) => {
      return {
        ...annotation,
      };
    });

    const res = this.annotationDuplicatesService.mergeWithProjectAnnotations(
      this.annotationDuplicatesService.filterOutDuplicatePipelineAnnotations(
        fileAnnotations,
        phases
      ),
      markedProjectAnnotations
    );
    return res;
  }
}
