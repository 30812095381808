import { DataSet } from '@cognite/sdk';
import { getClient } from '../config/cognitesdk';

export class GlobalResourceService {
  fetchDataSetInfo(ids: string[]): Promise<DataSet[]> {
    return getClient().datasets.retrieve(
      ids.map((id) => {
        return {
          externalId: id,
        };
      })
    );
  }
}
