import React from 'react';
import { Button } from '@cognite/cogs.js';

type Props = {
  handleResumeExecution: () => void;
  viewOnly: boolean;
  executorsList: string[];
  supervisorsList: string[];
};

const ResumeExecution = ({
  handleResumeExecution,
  viewOnly,
  executorsList,
  supervisorsList,
}: Props) => {
  return (
    <div style={{ textAlign: 'right' }}>
      <Button
        type="primary"
        data-testid="resume-execution-button"
        onClick={() => handleResumeExecution()}
      >
        {viewOnly ? 'View execution' : 'Resume execution'}
      </Button>

      {executorsList.length <= 1 && (
        <div
          style={{
            fontSize: '14px',
            color: '#B30539',
            width: '270px',
          }}
          data-testid="incomplete-execution-team-warning-dashboard"
        >
          You should have at least 2 users on the execution team
        </div>
      )}

      {supervisorsList?.length <= 0 && (
        <div
          style={{
            fontSize: '14px',
            color: '#B30539',
            width: '270px',
          }}
          data-testid="incomplete-supervision-team-warning-dashboard"
        >
          You should have at least 1 user on the supervision team
        </div>
      )}
    </div>
  );
};

export default ResumeExecution;
