import { FileInfo } from '@cognite/sdk';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { ModalLoader } from 'shared/ModalLoader';
import { ColorFiles } from 'types';
import { Drawer } from '../Drawer';

const SideDrawerContent = lazy(() => import('./SideDrawerContent'));

export type SideDrawerProps = {
  fileId: number | undefined;
  files: FileInfo[];
  fileColors: ColorFiles[];
  fileIds: number[];
  workStepsFileIds: number[];
  activeFile: FileInfo;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: boolean) => void;
  onFileSelect: (file: FileInfo) => void;
  onChooseStartingFile: (file: FileInfo) => void;
  onFileRemove: (file: FileInfo) => void;
  onAddFile: (file: FileInfo) => void;
  viewOnly: boolean;
};

const SideDrawer = React.memo((props: SideDrawerProps) => {
  const { isDrawerOpen, setIsDrawerOpen } = props;
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // Init component hook, use it if you want to
  // run something, before anything is shown
  useEffect(() => {
    if (isDrawerOpen && !isInitialized) {
      setIsInitialized(true);
    }
    // eslint-disable-next-line
  }, [isDrawerOpen, isInitialized]);

  return (
    <Drawer open={isDrawerOpen} toggle={setIsDrawerOpen} placement="right">
      {isInitialized && (
        <Suspense fallback={<ModalLoader />}>
          <SideDrawerContent {...props} />
        </Suspense>
      )}
    </Drawer>
  );
});

export default SideDrawer;
