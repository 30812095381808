import styled from 'styled-components/macro';

export const WorkordersInnerWrapper = styled.div`
  height: auto;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  width: 100%;
`;

export const WorkorderContainer = styled.div<{ new: boolean }>`
  margin-top: 7px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: ${(props) => (props.new ? '#c8f4e7' : '#efefef')};
  text-align: left;
`;

export const TextDetail = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--cogs-text-color);
`;

export const TextDetailBald = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #000000;
`;

export const SmallWoText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--cogs-greyscale-grey9);
`;

export const MediumWoText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--cogs-greyscale-grey9);
`;

export const BaldWoText = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--cogs-greyscale-grey9);
`;
