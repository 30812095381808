import styled from 'styled-components/macro';

export type NotificationProps = {
  color?: string;
  spaceBetween?: boolean;
};

export const NotificationDiv = styled.div<NotificationProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.spaceBetween ? 'space-between' : 'flex-start')};
  align-items: center;
  width: 100%;
  padding: 16px;
  margin-bottom: 10px;
  background-color: ${(p) => p.color};
  border-radius: 4px;

  &:last-child {
    margin-bottom: 15px;
  }
`;
