import styled from 'styled-components/macro';
import { spin } from 'styles/animations';

export const LoadingBoxWrapper = styled.div<{
  bgColor?: string;
  txtColor?: string;
}>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'var(--cogs-greyscale-grey10)'};
  color: ${(props) =>
    props.txtColor ? props.txtColor : 'var(--cogs-greyscale-grey1)'};
  padding: 12px 24px 16px 24px;
  border-radius: 4px;
  width: auto;
  display: inline-block;

  .loading-circle {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    transform-origin: center;
    color: var(--cogs-greyscale-grey1);

    &.loading-circle--animated {
      animation: ${spin} 2s linear infinite;
    }
  }
`;

export const LoadingText = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
`;
