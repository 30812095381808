import { Asset, CogniteEvent, FileInfo, Metadata } from '@cognite/sdk';
import {
  AssetMappingType,
  PnId,
  Project,
  ProjectStatus,
  WorkLogDetails,
  Comment,
  ProcedureFileAttachment,
  WorkOrder,
  PassValve,
  ProcedurePhasesWorkstepsDTO,
  Phase,
  StepExecutionDetails,
  PhaseType,
  WorkStep,
  KeyValuePair,
  ProcedureApprovalFile,
  ExecutionSubStates,
  UserActivity,
} from 'types';
import { tryParseJSON } from 'utils/jsonparse';
import { v4 as uuidv4 } from 'uuid';
import { PhaseWorkstepsDataMapper } from './phase-worksteps.data-mapper';

export class ModelFactory {
  private workstepsDataMapper: PhaseWorkstepsDataMapper;

  constructor() {
    this.workstepsDataMapper = new PhaseWorkstepsDataMapper();
  }
  createProject(file: FileInfo): Project {
    const metadata: Metadata = file.metadata as Metadata;
    return {
      id: file.externalId,
      name: metadata?.['procedure_name'] || file.name,
      fileName: file.name,
      created: Number(file.createdTime),
      lastUpdated: Number(file.lastUpdatedTime),
      executionStarted: Number(metadata.executionStarted),
      completed: metadata.completed ? +metadata.completed : undefined,
      due: metadata.due_date || '',
      compiler: metadata.updatedSource || file.source || '',
      objectives: metadata.objectives || '',
      method: metadata.method || '',
      type: metadata.type || '',
      isDeleted: metadata.isDeleted === 'true',
      status: this.createProjectStatus(metadata.status),
      rejected: {
        name: metadata.rejectedName || '',
        date: metadata.rejectedDate || '',
      },
      resubmitted: {
        name: metadata.resubmittedName || '',
        date: metadata.resubmittedDate || '',
      },
      reviewed: {
        name: metadata.reviewedName || '',
        date: metadata.reviewedDate || '',
      },
      isolated: {
        name: metadata.isolatorName || '',
        date: metadata.isolatedDate || '',
      },
      executionStopped: metadata.executionStoppedDate
        ? this.createWorkLogDetails(
            metadata.executionStoppedName,
            metadata.executionStoppedDate
          )
        : undefined,
      resolved: {
        compiler: metadata.compilerResolvedDate
          ? {
              name: metadata.compilerResolvedName,
              date: +metadata.compilerResolvedDate,
            }
          : undefined,
        reviewer: metadata.reviewerResolvedDate
          ? {
              name: metadata.reviewerResolvedName,
              date: +metadata.reviewerResolvedDate,
            }
          : undefined,
      },
      asset: {
        id: file.assetIds ? String(file.assetIds[0]) : '',
        externalId: metadata.assetExternalId || '',
      },
      rootAsset: metadata.rootAssetExternalId,
      pnIds:
        tryParseJSON<string[]>(metadata.pids).map((item) => {
          return {
            id: item,
          } as PnId;
        }) || [],
      pnidsTitles: tryParseJSON<string[]>(metadata.pidsTitles) || [],
      checkedValves: tryParseJSON<string[]>(metadata.passValves) || [],
      certificateNumber: metadata.certificateNumber || '',
      executorsList: tryParseJSON<string[]>(metadata.executorsList) || [],
      supervisorsList: tryParseJSON<string[]>(metadata.supervisorsList) || [],
      pnIdsDrawings:
        tryParseJSON<KeyValuePair>(metadata.pnIdsDrawings, {}) || {},
      needsMigration: !!(
        metadata.needsMigration && metadata.needsMigration === 'true'
      ),
      executionSubState: metadata.executionState as ExecutionSubStates,
      isApprovedProcedure: !!(
        metadata.isApprovedProcedure && metadata.isApprovedProcedure === 'true'
      ),
      copyOfApprovedProcedure: metadata.copyOfApprovedProcedure
        ? metadata.copyOfApprovedProcedure
        : undefined,
      deletionRecord: metadata.procedureDeletedDate
        ? this.createWorkLogDetails(
            metadata.procedureDeletedName,
            metadata.procedureDeletedDate
          )
        : undefined,
      logFile: tryParseJSON(metadata.logFile) as UserActivity[],
    };
  }

  createNewProject(
    asset: AssetMappingType,
    user: string,
    selectedRootAsset: string,
    newId?: string
  ): Project {
    const projectNewId = newId || uuidv4();
    const assetName = asset.assetFloc.split('.');
    const procedureName = `Isolation Procedure for ${
      assetName[assetName.length - 1]
    }`;
    const newProject: Project = {
      id: projectNewId,
      name: procedureName,
      fileName: procedureName,
      created: new Date().getTime(),
      compiler: user,
      status: 'compilation',
      objectives: '',
      method: '',
      asset: {
        id: String(asset.assetId),
        externalId: asset.assetFloc,
        name: asset.assetName,
      },
      rootAsset: selectedRootAsset,
      checkedValves: [],
      pnIds: [],
      pnidsTitles: [],
      pnIdsDrawings: {},
    };

    return newProject;
  }

  copyProject(project: Project, newUser: string, newId?: string): Project {
    const projectNewId = newId || uuidv4();
    const newProject: Project = {
      id: projectNewId,
      name: project.name,
      fileName: project.fileName,
      created: new Date().getTime(),
      compiler: newUser,
      objectives: project.objectives,
      method: project.method,
      type: project.type,
      status: 'compilation',
      asset: {
        id: project.asset?.id || '',
        externalId: project.asset?.externalId,
        name: project.asset?.name,
      },
      rootAsset: project.rootAsset,
      checkedValves: [],
      copyOfApprovedProcedure: project.isApprovedProcedure
        ? project.id
        : undefined,
    };
    return newProject;
  }

  createWorkLogDetails(name: string, date: string | number): WorkLogDetails {
    return {
      name: name || '',
      date: +date.toString(),
    };
  }

  createProjectStatus(inputString: string | undefined): ProjectStatus {
    if (
      inputString === 'compilation' ||
      inputString === 'reviewReady' ||
      inputString === 'review' ||
      inputString === 'executionReady' ||
      inputString === 'execution' ||
      inputString === 'done' ||
      inputString === 'deleted'
    ) {
      return inputString;
    }
    return undefined;
  }
  mapAssetsToType(asset: Asset): AssetMappingType {
    // need to change name and description
    return {
      assetId: asset.id,
      assetFloc: asset.externalId || '',
      assetName: asset.description || '',
      assetDescription: asset.name || '',
    };
  }

  createComment(event: CogniteEvent): Comment {
    return {
      id: event.externalId || '',
      commentContent: event.metadata?.content || '',
      role: event.metadata?.role === 'reviewer' ? 'reviewer' : 'compiler',
      date: Number(event.createdTime),
      author: event.source || '',
    };
  }

  mapToProcedureFileAttachment(file: FileInfo): ProcedureFileAttachment {
    return {
      id: file.externalId || '',
      name: file.name || '',
      author: file.metadata?.['uploaded_by'] || '',
      uploading: false,
      removed: file.metadata?.['removed'] === 'true' || false,
    };
  }

  mapToProcedureApprovalFile(file: FileInfo): ProcedureApprovalFile {
    return {
      id: file.externalId || '',
      name: file.name || '',
      author: file.metadata?.['uploaded_by'] || '',
      uploading: false,
      removed: file.metadata?.removed === 'true' || false,
      approvedBy: file.metadata?.approvedBy || '',
      description: file.metadata?.description || '',
    };
  }

  mapToWorkOrder(event: CogniteEvent): WorkOrder {
    return {
      id: event.externalId || '',
      floc: event.metadata?.['Functional Location'] || '',
      description: event.description || '',
      endDate: Number(event.endTime),
      createdDate: Number(event.metadata?.['Date on Which Record Was Created']),
      createdDateInCdf: Number(event.lastUpdatedTime),
      author: event.metadata?.['Name of Person who Created the Object'] || '',
    };
  }

  mapToPassValve(event: CogniteEvent): PassValve {
    return {
      id: event.externalId || '',
      floc: event.metadata?.['FLOC'] || '',
      description: event.description || '',
      tag: event.type || '',
      url: event.metadata?.['URL'] || '',
      raised: event.metadata?.['Z1_RAISED_DATE'] || '',
    };
  }

  mapToPhasesAndWorksteps(
    dto: ProcedurePhasesWorkstepsDTO,
    assets: Asset[],
    useDisplayPosition: boolean
  ): Phase[] {
    const phases = this.workstepsDataMapper.deserialize(
      {
        phases: dto.phases,
        annotations: dto.annotations,
      },
      assets,
      useDisplayPosition
    );

    if (!phases) {
      return [];
    }

    return this.mapWorkstepsExecution(phases);
  }

  mapWorkstepsExecution(phases: Phase[]): Phase[] {
    return phases.map((curPhase) => {
      const phase = { ...curPhase };
      if (!phase.phaseType) {
        phase.phaseType = 'isolation';
      }
      const workSteps = phase.workSteps.map((step) =>
        this.createWorkStep(step)
      );
      return Object.assign(phase, { workSteps });
    });
  }

  createWorkStep(workStep: WorkStep): WorkStep {
    return {
      ...workStep,
      execution: workStep.execution
        ? new StepExecutionDetails(workStep.execution)
        : StepExecutionDetails.notStartedWithExecution(),
    };
  }

  createNewFromExistingWorkStep(workStep: WorkStep): WorkStep {
    return {
      ...workStep,
      id: uuidv4(),
      execution: StepExecutionDetails.notStartedWithExecution(),
      review: undefined,
      tagNumber: '',
      comment: '',
    };
  }

  mapPhasesToDto(phases: Phase[]): ProcedurePhasesWorkstepsDTO {
    return this.workstepsDataMapper.serialize(phases);
  }

  createPhase(
    name: string,
    position: number,
    phaseType: PhaseType,
    isDeIsolationPhase = false
  ): Phase {
    return {
      id: uuidv4(),
      name,
      position,
      phaseType,
      deIsolation: isDeIsolationPhase,
      workSteps: [],
    };
  }
}
