import styled from 'styled-components/macro';

export const ProjectPageLayoutWrapper = styled.div`
  .layout-item {
    display: inline-block;
    box-sizing: border-box;
  }

  @media (min-width: 1448px) {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    height: 2000px;

    /* Force new columns */
    &::before,
    &::after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 2;
    }

    /* Re-order items into rows */
    .layout-item.col-lg-1 {
      order: 1;
      width: 42%;
    }
    .layout-item.col-lg-2 {
      order: 2;
      width: 30%;
    }
    .layout-item.col-lg-3 {
      order: 3;
      width: 25%;
    }
  }

  @media (min-width: 985px) and (max-width: 1448px) {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;

    /* Force new columns */
    &::before,
    &::after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 1;
    }

    .layout-item {
      width: 48%;
      order: 1;
      margin-left: -2%;
    }

    .layout-item.md-pos-1 {
      order: 1;
    }
    .layout-item.md-pos-2 {
      order: 2;
    }
    .layout-item.md-pos-3 {
      order: 3;
    }
    .layout-item.md-pos-4 {
      order: 4;
    }
    .layout-item.md-pos-5 {
      order: 5;
    }
    .layout-item.md-pos-6 {
      order: 6;
    }
    .layout-item.md-pos-7 {
      order: 7;
    }
    .layout-item.md-pos-8 {
      order: 8;
    }
    .layout-item.md-pos-9 {
      order: 9;
    }
    .layout-item.md-pos-10 {
      order: 10;
    }
    .layout-item.md-pos-11 {
      order: 11;
    }
    .layout-item.md-pos-12 {
      order: 12;
    }
  }

  @media (max-width: 984px) {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    height: auto !important;

    /* Force new columns */
    &::before,
    &::after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 0;
    }

    .layout-item {
      width: 100%;
      order: 0;
    }
  }
`;
