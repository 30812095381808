import { Project } from 'types';

export class ProjectsService {
  filterProjectsBy(
    projects: Project[],
    propertyName: keyof Project,
    propertyValue: string | number
  ): Project[] {
    return projects.filter(
      (project) => project[propertyName] === propertyValue
    );
  }
}
