/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { ActiveWorkStep, WorkStep } from 'types';
import { MissingWorkStepItemBox } from './elements';

export interface MissingWorkStepItemProps {
  active: ActiveWorkStep;
  phaseId: string;
  workStep: WorkStep;
  onWorkStepSelected: (workStep: WorkStep) => void;
  children?: any;
}
export const MissingWorkStepItem = ({
  active,
  phaseId,
  workStep,
  onWorkStepSelected,
  children,
}: MissingWorkStepItemProps) => {
  const isActive = active.workStep?.id === workStep.id;
  const [cssClassNames, setCssClassNames] = useState('');

  useEffect(() => {
    if (workStep.isIgnored) {
      setCssClassNames('ignored');
      return;
    }

    if (isActive && workStep.draftStepOptions) {
      setCssClassNames('padding-bottom-less');
    }
  }, [isActive, workStep]);

  const onSelect = useCallback(
    () => {
      if (isActive && workStep.isIgnored && cssClassNames === 'ignored') {
        setCssClassNames('');
        return;
      }
      onWorkStepSelected(workStep);

      if (isActive && workStep.isIgnored) {
        setCssClassNames('');
      }
    },
    // eslint-disable-next-line
    [cssClassNames, isActive, workStep]
  );

  return (
    <MissingWorkStepItemBox
      className={isActive ? `active ${cssClassNames}` : cssClassNames}
      data-testid={`missing-workstep-${workStep.id}`}
      data-wsid={`work-step-item-${workStep.id}`}
      title={
        workStep.isIgnored ? 'The workstep is ignored, click to expand' : ''
      }
      onClick={onSelect}
    >
      <span className="step-missing-lbl">Step missing?</span>
      {children}
    </MissingWorkStepItemBox>
  );
};
