import styled from 'styled-components/macro';

export type ContainerProps = {
  padding?: string;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || '30px'};
`;

export type RowProps = {
  direction?: 'row' | 'column';
  'justify-content'?: string;
  'align-items'?: string;
  margin?: string;
  display?: 'flex' | undefined;
};

export const Row = styled.div<RowProps>`
  ${({ display }) => display && `display: ${display};`}
  margin: ${({ margin }) => margin || '10px'};
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${(props) =>
    props['justify-content'] ? props['justify-content'] : 'flex-start'};
  align-items: ${(props) =>
    props['align-items'] ? props['align-items'] : 'flex-start'};
`;
