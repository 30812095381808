import React from 'react';
import { Button, Flex } from '@cognite/cogs.js';
import CreateProcedureButton from './CreateProcedureButton';
import { PageFooter } from './elements';

type SearchPageFooterProps = {
  showCreateButton: boolean;
};

const SearchPageFooter = ({ showCreateButton }: SearchPageFooterProps) => {
  return (
    <PageFooter>
      <Flex direction="row" className="firstFlex" style={{ width: '100%' }}>
        {showCreateButton && (
          <>
            <CreateProcedureButton />
            <Flex direction="column" className="suggestion">
              <p>Can&apos;t find a good procedure?</p>
              <p>Create one from a blank template</p>
            </Flex>
          </>
        )}
        <Button
          type="tertiary"
          data-testid="scrollToTop"
          className="scroll-to-top"
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        >
          To top
        </Button>
      </Flex>
    </PageFooter>
  );
};

export default SearchPageFooter;
