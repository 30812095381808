import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
  }

  // Move out of here
  .textArea {
    width: 100%;
    height: 180px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .hidden {
    display: none;
  }

  .tagChecked  {
    background-color:  grey !important;
    border: 1px solid #d9d9d9 !important;
  }

  .clearfix {
    clear: both;
  }
  
  
  #printWindow {
    position: inherit !important;
  }


  .cogs.cogs-checkbox {
    background-color: transparent;
  }
  

`;
