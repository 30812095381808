import React from 'react';
import { Flex } from '@cognite/cogs.js';

import { SupervisorItemContainer, LinkButton } from '../elements';

type SupervisorItemProps = {
  handleDelete: () => void;
  supervisor: string;
  viewOnly: boolean;
};

const SupervisorItem = ({
  viewOnly = false,
  supervisor,
  handleDelete,
}: SupervisorItemProps) => {
  return (
    <SupervisorItemContainer>
      <Flex justifyContent="space-between">
        <span data-testid="supervisor-item-name">{supervisor}</span>
        {!viewOnly && (
          <LinkButton
            style={{
              height: 'min-content',
              fontSize: '12px',
              margin: '3.5px 8px 0px 8px',
              color: '#4A67FB',
            }}
            key={`supervisor-item-delete-${supervisor}`}
            data-testid={`supervisor-item-delete-${supervisor}`}
            onClick={handleDelete}
          >
            Delete
          </LinkButton>
        )}
      </Flex>
    </SupervisorItemContainer>
  );
};

export default SupervisorItem;
