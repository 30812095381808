import React, { useState } from 'react';
import { List } from 'shared/List';
import Modal from 'shared/Modal';
import { Button } from '@cognite/cogs.js';

import { useHistory } from 'react-router-dom';

export interface ExecutionWorkStepModalProps {
  isModalOpen: boolean;
  isModalInChooseCheckedState: boolean;
  executorsList: string[];
  setModalOpen: (boolValue: boolean) => void;
  onSelection: (email: string) => void;
  projectId: string;
}

export const ExecutionWorkStepModal = (props: ExecutionWorkStepModalProps) => {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  const history = useHistory();

  const onOkClick = () => {
    props.onSelection(selectedEmail);
    setSelectedEmail('');
    setIsOkDisabled(true);
  };

  const onListSelectionChange = (email: string) => {
    setSelectedEmail(email);
    setIsOkDisabled(false);
  };

  return (
    <Modal
      visible={props.isModalOpen}
      setVisible={props.setModalOpen}
      title={props.isModalInChooseCheckedState ? 'Checked by' : 'Done by'}
      okText="Continue"
      isOkDisabled={isOkDisabled}
      okHandler={onOkClick}
      extraActionButtons={
        <Button
          type="tertiary"
          data-testid="add-user-modal"
          onClick={() => history.push(`/project/${props.projectId}`)}
        >
          Add users
        </Button>
      }
    >
      <div data-testid="executors-list">
        <List
          items={props.executorsList}
          selectedItem={selectedEmail}
          onSelectionChange={onListSelectionChange}
        />
      </div>
    </Modal>
  );
};
