import { Icon, Overline, TextInput } from '@cognite/cogs.js';
import React, { useCallback } from 'react';
import { PhaseType } from 'types';
import { TextDetail } from '../elements';
import { DeisolationTag, TagNumberInputWrapper } from './elements';
import { TagNumberPosition } from './types';

export interface TagNumberInputProps {
  tagNumberPosition: TagNumberPosition;
  editMode?: boolean;
  onTagNumberUpdated: (tagNumberPosition: TagNumberPosition) => void;
  tagNumbersUpdated?: boolean;
  generateTagNumbers: (tagNumberPosition: TagNumberPosition) => void;
  phaseType: PhaseType;
}

export const TagNumberInput = React.memo(
  ({
    tagNumberPosition,
    onTagNumberUpdated,
    editMode = false,
    tagNumbersUpdated = false,
    generateTagNumbers,
    phaseType,
  }: TagNumberInputProps) => {
    const shouldTagBeRendered = !(
      tagNumbersUpdated && !tagNumberPosition.tagNumber
    );

    const shouldTagBeEditable = editMode && !tagNumbersUpdated;

    const onValueChanged = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { value } = e.currentTarget;
        onTagNumberUpdated({ ...tagNumberPosition, tagNumber: value });
      },
      [onTagNumberUpdated, tagNumberPosition]
    );
    return shouldTagBeRendered ? (
      <TagNumberInputWrapper
        type={phaseType}
        data-testid="tag-number-input-wrapper"
      >
        <div className="tags-single-row-container">
          <Overline
            level="1"
            className="step-number"
            style={{ marginTop: !shouldTagBeEditable ? '1px' : '3px' }}
            data-testid="tag-number-input-position"
          >
            {tagNumberPosition.position}
          </Overline>
          <div className="tag-number">
            {shouldTagBeEditable && (
              <TextInput
                size="small"
                type="number"
                data-testid="tag-number-input-input"
                value={tagNumberPosition.tagNumber}
                onChange={onValueChanged}
              />
            )}

            {!shouldTagBeEditable && phaseType === 'deisolation' && (
              <DeisolationTag data-testid="tag-number-input-label">
                {tagNumberPosition.tagNumber}
              </DeisolationTag>
            )}

            {!shouldTagBeEditable && phaseType !== 'deisolation' && (
              <TextDetail data-testid="tag-number-input-label">
                {tagNumberPosition.tagNumber}
              </TextDetail>
            )}
          </div>
          {shouldTagBeEditable && tagNumberPosition.tagNumber && (
            <Icon
              className="tag-number-generate-icon"
              type="SortDescending"
              data-testid="tag-generate-icon"
              size={16}
              onClick={() => generateTagNumbers(tagNumberPosition)}
            />
          )}
        </div>
        {tagNumberPosition.connectedTagPosition && (
          <div className="tags-single-row-container">
            <Overline
              level="1"
              className="step-number"
              data-testid="tag-number-input-position-deisolation"
            >
              {tagNumberPosition.connectedTagPosition}
            </Overline>
            <DeisolationTag data-testid="deisolation-tag-input">
              {tagNumberPosition.tagNumber}
            </DeisolationTag>
          </div>
        )}
      </TagNumberInputWrapper>
    ) : null;
  }
);
