import React from 'react';

// taken and adjusted from cogs.js

type HtmlElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface BadgeProps extends HtmlElementProps {
  text: string;
  size?: number;
  background?: string;
  textColor?: string;
  borderColor?: string;
}

const Badge = ({
  text,
  size = 12,
  background,
  textColor,
  borderColor,
  ...rest
}: BadgeProps) => {
  const style = {
    ...rest.style,
    fontSize: size,
    background: background || 'white',
    color: textColor || 'black',
    border: `2px solid ${borderColor}`,
    padding: '2px 12px',
    fontWeight: 600,
  };

  return (
    <span className="cogs-badge" {...rest} style={style}>
      <span>{text}</span>
    </span>
  );
};

export default Badge;
