/* eslint-disable no-plusplus */
import { Button, toast } from '@cognite/cogs.js';
import { useSave } from 'hooks/useSave';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, ProjectState } from 'redux/reducers/project';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { RootState } from 'redux/store';
import { Status } from 'redux/types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { ProjectStatus } from 'types';
import { LogoArea, ProjectContainer, TextDetail, Title } from '../elements';
import { LogoSpan, TagsGroupsContainer } from './elements';
import { useWorkstepsTagsNumbers } from './hooks';
import { ReactComponent as Icon } from './TagIcon.svg';
import { TagNumberInput } from './TagNumberInput';
import { TagNumberPosition } from './types';
import { WorkstepsTagsGroup } from './WorkstepsTagsGroup';

export interface WorkstepsTagsProps {
  projectStatus: ProjectStatus;
  editMode?: boolean;
  showTagNumbers?: boolean;
}

export const WorkstepsTags = ({
  projectStatus,
  editMode = false,
  showTagNumbers = false,
}: WorkstepsTagsProps) => {
  const [isReady, setIsReady] = useState(false);
  const [saveTagNumbers, setSaveTagNumbers] = useState(false);
  const [originalTagList, setOriginalTagList] = useState(
    [] as TagNumberPosition[]
  );

  const dispatch = useDispatch();
  const {
    tagNumbersUpdated,
    hasEnteredProcessTagNumbers,
    hasEnteredElectricTagNumbers,
  } = useSelector<RootState, ProjectState>((state) => state.project);

  const { phases, status, batchUpdateWorkstepsTagNumbers } = useWorkSteps();
  const { saveProjectChanges } = useSave();
  const {
    tagsList,
    totalElectricTags,
    totalProcessTags,
    updateWorkstepTag,
    filterAndCountWorkstepsWithTags,
    updateWorkstepTags,
    generateTagNumbers,
  } = useWorkstepsTagsNumbers();

  const onTagNumberUpdated = useCallback(
    (tagPosition: TagNumberPosition) => {
      updateWorkstepTag(tagPosition);
    },
    [updateWorkstepTag]
  );
  const processTagsCount = totalProcessTags.filter((tg) => tg !== '').length;
  const shouldNotCountDraftSteps = projectStatus !== 'compilation';

  const showErrorMessage = useCallback(() => {
    setSaveTagNumbers(false);
    toast.error(
      <div>
        <h3>Oops</h3> We’re sorry, we weren’t able to do that. Try again.
      </div>
    );
    // eslint-disable-next-line
  }, []);

  const updateWorkStepsTagNumbers = async () => {
    if (tagNumbersUpdated) {
      setOriginalTagList(tagsList);
      dispatch(actions.setTagNumbersUpdated(false));
    } else {
      await batchUpdateWorkstepsTagNumbers(tagsList);
      setSaveTagNumbers(true);
    }
  };

  const undoTagNumbersEdit = async () => {
    updateWorkstepTags(originalTagList);
  };

  const saveWorkstepsTagNumbers = () => {
    saveProjectChanges({
      fileContent: phases,
      action: 'saveWorkstepsTagNumbers',
    })
      .then(
        () => {
          setSaveTagNumbers(false);
          dispatch(actions.setTagNumbersUpdated(true));
        },
        () => showErrorMessage()
      )
      .catch(() => showErrorMessage());
  };

  useEffect(() => {
    if (!originalTagList.length && !!tagsList.length) {
      setOriginalTagList(tagsList);
    }
  }, [tagsList, originalTagList]);

  useEffect(() => {
    filterAndCountWorkstepsWithTags(phases, shouldNotCountDraftSteps);

    if (!isReady) {
      setIsReady(true);
    }

    if (saveTagNumbers) {
      saveWorkstepsTagNumbers();
    }
    // eslint-disable-next-line
  }, [phases, saveTagNumbers]);

  const isSaveBtnDisabled = status === Status.loading || tagsList.length === 0;
  const showLoading =
    !isReady || status === Status.idle || status === Status.loading;

  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon width="24" className="tags-icon" />
        </LogoSpan>
        <Title>Tags</Title>
      </LogoArea>

      {showLoading && (
        <div style={{ display: 'block', overflow: 'hidden', height: '150px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}

      {!showLoading && isReady && (
        <div style={{ width: '100%' }}>
          {tagsList.length ? (
            <div data-testid="worksteps-tags-container">
              <TextDetail>
                {processTagsCount} process tag
                {processTagsCount !== 1 && 's'}
              </TextDetail>
              <TextDetail>
                {totalElectricTags} electrical tag
                {totalElectricTags !== 1 && 's'}
              </TextDetail>
              {projectStatus === 'compilation' && (
                <TextDetail>
                  <br />
                  Tentative tally - procedure not finalised
                </TextDetail>
              )}
              <br />

              {showTagNumbers && (
                <div data-testid="worksteps-tags-expanded">
                  <TagsGroupsContainer>
                    {!!totalProcessTags.length &&
                      !(tagNumbersUpdated && !hasEnteredProcessTagNumbers) && (
                        <WorkstepsTagsGroup title="Process tags">
                          {tagsList
                            .filter((tag) => tag.type === 'process')
                            .map((tag) => (
                              <TagNumberInput
                                phaseType={tag.phaseType}
                                key={tag.workStepId}
                                tagNumberPosition={tag}
                                onTagNumberUpdated={onTagNumberUpdated}
                                tagNumbersUpdated={tagNumbersUpdated}
                                editMode={editMode}
                                generateTagNumbers={generateTagNumbers}
                              />
                            ))}
                        </WorkstepsTagsGroup>
                      )}

                    {totalElectricTags > 0 &&
                      !(tagNumbersUpdated && !hasEnteredElectricTagNumbers) && (
                        <>
                          <br />
                          <WorkstepsTagsGroup title="Electrical tags">
                            {tagsList
                              .filter((tag) => tag.type === 'electrical')
                              .map((tag) => (
                                <TagNumberInput
                                  phaseType={tag.phaseType}
                                  key={tag.workStepId}
                                  tagNumberPosition={tag}
                                  onTagNumberUpdated={onTagNumberUpdated}
                                  tagNumbersUpdated={tagNumbersUpdated}
                                  editMode={editMode}
                                  generateTagNumbers={generateTagNumbers}
                                />
                              ))}
                          </WorkstepsTagsGroup>
                        </>
                      )}
                  </TagsGroupsContainer>
                  <div className="clearfix" />
                  {(totalElectricTags || totalProcessTags) && editMode && (
                    <div
                      style={{
                        display: 'block',
                        float: 'right',
                        marginTop: '10px',
                      }}
                    >
                      {!tagNumbersUpdated && (
                        <Button
                          style={{
                            marginRight: '5px',
                          }}
                          data-testid="tags-undo"
                          type="secondary"
                          htmlType="button"
                          disabled={isSaveBtnDisabled}
                          onClick={undoTagNumbersEdit}
                        >
                          Undo
                        </Button>
                      )}
                      <Button
                        data-testid="tags-save"
                        type="secondary"
                        htmlType="button"
                        disabled={isSaveBtnDisabled}
                        onClick={updateWorkStepsTagNumbers}
                      >
                        {tagNumbersUpdated ? 'Edit' : 'Save'} tag numbers
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div data-testid="worksteps-tags-empty">
              <TextDetail>No tags</TextDetail>
              {projectStatus === 'compilation' && (
                <TextDetail>
                  Tentative tally - procedure not finalised
                </TextDetail>
              )}
            </div>
          )}
        </div>
      )}
    </ProjectContainer>
  );
};
