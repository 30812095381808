import React from 'react';
import { Icon } from '@cognite/cogs.js';

import { ProcedureApprovalFile } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { Title, ProjectContainer, LogoArea, TextDetail } from '../elements';
import { LogoSpan, ApprovalFilesList } from './elements';
import ApprovalFile from './ApprovalFile';
import { useApprovalFileUpload } from '../../../../hooks/useApprovalFileUpload';

type ApprovalFilesBody = {
  filesList: ProcedureApprovalFile[];
  canEdit?: boolean;
};

const ApprovalFilesBody = ({
  filesList = [],
  canEdit = false,
}: ApprovalFilesBody) => {
  const { fileUploadHandler } = useApprovalFileUpload();
  const hasFiles = !!filesList.length;

  return (
    <>
      {!hasFiles && (
        <TextDetail
          data-testid="no-approval-files-text"
          style={{ marginLeft: '3px' }}
        >
          No approval files were added
        </TextDetail>
      )}
      {hasFiles && (
        <ApprovalFilesList>
          {filesList.map((file) => (
            <ApprovalFile
              key={`approval-file-${file.id}`}
              file={file}
              canEdit={canEdit}
            />
          ))}
        </ApprovalFilesList>
      )}
      {canEdit && (
        <div style={{ alignSelf: 'flex-end' }}>
          <label
            htmlFor="approval_file_input"
            className="cogs-btn cogs-btn-secondary"
            data-testid="approval-add-button"
            style={{ marginTop: '22px', padding: '0 10px' }}
          >
            Add file
            <input
              hidden
              id="approval_file_input"
              name="file"
              type="file"
              onChange={(event) => {
                if (event.currentTarget.files?.length) {
                  fileUploadHandler(event.currentTarget.files[0]);
                }
              }}
            />
          </label>
        </div>
      )}
    </>
  );
};

type ApprovalFilesProps = {
  filesList: ProcedureApprovalFile[];
  canEdit?: boolean;
  showLoading?: boolean;
};

const ApprovalFiles = ({
  filesList,
  canEdit = false,
  showLoading = false,
}: ApprovalFilesProps) => {
  return (
    <ProjectContainer data-testid="ProjectApprovalFiles">
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Checklist"
            style={{ color: 'var(--cogs-grey9)', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>Approval files</Title>
      </LogoArea>
      {showLoading ? (
        <div style={{ display: 'block', overflow: 'hidden', height: '180px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      ) : (
        <ApprovalFilesBody filesList={filesList} canEdit={canEdit} />
      )}
    </ProjectContainer>
  );
};

export default ApprovalFiles;
