import { Button, Flex } from '@cognite/cogs.js';
import React, { useState } from 'react';
import classnames from 'classnames';
import { Comment, Role } from 'types';
import { CommentContainer } from '../elements';

type NewCommentProps = {
  userRole: Role;
  user: string;
  identifier?: string;
  secondaryItem?: () => React.ReactElement;
  newCommentTitleElement?: () => React.ReactElement;
  onSave: (comment: Comment) => Promise<boolean>;
};

const NewComment = ({
  userRole,
  user,
  onSave,
  identifier,
  secondaryItem,
  newCommentTitleElement,
}: NewCommentProps) => {
  const [comment, setComment] = useState('');

  const backgroundColor = userRole === 'reviewer' ? '#fdf3f5' : '#f5f5f5';

  return (
    <CommentContainer style={{ backgroundColor }} data-testid="new-comment">
      {!!newCommentTitleElement && (
        <div style={{ marginBottom: '8px' }}>{newCommentTitleElement()}</div>
      )}
      <Flex direction="row">
        <textarea
          className={classnames('textArea', 'cogs-input', 'cogs-input-default')}
          name="new-comment"
          data-testid="new-comment-textfield"
          style={{
            flexGrow: 1,
            alignSelf: 'stretch',
            resize: 'vertical',
            height: '42px',
          }}
          value={comment}
          placeholder="Enter comments"
          onChange={(event) => {
            setComment(event.currentTarget.value);
          }}
        />
      </Flex>
      <Flex
        direction="row"
        style={{ marginTop: '8px', justifyContent: 'flex-end' }}
      >
        {!!secondaryItem && secondaryItem()}
        <Button
          type="secondary"
          data-testid="save-new-comment"
          style={{ marginLeft: '5px' }}
          onClick={async () => {
            if (comment && onSave) {
              const createdDate = Date.now();
              const result = await onSave({
                id: `comment_${identifier}_${createdDate}`,
                commentContent: comment,
                role: userRole,
                date: createdDate,
                author: user,
              });
              if (result) {
                setComment('');
              }
            }
          }}
        >
          Comment
        </Button>
      </Flex>
    </CommentContainer>
  );
};

export default NewComment;
