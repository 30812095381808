import React, { useState } from 'react';
import { Button, Flex, Input } from '@cognite/cogs.js';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/project';
import { PreWorkItemContainer } from '../elements';

type NewPreWorkItemType = {
  position: number;
  identifier?: string;
};

const NewPreWorkItem = ({ position, identifier }: NewPreWorkItemType) => {
  const dispatch = useDispatch();
  const [preWorkItem, setPreWorkItem] = useState('');

  const onAddHandler = () => {
    if (preWorkItem) {
      const raisedDate = Date.now();
      const result = {
        id: `pre_work_${identifier || '_'}_${raisedDate}`,
        itemContent: preWorkItem,
        raisedDate,
        checked: false,
        removed: false,
      };
      if (result) {
        dispatch(actions.addNewPreWorkActionItem(result));
        setPreWorkItem('');
      }
    }
  };

  return (
    <PreWorkItemContainer data-testid="new-pre-work-item">
      <Flex direction="row">
        <div
          style={{ margin: '4px 8px 0px 0px' }}
          data-testid="new-pre-work-position"
        >
          {`${position}.`}
        </div>
        <Input
          style={{
            flexGrow: 1,
            alignSelf: 'stretch',
            padding: '0px 10px',
            height: '30px',
            minWidth: '80px',
          }}
          placeholder="Add item"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAddHandler();
            }
          }}
          onChange={(event) => {
            setPreWorkItem(event.currentTarget.value);
          }}
          value={preWorkItem}
          data-testid="new-pre-work-item-textfield"
        />
        <Button
          type="secondary"
          size="small"
          data-testid="save-new-pre-work-item"
          style={{ margin: '1px 0px 0px 8px', fontSize: '12px' }}
          onClick={onAddHandler}
        >
          Add
        </Button>
      </Flex>
    </PreWorkItemContainer>
  );
};

export default NewPreWorkItem;
