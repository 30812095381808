import React from 'react';
import { Button, Slider, ToastContainer, toast } from '@cognite/cogs.js';
import 'react-color-palette/css';
import { IColor } from 'react-color-palette';
import { UnifiedViewerNode } from '@cognite/unified-file-viewer';
import { ToolBarWrapper, BarSection } from './elements';
import ColorPicker from '../ColorPicker/ColorPicker';
import { DEFAULT } from '../../../../utils/brushSize';

type Props = {
  polylineSize: number;
  color: IColor;
  polylineNodes?: UnifiedViewerNode[];
  setPolylineSize: (value: number) => void;
  setColor: (color: IColor) => void;
  undoLastPolyline: Function;
  eraseAllPolylines: Function;
  savePolylineAnnotations: (annotations: UnifiedViewerNode[]) => void;
};

const PolylineToolBar = (props: Props) => {
  const {
    polylineSize,
    color,
    polylineNodes,
    setPolylineSize,
    setColor,
    undoLastPolyline,
    eraseAllPolylines,
    savePolylineAnnotations,
  } = props;

  const onUndoClick = () => {
    undoLastPolyline();
  };

  const onClearClick = () => {
    eraseAllPolylines();
  };

  const onSaveClick = () => {
    if (polylineNodes) {
      savePolylineAnnotations(polylineNodes);
      toast.success(
        <div>
          <h3>Success!</h3>
          <p>Your drawing had been saved!</p>
        </div>,
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <ToolBarWrapper>
        <BarSection hasMargin noBorder>
          <span>Color</span>
          <ColorPicker color={color} setColor={setColor} />
        </BarSection>
        <BarSection hasMargin>
          <span>Size</span>
          <div style={{ width: '68px', padding: '0 4px' }}>
            <Slider
              min={DEFAULT.RADIUS_MIN}
              max={DEFAULT.RADIUS_MAX}
              step={1}
              value={polylineSize}
              onChange={setPolylineSize}
            />
          </div>
        </BarSection>
        <BarSection hasMargin>
          <Button
            size="small"
            onClick={onUndoClick}
            aria-label="redoDrawChangesButton"
            style={{ fontSize: '11px', height: '24px' }}
          >
            Undo
          </Button>
          <Button
            size="small"
            type="ghost-destructive"
            onClick={onClearClick}
            aria-label="deleteDrawingButton"
            style={{ fontSize: '11px', height: '24px' }}
          >
            Erase all
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={onSaveClick}
            aria-label="saveDrawingButton"
            style={{ fontSize: '11px', height: '24px' }}
          >
            Save
          </Button>
        </BarSection>
      </ToolBarWrapper>
    </>
  );
};

export default PolylineToolBar;
