import { useState } from 'react';
import { Metrics, Properties, ITimer } from '@cognite/metrics';
import BaseMetrics, { Dict } from 'mixpanel-browser';

export type MetricsApi = {
  initialize: (user: string, project: string) => void;
  start: (name: string, properties?: Dict) => ITimer | undefined;
  stop: (timer: ITimer | undefined) => void;
  track: (name: string, properties?: Dict) => void;
};

export default (componentName: string, props?: Properties) => {
  const [metrics] = useState<Metrics>(() =>
    Metrics.create(componentName, props)
  );

  const metricsAllowed = !(
    process.env.REACT_APP_ENV === 'mock' ||
    process.env.REACT_APP_ENV === 'testcafe' ||
    !BaseMetrics.people
  );

  const functionInitialize = (user: string, project: string) => {
    if (metricsAllowed) {
      Metrics.identify(user || 'UNKNOWN');
      Metrics.props({
        tenant: project,
      });
      BaseMetrics.people.set_once({
        'First Login Date': new Date(),
        email: user,
        name: user,
      });
    }
  };

  const functionStart = (name: string, properties?: Dict) => {
    if (metricsAllowed) {
      return metrics.start(name, properties);
    }
    return undefined;
  };

  const functionStop = (timer: ITimer | undefined) => {
    if (timer) {
      Metrics.stop(timer);
    }
  };

  const functionTrack = (name: string, properties?: Dict) => {
    if (metricsAllowed) {
      metrics.track(name, properties);
    }
  };

  return {
    initialize: functionInitialize,
    start: functionStart,
    stop: functionStop,
    track: functionTrack,
  } as MetricsApi;
};
