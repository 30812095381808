import isEmpty from 'lodash/isEmpty';
import {
  ExecutionSubStates,
  Phase,
  Project,
  StepExecutonStatus,
} from '../../../../types';

export const getActivePhase = (phases: Phase[]) => {
  const result = phases.find((phase) => {
    return isEmpty(phase.approvedBy);
  });

  return result || undefined;
};

export const hasApprovedIsolationPhases = (phases: Phase[]): boolean => {
  return phases.some((phase) => {
    return (
      phase.phaseType === 'isolation' &&
      !!phase.approvedBy &&
      !!phase.approvedTime
    );
  });
};

export const hasApprovedDeisolationPhases = (phases: Phase[]): boolean => {
  return phases.some((phase) => {
    return (
      phase.phaseType === 'deisolation' &&
      !!phase.approvedBy &&
      !!phase.approvedTime
    );
  });
};

export const countAllWorkStepsPhaseExecuted = (phase: Phase): number => {
  const allPositions = phase?.workSteps
    .filter((workstep) => !workstep.isDraft)
    .filter(
      (workstep) =>
        workstep.execution?.getStatus() === StepExecutonStatus.Executed ||
        workstep.execution?.getStatus() === StepExecutonStatus.Checked
    );

  return allPositions?.length || 0;
};

export const getExecutionSubState = (
  currentProject: Project,
  phases: Phase[]
) => {
  // phases need to be filled
  if (!phases || phases.length === 0) {
    return undefined;
  }
  // check the current activePhase
  // if no activePhase all worksteps are done
  const activePhase = getActivePhase(phases);
  if (
    activePhase &&
    (currentProject.status === 'executionReady' ||
      currentProject.status === 'execution')
  ) {
    const activeWorkStepDone = countAllWorkStepsPhaseExecuted(activePhase);

    if (activePhase.phaseType === 'isolation') {
      return hasApprovedIsolationPhases(phases) || activeWorkStepDone > 0
        ? ExecutionSubStates.IsolationStarted
        : ExecutionSubStates.NotStarted;
    }

    if (activePhase.phaseType === 'deisolation') {
      return hasApprovedDeisolationPhases(phases) || activeWorkStepDone > 0
        ? ExecutionSubStates.DeIsolationStarted
        : ExecutionSubStates.IsolationDone;
    }
  }

  // check that all phases are approved
  if (phases.filter((phase) => isEmpty(phase.approvedBy)).length === 0) {
    return ExecutionSubStates.DeIsolationDone;
  }

  return undefined;
};
