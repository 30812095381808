import {
  Item,
  Phase,
  PhaseType,
  Project,
  ProjectStatus,
  Reference,
  StepExecutionDetails,
  StepItem,
  CogniteAnnotation,
  CogniteAnnotationSpec,
  PendingCogniteAnnotation,
  WorkStep,
  WorkStepAction,
  ItemType,
} from 'types';
import { v4 as uuidv4 } from 'uuid';
import uniqueId from 'lodash/uniqueId';
import {
  InstallBlindAndTag,
  RemoveItem,
  TagCloseAction,
  TagOpenAction,
  Valve,
  types,
  OpenAction,
} from './actions';

export const createIsolationProject = ({
  id = uniqueId(),
  name = 'ProjectName',
  compiler = 'Compiler',
  objectives = 'Serious Objectives',
  status = 'compilation' as ProjectStatus,
  ...rest
}): Project => {
  return {
    id,
    name,
    fileName: name,
    created: 1603194605971,
    compiler,
    objectives,
    status,
    ...rest,
  };
};

export const projects = [
  createIsolationProject({
    id: '1',
    name: 'V-9660 Internal Inspection',
    created: 1603194605971,
    compiler: 'Peter',
    objectives:
      'Isolate, drain and de-pressure V-9660 in a way that the platform is not destroyed.',
  } as Project),
  createIsolationProject({
    id: '2',
    name: 'U-0550 Internal Inspection',
    created: 1603194605971,
    compiler: 'Peter Again',
    objectives: 'Not really sure what we want',
  } as Project),
  createIsolationProject({
    id: '3',
    created: 1603194605971,
    name: 'Created by mistake',
    compiler: 'Foo',
  } as Project),
  createIsolationProject({
    id: '4',
    created: 1603194605971,
    name: '',
    compiler: '',
  } as Project),
];

export const AttachedTo = {
  id: uuidv4(),
  text: 'attached to',
  description: 'Attached to',
};

export const Upstream = {
  id: uuidv4(),
  text: 'upstream of',
  description: 'Upstream of',
};

export const Downstream = {
  id: uuidv4(),
  text: 'downstream of',
  description: 'Downstream of',
};

export const On = {
  id: uuidv4(),
  text: 'on',
  description: 'On',
};

export const references: { [key: string]: Array<Reference> } = {
  nozzle: [AttachedTo],
  asset: [Upstream, Downstream, On],
  vessel: [Upstream, Downstream, On],
  pipe: [Upstream, Downstream, On],
  'pipe-vessel': [Upstream, Downstream, On],
  instrument: [Upstream, Downstream, On],
  motor: [Upstream, Downstream, On],
  pump: [Upstream, Downstream, On],
  heater: [Upstream, Downstream, On],
  line: [On],
};

export const DetailItem = {
  detail: '15V77',
  type: Valve,
  annotation: createAnnotation({
    id: 46,
    linkedResourceExternalId: '',
  }),
};

export const AssetItem = {
  asset: {
    id: 12334,
    externalId: 'V-1070',
    name: 'V-1070',
  },
  type: types.find((it) => it.type === 'pump'),
  annotation: createAnnotation({
    id: 46,
    linkedResourceExternalId: '',
  }),
};

export const itemWithLocationOnAnnotation = {
  detail: '15V77',
  type: Valve,
  annotation: createAnnotation({
    id: 46,
    data: {
      indirectExternalId: 'V-1540',
      indirectRelation: 'upstream of',
      lineExternalId: 'HC-6-20106',
      type: 'valve',
    },
    linkedResourceExternalId: '',
  }),
};

export const LineItem = {
  asset: {
    id: 3456,
    externalId: '3/8HF-50-1010',
    name: '3456',
    description: '',
    metadata: {},
  },
  indirectReference: On,
  annotation: createAnnotation({
    id: 45,
    data: {
      type: 'line',
      linkedResourceExternalId: '3/8HF-50-1010',
    },
  }),
};

export const itemForMechanicalValve = {
  detail: '15V77',
  type: Valve,
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  } as StepItem,
  annotation: createAnnotation({
    id: 46,
    data: { type: 'valve' },
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const itemWithRelativePosition = {
  detail: '15V77',
  type: Valve,
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  },
  annotation: createAnnotation({
    id: 46,
    data: { type: 'valve', relativePosition: '2nd' },
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const itemWithSubDetail = {
  detail: '15V77',
  type: Valve,
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  },
  annotation: createAnnotation({
    id: 46,
    data: {
      subDetail: 'LO',
    },
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const itemWithFloc = {
  asset: {
    id: 12334,
    externalId: 'V-1070',
    name: 'V-1070',
  },
  type: types.find((it) => it.type === 'motor'),
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  },
  annotation: createAnnotation({
    id: 46,
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const mockPhase = {
  id: 'phaseId',
  name: 'Phase one',
  phaseType: 'isolation',
  position: 0,
  workSteps: [
    {
      id: '12345',
      position: 1,
      comment: 'Mr Foobar 1',
    },
    {
      id: '23456',
      position: 2,
      comment: 'Mr Foobar 2',
    },
  ],
};

export const phasesWithEmptyPhase = [
  {
    id: 'phase1',
    name: 'Phase one',
    phaseType: 'isolation',
    position: 0,
    workSteps: [
      {
        id: '12345',
        position: 1,
        comment: 'This is step 1',
      },
      {
        id: '23456',
        position: 2,
        comment: 'This is step 2',
      },
    ],
  },
  {
    id: 'phase2',
    name: 'Phase two',
    phaseType: 'isolation',
    position: 1,
    workSteps: [],
  },
];

export const phasesWithTwoEmptyPhases = [
  {
    id: 'phase1',
    name: 'Phase one',
    phaseType: 'isolation',
    position: 0,
    workSteps: [
      {
        id: '12345',
        position: 1,
        comment: 'This is step 1',
      },
      {
        id: '23456',
        position: 2,
        comment: 'This is step 2',
      },
    ],
  },
  {
    id: 'phase2',
    name: 'Phase two',
    phaseType: 'isolation',
    position: 1,
    workSteps: [],
  },
  {
    id: 'phase2deiso',
    name: 'Phase two - deisolation',
    phaseType: 'deisolation',
    deIsolation: true,
    linkedPhaseId: 'phase2',
    position: 2,
    workSteps: [],
  },
  {
    id: 'phase1deiso',
    name: 'Phase one - deisolation',
    phaseType: 'deisolation',
    deIsolation: true,
    linkedPhaseId: 'phase1',
    position: 3,
    workSteps: [
      {
        id: '1152345',
        position: 3,
        comment: 'This is step 3 - deisolation',
      },
      {
        id: '1233456',
        position: 4,
        comment: 'This is step 4 - deisolation',
      },
    ],
  },
];

export const phasesWorkstepInIsolationStarted = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: new StepExecutionDetails({
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        }),
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesWorkstepInNotStarted = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesWithReviewComments = [
  {
    id: 'A',
    name: 'Phase one',
    position: 0,
    phaseType: 'isolation',
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        safeguarding: 'LC',
        comment: 'Must check with the checker',
        review: {
          resolved: {
            compiler: {
              name: 'jest.testing@cognite.com',
              date: 1607551145690,
            },
          },
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
          ],
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
      },
    ],
  } as Phase,
  {
    id: 'B',
    name: 'Phase two',
    position: 1,
    phaseType: 'isolation',
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
        review: {
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
          ],
        },
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: OpenAction,
        stepItem: itemForMechanicalValve,
        review: {
          resolved: {
            compiler: {
              name: 'jest.testing@cognite.com',
              date: 1607551145690,
            },
            reviewer: {
              name: 'testing.jest@cognite.com',
              date: 1607751145690,
            },
          },
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
          ],
        },
      },
      {
        id: '6',
        position: 6,
        stepAction: OpenAction,
        stepItem: itemForMechanicalValve,
        review: {
          resolved: {
            compiler: {
              name: 'jest.testing@cognite.com',
              date: 1607551145690,
            },
          },
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
            {
              id: '2',
              commentContent: 'Rejected',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607651145690,
            },
          ],
        },
      },
    ],
  } as Phase,
];

export const phasesWorkstepInDeisolation = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'deisolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
        execution: new StepExecutionDetails({
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        }),
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesWorkstepInDeisolationDone = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'deisolation' as PhaseType,
    position: 1,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
] as Phase[];

export const phasesFirstIsolationPhaseApproved = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesFirstDeisolationPhaseApproved = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'deisolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'deisolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phases = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
];

export const mockAssets: Array<Item> = [
  createAsset({
    id: 2345,
    externalId: '2345',
    name: '15V77',
    type: 'asset',
  }),
  createAsset({
    id: 3456,
    externalId: '3456',
    name: 'SDV-1011',
    type: 'asset',
  }),
  createAsset({
    id: 4567,
    externalId: '4567',
    name: '3/8HF-50-1010',
    type: 'line',
  }),
  createAsset({
    id: 5678,
    externalId: '5678',
    name: 'SDV-5102D',
    type: 'asset',
  }),
];

export function createAnnotation({
  id = 1,
  annotatedResourceId = 8449952732388192,
  annotatedResourceType = 'file',
  annotationType = 'isoplan.IsoPlanAnnotation',
  creatingApp = 'iso-plan',
  creatingAppVersion = '1.2.1',
  creatingUser = 'john.doe@cognite.com',
  data = {
    text: null,
    assetRef: null,
    textRegion: {
      xMax: 0.7544609571788413,
      xMin: 0.7426364399664148,
      yMax: 0.6282743467933491,
      yMin: 0.6212209026128266,
    },
    indirectRelation: null,
    indirectExternalId: null,
    lineExternalId: null,
    detail: null,
    type: 'pipeline',
    revision: null,
    relativePosition: null,
  },
  status = 'approved',
  lastUpdatedTime = 1667912304194,
  createdTime = 1667912304194,
}: any): CogniteAnnotation {
  return {
    id,
    creatingApp,
    creatingAppVersion,
    creatingUser,
    lastUpdatedTime,
    createdTime,
    annotatedResourceId,
    annotatedResourceType,
    annotationType,
    data,
    status,
  };
}

export function createAnnotationSpec({
  annotatedResourceId = 8449952732388192,
  annotatedResourceType = 'file',
  annotationType = 'isoplan.IsoPlanAnnotation',
  data = {
    text: null,
    assetRef: null,
    textRegion: {
      xMax: 0.7544609571788413,
      xMin: 0.7426364399664148,
      yMax: 0.6282743467933491,
      yMin: 0.6212209026128266,
    },
    indirectRelation: null,
    indirectExternalId: null,
    lineExternalId: null,
    detail: null,
    type: 'pipeline',
    revision: null,
    relativePosition: null,
  },
  creatingApp = 'iso-plan',
  creatingAppVersion = '1.2.1',
  creatingUser = 'john.doe@cognite.com',
}: any): CogniteAnnotationSpec {
  return {
    annotatedResourceId,
    annotatedResourceType,
    annotationType,
    creatingApp,
    creatingAppVersion,
    creatingUser,
    data,
  };
}

export function createPendingAnnotation({
  annotatedResourceId = 8449952732388192,
  annotatedResourceType = 'file',
  annotationType = 'isoplan.IsoPlanAnnotation',
  creatingApp = 'iso-plan',
  creatingAppVersion = '1.2.1',
  creatingUser = 'john.doe@cognite.com',
  data = {
    text: null,
    assetRef: null,
    textRegion: {
      xMax: 0.7544609571788413,
      xMin: 0.7426364399664148,
      yMax: 0.6282743467933491,
      yMin: 0.6212209026128266,
    },
    indirectRelation: null,
    indirectExternalId: null,
    lineExternalId: null,
    detail: null,
    type: 'pipeline',
    revision: null,
    relativePosition: null,
  },
  status = 'approved',
}: any): PendingCogniteAnnotation {
  return {
    annotatedResourceId,
    annotatedResourceType,
    annotationType,
    data,
    creatingApp,
    creatingAppVersion,
    creatingUser,
    status,
  };
}

export function createAsset({
  id,
  externalId,
  name,
  type,
}: {
  id: number;
  externalId: string;
  name: string;
  type: string;
}) {
  return {
    id,
    externalId,
    rootId: 0,
    name,
    description: '',
    metadata: {
      type,
    },
  };
}

export function cloneMockData(mockData: any) {
  return JSON.parse(JSON.stringify(mockData));
}

export const assetTypes = [
  { type: 'valve', description: 'Valve' },
  { type: 'spade', description: 'Spade' },
];

// export const workStepPairs = [
//   {
//     id: '8e567edf-5bf3-469b-b4e1-a0523d65d598',
//     position: 4,
//     color: 'rgb(255, 187, 0)',
//     deisolationId: '5ed3776e-d078-48d6-9916-b36551d1b001',
//     deisolationPosition: 5,
//     deisolationColor: 'rgb(74, 103, 251)',
//   },
// ];

// export const workStepPairs = [
//   {
//     id: '31ad0af0-b436-47cb-a71a-6540caf9fba8',
//     position: 1,
//     color: '#4A67FB',
//   },
// ];

export const workStepPairs = [
  {
    id: '31ad0af0-b436-47cb-a71a-6540caf9fba8',
    position: 1,
    color: '#4A67FB',
    deisolationId: '752e5166-f395-4f58-a373-a53648babe3c',
    deisolationPosition: 8,
    deisolationColor: '#FFBB00',
  },
];

// working mocks

// export const workStepPairs = [
//   {
//     id: '7dad92aa-45a0-47e2-9c76-6ca4acbc8e2a',
//     position: 2,
//     color: 'rgb(255, 187, 0)',
//   },
// ];

export const workSteps = [
  {
    id: '31ad0af0-b436-47cb-a71a-6540caf9fba8',
    position: 1,
    stepAction: {
      id: 'c17b194a-3dd4-46f5-af2b-b37a69b06d04',
      text: 'Open',
      template: 'Open [item]',
      color: '#4A67FB',
      slug: 'open',
    } as WorkStepAction,
    stepItem: {
      detail: '50V28',
      type: {
        type: 'valve',
        description: 'Valve',
        template: '[position] [type] [detail] [relation] [relativeRef]',
        options: ['safeguard'],
      } as ItemType,
      relativeRef: {
        detail: '15V77',
        type: {
          type: 'valve',
          description: 'Valve',
          template: '[position] [type] [detail] [relation] [relativeRef]',
          options: ['safeguard'],
        },
        annotation: {
          annotatedResourceExternalId:
            'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
          annotatedResourceId: 5230230714634294,
          annotatedResourceType: 'file',
          annotationType: 'file',
          createdTime: new Date(),
          creatingApp: 'isoplan',
          creatingAppVersion: '1.2.0',
          creatingUser: 'currentLoginUser',
          data: {
            REVISION: '11',
            detail: '15V77',
            // indirectExternalId: null,
            // indirectRelation: null,
            // lineExternalId: null,
            sourceType: 'user',
            subDetail: '',
            type: 'valve',
            textRegion: {
              xMax: 0.23093786733837116,
              xMin: 0.22595130142737196,
              yMax: 0.3892161520190024,
              yMin: 0.3724904988123514,
            },
            linkedResourceType: 'asset',
          },
          id: 7938891344557368,
          lastUpdatedTime: new Date(),
          // region: {
          //   type: 'boundingBox',
          //   xMax: 0.23093786733837116,
          //   xMin: 0.22595130142737196,
          //   yMax: 0.3892161520190024,
          //   yMin: 0.3724904988123514,
          // },
          mark: {
            strokeWidth: 0,
            backgroundColor: 'rgb(255, 201, 51)',
          },
          source: 'sasiru.hapuarachchi@cognitedata.com',
          status: 'approved',
        } as CogniteAnnotation,
      } as StepItem,
      indirectReference: {
        id: 'c179e2d5-f688-4bb9-84e0-67c6a48a8fed',
        text: 'downstream of',
        description: 'Downstream of',
      } as Reference,
      line: {
        detail: '36FL-1-6',
        annotation: {
          // annotatedResourceExternalId:
          //   'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
          annotatedResourceId: 5230230714634294,
          annotatedResourceType: 'file',
          annotationType: 'isoplan.IsoPlanAnnotation',
          createdTime: new Date(),
          creatingApp: 'iso-plan',
          creatingAppVersion: '1.2.1',
          creatingUser: 'pipeline',
          data: {
            REVISION: '11',
            detail: '36FL-1-6',
            // indirectExternalId: null,
            // indirectRelation: null,
            // lineExternalId: null,
            sourceType: 'user',
            subDetail: '',
            type: 'line',
            textRegion: {
              xMax: 0.18294710327455918,
              xMin: 0.1649034424853065,
              yMax: 0.2669916864608076,
              yMin: 0.25993824228028506,
            },
            linkedResourceType: 'asset',
          },
          id: 5030679568924013,
          lastUpdatedTime: new Date(),
          // region: {
          //   type: 'boundingBox',
          //   xMax: 0.18294710327455918,
          //   xMin: 0.1649034424853065,
          //   yMax: 0.2669916864608076,
          //   yMin: 0.25993824228028506,
          // },
          source: 'sasiru.hapuarachchi@cognitedata.com',
          status: 'approved',
        },
      } as StepItem,
      annotation: {
        // annotatedResourceExternalId:
        //   'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
        annotatedResourceId: 5230230714634294,
        annotatedResourceType: 'file',
        annotationType: 'isoplan.IsoPlanAnnotation',
        createdTime: new Date(),
        creatingApp: 'isoplan',
        creatingAppVersion: '1.2.0',
        creatingUser: 'currentLoginUser',
        data: {
          REVISION: '11',
          detail: '50V28',
          // indirectRelation: 'downstream of',
          sourceType: 'user',
          subDetail: 'LO',
          type: 'valve',
          textRegion: {
            xMax: 0.19587153652392947,
            xMin: 0.1840470193115029,
            yMax: 0.40509144893111637,
            yMin: 0.3980380047505938,
          },
          linkedResourceType: 'asset',
        },
        id: 6966724280829870,
        lastUpdatedTime: new Date(),
        // region: {
        //   type: 'boundingBox',
        //   xMax: 0.19587153652392947,
        //   xMin: 0.1840470193115029,
        //   yMax: 0.40509144893111637,
        //   yMin: 0.3980380047505938,
        // },
        source: 'sasiru.hapuarachchi@cognitedata.com',
        status: 'approved',
        // customOffset: {
        //   x: -4,
        //   y: -82,
        // },
      } as CogniteAnnotation,
    },
    tag: false,
    safeguarding: 'LO',
    execution: {
      doneBy: '',
      checkedBy: '',
      executionStopped: false,
    },
  } as WorkStep,
  {
    id: '752e5166-f395-4f58-a373-a53648babe3c',
    position: 8,
    stepAction: {
      id: 'f2de328f-7c57-486a-9dcd-b24a6a122630',
      text: 'Close',
      template: 'Close [item]',
      color: '#FFBB00',
      slug: 'close',
    } as WorkStepAction,
    tag: false,
    safeguarding: 'LO',
    execution: {
      doneBy: '',
      checkedBy: '',
      executionStopped: false,
    },
    tagNumber: '',
    comment: '',
    workStepType: 'deisolation',
    isDraft: false,
    linkedWorkStepId: '31ad0af0-b436-47cb-a71a-6540caf9fba8',
    linkedWorkStepSlug: 'open',
    isIgnored: false,
    stepItem: {
      detail: '50V28',
      type: {
        type: 'valve',
        description: 'Valve',
        template: '[position] [type] [detail] [relation] [relativeRef]',
        options: ['safeguard'],
      } as ItemType,
      relativeRef: {
        detail: '15V77',
        type: {
          type: 'valve',
          description: 'Valve',
          template: '[position] [type] [detail] [relation] [relativeRef]',
          options: ['safeguard'],
        },
        annotation: {
          annotatedResourceExternalId:
            'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
          annotatedResourceId: 5230230714634294,
          annotatedResourceType: 'file',
          annotationType: 'file',
          createdTime: new Date(),
          creatingApp: 'isoplan',
          creatingAppVersion: '1.2.0',
          creatingUser: 'currentLoginUser',
          data: {
            REVISION: '11',
            detail: '15V77',
            // indirectExternalId: null,
            // indirectRelation: null,
            // lineExternalId: null,
            sourceType: 'user',
            subDetail: '',
            type: 'valve',
          },
          id: 7938891344557368,
          lastUpdatedTime: new Date(),
          linkedResourceType: 'asset',
          region: {
            type: 'boundingBox',
            xMax: 0.23093786733837116,
            xMin: 0.22595130142737196,
            yMax: 0.3892161520190024,
            yMin: 0.3724904988123514,
          },
          source: 'sasiru.hapuarachchi@cognitedata.com',
          status: 'approved',
        } as CogniteAnnotation,
      } as StepItem,
      indirectReference: {
        id: 'c179e2d5-f688-4bb9-84e0-67c6a48a8fed',
        text: 'downstream of',
        description: 'Downstream of',
      } as Reference,
      line: {
        detail: '36FL-1-6',
        annotation: {
          annotatedResourceExternalId:
            'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
          annotatedResourceId: 5230230714634294,
          annotatedResourceType: 'file',
          annotationType: 'file',
          createdTime: new Date(),
          creatingApp: 'iso-plan',
          creatingAppVersion: '1.2.1',
          creatingUser: 'pipeline',
          data: {
            REVISION: '11',
            detail: '36FL-1-6',
            // indirectExternalId: null,
            // indirectRelation: null,
            // lineExternalId: null,
            sourceType: 'user',
            subDetail: '',
            type: 'line',
          },
          id: 5030679568924013,
          lastUpdatedTime: new Date(),
          linkedResourceType: 'asset',
          region: {
            type: 'boundingBox',
            xMax: 0.18294710327455918,
            xMin: 0.1649034424853065,
            yMax: 0.2669916864608076,
            yMin: 0.25993824228028506,
          },
          source: 'sasiru.hapuarachchi@cognitedata.com',
          status: 'approved',
        },
      } as StepItem,
      annotation: {
        annotatedResourceExternalId:
          'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
        annotatedResourceId: 5230230714634294,
        annotatedResourceType: 'file',
        annotationType: 'file',
        createdTime: new Date(),
        creatingApp: 'isoplan',
        creatingAppVersion: '1.2.0',
        creatingUser: 'currentLoginUser',
        data: {
          REVISION: '11',
          detail: '50V28',
          indirectRelation: 'downstream of',
          sourceType: 'user',
          subDetail: 'LO',
          type: 'valve',
        },
        id: 6966724280829870,
        lastUpdatedTime: new Date(),
        linkedResourceType: 'asset',
        region: {
          type: 'boundingBox',
          xMax: 0.19587153652392947,
          xMin: 0.1840470193115029,
          yMax: 0.40509144893111637,
          yMin: 0.3980380047505938,
        },
        source: 'sasiru.hapuarachchi@cognitedata.com',
        status: 'approved',
        customOffset: {
          x: -4,
          y: -82,
        },
      } as CogniteAnnotation,
    },
  } as WorkStep,
];

// OLD
// export const workStepssss = [
//   {
//     id: '31ad0af0-b436-47cb-a71a-6540caf9fba8',
//     position: 1,
//     stepAction: {
//       id: 'c17b194a-3dd4-46f5-af2b-b37a69b06d04',
//       text: 'Open',
//       template: 'Open [item]',
//       color: '#4A67FB',
//       slug: 'open',
//     } as WorkStepAction,
//     stepItem: {
//       detail: '50V28',
//       type: {
//         type: 'valve',
//         description: 'Valve',
//         template: '[position] [type] [detail] [relation] [relativeRef]',
//         options: ['safeguard'],
//       } as ItemType,
//       relativeRef: {
//         detail: '15V77',
//         type: {
//           type: 'valve',
//           description: 'Valve',
//           template: '[position] [type] [detail] [relation] [relativeRef]',
//           options: ['safeguard'],
//         },
//         annotation: {
//           annotatedResourceExternalId:
//             'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//           annotatedResourceId: 5230230714634294,
//           annotatedResourceType: 'file',
//           annotationType: 'isoplan.IsoPlanAnnotation', //
//           createdTime: new Date(), //
//           creatingApp: 'isoplan',
//           creatingAppVersion: '1.2.0',
//           creatingUser: 'currentLoginUser',
//           data: {
//             textRegion: {
//               xMax: 0.23093786733837116,
//               xMin: 0.2259513014273719,
//               yMax: 0.3892161520190024,
//               yMin: 0.3724904988123514,
//             },
//             detail: '15V77',
//             sourceType: 'user',
//             type: 'valve',
//             linkedResourceType: 'asset',
//           },
//           id: 7938891344557368,
//           lastUpdatedTime: new Date(), //
//           mark: {
//             strokeWidth: 0,
//             backgroundColor: 'rgb(255, 201, 51)',
//           },
//           source: 'sasiru.hapuarachchi@cognitedata.com',
//           status: 'approved', //
//         } as CogniteAnnotation,
//       } as StepItem,
//       indirectReference: {
//         id: 'c179e2d5-f688-4bb9-84e0-67c6a48a8fed',
//         text: 'downstream of',
//         description: 'Downstream of',
//       } as Reference,
//       line: {
//         detail: '36FL-1-6',
//         annotation: {
//           annotatedResourceId: 5230230714634294,
//           annotatedResourceType: 'file',
//           annotationType: 'isoplan.IsoPlanAnnotation', //
//           createdTime: new Date(), //
//           creatingApp: 'iso-plan',
//           creatingAppVersion: '1.2.1',
//           creatingUser: 'pipeline',
//           data: {
//             pageNumber: 1,
//             detail: '36FL-1-6',
//             sourceType: 'user',
//             type: 'line',
//             textRegion: {
//               xMax: 0.18294710327455918,
//               xMin: 0.1649034424853065,
//               yMax: 0.2669916864608076,
//               yMin: 0.25993824228028506,
//             },
//             linkedResourceType: 'asset',
//           },
//           id: 5030679568924013,
//           lastUpdatedTime: new Date(), //
//           source: 'sasiru.hapuarachchi@cognitedata.com',
//           status: 'approved', //
//         },
//       } as StepItem,
//       annotation: {
//         annotatedResourceId: 5230230714634294,
//         annotatedResourceType: 'file',
//         annotationType: 'isoplan.IsoPlanAnnotation',
//         createdTime: new Date(), //
//         creatingApp: 'isoplan',
//         creatingAppVersion: '1.2.0',
//         creatingUser: 'currentLoginUser',
//         data: {
//           textRegion: {
//             xMax: 0.19587153652392947,
//             xMin: 0.1840470193115029,
//             yMax: 0.40509144893111637,
//             yMin: 0.3980380047505938,
//           },
//           detail: '50V28',
//           indirectRelation: 'downstream of',
//           sourceType: 'user',
//           subDetail: 'LO',
//           type: 'valve',
//           linkedResourceType: 'asset',
//         },
//         id: 6966724280829870,
//         lastUpdatedTime: new Date(), //
//         source: 'sasiru.hapuarachchi@cognitedata.com',
//         mark: {
//           strokeWidth: 0,
//           backgroundColor: 'rgb(255, 201, 51)',
//         },
//         status: 'approved',
//       } as CogniteAnnotation,
//     },
//     tag: false,
//     safeguarding: 'LO',
//     execution: {
//       doneBy: '',
//       checkedBy: '',
//       executionStopped: false,
//     },
//   } as WorkStep,
// ];

// export const workSteps = [
//   {
//     id: '8e567edf-5bf3-469b-b4e1-a0523d65d598',
//     position: 4,
//     stepAction: {
//       id: '26405a4c-cac2-493d-94cd-7c4a3fd508c6',
//       text: 'Tag closed air supply',
//       template: 'Tag closed air supply to [item]',
//       color: 'rgb(255, 187, 0)',
//       slug: 'tag-closed-air-supply-to',
//       defaultTagged: true,
//     } as WorkStepAction,
//     stepItem: {
//       detail: 'VAL-TEST-2',
//       type: {
//         type: 'valve',
//         description: 'Valve',
//         template: '[position] [type] [detail] [relation] [relativeRef]',
//         options: ['safeguard'],
//       } as ItemType,
//       relativeRef: {
//         detail: '50V28',
//         type: {
//           type: 'valve',
//           description: 'Valve',
//           template: '[position] [type] [detail] [relation] [relativeRef]',
//           options: ['safeguard'],
//         },
//         annotation: {
//           annotatedResourceExternalId:
//             'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//           annotatedResourceId: 5230230714634294,
//           annotatedResourceType: 'file',
//           annotationType: 'isoplan.IsoPlanAnnotation',
//           createdTime: new Date(),
//           creatingApp: 'Annotations-migrator',
//           creatingAppVersion: '0.1.0',
//           creatingUser: 'Annotations_migrator_consumer',
//           data: {
//             textRegion: {
//               xMax: 0.6124962216624684,
//               xMin: 0.6006717044500419,
//               yMax: 0.5618610451306413,
//               yMin: 0.5548076009501188,
//             },
//             detail: '50V28',
//             type: 'valve',
//             indirectRelation: 'downstream of',
//             linkedResourceType: 'asset',
//             sourceType: 'user',
//             text: '',
//             oldAnnotationId: '1518139141682998',
//           },
//           id: 5908439732662321,
//           lastUpdatedTime: new Date(),
//           status: 'approved',
//           mark: {
//             strokeWidth: 0,
//             backgroundColor: 'rgb(255, 201, 51)',
//           },
//           color: 'rgb(255, 201, 51)',
//         } as CogniteAnnotation,
//       } as StepItem,
//       indirectReference: {
//         id: '9b232a10-ed6b-4c85-9126-b57fd958e3e8',
//         text: 'upstream of',
//         description: 'Upstream of',
//       } as Reference,
//       line: {
//         detail: '100HL-1-326',
//         annotation: {
//           annotatedResourceExternalId:
//             'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//           annotatedResourceId: 5230230714634294,
//           annotatedResourceType: 'file',
//           annotationType: 'isoplan.IsoPlanAnnotation',
//           createdTime: new Date(),
//           creatingApp: 'Annotations-migrator',
//           creatingAppVersion: '0.1.0',
//           creatingUser: 'Annotations_migrator_consumer',
//           data: {
//             textRegion: {
//               xMax: 0.5420713685978169,
//               xMin: 0.5370848026868178,
//               yMax: 0.39306413301662707,
//               yMin: 0.3593016627078385,
//             },
//             detail: '100HL-1-326',
//             type: 'line',
//             linkedResourceType: 'asset',
//             sourceType: 'user',
//             text: '',
//             oldAnnotationId: '3586575447314403',
//           },
//           id: 8472067814397760,
//           lastUpdatedTime: new Date(),
//           status: 'approved',
//           mark: {
//             strokeWidth: 0,
//             backgroundColor: 'rgb(255, 201, 51)',
//           },
//           color: 'rgb(255, 201, 51)',
//         },
//       } as StepItem,
//       annotation: {
//         annotatedResourceExternalId:
//           'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//         annotatedResourceId: 5230230714634294,
//         annotatedResourceType: 'file',
//         annotationType: 'isoplan.IsoPlanAnnotation',
//         createdTime: new Date(),
//         creatingApp: 'isoplan',
//         creatingAppVersion: '1.2.0',
//         creatingUser: 'currentLoginUser',
//         data: {
//           textRegion: {
//             xMax: 0.5786555576226007,
//             xMin: 0.5640223072865153,
//             yMax: 0.5453528664547942,
//             yMin: 0.5231198100404212,
//           },
//           detail: 'VAL-TEST-2',
//           type: 'valve',
//           linkedResourceType: 'asset',
//           sourceType: 'user',
//           text: '',
//           oldAnnotationId: null,
//         },
//         id: 7457738975527263,
//         lastUpdatedTime: new Date(),
//         status: 'approved',
//         mark: {
//           strokeWidth: 0,
//           backgroundColor: 'rgb(255, 201, 51)',
//         },
//         color: 'rgb(255, 201, 51)',
//       } as CogniteAnnotation,
//     },
//     tag: true,
//     safeguarding: '',
//     execution: {
//       doneBy: '',
//       checkedBy: '',
//       executionStopped: false,
//     },
//   } as WorkStep,
//   {
//     id: '5ed3776e-d078-48d6-9916-b36551d1b001',
//     position: 5,
//     execution: {
//       doneBy: '',
//       checkedBy: '',
//       executionStopped: false,
//     },
//     comment: '',
//     workStepType: 'deisolation',
//     isDraft: false,
//     linkedWorkStepId: '8e567edf-5bf3-469b-b4e1-a0523d65d598',
//     safeguarding: '',
//     tag: true,
//     stepItem: {
//       detail: 'VAL-TEST-2',
//       type: {
//         type: 'valve',
//         description: 'Valve',
//         template: '[position] [type] [detail] [relation] [relativeRef]',
//         options: ['safeguard'],
//       } as ItemType,
//       relativeRef: {
//         detail: '50V28',
//         type: {
//           type: 'valve',
//           description: 'Valve',
//           template: '[position] [type] [detail] [relation] [relativeRef]',
//           options: ['safeguard'],
//         },
//         annotation: {
//           annotatedResourceExternalId:
//             'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//           annotatedResourceId: 5230230714634294,
//           annotatedResourceType: 'file',
//           annotationType: 'isoplan.IsoPlanAnnotation',
//           createdTime: new Date(),
//           creatingApp: 'Annotations-migrator',
//           creatingAppVersion: '0.1.0',
//           creatingUser: 'Annotations_migrator_consumer',
//           data: {
//             textRegion: {
//               xMax: 0.6124962216624684,
//               xMin: 0.6006717044500419,
//               yMax: 0.5618610451306413,
//               yMin: 0.5548076009501188,
//             },
//             detail: '50V28',
//             type: 'valve',
//             indirectRelation: 'downstream of',
//             linkedResourceType: 'asset',
//             sourceType: 'user',
//             text: '',
//             oldAnnotationId: '1518139141682998',
//           },
//           id: 5908439732662321,
//           lastUpdatedTime: new Date(),
//           status: 'approved',
//           mark: {
//             strokeWidth: 0,
//             backgroundColor: 'rgb(255, 201, 51)',
//           },
//           color: 'rgb(255, 201, 51)',
//         } as CogniteAnnotation,
//       } as StepItem,
//       indirectReference: {
//         id: '9b232a10-ed6b-4c85-9126-b57fd958e3e8',
//         text: 'upstream of',
//         description: 'Upstream of',
//       } as Reference,
//       line: {
//         detail: '100HL-1-326',
//         annotation: {
//           annotatedResourceExternalId:
//             'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//           annotatedResourceId: 5230230714634294,
//           annotatedResourceType: 'file',
//           annotationType: 'isoplan.IsoPlanAnnotation',
//           createdTime: new Date(),
//           creatingApp: 'Annotations-migrator',
//           creatingAppVersion: '0.1.0',
//           creatingUser: 'Annotations_migrator_consumer',
//           data: {
//             textRegion: {
//               xMax: 0.5420713685978169,
//               xMin: 0.5370848026868178,
//               yMax: 0.39306413301662707,
//               yMin: 0.3593016627078385,
//             },
//             detail: '100HL-1-326',
//             type: 'line',
//             linkedResourceType: 'asset',
//             sourceType: 'user',
//             text: '',
//             oldAnnotationId: '3586575447314403',
//           },
//           id: 8472067814397760,
//           lastUpdatedTime: new Date(),
//           status: 'approved',
//           mark: {
//             strokeWidth: 0,
//             backgroundColor: 'rgb(255, 201, 51)',
//           },
//           color: 'rgb(255, 201, 51)',
//         },
//       } as StepItem,
//       annotation: {
//         annotatedResourceExternalId:
//           'NZ_fileshare_//nplcifs01.ww.omv.com/drawings/MPS/PX/2365 - PID/OPS-10-00180-1.pdf_OPS-10-00180-1.pdf',
//         annotatedResourceId: 5230230714634294,
//         annotatedResourceType: 'file',
//         annotationType: 'isoplan.IsoPlanAnnotation',
//         createdTime: new Date(),
//         creatingApp: 'isoplan',
//         creatingAppVersion: '1.2.0',
//         creatingUser: 'currentLoginUser',
//         data: {
//           textRegion: {
//             xMax: 0.5786555576226007,
//             xMin: 0.5640223072865153,
//             yMax: 0.5453528664547942,
//             yMin: 0.5231198100404212,
//           },
//           detail: 'VAL-TEST-2',
//           type: 'valve',
//           linkedResourceType: 'asset',
//           sourceType: 'user',
//           text: '',
//           oldAnnotationId: null,
//         },
//         id: 7457738975527263,
//         lastUpdatedTime: new Date(),
//         status: 'approved',
//         mark: {
//           strokeWidth: 0,
//           backgroundColor: 'rgb(255, 201, 51)',
//         },
//         color: 'rgb(255, 201, 51)',
//       } as CogniteAnnotation,
//     },
//     linkedWorkStepSlug: 'tag-closed-air-supply-to',
//     isIgnored: false,
//     stepAction: {
//       id: '56271b36-3dfe-40e6-9811-dd55d0b6444a',
//       text: 'Remove tag and open',
//       template: 'Remove tag and open air supply [item]',
//       color: 'rgb(74, 103, 251)',
//       slug: 'remove-tag-and-open-air-supply',
//       defaultTagged: true,
//     } as WorkStepAction,
//   } as WorkStep,
// ];

export const active = {
  workStep: undefined,
};

export * from 'utils/test/actions';
