import styled from 'styled-components/macro';
import { Colors } from '@cognite/cogs.js';
import zIndex from '../../../../utils/zindex';

type BarSectionType = {
  hasMargin?: boolean;
  noBorder?: boolean;
};

export const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(106, 108, 113, 0.02),
    0px 0px 4px rgba(106, 108, 113, 0.03), 0px 1px 9px rgba(106, 108, 113, 0.05),
    0px 2px 15px rgba(106, 108, 113, 0.06),
    0px 3px 24px rgba(106, 108, 113, 0.08);
  box-sizing: border-box;
  position: absolute;
  background-color: white;
  top: 50px;
  right: 25px;
  height: auto;
  width: auto;
  z-index: ${zIndex.MAXIMUM};
  font-size: 10px;
`;

export const BarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: ${({ hasMargin }: BarSectionType) => (hasMargin ? '0 4px' : '0')};
  border-left: ${({ noBorder }: BarSectionType) =>
    noBorder ? '0' : `1px solid ${Colors['decorative--grayscale--100']}`};

  & > * {
    box-sizing: border-box;
    margin: ${({ hasMargin }: BarSectionType) => (hasMargin ? '0 4px' : '0')};
  }
`;
