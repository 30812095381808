import { Menu } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import zIndex from 'utils/zindex';

export const HotSpotContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const HotSpotElement = styled.div`
  border: 1px solid grey;
  padding: 5px;
  border-radius: 10%;
  width: 100px;
  margin: 5px;
`;

export const HotShotMenu = styled(Menu)`
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: ${zIndex.POPUP};
`;
