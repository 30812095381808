import { Middleware } from 'redux';

import searchSlice from '../../reducers/search';
import { fetchProject } from '../../reducers/project';
import { AssetMappingType } from '../../../types';

export const projectMiddleware: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === fetchProject.fulfilled.type) {
      const storeSearch = getState().search;
      if (storeSearch.selectedRootAsset !== action.payload.rootAsset) {
        const result = storeSearch.rootAssetsContent.find(
          (asset: AssetMappingType) =>
            asset.assetFloc === action.payload.rootAsset
        );

        if (result) {
          dispatch(searchSlice.actions.setSelectedRootAsset(result));
        }
      }
    }

    next(action);
  };
