import styled from 'styled-components/macro';

export type Props = {
  minHeight?: string;
};
const CardContainer = styled.div<Props>`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  min-height: ${(props) => props.minHeight};

  border-right: 1px solid #eee;
  margin-left: 0;

  &:last-of-type {
    border-right: none;
  }
`;

export default CardContainer;
