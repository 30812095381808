// temporary eslint disable
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Colors, Flex } from '@cognite/cogs.js';
import { SelectionMode } from 'redux/reducers/activeWorkStep';
import { useActiveWorkStep } from 'redux/reducers/activeWorkStep/hooks';
import styled from 'styled-components/macro';
import {
  ActiveWorkStep,
  Reference,
  StepItem,
  WorkStep,
} from 'types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AnnotationsState } from 'redux/reducers/annotations';
import { useActiveWorkstepActions } from 'redux/reducers/activeWorkStep/workstepActions';
import { createSelector } from '@reduxjs/toolkit';
import { SizeAndClass } from '../SizeAndClass/SizeAndClass';

export const activeWorkstepSelectionModeSelector = createSelector(
  (state: any) => state.activeWorkStep.selectionMode,
  (selectionMode) => selectionMode
);

const Text = styled.span.attrs({ className: 'cogs-body-1' })`
  margin-right: 5px;
  color: var(--cogs-greyscale-grey9);
`;

const Linked = styled.span.attrs({ className: 'cogs-body-1' })<{
  textColor: string;
}>`
  cursor: pointer;
  color: ${(props) => props.textColor};
  font-weight: bold;
  margin-right: 5px;
  white-space: normal;
`;

const regExpHelper = {
  splitIntoBulks: (str?: string) =>
    // eslint-disable-next-line no-useless-escape
    str ? str.match(/<(.*?)>|{(.*?)}|\[(.*?)\]|([\w\/]+)/g) : [],
  removeGreaterThan: (str: string) => str.replace(/<|>/g, ''),
};

export type WorkStepItemTemplateProps = {
  active: ActiveWorkStep;
  workStep: WorkStep;
  editable: boolean;
};

const textColor = (editable: boolean, inSelectionMode: boolean) => {
  if (editable && inSelectionMode) {
    return 'red';
  }
  return Colors['decorative--grayscale--black'];
};

const Options = ({ item }: { item: StepItem }) => {
  const { referenceTypes } = useActiveWorkStep();

  const { getReferenceTypes, updateItemOption } = useActiveWorkstepActions();

  const [type, setType] = useState<string>();

  useEffect(() => {
    if (
      item?.relativeRef?.annotation?.data &&
      'type' in item?.relativeRef?.annotation?.data
    )
      setType(item?.relativeRef?.annotation?.data?.type);
  }, [item]);

  useEffect(() => {
    getReferenceTypes(type || 'asset');
  }, [getReferenceTypes, type]);

  const selectRelation = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();
    const selectedOption = referenceTypes[type || 'asset']?.find(
      (refType: Reference) => {
        return refType.text === event.target.value;
      }
    );
    if (selectedOption) {
      updateItemOption({
        item,
        option: selectedOption,
      });
    }
  };

  return (
    <select
      style={{ marginRight: '5px' }}
      key="workStep-relativRef-options"
      data-testid="workStep-relativRef-options"
      value={item.indirectReference?.text}
      onChange={selectRelation}
    >
      {referenceTypes[type || 'asset']?.map((option) => {
        return (
          <option
            data-testid={`option-${option.text}`}
            key={`relativeRef-option-${option.text}`}
            value={option.text}
          >
            {option.text}
          </option>
        );
      })}
    </select>
  );
};

const Item = ({
  item,
  parent,
  active,
  editable,
}: {
  item?: StepItem;
  parent?: StepItem;
  active: boolean;
  editable: boolean;
}) => {
  const selectionMode = useSelector((state) =>
    activeWorkstepSelectionModeSelector(state)
  );

  const { setSelectionMode, updateRelativePosition, updateItemDetail } =
    useActiveWorkstepActions();
  const [detailInputVisible, setDetailInputVisible] = useState(false);

  if (item) {
    const mode = !parent ? SelectionMode.Item : SelectionMode.RelativeRef;

    const bulks = regExpHelper
      .splitIntoBulks(item?.type?.template || '[floc]')
      ?.map((it) => it);

    return (
      <>
        {bulks?.map((bulk, index) => {
          if (bulk === '[floc]') {
            return (
              <span key={`item-${item.annotation?.id}`}>
                {item.asset?.externalId ? (
                  <Linked
                    data-testid="select-item-by-floc"
                    textColor={textColor(editable, selectionMode === mode)}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (editable) {
                        setSelectionMode(mode);
                      }
                    }}
                  >
                    {item.asset?.externalId.split('.').pop()}
                  </Linked>
                ) : (
                  editable && <Text>{bulk}</Text>
                )}
              </span>
            );
          }
          if (bulk === '[position]' && (editable || active)) {
            if (
              item.relativeRef &&
              editable &&
              item.annotation?.data &&
              'relativePosition' in item.annotation?.data
            ) {
              return (
                <select
                  style={{ marginRight: '5px' }}
                  key="workStep-position-options"
                  data-testid="workStep-position-options"
                  value={item.annotation?.data?.relativePosition}
                  onChange={(event) => {
                    updateRelativePosition({
                      item,
                      position: event.target.value,
                    });
                  }}
                >
                  {['1st', '2nd', '3rd'].map((option) => {
                    return (
                      <option
                        data-testid={`position-option-${option}`}
                        key={`position-option-${option}`}
                        value={option}
                      >
                        {option}
                      </option>
                    );
                  })}
                </select>
              );
            }
            if (
              item.annotation?.data &&
              'relativePosition' in item.annotation?.data &&
              item.annotation?.data?.relativePosition
            ) {
              return (
                <Text
                  data-testid={`relative-position-${item.annotation?.id}`}
                  key={`relative-position-${item.annotation?.id}`}
                >
                  {item.annotation?.data?.relativePosition}
                </Text>
              );
            }
          }
          if (bulk === '[type]') {
            if (item?.annotation?.data && 'type' in item?.annotation?.data) {
              return (
                <Text key={`template-type-${item.type}`}>
                  {item?.annotation?.data?.type}
                </Text>
              );
            }
          }
          if (bulk === '[detail]') {
            return (
              <span key={`bulk-${index}`}>
                {item.detail && !detailInputVisible ? (
                  <Linked
                    data-testid="update-item-by-detail"
                    textColor={textColor(editable, selectionMode === mode)}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (editable) {
                        setSelectionMode(mode);
                      }
                    }}
                  >
                    {item.detail}
                  </Linked>
                ) : (
                  editable && (
                    <>
                      {!detailInputVisible && (
                        <Linked
                          data-testid="set-item-by-detail"
                          textColor={textColor(
                            editable,
                            selectionMode === mode
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (editable) {
                              setDetailInputVisible(false);
                              setSelectionMode(mode);
                            }
                          }}
                        >
                          {bulk}
                        </Linked>
                      )}

                      {detailInputVisible && (
                        <input
                          data-testid="detail-input"
                          style={{ width: '80px' }}
                          onChange={(e) => {
                            updateItemDetail(e.currentTarget.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              updateItemDetail(e.currentTarget.value);
                              setDetailInputVisible(!detailInputVisible);
                            }
                          }}
                          value={item.detail ? item.detail : ''}
                        />
                      )}

                      <Linked
                        textColor="black"
                        onClick={() =>
                          setDetailInputVisible(!detailInputVisible)
                        }
                        style={{ marginRight: '10px', marginLeft: '5px' }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/Edit.svg`}
                          alt="Edit"
                          height="10"
                          style={{ filter: 'invert(1)' }}
                          title="Click to Set Detail"
                        />
                      </Linked>
                    </>
                  )
                )}
              </span>
            );
          }
          if (bulk === '[relation]' && (editable || active)) {
            if (item.relativeRef) {
              if (editable) {
                return <Options key={`bulk-${index}`} item={item} />;
              }
              return (
                item.indirectReference && (
                  <Text key={`bulk-${index}`}>
                    {item.indirectReference?.text}
                  </Text>
                )
              );
            }
            if (
              item.annotation?.data &&
              'indirectRelation' in item.annotation?.data &&
              item.annotation?.data?.indirectRelation
            ) {
              return (
                <Text
                  key={`bulk-relativeRef-${item.annotation?.data.indirectRelation}`}
                >
                  {item.annotation?.data.indirectRelation}
                </Text>
              );
            }
          }
          if (bulk === '[relativeRef]' && (editable || active)) {
            if (item?.relativeRef) {
              return (
                <Item
                  key={`bulk-relativeref-${index}`}
                  item={item.relativeRef}
                  parent={item}
                  active={active}
                  editable={editable}
                />
              );
            }
            if (
              item.annotation?.data &&
              'indirectExternalId' in item.annotation?.data
            ) {
              return (
                <Linked
                  key={`bulk-relativeRef-${item.annotation?.data.indirectExternalId?.externalId}`}
                  textColor={textColor(
                    editable,
                    selectionMode === SelectionMode.RelativeRef
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (editable) {
                      setSelectionMode(SelectionMode.RelativeRef);
                    }
                  }}
                >
                  {item.annotation?.data.indirectExternalId?.externalId}
                </Linked>
              );
            }
            if (!parent && editable) {
              return (
                <Text key={`bulk-relativeref-${index}`}>
                  <Linked
                    data-testid="set-selectionMode-relativeRef"
                    textColor={textColor(
                      editable,
                      selectionMode === SelectionMode.RelativeRef
                    )}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectionMode(SelectionMode.RelativeRef);
                    }}
                  >
                    [select relative reference]
                  </Linked>
                </Text>
              );
            }
          }
          return null;
        })}
      </>
    );
  }
  if (editable) {
    return (
      <Linked
        data-testid="set-selectionMode-item"
        textColor={textColor(editable, selectionMode === SelectionMode.Item)}
        onClick={(event) => {
          event.stopPropagation();
          setSelectionMode(SelectionMode.Item);
        }}
      >
        [select item]
      </Linked>
    );
  }
  return null;
};

const Line = ({ item, editable }: { item?: StepItem; editable: boolean }) => {
  const { selectionMode } = useActiveWorkStep();
  const { setSelectionMode } = useActiveWorkstepActions();
  const { annotations } = useSelector<RootState, AnnotationsState>(
    (state) => state.annotations
  );
  const getLinkedContent = () => {
    if (item?.line) {
      const linePipeSize =
        item.line.detail ||
        item.line.asset?.metadata?.PIPING_SIZE_NF?.replace('MM', '') ||
        '';
      const linePipeDisplayName =
        item.line?.asset?.externalId || item.line?.asset?.name || '';

      return `${linePipeSize}${linePipeDisplayName}`;
    }
    if (item?.annotation?.data && 'lineExternalId' in item?.annotation?.data) {
      let lineAnnotation;
      const lineExternalId = item?.annotation?.data?.lineExternalId?.externalId;
      // try fetch the information for store for that annotation
      if (
        // eslint-disable-next-line no-prototype-builtins
        annotations.hasOwnProperty(item.annotation?.annotatedResourceId)
      ) {
        lineAnnotation = annotations[item.annotation?.annotatedResourceId].find(
          (annotationItem) => {
            if (
              'linkedResourceExternalId ' in annotationItem.data &&
              'type' in annotationItem.data
            ) {
              annotationItem?.data?.linkedResourceExternalId ===
                lineExternalId && annotationItem.data.type === 'line';
            }
          }
        );
      }
      let linePipeSize = '';
      if (lineAnnotation?.data && 'detail' in lineAnnotation?.data)
        linePipeSize = lineAnnotation?.data.detail || '';

      return lineAnnotation
        ? `${linePipeSize}${lineExternalId}`
        : lineExternalId;
    }
    if (
      !item?.line &&
      item?.annotation?.data &&
      !('lineExternalId' in item?.annotation?.data) &&
      editable
    ) {
      return '[select line]';
    }
    return null;
  };

  return (
    <>
      {item &&
        (item?.line ||
          (item.annotation?.data &&
            'lineExternalId' in item.annotation?.data &&
            item.annotation?.data.lineExternalId?.externalId)) && (
          <Text>on line</Text>
        )}
      <Linked
        data-testid="select-line"
        textColor={textColor(editable, selectionMode === SelectionMode.Line)}
        onClick={(event) => {
          event.stopPropagation();
          if (editable) {
            setSelectionMode(SelectionMode.Line);
          }
        }}
      >
        {getLinkedContent()}
      </Linked>
    </>
  );
};

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: pre-line;
  padding-right: 10px;
`;

const EditBorderStyles = {
  padding: '8px',
  backgroundColor: Colors['decorative--blue--100'],
  marginRight: '11px',
  width: '100%',
};

export const Template = React.memo(
  ({ active, workStep, editable }: WorkStepItemTemplateProps) => {
    const { setSelectionMode, setSizeAndClass } = useActiveWorkstepActions();
    const templateItems = regExpHelper.splitIntoBulks(
      workStep.stepAction?.template
    ) as string[];

    return (
      <TemplateContainer
        key={`template-workstep-${workStep.id}`}
        data-testid={`workStep-${workStep.id}`}
        style={editable ? EditBorderStyles : {}}
      >
        {editable && !workStep.stepAction ? (
          <Text key={`template-select-action-${workStep.id}`}>
            <Linked
              textColor={Colors['decorative--blue--500']}
              onClick={(event) => {
                event.stopPropagation();
                if (editable) {
                  setSelectionMode(SelectionMode.Item);
                }
              }}
            >
              Select item and action
            </Linked>
          </Text>
        ) : (
          <Flex direction="column">
            <Flex direction="row" style={{ flexFlow: 'wrap' }}>
              {templateItems?.map((templateItem) => {
                if (templateItem === '[item]') {
                  return (
                    <div
                      style={{ display: 'contents' }}
                      key={`template-item-wrapper-${workStep.id}`}
                    >
                      <Item
                        key={`template-item-${workStep.id}`}
                        item={workStep.stepItem}
                        editable={editable}
                        active={workStep.id === active.workStep?.id}
                      />
                      {(editable || workStep.id === active.workStep?.id) && (
                        <Line
                          item={workStep.stepItem}
                          editable={editable}
                          key={`template-line-${workStep.id}`}
                        />
                      )}
                    </div>
                  );
                }
                if (templateItem === '[line]') {
                  // backwards compatibillity
                  return null;
                }
                if (templateItem === '[type]') {
                  // backwards compatibillity
                  return null;
                }
                if (templateItem === '[class]') {
                  if (
                    workStep.stepItem?.annotation?.data &&
                    !('sizeAndClass' in workStep.stepItem?.annotation?.data)
                  ) {
                    return (
                      <Text key="template-class-undefined">
                        (size and class)
                      </Text>
                    );
                  }
                  return (
                    <div key={`template-class-wrapper-${workStep.id}`}>
                      {workStep.stepItem?.annotation?.data &&
                        'sizeAndClass' in workStep.stepItem?.annotation?.data &&
                        workStep.stepItem?.annotation?.data?.sizeAndClass
                          ?.size && (
                          <Text
                            data-testid="template-class-size"
                            key={`template-class-size-${workStep.id}`}
                          >
                            {
                              workStep.stepItem?.annotation?.data?.sizeAndClass
                                ?.size
                            }
                          </Text>
                        )}
                      {workStep.stepItem?.annotation?.data &&
                        'sizeAndClass' in workStep.stepItem?.annotation?.data &&
                        workStep.stepItem?.annotation?.data?.sizeAndClass
                          ?.unit && (
                          <Text
                            data-testid="template-class-unit"
                            key={`template-class-unit-${workStep.id}`}
                          >
                            {
                              workStep.stepItem?.annotation?.data?.sizeAndClass
                                ?.unit
                            }
                          </Text>
                        )}
                      {workStep.stepItem?.annotation?.data &&
                        'sizeAndClass' in workStep.stepItem?.annotation?.data &&
                        workStep.stepItem?.annotation?.data?.sizeAndClass
                          ?.classType && (
                          <Text
                            data-testid="template-class-class"
                            key={`template-class-class-${workStep.id}`}
                          >
                            {
                              workStep.stepItem?.annotation?.data?.sizeAndClass
                                ?.classType
                            }
                          </Text>
                        )}
                    </div>
                  );
                }
                return (
                  <Text key={`template-part-${templateItem}-${workStep.id}`}>
                    {templateItem}
                  </Text>
                );
              })}
            </Flex>
            {templateItems?.includes('[class]') && (
              <SizeAndClass
                editable={editable}
                {...(workStep.stepItem?.annotation?.data &&
                  'sizeAndClass' in workStep.stepItem?.annotation?.data && {
                    sizeAndClass:
                      workStep.stepItem?.annotation?.data.sizeAndClass,
                  })}
                setSizeAndClass={setSizeAndClass}
              />
            )}
          </Flex>
        )}
      </TemplateContainer>
    );
  }
);
