import React, { useState } from 'react';
import { Button } from '@cognite/cogs.js';
import Modal from 'shared/Modal';
import { useResolvedProject, useResolvedWorkSteps } from 'hooks/useResolved';
import { DashboardWarning } from './elements';

type Props = {
  cannotSubmitToReview: boolean;
  migrationInProgress: boolean;
  handleReviewSubmit: Function;
  hasDraftSteps: boolean;
  viewOnly: boolean;
};

const SubmitProcedure = ({
  cannotSubmitToReview,
  migrationInProgress,
  handleReviewSubmit,
  hasDraftSteps,
  viewOnly,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const isProjectResolved = useResolvedProject();
  const isWorkStepsResolved = useResolvedWorkSteps();

  const submitDisabled =
    cannotSubmitToReview ||
    !isWorkStepsResolved ||
    !isProjectResolved ||
    hasDraftSteps ||
    migrationInProgress;

  const renderActionButton = () => {
    if (!viewOnly) {
      return (
        <Button
          disabled={submitDisabled}
          type="secondary"
          style={{ width: 'max-content', marginBottom: '8px' }}
          data-testid="submit-for-review-button"
          onClick={() => setModalOpen(true)}
        >
          Submit procedure for review
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Modal
        visible={modalOpen}
        setVisible={setModalOpen}
        title="Submit procedure to review"
        okText="Submit"
        okHandler={() => handleReviewSubmit('Submit procedure to review')}
      >
        <div data-testid="review-submit-confirmation-text">
          You won&apos;t be able to change the procedure after submission.
        </div>
      </Modal>
      <div style={{ textAlign: 'right' }}>
        {renderActionButton()}
        {hasDraftSteps && (
          <DashboardWarning data-testid="approve-reject-draft-steps-warning-dashboard">
            There are work steps in draft mode which need to be handled before
            you can submit
          </DashboardWarning>
        )}
        {submitDisabled && (
          <DashboardWarning data-testid="not-ready-for-review-warning-dashboard">
            You must resolve any issues marked in red before submittion
          </DashboardWarning>
        )}
        {migrationInProgress && (
          <DashboardWarning data-testid="migration-in-progress-warning-dashboard">
            You haven&apos;t completed updating the procedure to new revisions
            of P&IDs.
          </DashboardWarning>
        )}
      </div>
    </>
  );
};

export default SubmitProcedure;
