import styled from 'styled-components/macro';

export const LogoSpan = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--cogs-green-6);
  margin-right: 6px;
  text-align: center;
`;
