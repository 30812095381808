import { ProcedureApprovalFile } from 'types';
import { APPROVAL_FILES_DATASET_ID } from 'config/apiConfig';
import { modelFactory } from 'factories';
import { FileInfo, FileUploadResponse } from '@cognite/sdk';
import { getClient } from '../config/cognitesdk';

export class ProcedureApprovalFilesResourceService {
  async fetchApprovalFiles(
    procedureId: string,
    client = getClient()
  ): Promise<ProcedureApprovalFile[]> {
    const rawApprovalFilesList = await client.files
      .list({
        filter: {
          dataSetIds: [{ externalId: APPROVAL_FILES_DATASET_ID }],
          source: procedureId,
          metadata: { removed: 'false' },
        },
      })
      .autoPagingToArray({ limit: -1 });

    return rawApprovalFilesList.map((file) =>
      modelFactory.mapToProcedureApprovalFile(file)
    );
  }

  upload(
    procedureId: string,
    file: File,
    approvalFile: ProcedureApprovalFile,
    fileContent: any,
    dataSetId?: number,
    client = getClient()
  ): Promise<FileInfo | FileUploadResponse> {
    return client.files.upload(
      {
        externalId: approvalFile.id,
        dataSetId,
        name: approvalFile.name,
        mimeType: file.type ? file.type : undefined,
        source: procedureId,
        metadata: {
          uploaded_by: approvalFile.author,
          removed: 'false',
          approvedBy: '',
          description: '',
        },
      },
      fileContent,
      true
    );
  }

  softDelete(approvalFile: ProcedureApprovalFile, client = getClient()) {
    return client.files.update([
      {
        externalId: approvalFile.id,
        update: {
          metadata: {
            set: {
              uploaded_by: approvalFile.author,
              removed: 'true',
              approvedBy: approvalFile.approvedBy,
              description: approvalFile.description,
            },
          },
        },
      },
    ]);
  }

  undoSoftDelete(approvalFile: ProcedureApprovalFile, client = getClient()) {
    return client.files.update([
      {
        externalId: approvalFile.id,
        update: {
          metadata: {
            set: {
              uploaded_by: approvalFile.author,
              removed: 'false',
              approvedBy: approvalFile.approvedBy,
              description: approvalFile.description,
            },
          },
        },
      },
    ]);
  }

  updateFileMetaData(
    approvalFile: ProcedureApprovalFile,
    client = getClient()
  ) {
    return client.files.update([
      {
        externalId: approvalFile.id,
        update: {
          metadata: {
            set: {
              uploaded_by: approvalFile.author,
              removed: approvalFile.removed.toString(),
              approvedBy: approvalFile.approvedBy,
              description: approvalFile.description,
            },
          },
        },
      },
    ]);
  }
}
