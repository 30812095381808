import { Project } from 'types';
import { useCallback } from 'react';
import { LibrarySearchFilters } from './SearchBar';

export const useProcedureSearch = () => {
  const filterRelevantProjects = useCallback(
    (filters: LibrarySearchFilters, projects: Project[]): Project[] => {
      let filteredProjects = [...projects];

      // filter deleted procedures
      filteredProjects = filteredProjects.filter(
        (project) => project.isDeleted !== true
      );

      if (filters.compilerName) {
        filteredProjects = filteredProjects.filter((project) =>
          project.compiler
            ?.toLowerCase()
            .includes(filters.compilerName?.toLowerCase() || '')
        );
      }

      if (filters.procedureTitle) {
        filteredProjects = filteredProjects.filter((project) =>
          project.name
            ?.toLowerCase()
            .includes(filters.procedureTitle?.toLowerCase() || '')
        );
      }

      if (filters.icNumber) {
        filteredProjects = filteredProjects.filter((project) =>
          project.certificateNumber
            ?.toLowerCase()
            .includes(filters.icNumber?.toLowerCase() || '')
        );
      }

      if (filters.archive) {
        filteredProjects = filteredProjects.filter(
          (project) => project.status !== 'done'
        );
      }

      if (filters.externalAssetId) {
        filteredProjects = filteredProjects.filter((project) =>
          project.asset?.externalId
            ?.split('.')
            .slice(-1)[0]
            .toLowerCase()
            .includes(filters.externalAssetId?.toLowerCase() || '')
        );
      }

      if (filters.onlyApprovedProcedures) {
        filteredProjects = filteredProjects.filter(
          (project) => !!project.isApprovedProcedure
        );
      }

      return filteredProjects;
    },
    []
  );

  return {
    filterRelevantProjects,
  };
};
