// temporary eslint disable
/* eslint-disable */
import { toast } from '@cognite/cogs.js';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchAsset } from 'services/services';
import { Item, ItemType, Reference, CogniteAnnotation } from 'types';
import { logger } from 'utils/logger';
import { HotSpotState } from '.';
import { useActiveWorkStep } from '../activeWorkStep/hooks';
import { actions } from './hotSpotReducer';

export const useHotSpot = () => {
  const dispatch = useDispatch();

  const { itemTypes } = useActiveWorkStep();
  // const { setSelectedAnnotations } = useSelectedAnnotations();

  const { activeHotSpot } = useSelector<RootState, HotSpotState>(
    (state) => state.hotSpot
  );

  const selectHotSpot = (annotation: CogniteAnnotation) => {
    if (annotation) {
      // @ts-ignore-line
      setActiveHotSpot(annotation);
    } else if (!annotation && activeHotSpot.annotation) {
      clearActiveHotSpot();
    }
  };

  const setActiveHotSpot = (hotSpot: CogniteAnnotation) => {
    if (
      !hotSpot ||
      (activeHotSpot && activeHotSpot.annotation?.id === hotSpot.id)
    ) {
      return;
    }
    dispatch(actions.setActiveHotSpot({ hotSpot }));
    if (
      'linkedResourceInternalId' in hotSpot.data &&
      activeHotSpot.asset?.id !== hotSpot.data.linkedResourceInternalId
    ) {
      getAsset({
        id: hotSpot.data.linkedResourceInternalId as number,
      })
        .then((item) => {
          dispatch(actions.setActiveAsset({ asset: item as Item }));
        })
        .catch((errResponse) => {
          const msg =
            errResponse.error && errResponse.error.message
              ? errResponse.error.message
              : 'Asset not found';
          toast.error(msg);
          logger(errResponse);
        });
    }
  };

  const clearActiveHotSpot = () => {
    dispatch(actions.clearActiveHotSpot());
  };

  const getItemFromAnnotation = async (
    annotation: CogniteAnnotation,
    indirectReference?: Reference
  ) => {
    if (
      'assetRef' in annotation.data &&
      annotation.data.assetRef &&
      annotation.data.assetRef.id
    ) {
      const asset = await getAsset({
        id: annotation.data.assetRef.id,
      });
      return {
        asset,
        indirectReference,
        annotation,
      };
    }
    if ('detail' in annotation.data)
      return {
        detail: annotation.data.detail,
        indirectReference,
        annotation,
      };
  };

  const getItemByType = (type?: string) => {
    return getItem(itemTypes.find((it: ItemType) => it.type === type));
  };

  const getItem = (type?: ItemType) => {
    if (
      activeHotSpot &&
      activeHotSpot.annotation &&
      'detail' in activeHotSpot.annotation.data
    ) {
      if (activeHotSpot.asset) {
        return {
          asset: activeHotSpot.asset,
          detail: activeHotSpot.annotation.data.detail,
          type,
          annotation: activeHotSpot.annotation,
        };
      }
      return {
        detail: activeHotSpot.annotation.data.detail,
        type,
        annotation: activeHotSpot.annotation,
      };
    }
    return undefined;
  };

  const isActiveHotSpot = useCallback(
    (hotSpot: CogniteAnnotation) => {
      return Number(hotSpot.id) === Number(activeHotSpot.annotation?.id);
    },
    [activeHotSpot.annotation]
  );

  const getAsset = useCallback(async ({ id }: { id: number }) => {
    return fetchAsset({ id }).then((response) => {
      return response
        .map((item) => {
          return {
            id: item.id,
            externalId: item.externalId,
            name: item.name,
            description: item.description,
            metadata: item.metadata,
          } as Item;
        })
        .pop();
    });
  }, []);

  return {
    activeHotSpot,
    clearActiveHotSpot,
    setActiveHotSpot,
    isActiveHotSpot,
    getAsset,
    getItem,
    getItemByType,
    getItemFromAnnotation,
    selectHotSpot,
  };
};
