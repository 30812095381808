import { Icon } from '@cognite/cogs.js';
import React from 'react';
import { ListItem } from './elements';

export interface ListProps {
  items: string[];
  selectedItem: string;
  onSelectionChange: (selectedItem: string) => void;
}

export const List = ({ items, selectedItem, onSelectionChange }: ListProps) => {
  return (
    <div data-testid="list-component">
      {items.map((item) => (
        <ListItem
          data-testid="list-item"
          key={item}
          onClick={() => onSelectionChange(item)}
        >
          <span className="checkmark-icon">
            {selectedItem === item ? <Icon type="Checkmark" size={16} /> : ''}
          </span>{' '}
          {item}
        </ListItem>
      ))}
    </div>
  );
};
