import React from 'react';
import isUndefined from 'lodash/isUndefined';
import { useHistory } from 'react-router-dom';
import { Project } from 'types';
import {
  CogniteLogo,
  ContentWrapper,
  LogoArea,
  ProcedureButton,
  Text,
  VersionText,
  SideBarParentMenu,
  SideBarChildMenu,
} from './elements';

type ContentProps = {
  project: Project;
  setDrawerVisible: Function;
};

const SideBarContent = ({ project, setDrawerVisible }: ContentProps) => {
  const history = useHistory();

  const ProjectSelected = !isUndefined(project.name);

  const moveTo = (page: string) => {
    history.push(page);
    setDrawerVisible(false);
  };

  const isPath = (page: string) => history.location.pathname === page;

  return (
    <ContentWrapper>
      <SideBarParentMenu data-testid="sidebar-content">
        <SideBarParentMenu.Item
          toggled={isPath('/')}
          onClick={() => moveTo('/')}
        >
          <Text>Overview</Text>
        </SideBarParentMenu.Item>
        <SideBarParentMenu.Item
          toggled={isPath('/search')}
          onClick={() => moveTo('/search')}
        >
          <Text>Search for procedures</Text>
        </SideBarParentMenu.Item>
        <SideBarParentMenu.Item disabled>
          <Text>Current procedure</Text>
        </SideBarParentMenu.Item>
        <SideBarChildMenu>
          <SideBarChildMenu.Item
            disabled={!ProjectSelected}
            toggled={isPath(`/project/${project.id}`)}
            onClick={() => moveTo(`/project/${project.id}`)}
          >
            Dashboard
          </SideBarChildMenu.Item>
          <SideBarChildMenu.Item
            disabled={!ProjectSelected}
            toggled={isPath(`/project/edit/${project.id}`)}
            onClick={() => moveTo(`/project/edit/${project.id}`)}
          >
            Job steps
          </SideBarChildMenu.Item>
        </SideBarChildMenu>
      </SideBarParentMenu>
      <div>
        <ProcedureButton onClick={() => moveTo('/search')}>
          New procedure
        </ProcedureButton>
        <LogoArea>
          <CogniteLogo height="20px" width="30px" />
          <span>
            {!!process.env.REACT_APP_VERSION && (
              <VersionText>v. {process.env.REACT_APP_VERSION}</VersionText>
            )}
            <div>Powered by Cognite</div>
          </span>
        </LogoArea>
      </div>
    </ContentWrapper>
  );
};

export default SideBarContent;
