import React from 'react';
import { Detail } from '@cognite/cogs.js';
import TextArea from 'shared/TextArea/TextArea';
import { ExpandableText } from './ExpandableText';
import { ColumnContainer, CommentField } from './elements';

export interface CommentAreaProps {
  editable: boolean;
  comment: string | undefined;
  newComment: string | undefined;
  workstepId: string;
  isActive: boolean;
  isWorkstepOnlyComment: boolean;
  setComment: Function;
}
export const CommentArea = ({
  editable,
  comment,
  newComment,
  workstepId,
  isActive,
  isWorkstepOnlyComment,
  setComment,
}: CommentAreaProps) => {
  return (
    <>
      {!editable && !!comment && !isActive && !isWorkstepOnlyComment && (
        <span
          className="comment-active"
          data-testid={`workStep-commented-label-${workstepId}`}
        >
          Commented
        </span>
      )}

      {!editable && !!comment && !isActive && isWorkstepOnlyComment && (
        <ExpandableText childrenText={comment} />
      )}

      {!editable && !!comment && isActive && (
        <ColumnContainer style={{ paddingRight: '20px' }}>
          <div className="comment-container-title">Comment</div>
          <div
            className="comment-container-body"
            data-testid={`workStep-comment-${workstepId}`}
          >
            {comment}
          </div>
        </ColumnContainer>
      )}

      {editable && (
        <ColumnContainer style={{ marginRight: '11px' }}>
          <CommentField>
            <Detail style={{ color: 'var(--cogs-greyscale-grey7)' }}>
              Add comment
            </Detail>
            <TextArea
              data-testid={`add-comment-workstep-${workstepId}`}
              name="comment"
              placeholder=""
              style={{
                height: '50px',
                border: '1px solid var(--cogs-greyscale-grey7)',
              }}
              value={newComment}
              onChange={(event) => {
                setComment(event.currentTarget.value);
              }}
            />
          </CommentField>
        </ColumnContainer>
      )}
    </>
  );
};
