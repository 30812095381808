import React from 'react';
import { ProjectStatus } from 'types';
import styled from 'styled-components/macro';
import { Title } from '@cognite/cogs.js';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { hasReviewerCommentsAfterResolved } from 'pages/EditProcedure/components/Worksteps/Review/Resolved';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
`;

export type Props = {
  projectStatus: ProjectStatus;
  backgroundColor?: string;
  hideTitle?: boolean;
};

export const StepRevision = ({
  projectStatus,
  backgroundColor = '#fdf3f5',
  hideTitle = false,
}: Props) => {
  const { stepsNeedRevision } = useWorkSteps();

  const items = stepsNeedRevision();

  if (items?.length) {
    return (
      <>
        <Container style={{ backgroundColor }}>
          {!hideTitle && (
            <Title level={5} style={{ textAlign: 'left' }}>
              STEPS THAT NEED REVISION
            </Title>
          )}
        </Container>
        <Container style={{ backgroundColor }} data-testid="steps-for-revision">
          {items.map((item, index) => {
            let stepDone = false;
            const lastItem = index === items?.length - 1;
            if (projectStatus === 'compilation') {
              stepDone =
                !!item?.review?.resolved?.compiler?.date &&
                !hasReviewerCommentsAfterResolved(item);
            }
            if (projectStatus === 'review') {
              stepDone = !!item?.review?.resolved?.reviewer?.date;
            }
            return (
              <div
                key={`step-revision-${item?.position}`}
                style={{
                  color: stepDone
                    ? 'var(--cogs-greyscale-grey6)'
                    : 'var(--cogs-text-color)',
                }}
              >
                <span
                  data-testid={`step-revision-${item?.position}`}
                  style={{
                    textDecoration: stepDone ? 'line-through' : 'none',
                  }}
                >
                  {item?.position}
                </span>
                {!lastItem && (
                  <span
                    key={`step-revision-spacer-${item?.position}`}
                    style={{ marginRight: '4px' }}
                  >
                    ,
                  </span>
                )}
              </div>
            );
          })}
        </Container>
      </>
    );
  }
  return null;
};
