import React from 'react';
import { Button, Icon } from '@cognite/cogs.js';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';

import {
  StyledDrawer,
  DrawerNob,
  Header,
  Dummy,
  Container,
  StyledDrawerProps,
} from './element';

interface DrawerProps extends StyledDrawerProps {
  children?: React.ReactNode;
  header?: string;
  toggle: (value: boolean) => void;
}

export const Drawer = (props: DrawerProps) => {
  const { open, children, header, placement, toggle } = props;
  const metrics = useMetrics(METRICS_AREA.PROJECT_EDIT_DRAWER);
  return (
    <StyledDrawer {...props} data-testid="drawer">
      <DrawerNob
        {...props}
        onClick={() => {
          if (!open) {
            metrics.track(METRICS.PROJECT_EDIT_DRAWER_OPEN);
          }
          toggle(!open);
        }}
        data-testid="drawer-nob"
      >
        {placement === 'right' &&
          (open ? (
            <Icon type="ChevronRight" size={25} />
          ) : (
            <Icon type="ChevronLeft" size={25} />
          ))}
        {placement === 'left' &&
          (open ? (
            <Icon type="ChevronLeft" size={25} />
          ) : (
            <Icon type="ChevronRight" size={25} />
          ))}
      </DrawerNob>
      <Header>
        {placement === 'right' && (
          <Button
            icon="CloseLarge"
            aria-label="close"
            onClick={() => toggle(false)}
            type="ghost"
          />
        )}
        {header ? <span>{header}</span> : <Dummy />}
        {placement === 'left' && (
          <Button
            icon="CloseLarge"
            aria-label="close"
            onClick={() => toggle(false)}
            type="ghost"
          />
        )}
      </Header>
      {/** @ts-ignore */}
      <Container hasHeader={toggle}>{children}</Container>
    </StyledDrawer>
  );
};
