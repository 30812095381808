import { Icon, Input } from '@cognite/cogs.js';
import features from 'config/features';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { ModalLoader } from 'shared/ModalLoader';
import { customEvents, Phase, ProjectStatus } from 'types';
import DeleteEmptyPhaseModal from './DeleteEmptyPhaseModal';
import { PhaseContainerWrapper, PhaseToggleIcon } from './elements';
import { usePhasesScroll } from './hooks/usePhasesScroll';

export type Props = {
  phase: Phase;
  children: React.ReactNode;
  projectStatus?: ProjectStatus;
  viewOnly: boolean;
};

export const PhaseContainer = React.memo(
  ({ phase, projectStatus, viewOnly, children }: Props) => {
    const [editMode, setEditMode] = useState(false);
    const [phaseName, setPhaseName] = useState(phase.name);
    const [hidden, setHidden] = useState(false);
    const [deleteEmptyPhaseModalVisible, setDeleteEmptyPhaseModalVisible] =
      useState(false);

    const { editPhaseName, deleteEmptyPhase } = useWorkSteps();
    const { toggleCollapseLinkedPhase } = usePhasesScroll();

    const forceExpandPhase = useCallback((e: any): void => {
      const { id, position } = e.detail;
      if (phase.id === id && phase.position === position) {
        setHidden(false);
      }
      // eslint-disable-next-line
    }, []);

    const onToggleCollapse = useCallback(() => {
      setHidden(!hidden);

      if (features.WORKSTEPS_SCROLL_ALIGN) {
        toggleCollapseLinkedPhase(phase.id, phase.phaseType);
      }
      // eslint-disable-next-line
    }, [hidden]);

    const onDeletePhase = useCallback(() => {
      if (phase.workSteps.length === 0) {
        deleteEmptyPhase(phase.id, phase.linkedPhaseId);
      }
    }, [
      deleteEmptyPhase,
      phase.id,
      phase.linkedPhaseId,
      phase.workSteps.length,
    ]);

    const updatePhase = useCallback(() => {
      if (phaseName !== '') {
        editPhaseName(phase.id, phaseName);
      } else {
        setPhaseName(phase.name);
      }
      setEditMode(false);
    }, [
      phaseName,
      editPhaseName,
      phase.id,
      phase.name,
      setPhaseName,
      setEditMode,
    ]);

    useEffect(() => {
      window.addEventListener(customEvents.expandPhase, forceExpandPhase, {
        passive: true,
      });

      return () => {
        window.removeEventListener(customEvents.expandPhase, forceExpandPhase);
      };
      // eslint-disable-next-line
    }, []);

    return (
      <PhaseContainerWrapper
        className="phase-container-wrapper"
        data-phaseid={phase.id}
        data-linked-phase-id={phase.linkedPhaseId}
        deisolation={phase.phaseType === 'deisolation'}
      >
        <div className="phase-wrapper-outer">
          <div className="phase-wrapper-inner">
            <div data-testid={`phaseName-${phase.id}`}>
              {editMode ? (
                <Input
                  key="phaseName-edit"
                  variant="noBorder"
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  placeholder="Add a new name"
                  data-testid="phase-name-edit"
                  value={phaseName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      updatePhase();
                    }
                  }}
                  onBlur={updatePhase}
                  onChange={(e) => {
                    setPhaseName(e.target.value);
                  }}
                />
              ) : (
                <div className="phase-name-wrapper">
                  {!viewOnly && projectStatus === 'compilation' && (
                    <span
                      className="edit-phase-name-btn"
                      role="button"
                      tabIndex={0}
                      data-testid={`edit-phase-name-${phase.id}`}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          setEditMode(true);
                        }
                      }}
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Edit.svg`}
                        alt="Edit"
                      />
                    </span>
                  )}
                  <span className="phase-name-label">
                    {phaseName}
                    {phase.approvedBy && ' (approved)'}
                    {!phase.approvedBy &&
                      phase.disapprovedBy &&
                      ' (disapproved)'}
                  </span>
                </div>
              )}
            </div>
            {editMode && phase.workSteps.length === 0 && (
              <Icon
                type="Delete"
                className="delete-phase-icon"
                data-testid="deletePhaseButton"
                onClick={() => setDeleteEmptyPhaseModalVisible(true)}
              />
            )}
            {deleteEmptyPhaseModalVisible && (
              <Suspense fallback={<ModalLoader />}>
                <DeleteEmptyPhaseModal
                  isModalOpen={deleteEmptyPhaseModalVisible}
                  setModalOpen={setDeleteEmptyPhaseModalVisible}
                  onDeletePhase={onDeletePhase}
                />
              </Suspense>
            )}
          </div>
          <PhaseToggleIcon
            data-testid="togglePhase"
            type={hidden ? 'ChevronLeftLarge' : 'ChevronDownLarge'}
            onClick={onToggleCollapse}
          />
        </div>
        <div
          data-phase-id={phase.id}
          data-testid="childContainer"
          className={hidden ? 'hidden' : ''}
        >
          {children}
        </div>
      </PhaseContainerWrapper>
    );
  }
);
