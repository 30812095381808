import { Button, Input } from '@cognite/cogs.js';
import React, { useCallback, useMemo, useState } from 'react';

export interface AddPhaseProps {
  onAddNewPhase: (name: string) => void;
}

export const AddPhase = React.memo(({ onAddNewPhase }: AddPhaseProps) => {
  const [phase, setPhase] = useState('');

  const addPhase = useCallback(() => {
    if (phase) {
      onAddNewPhase(phase);
      setPhase('');
    }
    // eslint-disable-next-line
  }, [phase]);

  const inputStyle = useMemo(() => {
    return {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      padding: '0 10px',
      width: 'calc(100% - 80px)',
    };
  }, []);

  const btnStyle = useMemo(() => {
    return {
      position: 'absolute' as any,
      right: '20px',
      bottom: '5px',
      height: '30px',
    };
  }, []);

  const onInputChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setPhase(event.currentTarget.value);
    },
    []
  );

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        addPhase();
      }
    },
    // eslint-disable-next-line
    [addPhase]
  );

  return (
    <div>
      <div title="Click and type to add a phase">
        <Input
          data-testid="newPhaseName"
          variant="noBorder"
          fullWidth
          style={inputStyle}
          placeholder="Add phase"
          value={phase}
          onChange={onInputChange}
          onKeyUp={onKeyUp}
        />
        {phase && (
          <Button data-testid="addPhase" style={btnStyle} onClick={addPhase}>
            Add
          </Button>
        )}
      </div>
    </div>
  );
});
