import { CogniteAnnotation, Phase } from 'types';

export class AnnotationsService {
  /**
   * Collects all user created annotations from worksteps for the specified file
   * @param fileId
   * @param phases
   * @returns
   */
  getAllAnnotationsForFile(
    fileId: number,
    phases: Phase[]
  ): CogniteAnnotation[] {
    return phases
      .map((phase) =>
        phase.workSteps
          .map((workStep) => {
            const workStepAnnotations = [];
            if (workStep.stepItem?.annotation?.annotatedResourceId === fileId) {
              workStepAnnotations.push(workStep.stepItem?.annotation);
            }
            if (
              workStep.stepItem?.relativeRef?.annotation
                ?.annotatedResourceId === fileId
            ) {
              workStepAnnotations.push(
                workStep.stepItem?.relativeRef?.annotation
              );
            }
            if (
              workStep.stepItem?.line?.annotation?.annotatedResourceId ===
              fileId
            ) {
              workStepAnnotations.push(workStep.stepItem?.line.annotation);
            }
            return workStepAnnotations;
          })
          .flat()
      )
      .flat();
  }
}
