import React from 'react';
import { StepExecutionDetails } from 'types';
import { toDisplayDate } from 'utils/date';
import { ExecutionWorkStepItemActionsContainer } from './elements';

export interface WorkstepExecutedInfoProps {
  execution: StepExecutionDetails;
}
export const WorkstepExecutedInfo = ({
  execution,
}: WorkstepExecutedInfoProps) => {
  return (
    <ExecutionWorkStepItemActionsContainer>
      <span data-testid="doneby-lbl">
        {`Done ${toDisplayDate(execution?.doneTime, 'D.M.YYYY hh:mm a')} by ${
          execution?.doneBy
        }`}
      </span>
      {execution?.checkedTime && (
        <>
          <br />
          <span data-testid="checked-lbl">
            {`Checked ${toDisplayDate(
              execution?.checkedTime,
              'D.M.YYYY hh:mm a'
            )} by ${execution?.checkedBy}`}
            <br />
          </span>
        </>
      )}
    </ExecutionWorkStepItemActionsContainer>
  );
};
