import React from 'react';
import Modal from 'shared/Modal';

interface DeleteEmptyPhaseModalProps {
  isModalOpen: boolean;
  setModalOpen: (boolValue: boolean) => void;
  onDeletePhase: () => void;
}

const DeleteEmptyPhaseModal = (props: DeleteEmptyPhaseModalProps) => {
  return (
    <Modal
      visible={props.isModalOpen}
      setVisible={props.setModalOpen}
      title="Do you want to delete this phase?"
      okText="Delete"
      isOkDisabled={false}
      okHandler={props.onDeletePhase}
    />
  );
};

export default DeleteEmptyPhaseModal;
