import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Status } from 'redux/types';
import { SearchState } from 'redux/reducers/search';
import SearchBar from './SearchBar';
import SearchManager from '../../../components/SearchResults/SearchManager';

const SearchAsset = () => {
  const { statusIsolationSearch, statusAssetSearch, procedures } = useSelector<
    RootState,
    SearchState
  >((state) => {
    return state.search;
  });

  // remove deleted procedures from search list
  const filteredProcedures = procedures?.filter((p) => !p.isDeleted);

  return (
    <>
      <SearchBar />
      <SearchManager
        procedures={filteredProcedures}
        isError={statusAssetSearch === Status.failed}
        isLoading={
          statusIsolationSearch === Status.loading ||
          statusAssetSearch === Status.loading
        }
        isSuccess={statusIsolationSearch === Status.success}
        noResultsMessage="No existing procedures found for that FLOC ID"
        showCreateButton
      />
    </>
  );
};

export default SearchAsset;
