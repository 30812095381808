import localforage from 'localforage';
import { IMemoryProvider } from './types';

export class IndexedDbCacheService implements IMemoryProvider {
  private driver: LocalForage;
  constructor() {
    this.driver = localforage.createInstance({
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
      name: 'cache',
      storeName: 'dataStore',
    });
  }
  hasKey(keyName: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.driver.getItem(keyName).then((value) => {
        resolve(value !== null);
      });
    });
  }
  removeItem(keyName: string): Promise<void> {
    return this.driver.removeItem(keyName);
  }
  setItem(keyName: string, value: any): Promise<void> {
    return this.driver.setItem(keyName, value);
  }
  getItem(keyName: string): Promise<any> {
    return this.driver.getItem(keyName);
  }
  clear(): void {
    this.driver.clear();
  }
}
