import styled from 'styled-components/macro';
import { Button } from '@cognite/cogs.js';

export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LogoArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 11px;
`;

export const Title = styled.div`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--cogs-text-color);
`;

export const TextDetail = styled.div`
  line-height: 20px;
  color: var(--cogs-text-color);
  word-break: break-all;
`;

export const TextDetailSmall = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--cogs-text-color);
  word-break: break-all;
`;

export const TextDetailRed = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--cogs-danger);
  word-break: break-all;
`;

export const TextDetailExtraSmall = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #000000;
`;

export const HorizontalLine = styled.hr`
  height: 0.5px;
  margin: 0px;
  max-width: 410px;
  width: 90%;
  background: var(--cogs-greyscale-grey6);
`;

export const LinkButton = styled(Button).attrs({
  unstyled: true,
  type: 'secondary',
})`
  color: var(--cogs-border--interactive--toggled-default) !important;
  display: inline-block !important;
  padding: 0px !important;
  background: transparent !important;
  font-weight: 400 !important;
  &:hover {
    cursor: pointer;
    color: var(--cogs-border--interactive--toggled-hover) !important;
  }
`;
