import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from '@cognite/cogs.js';
import {
  FilterBar,
  FilterInputs,
  FilterActions,
  Filter,
  FilterLabel,
} from '../../../containers/FilterBar/FilterBar';
import { useSearch } from '../../../redux/reducers/search/hooks';
import searchSlice, { SearchState } from '../../../redux/reducers/search';
import { RootState } from '../../../redux/store';

const SearchBar = () => {
  const dispatch = useDispatch();
  const { search } = useSearch();

  const { flocInput } = useSelector<RootState, SearchState>((state) => {
    return state.search;
  });

  const onResetClick = () => {
    dispatch(searchSlice.actions.setClearSearch());
  };

  return (
    <FilterBar>
      <FilterInputs>
        <Filter>
          <FilterLabel>Asset</FilterLabel>
          <Input
            style={{ width: '100%', maxWidth: '400px' }}
            placeholder="FLOC ID and/or words in the description of the asset"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                search();
              }
            }}
            onChange={(e) =>
              dispatch(searchSlice.actions.setFlocInput(e.target.value))
            }
            value={flocInput}
            data-testid="card-search-input"
          />
        </Filter>
      </FilterInputs>
      <FilterActions>
        <Button
          type="primary"
          data-testid="card-search-button"
          onClick={search}
        >
          Search
        </Button>
        <Button type="ghost" onClick={onResetClick}>
          Clear
        </Button>
      </FilterActions>
    </FilterBar>
  );
};

export default SearchBar;
