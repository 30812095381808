import { CogniteAnnotation } from 'types';
import isMatch from 'lodash/isMatch';
import { uniqBy } from 'utils/collection';

export class AnnotationDuplicatesService {
  private extractAnnotationIdsFromPhases(phases: any[]): number[] {
    const annotationIds: number[] = [];
    phases.forEach((phase) => {
      phase.workSteps.forEach((workStep: any) => {
        if (workStep.stepItem.annotation) {
          annotationIds.push(workStep.stepItem.annotation.id);
        }
        if (
          workStep.stepItem.relativeRef &&
          workStep.stepItem.relativeRef.annotation
        ) {
          annotationIds.push(workStep.stepItem.relativeRef.annotation.id);
        }
        if (workStep.stepItem.line && workStep.stepItem.line.annotation) {
          annotationIds.push(workStep.stepItem.line.annotation.id);
        }
      });
    });
    return annotationIds;
  }

  filterOutDuplicatePipelineAnnotations(
    fileAnnotations: CogniteAnnotation[],
    phases: any[]
  ) {
    const annotationIds = this.extractAnnotationIdsFromPhases(phases);

    const unique = fileAnnotations.reduce(
      (
        newArray: CogniteAnnotation[],
        current: CogniteAnnotation,
        _index: number,
        array: CogniteAnnotation[]
      ) => {
        const duplicates = array
          .filter((it) => {
            if (
              it.annotationType === 'isoplan.IsoPlanAnnotation' &&
              it.data &&
              'textRegion' in it.data &&
              it.data.textRegion &&
              current.annotationType === 'isoplan.IsoPlanAnnotation' &&
              current.data &&
              'textRegion' in current.data &&
              current.data.textRegion &&
              'sourceType' in it.data &&
              it.data.sourceType === 'user'
            ) {
              return isMatch(it.data.textRegion, current.data.textRegion);
            }
            return false;
          })
          .sort((a, b) => {
            // @ts-ignore
            return b.lastUpdatedTime - a.lastUpdatedTime;
          });

        if (duplicates.length > 0) {
          const preferredDuplicate =
            duplicates.find(
              (duplicate) =>
                'oldAnnotationId' in duplicate.data &&
                duplicate.data.oldAnnotationId &&
                annotationIds.includes(Number(duplicate.data.oldAnnotationId))
            ) || duplicates[0];

          if (
            newArray.filter((it) => it.id === preferredDuplicate.id).length ===
            0
          ) {
            newArray.push(preferredDuplicate);
          }
        } else {
          newArray.push(current);
        }
        return newArray;
      },
      []
    );
    return unique;
  }

  filterOutDuplicateProjectAnnotations(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[]
  ) {
    const diff = fileAnnotations.filter(
      (fileAnnotation) =>
        !projectAnnotations.find((projectAnnotation: any) => {
          if (
            'textRegion' in fileAnnotation.data &&
            fileAnnotation.data.textRegion
          ) {
            return isMatch(
              projectAnnotation.data.textRegion,
              fileAnnotation.data.textRegion
            );
          }
          return false;
        })
    );
    return projectAnnotations.concat(diff);
  }

  mergeWithProjectAnnotations(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[]
  ) {
    const uniqueProjectAnnotations = uniqBy(projectAnnotations, 'id');
    const res = fileAnnotations.concat(uniqueProjectAnnotations);
    return res;
  }
}
