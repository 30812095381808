import { ProjectContainer, Title } from 'pages/Project/components/elements';
import React from 'react';
import { Comment, Role } from 'types';
import NewComment from './NewComment/NewComment';
import UploadedComment, { Undo } from './UploadedComment/UploadedComment';

export type Props = {
  userRole: Role;
  user: string;
  comments: Comment[];
  onSave: (comment: Comment) => Promise<boolean>;
  identifier?: string;
  title?: string;
  readOnly?: boolean;
  newCommentTitleElement?: () => React.ReactElement;
  secondaryItem?: () => React.ReactElement;
  undo?: Undo;
};

export const Comments = ({
  title,
  userRole,
  user,
  comments,
  identifier,
  onSave,
  readOnly = false,
  secondaryItem,
  newCommentTitleElement,
  undo,
}: Props) => {
  return (
    <ProjectContainer>
      {title && (
        <Title style={{ display: 'block', marginBottom: '10px' }}>
          {title}
        </Title>
      )}
      {!!comments.length &&
        comments
          .slice()
          .sort((a, b) => {
            return a.date - b.date;
          })
          .map((comment) => {
            return (
              <UploadedComment
                key={`comment-${comment.id}`}
                comment={comment}
                undo={undo}
              />
            );
          })}
      {!readOnly && (
        <NewComment
          identifier={identifier}
          userRole={userRole}
          user={user}
          onSave={onSave}
          secondaryItem={secondaryItem}
          newCommentTitleElement={newCommentTitleElement}
        />
      )}
    </ProjectContainer>
  );
};
