import React, { useState } from 'react';
import { List } from 'shared/List';
import Modal from 'shared/Modal';
import { Button } from '@cognite/cogs.js';

import { useHistory } from 'react-router-dom';
import { PhaseDisapproveDescription } from './elements';

export interface ApprovePhaseModalProps {
  isModalOpen: {
    isOpenModel: boolean;
    phaseId: string;
  };
  executorsList: string[];
  setModalOpen: (modelState: { isOpenModel: boolean; phaseId: string }) => void;
  onSelection: (email: string, phaseId: string) => void;
  projectId: string;
}

export const UnApprovePhaseModal = (props: ApprovePhaseModalProps) => {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  const history = useHistory();

  const onOkClick = () => {
    props.onSelection(selectedEmail, props.isModalOpen.phaseId);
    setSelectedEmail('');
    setIsOkDisabled(true);
  };

  const onListSelectionChange = (email: string) => {
    setSelectedEmail(email);
    setIsOkDisabled(false);
  };

  return (
    <Modal
      visible={props.isModalOpen.isOpenModel}
      setVisible={props.setModalOpen}
      title="Disapproved by"
      okText="Disapprove"
      isOkDisabled={isOkDisabled}
      okHandler={onOkClick}
      extraActionButtons={
        <Button
          type="tertiary"
          data-testid="unapprove-add-user-modal"
          onClick={() => history.push(`/project/${props.projectId}`)}
        >
          Add users
        </Button>
      }
    >
      <>
        <div data-testid="disapprove-executors-list">
          <List
            items={props.executorsList}
            selectedItem={selectedEmail}
            onSelectionChange={onListSelectionChange}
          />
        </div>

        <PhaseDisapproveDescription>
          After disapproving this phase, you can add new steps. Click the Stop
          Execution button, go back to compile mode, and add a new steps.
        </PhaseDisapproveDescription>
      </>
    </Modal>
  );
};
