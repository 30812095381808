import React, { useMemo } from 'react';

import { Project } from 'types';
import { NoResultSpan, Column, ColumnWrapper } from './elements';
import SearchResult from './SearchResult';

export type SearchResultsProps = {
  procedures: Array<Project>;
  noResultsMessage: String;
};

export const SearchResults = ({
  procedures,
  noResultsMessage,
}: SearchResultsProps) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const columns = useMemo(() => {
    if (screenWidth > 1600) {
      return [
        procedures.filter((procedure, i) => i % 3 === 0),
        procedures.filter((procedure, i) => (i - 1) % 3 === 0),
        procedures.filter((procedure, i) => (i - 2) % 3 === 0),
      ];
    }

    if (screenWidth > 1000) {
      return [
        procedures.filter((procedure, i) => i % 2 === 0),
        procedures.filter((procedure, i) => i % 2 !== 0),
      ];
    }

    return [procedures];
  }, [procedures, screenWidth]);

  React.useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <>
      {procedures && procedures.length > 0 ? (
        <ColumnWrapper data-testid="searchResults">
          {columns.map((column, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Column key={i}>
              {column.map((procedure) => (
                <SearchResult key={procedure.id} procedure={procedure} />
              ))}
            </Column>
          ))}
        </ColumnWrapper>
      ) : (
        <NoResultSpan data-testid="noResults">{noResultsMessage}</NoResultSpan>
      )}
    </>
  );
};
