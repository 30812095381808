import React from 'react';

import Routes from 'pages/Routes';
import AppProviders from 'containers/AppProviders';
import AuthContainer from 'containers/AuthContainer';
import { TopBar } from 'shared/TopBar';
import { Container, AuthConsumer } from '@cognite/react-container';

import { getTenant } from './utils/tenancy';
import store from './redux/store';

import sidecar from './config/sidecar';
import MainContainer from './containers/MainContainer';

const App = () => {
  const tenant = getTenant();

  const renderMainApp = () => (
    <AppProviders store={store} tenant={tenant}>
      <AuthContainer>
        <MainContainer>
          <TopBar />
          <Routes />
        </MainContainer>
      </AuthContainer>
    </AppProviders>
  );

  // skip auth for mock/testcafe
  if (tenant === 'testcafe' || tenant === 'mock') {
    return renderMainApp();
  }

  const clusters = [
    {
      label: 'Single customer environments',
      options: [{ value: 'omv', label: 'OMV' }],
    },
  ];

  return (
    <Container
      sidecar={{
        ...(sidecar as any),
        disableSentry: true,
        disableIntercom: true,
        disableTranslations: true,
        disableMixpanel: true,
        availableClusters: clusters,
        aadApplicationId: 'f825c1bb-0dbd-401a-8f72-2a5ea16a19ba',
        defaultAzureDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
        AADTenantID: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
      }}
    >
      <AuthConsumer>
        {(authState) => {
          if (!authState || !authState.authState?.authenticated) {
            return null;
          }
          return renderMainApp();
        }}
      </AuthConsumer>
    </Container>
  );
};

export default App;
