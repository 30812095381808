import { useDispatch } from 'react-redux';
import { Phase, Project } from 'types';
import {
  loadProcedureFilesByIds,
  actions as filesActions,
} from 'redux/reducers/files';
import { useAnnotations } from 'redux/reducers/annotations';
import { useCallback } from 'react';
import { cacheProvider, workstepsService } from 'services';
import features from 'config/features';
import { AppDispatch } from 'redux/store';

export const useProcedureFilesLoad = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadAnnotationsForProcedure, clearAnnotations } = useAnnotations();

  const clearConnectedFiles = useCallback(() => {
    // dispatch(filesActions.clearFiles());
    clearAnnotations();

    if (features.CACHE_PNIDS) {
      cacheProvider.clear();
    }
  }, [clearAnnotations]);

  const preloadFilesWithAnnotations = useCallback(
    (
      proj: Project,
      phases: Phase[],
      newProject: boolean,
      connectedFileIds: number[]
    ) => {
      let fileIds = [] as number[];

      if (newProject) {
        fileIds = connectedFileIds;
      } else {
        const listOfFileIds = proj.pnIds
          ? proj.pnIds?.map((pnid) => Number(pnid.id))
          : [];
        fileIds = workstepsService.getWorkstepsFileIds(phases, listOfFileIds);
      }

      if (fileIds) {
        const needsMigration =
          proj.needsMigration && proj.needsMigration === true;
        clearAnnotations();

        dispatch(filesActions.clearFiles());
        dispatch(loadProcedureFilesByIds(fileIds));
        loadAnnotationsForProcedure(fileIds, phases, needsMigration);
      }
    },
    [dispatch, clearAnnotations, loadAnnotationsForProcedure]
  );

  return {
    preloadFilesWithAnnotations,
    clearConnectedFiles,
  };
};
