import React from 'react';
import styled from 'styled-components/macro';

type ProjectCardProps = {
  highlighted?: boolean;
};

export const ProjectCard = React.memo(styled.div<ProjectCardProps>`
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 24px;
  width: 100%;
  ${(p) => p.highlighted && 'border: 1px solid #B30539;'}
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.08),
    0px 1px 4px rgba(0, 0, 0, 0.06);
`);
