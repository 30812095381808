import styled from 'styled-components/macro';
import zIndex from 'utils/zindex';

export const ProjectInfoModalWrapper = styled.div`
  font-size: 14px;
  padding: 30px;

  .modal-spacer {
    margin-bottom: 30px;
  }
`;

export const ExecutionMenuBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  background-color: white;
  padding: 8px 13px 8px 13px;
  z-index: ${zIndex.POPUP};

  .menu-bar-icon {
    position: absolute;
    right: 15px;
    top: 16px;
    cursor: pointer;
  }
`;

export const ExecuteViewOnlyNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  padding: 8px 10px;
  border-radius: 6px;
  background: var(--cogs-midblue-6);
  color: var(--cogs-greyscale-grey8);
  font-size: 11px;
`;
