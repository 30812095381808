import styled from 'styled-components/macro';
import { Button, Modal } from '@cognite/cogs.js';

export const AssetItemButton = styled(Button).attrs({ type: 'ghost' })`
  width: 100%;
  justify-content: start !important;
  text-align: left;
`;

export const AssetsModal = styled(Modal).attrs({
  size: 'medium',
  title: '',
  position: 'top',
})`
  max-height: calc(100% - 150px) !important;
`;
