/* eslint-disable no-empty-function */
/* eslint-disable no-param-reassign */
import { PhasesService } from 'services/phases.service';
import { WorkstepsService } from 'services/worksteps.service';
import {
  Phase,
  PhaseType,
  RemoveWorkStepActionDTO,
  WorkstepsStateVM,
} from 'types';

/**
 * Remove Workstep Command
 * Extracted logic for deleting worksteps used in workstep reducer
 */
export class RemoveWorkStepCommand {
  constructor(
    private workstepsService: WorkstepsService,
    private phasesService: PhasesService
  ) {}

  execute(
    state: WorkstepsStateVM,
    dto: RemoveWorkStepActionDTO
  ): WorkstepsStateVM {
    const { phaseId, workStepId } = dto;
    const { phases } = state;

    const phaseIdx = this.workstepsService.findPhaseIndex(phases, phaseId);

    if (phaseIdx === -1) {
      return state;
    }

    const phaseType = this.getPhaseType(phases[phaseIdx]);
    const linkedWorkStep = state.hasDeIsolationPhases
      ? this.workstepsService.findLinkedWorkStep(
          phases,
          phaseIdx,
          workStepId,
          phaseType
        )
      : null;

    if (state.hasDeIsolationPhases && linkedWorkStep) {
      if (linkedWorkStep?.workStepType === 'missing') {
        this.removeDeletedAndLinkedSteps(
          phases,
          phaseIdx,
          workStepId,
          linkedWorkStep.id,
          phaseType
        );
      } else {
        this.convertDeletedStepToMissing(phases, phaseIdx, workStepId);
      }
    } else {
      this.removeWorkStepFromPhase(phases, phaseIdx, workStepId);
    }

    state.phases = this.workstepsService.updateOrderOfWorkSteps(phases);
    return state;
  }

  /**
   * Deleted step is not actually deleted if there is a linked step
   * that is not misssing or draft.
   * In that case the deleted step is converted to missing.
   */
  private convertDeletedStepToMissing(
    phases: Phase[],
    phaseIdx: number,
    workStepId: string
  ): void {
    const workStepIndex = phases[phaseIdx].workSteps.findIndex(
      (ws) => ws.id === workStepId
    );

    const workStep = phases[phaseIdx].workSteps[workStepIndex];

    if (workStep.workStepType === 'missing' && workStep.draftStepOptions) {
      phases[phaseIdx].workSteps[workStepIndex].stepAction = undefined;
    } else {
      phases[phaseIdx].workSteps[workStepIndex].workStepType = 'missing';
      phases[phaseIdx].workSteps[workStepIndex].isDraft = true;
      phases[phaseIdx].workSteps[workStepIndex].stepItem = undefined;
      phases[phaseIdx].workSteps[workStepIndex].error = undefined;
    }
  }

  /**
   * Removes both deleted workstep and linked workstep with the deleted one
   * in case if the linked workstep is of type missing.
   */
  private removeDeletedAndLinkedSteps(
    phases: Phase[],
    phaseIdx: number,
    workStepId: string,
    linkedWorkStepId: string,
    phaseType: PhaseType
  ): void {
    const linkedPhaseId = this.phasesService.getLinkedPhaseId(
      phases[phaseIdx],
      phaseType
    );
    const linkedPhaseIdx = this.phasesService.getLinkedPhaseIndex(
      phases,
      linkedPhaseId,
      phaseType
    );

    phases[phaseIdx] = this.workstepsService.removeWorkStep(
      phases[phaseIdx],
      workStepId
    );

    phases[linkedPhaseIdx] = this.workstepsService.removeWorkStep(
      phases[linkedPhaseIdx],
      linkedWorkStepId
    );
  }

  private getPhaseType(phase: Phase): PhaseType {
    return phase.phaseType || 'isolation';
  }

  private removeWorkStepFromPhase(
    phases: Phase[],
    phaseIdx: number,
    workStepId: string
  ): void {
    phases[phaseIdx] = this.workstepsService.removeWorkStep(
      phases[phaseIdx],
      workStepId
    );
  }
}
