import React, { useEffect, useLayoutEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Logout } from '@cognite/react-container';

import { useMetrics } from '@cognite/metrics';
import StartPage from '../StartPage';
import SearchAsset from '../Search';
import ProjectContainer from '../../containers/ProjectContainer';

const LogoutPage = () => {
  const metrics = useMetrics('Logout');
  metrics.track('logout');

  useEffect(() => {
    // router issue
    // logout could be moved to root of applications
    // manual cleanup
    localStorage.clear();
  }, []);

  return <Logout />;
};

const Routes = () => {
  const location = useLocation();

  // on page change scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/" exact component={StartPage} />
      <Route path="/search" exact component={SearchAsset} />
      <Route path="/logout" exact component={LogoutPage} />

      <Route path="/project/*" component={ProjectContainer} />
    </Switch>
  );
};

export default Routes;
