import { Colors } from '@cognite/cogs.js';
import { ItemType, Modes, ItemActions } from 'types';
import { v4 as uuidv4 } from 'uuid';

const blue = Colors['decorative--blue--500'];
const yellow = Colors['decorative--yellow--500'];
const purple = Colors['decorative--purple--600'];
const pink = Colors['decorative--pink--300'];
const green = Colors['decorative--green--500'];
const orange = Colors['decorative--orange--400'];

export const OpenAction = {
  id: uuidv4(),
  text: 'Open',
  template: 'Open [item]',
  color: blue,
  slug: 'open',
};

export const CloseAction = {
  id: uuidv4(),
  text: 'Close',
  template: 'Close [item]',
  color: yellow,
  slug: 'close',
};

export const TagOpenAction = {
  id: uuidv4(),
  text: 'Tag open',
  template: 'Tag open [item]',
  color: blue,
  slug: 'tag-open',
  defaultTagged: true,
};

export const TagCloseAction = {
  id: uuidv4(),
  text: 'Tag close',
  template: 'Tag close [item]',
  color: yellow,
  slug: 'tag-close',
  defaultTagged: true,
};

export const TagAirSupply = {
  id: uuidv4(),
  text: 'Tag closed air supply',
  template: 'Tag closed air supply to [item]',
  color: yellow,
  slug: 'tag-closed-air-supply-to',
  defaultTagged: true,
};

export const RemoveItem = {
  id: uuidv4(),
  text: 'Valve/item',
  template: 'Remove [item]',
  color: purple,
  slug: 'remove',
};

export const RemovePlugCap = {
  id: uuidv4(),
  text: 'Plug/Cap',
  template: 'Remove plug/cap from [item]',
  color: purple,
  slug: 'remove-plugcap-from',
};

export const ReinstatePlugCap = {
  id: uuidv4(),
  text: 'Reinstate cap/plug',
  template: 'Reinstate cap/plug to [item]',
  color: pink,
  slug: 'reinstate-capplug-to',
};

export const RemoveTubing = {
  id: uuidv4(),
  text: 'Tubing',
  template: 'Remove tubing from [item], cap/plug and tag',
  color: purple,
  slug: 'remove-tubing-from-capplug-and-tag',
  defaultTagged: true,
};

export const RemoveManwayDoor = {
  id: uuidv4(),
  text: 'Remove manway door',
  template: 'Remove manway door from [item]',
  color: purple,
  slug: 'remove-manway-door-from',
};

export const ReinstateManwayDoor = {
  id: uuidv4(),
  text: 'Reinstate manway door',
  template: 'Reinstate manway door to [item]',
  color: pink,
  slug: 'reinstate-manway-door-to',
};

export const RemoveTagAndOpen = {
  id: uuidv4(),
  text: 'Remove tag and open',
  template: 'Remove tag and open [item]',
  color: blue,
  slug: 'remove-tag-and-open',
  defaultTagged: true,
};

export const RemoveTagAndOpenAirSupply = {
  id: uuidv4(),
  text: 'Remove tag and open',
  template: 'Remove tag and open air supply [item]',
  color: blue,
  slug: 'remove-tag-and-open-air-supply',
  defaultTagged: true,
};

export const RemoveTagAndLockWireOpen = {
  id: uuidv4(),
  text: 'Remove tag and lock wire open',
  template: 'Remove tag and lock wire open [item]',
  color: blue,
  slug: 'remove-tag-and-lock-wire-open',
  defaultTagged: true,
};

export const RemoveTagAndLeaveOpen = {
  id: uuidv4(),
  text: 'Remove tag and leave open',
  template: 'Remove tag and leave open [item]',
  color: blue,
  slug: 'remove-tag-and-leave-open',
  defaultTagged: true,
};

export const RemoveTagAndClose = {
  id: uuidv4(),
  text: 'Remove tag and close',
  template: 'Remove tag and close [item]',
  color: yellow,
  slug: 'remove-tag-and-close',
  defaultTagged: true,
};

export const RemoveTagAndLockWireClose = {
  id: uuidv4(),
  text: 'Remove tag and lock wire close',
  template: 'Remove tag and lock wire close [item]',
  color: yellow,
  slug: 'remove-tag-and-lock-wire-close',
  defaultTagged: true,
};

export const RemoveTagAndLeaveClose = {
  id: uuidv4(),
  text: 'Remove tag and leave closed',
  template: 'Remove tag and leave closed [item]',
  color: yellow,
  slug: 'remove-tag-and-leave-close',
  defaultTagged: true,
};

export const RemoveTagAndLeaveCloseAirSupply = {
  id: uuidv4(),
  text: 'Remove tag and leave closed air',
  template: 'Remove tag and leave closed air supply [item]',
  color: yellow,
  slug: 'remove-tag-and-leave-close-air-supply',
  defaultTagged: true,
};

export const TubingRemoveTagAndCap = {
  id: uuidv4(),
  text: 'Tubing - remove tag and cap/plug and reconnect',
  template: 'Remove tag and cap/plug, reconnect tubing to [item]',
  color: pink,
  slug: 'remove-tag-and-cap-reconnect-tubing-to',
  defaultTagged: true,
};

export const ElectricallyIsolate = {
  id: uuidv4(),
  text: 'Electrically isolate',
  template: 'Electrically isolate and tag [item]',
  color: orange,
  slug: 'electrically-isolate-and-tag',
  defaultTagged: true,
};

export const ElectricallyReinstate = {
  id: uuidv4(),
  text: 'Electrically reinstate',
  template: 'Electrically reinstate [item]',
  color: purple,
  slug: 'electrically-reinstate',
};
// Spade
export const InstallSpadeAndTag = {
  id: uuidv4(),
  text: 'Install and tag',
  template: 'Install and tag a rated [class] spade at [item]',
  color: orange,
  slug: 'install-and-tag-a-rated-spade-to',
  defaultTagged: true,
};

export const TurnSpecBlindToClosed = {
  id: uuidv4(),
  text: 'Turn spec blind to close',
  template: 'Turn [class] spec blind to closed position and tag at [item]',
  color: orange,
  slug: 'turn-spec-blind-to-closed-position-and-tag',
  defaultTagged: true,
};

export const RemoveTagSpade = {
  id: uuidv4(),
  text: 'Remove tag',
  template: 'Remove tag from [class] spade [item]',
  color: green,
  slug: 'remove-tag-from-spade',
  defaultTagged: true,
};

export const RemoveTagAndSpade = {
  id: uuidv4(),
  text: 'Remove tag and remove spade',
  template: 'Remove tag and remove [class] spade at [item]',
  color: green,
  slug: 'remove-tag-and-remove-spade',
  defaultTagged: true,
};

export const RemoveTagAndOpenSpecBlind = {
  id: uuidv4(),
  text: 'Remove tag and open spec blind',
  template: 'Remove tag and open [class] spec blind at [item]',
  color: green,
  slug: 'remove-tag-and-open-spec-blind',
  defaultTagged: true,
};

export const RemoveTagAndLeaveSpecBlindClosed = {
  id: uuidv4(),
  text: 'Remove tag and leave spec blind closed',
  template: 'Remove tag and leave [class] spec blind in closed position [item]',
  color: green,
  slug: 'remove-tag-and-leave-spec-blind-in-closed-position',
  defaultTagged: true,
};

// Blind
export const InstallBlindAndTag = {
  id: uuidv4(),
  text: 'Install and tag',
  template: 'Install and tag a rated [class] blind at [item]',
  color: orange,
  slug: 'install-and-tag-a-rated-blind-to',
  defaultTagged: true,
};

export const InstalAndTagOnPipeWork = {
  id: uuidv4(),
  text: 'Install and tag on pipework',
  template: 'Install and tag a [class] rated blind to [item] on pipework',
  color: orange,
  slug: 'install-and-tag-a-rated-blind-to-on-pipework',
  defaultTagged: true,
};

export const RemoveBlind = {
  id: uuidv4(),
  text: 'Remove',
  template: 'Remove blind from [item]',
  color: purple,
  slug: 'remove-blind-from',
};

export const RemoveTagBlind = {
  id: uuidv4(),
  text: 'Remove tag',
  template: 'Remove tag from [class] rated blind [item]',
  color: green,
  slug: 'remove-tag-from-rated-blind',
  defaultTagged: true,
};

export const RemoveTagAndBlind = {
  id: uuidv4(),
  text: 'Remove tag and remove blind',
  template: 'Remove tag and remove [class] rated blind from [item]',
  color: green,
  slug: 'remove-tag-and-remove-rated-blind',
  defaultTagged: true,
};

export const RemoveTagFromBlindOnPipework = {
  id: uuidv4(),
  text: 'Remove tag from blind on pipework',
  template: 'Remove tag from [class] rated blind on [item] upstream pipework',
  color: green,
  slug: 'remove-tag-from-rated-blind-on-upstream-pipework',
  defaultTagged: true,
};

export const RemoveTagAndRemoveBlindOnPipework = {
  id: uuidv4(),
  text: 'Remove tag and blind on pipework',
  template:
    'Remove tag and remove [class] rated blind from [item] upstream pipework',
  color: green,
  slug: 'remove-tag-and-remove-rated-blind-from-upstream-pipework',
  defaultTagged: true,
};

export const ReinstateBlind = {
  id: uuidv4(),
  text: 'Reinstate blind',
  template: 'Reinstate blind to [item]',
  color: pink,
  slug: 'reinstate-blind-to',
};

export const ReplaceItem = {
  id: uuidv4(),
  text: 'Replace valve/item',
  template: 'Replace [item]',
  color: pink,
  slug: 'replace',
};

export const Valve = {
  type: 'valve',
  description: 'Valve',
  template: '[position] [type] [detail] [relation] [relativeRef]',
  options: ['safeguard'],
};

export const Spade = {
  type: 'spade',
  description: 'Spade',
  template: '[position] [type] [detail] [relation] [relativeRef]',
};

export const Blind = {
  type: 'blind',
  description: 'Blind',
  template: '[position] [type] [detail] [relation] [relativeRef]',
};

export const Pipe = {
  type: 'pipe',
  description: 'Pipe',
  template: '[position] [type] [detail] [relation] [relativeRef]',
};

export const Vessel = {
  type: 'vessel',
  description: 'Vessel',
  template: '[type] [floc]',
};

export const Instrument = {
  type: 'instrument',
  description: 'Instrument',
  template: '[type] [floc]',
};

export const Motor = {
  type: 'motor',
  description: 'Motor',
  template: '[type] [floc]',
};

export const Heater = {
  type: 'heater',
  description: 'Heater',
  template: '[type] [floc]',
};

export const Line = { type: 'line', description: 'Line', template: '[floc]' };

export const types: Array<ItemType> = [
  Valve,
  Pipe,
  Vessel,
  Instrument,
  Motor,
  Heater,
];

export const ClassTypes: string[] = [
  '1 - Class 150 - 19.5 bar',
  '3 - Class 300 - 51 bar',
  '6 - Class 600 - (56.88 bar and 102 bar)',
  '6A - Class 600 - 102 bar',
  '15 - Class 1500 - 232 bar',
  '25D - Class 2500 - >354 bar',
  '50 - API 5000 - 345 bar',
];

export const ActionsList = [
  OpenAction,
  CloseAction,
  TagOpenAction,
  TagCloseAction,
  TagAirSupply,
  RemoveItem,
  RemovePlugCap,
  ReinstatePlugCap,
  RemoveTubing,
  RemoveManwayDoor,
  ReinstateManwayDoor,
  RemoveTagAndOpen,
  RemoveTagAndLockWireOpen,
  RemoveTagAndLeaveOpen,
  RemoveTagAndClose,
  RemoveTagAndLockWireClose,
  RemoveTagAndLeaveClose,
  TubingRemoveTagAndCap,
  ElectricallyIsolate,
  ElectricallyReinstate,
  InstallSpadeAndTag,
  TurnSpecBlindToClosed,
  RemoveTagSpade,
  RemoveTagAndSpade,
  RemoveTagAndOpenSpecBlind,
  RemoveTagAndLeaveSpecBlindClosed,
  InstallBlindAndTag,
  InstalAndTagOnPipeWork,
  RemoveBlind,
  RemoveTagBlind,
  RemoveTagAndBlind,
  RemoveTagFromBlindOnPipework,
  RemoveTagAndRemoveBlindOnPipework,
  ReinstateBlind,
  ReplaceItem,
  RemoveTagAndOpenAirSupply,
  RemoveTagAndLeaveCloseAirSupply,
];

export const actions: {
  [key: string]: Array<ItemActions>;
} = {
  valve: [
    {
      label: 'Open',
      items: [OpenAction, TagOpenAction],
      modes: [Modes.isolation],
    },
    {
      label: 'Close',
      items: [CloseAction, TagCloseAction],
      modes: [Modes.isolation],
    },
    {
      label: 'Close air supply',
      items: [TagAirSupply],
      modes: [Modes.isolation],
    },
    {
      label: 'Remove',
      items: [RemoveItem, RemovePlugCap, RemoveTubing],
      modes: [Modes.isolation],
    },
    {
      label: 'Blind',
      items: [InstallBlindAndTag, InstalAndTagOnPipeWork, RemoveBlind],
      modes: [Modes.isolation],
    },
    {
      label: 'Spade',
      items: [InstallSpadeAndTag, TurnSpecBlindToClosed],
      modes: [Modes.isolation],
    },
    {
      label: 'Electrically isolate',
      items: [ElectricallyIsolate],
      modes: [Modes.isolation],
    },
    {
      label: 'Open',
      items: [
        OpenAction,
        RemoveTagAndOpen,
        RemoveTagAndLockWireOpen,
        RemoveTagAndLeaveOpen,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Close',
      items: [
        CloseAction,
        RemoveTagAndClose,
        RemoveTagAndLockWireClose,
        RemoveTagAndLeaveClose,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Tubing - remove tag and cap/plug and reconnect',
      items: [TubingRemoveTagAndCap],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Air supply',
      items: [RemoveTagAndOpenAirSupply, RemoveTagAndLeaveCloseAirSupply],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Reinstate',
      items: [ReinstatePlugCap, ReinstateBlind],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Electrically reinstate',
      items: [ElectricallyReinstate],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Blind',
      items: [
        RemoveTagBlind,
        RemoveTagAndBlind,
        RemoveTagFromBlindOnPipework,
        RemoveTagAndRemoveBlindOnPipework,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Spade',
      items: [
        RemoveTagSpade,
        RemoveTagAndSpade,
        RemoveTagAndOpenSpecBlind,
        RemoveTagAndLeaveSpecBlindClosed,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Replace valve/item',
      items: [ReplaceItem],
      modes: [Modes.deIsolation],
    },
  ],
  vessel: [
    {
      label: 'Blind',
      items: [InstallBlindAndTag, InstalAndTagOnPipeWork, RemoveBlind],
      modes: [Modes.isolation],
    },
    {
      label: 'Spade',
      items: [InstallSpadeAndTag, TurnSpecBlindToClosed],
      modes: [Modes.isolation],
    },
    {
      label: 'Remove',
      items: [RemoveItem, RemovePlugCap, RemoveTubing, RemoveManwayDoor],
      modes: [Modes.isolation],
    },
    {
      label: 'Open',
      items: [OpenAction],
      modes: [Modes.isolation],
    },
    {
      label: 'Close',
      items: [CloseAction],
      modes: [Modes.isolation],
    },
    {
      label: 'Open',
      items: [OpenAction],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Close',
      items: [CloseAction],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Reinstate',
      items: [ReinstatePlugCap, ReinstateBlind, ReinstateManwayDoor],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Tubing - remove tag and cap/plug and reconnect',
      items: [TubingRemoveTagAndCap],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Replace valve/item',
      items: [ReplaceItem],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Blind',
      items: [
        RemoveTagBlind,
        RemoveTagAndBlind,
        RemoveTagFromBlindOnPipework,
        RemoveTagAndRemoveBlindOnPipework,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Spade',
      items: [
        RemoveTagSpade,
        RemoveTagAndSpade,
        RemoveTagAndOpenSpecBlind,
        RemoveTagAndLeaveSpecBlindClosed,
      ],
      modes: [Modes.deIsolation],
    },
  ],
  pipe: [
    {
      label: 'Blind',
      items: [InstallBlindAndTag, InstalAndTagOnPipeWork, RemoveBlind],
      modes: [Modes.isolation],
    },
    {
      label: 'Spade',
      items: [InstallSpadeAndTag, TurnSpecBlindToClosed],
      modes: [Modes.isolation],
    },
    {
      label: 'Remove',
      items: [RemoveItem, RemovePlugCap],
      modes: [Modes.isolation],
    },
    {
      label: 'Tubing - remove tag and cap/plug and reconnect',
      items: [TubingRemoveTagAndCap],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Blind',
      items: [
        RemoveTagBlind,
        RemoveTagAndBlind,
        RemoveTagFromBlindOnPipework,
        RemoveTagAndRemoveBlindOnPipework,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Spade',
      items: [
        RemoveTagSpade,
        RemoveTagAndSpade,
        RemoveTagAndOpenSpecBlind,
        RemoveTagAndLeaveSpecBlindClosed,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Reinstate',
      items: [ReinstatePlugCap, ReinstateBlind],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Replace valve/item',
      items: [ReplaceItem],
      modes: [Modes.deIsolation],
    },
  ],
  motor: [
    {
      label: 'Electrically isolate',
      items: [ElectricallyIsolate],
      modes: [Modes.isolation],
    },
    {
      label: 'Remove',
      items: [RemoveItem],
      modes: [Modes.isolation],
    },
    {
      label: 'Blind',
      items: [InstallBlindAndTag, InstalAndTagOnPipeWork, RemoveBlind],
      modes: [Modes.isolation],
    },
    {
      label: 'Spade',
      items: [InstallSpadeAndTag, TurnSpecBlindToClosed],
      modes: [Modes.isolation],
    },
    {
      label: 'Electrically reinstate',
      items: [ElectricallyReinstate],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Replace valve/item',
      items: [ReplaceItem],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Reinstate',
      items: [ReinstateBlind],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Tubing - remove tag and cap/plug and reconnect',
      items: [TubingRemoveTagAndCap],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Blind',
      items: [
        RemoveTagBlind,
        RemoveTagAndBlind,
        RemoveTagFromBlindOnPipework,
        RemoveTagAndRemoveBlindOnPipework,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Spade',
      items: [
        RemoveTagSpade,
        RemoveTagAndSpade,
        RemoveTagAndOpenSpecBlind,
        RemoveTagAndLeaveSpecBlindClosed,
      ],
      modes: [Modes.deIsolation],
    },
  ],
  instrument: [
    {
      label: 'Close air supply',
      items: [TagAirSupply],
      modes: [Modes.isolation],
    },
    {
      label: 'Electrically isolate',
      items: [ElectricallyIsolate],
      modes: [Modes.isolation],
    },
    {
      label: 'Remove',
      items: [RemoveItem, RemovePlugCap, RemoveTubing],
      modes: [Modes.isolation],
    },
    {
      label: 'Blind',
      items: [InstallBlindAndTag, InstalAndTagOnPipeWork, RemoveBlind],
      modes: [Modes.isolation],
    },
    {
      label: 'Spade',
      items: [InstallSpadeAndTag, TurnSpecBlindToClosed],
      modes: [Modes.isolation],
    },
    {
      label: 'Tubing - remove tag and cap/plug and reconnect',
      items: [TubingRemoveTagAndCap],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Air supply',
      items: [RemoveTagAndOpenAirSupply, RemoveTagAndLeaveCloseAirSupply],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Electrically reinstate',
      items: [ElectricallyReinstate],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Reinstate',
      items: [ReinstatePlugCap, ReinstateBlind],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Replace valve/item',
      items: [ReplaceItem],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Blind',
      items: [
        RemoveTagBlind,
        RemoveTagAndBlind,
        RemoveTagFromBlindOnPipework,
        RemoveTagAndRemoveBlindOnPipework,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Spade',
      items: [
        RemoveTagSpade,
        RemoveTagAndSpade,
        RemoveTagAndOpenSpecBlind,
        RemoveTagAndLeaveSpecBlindClosed,
      ],
      modes: [Modes.deIsolation],
    },
  ],
  heater: [
    {
      label: 'Electrically isolate',
      items: [ElectricallyIsolate],
      modes: [Modes.isolation],
    },
    {
      label: 'Remove',
      items: [RemoveItem, RemovePlugCap],
      modes: [Modes.isolation],
    },
    {
      label: 'Blind',
      items: [InstallBlindAndTag, InstalAndTagOnPipeWork, RemoveBlind],
      modes: [Modes.isolation],
    },
    {
      label: 'Spade',
      items: [InstallSpadeAndTag, TurnSpecBlindToClosed],
      modes: [Modes.isolation],
    },
    {
      label: 'Electrically reinstate',
      items: [ElectricallyReinstate],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Tubing - remove tag and cap/plug and reconnect',
      items: [TubingRemoveTagAndCap],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Reinstate',
      items: [ReinstatePlugCap, ReinstateBlind, ReinstateManwayDoor],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Replace valve/item',
      items: [ReplaceItem],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Blind',
      items: [
        RemoveTagBlind,
        RemoveTagAndBlind,
        RemoveTagFromBlindOnPipework,
        RemoveTagAndRemoveBlindOnPipework,
      ],
      modes: [Modes.deIsolation],
    },
    {
      label: 'Spade',
      items: [
        RemoveTagSpade,
        RemoveTagAndSpade,
        RemoveTagAndOpenSpecBlind,
        RemoveTagAndLeaveSpecBlindClosed,
      ],
      modes: [Modes.deIsolation],
    },
  ],
};

export const actionsMappings = {
  valve: {
    'tag-closed-air-supply-to': [
      'remove-tag-and-open-air-supply',
      'remove-tag-and-leave-close-air-supply',
    ],
    'electrically-isolate-and-tag': ['electrically-reinstate'],
    'tag-close': [
      'remove-tag-and-leave-close',
      'remove-tag-and-open',
      'remove-tag-and-lock-wire-open',
    ],
    'tag-open': [
      'remove-tag-and-leave-open',
      'remove-tag-and-close',
      'remove-tag-and-lock-wire-close',
    ],
    'remove-tubing-from-capplug-and-tag': [
      'remove-tag-and-cap-reconnect-tubing-to',
    ],
    close: ['open'],
    open: ['close'],
    'remove-plugcap-from': ['reinstate-capplug-to'],
    'install-and-tag-a-rated-spade-to': [
      'remove-tag-from-spade',
      'remove-tag-and-remove-spade',
    ],
    'turn-spec-blind-to-closed-position-and-tag': [
      'remove-tag-and-open-spec-blind',
      'remove-tag-and-leave-spec-blind-in-closed-position',
    ],
    'install-and-tag-a-rated-blind-to': [
      'remove-tag-from-rated-blind',
      'remove-tag-and-remove-rated-blind',
    ],
    'install-and-tag-a-rated-blind-to-on-pipework': [
      'remove-tag-from-rated-blind-on-upstream-pipework',
      'remove-tag-and-remove-rated-blind-from-upstream-pipework',
    ],
    'remove-blind-from': ['reinstate-blind-to'],
    remove: ['replace'],
  },
  vessel: {
    'remove-tubing-from-capplug-and-tag': [
      'remove-tag-and-cap-reconnect-tubing-to',
    ],
    close: ['open'],
    open: ['close'],
    'remove-plugcap-from': ['reinstate-capplug-to'],
    'install-and-tag-a-rated-spade-to': [
      'remove-tag-from-spade',
      'remove-tag-and-remove-spade',
    ],
    'turn-spec-blind-to-closed-position-and-tag': [
      'remove-tag-and-open-spec-blind',
      'remove-tag-and-leave-spec-blind-in-closed-position',
    ],
    'install-and-tag-a-rated-blind-to': [
      'remove-tag-from-rated-blind',
      'remove-tag-and-remove-rated-blind',
    ],
    'install-and-tag-a-rated-blind-to-on-pipework': [
      'remove-tag-from-rated-blind-on-upstream-pipework',
      'remove-tag-and-remove-rated-blind-from-upstream-pipework',
    ],
    'remove-manway-door-from': ['reinstate-manway-door-to'],
    'remove-blind-from': ['reinstate-blind-to'],
    remove: ['replace'],
  },
  pipe: {
    'remove-plugcap-from': ['reinstate-capplug-to'],
    'install-and-tag-a-rated-spade-to': [
      'remove-tag-from-spade',
      'remove-tag-and-remove-spade',
    ],
    'turn-spec-blind-to-closed-position-and-tag': [
      'remove-tag-and-open-spec-blind',
      'remove-tag-and-leave-spec-blind-in-closed-position',
    ],
    'install-and-tag-a-rated-blind-to': [
      'remove-tag-from-rated-blind',
      'remove-tag-and-remove-rated-blind',
    ],
    'install-and-tag-a-rated-blind-to-on-pipework': [
      'remove-tag-from-rated-blind-on-upstream-pipework',
      'remove-tag-and-remove-rated-blind-from-upstream-pipework',
    ],
    'remove-blind-from': ['reinstate-blind-to'],
    remove: ['replace'],
  },
  motor: {
    'electrically-isolate-and-tag': ['electrically-reinstate'],
    'install-and-tag-a-rated-spade-to': [
      'remove-tag-from-spade',
      'remove-tag-and-remove-spade',
    ],
    'turn-spec-blind-to-closed-position-and-tag': [
      'remove-tag-and-open-spec-blind',
      'remove-tag-and-leave-spec-blind-in-closed-position',
    ],
    'install-and-tag-a-rated-blind-to': [
      'remove-tag-from-rated-blind',
      'remove-tag-and-remove-rated-blind',
    ],
    'install-and-tag-a-rated-blind-to-on-pipework': [
      'remove-tag-from-rated-blind-on-upstream-pipework',
      'remove-tag-and-remove-rated-blind-from-upstream-pipework',
    ],
    'remove-blind-from': ['reinstate-blind-to'],
    remove: ['replace'],
  },
  instrument: {
    'tag-closed-air-supply-to': [
      'remove-tag-and-open-air-supply',
      'remove-tag-and-leave-close-air-supply',
    ],
    'electrically-isolate-and-tag': ['electrically-reinstate'],
    'remove-tubing-from-capplug-and-tag': [
      'remove-tag-and-cap-reconnect-tubing-to',
    ],
    'remove-plugcap-from': ['reinstate-capplug-to'],
    'install-and-tag-a-rated-spade-to': [
      'remove-tag-from-spade',
      'remove-tag-and-remove-spade',
    ],
    'turn-spec-blind-to-closed-position-and-tag': [
      'remove-tag-and-open-spec-blind',
      'remove-tag-and-leave-spec-blind-in-closed-position',
    ],
    'install-and-tag-a-rated-blind-to': [
      'remove-tag-from-rated-blind',
      'remove-tag-and-remove-rated-blind',
    ],
    'install-and-tag-a-rated-blind-to-on-pipework': [
      'remove-tag-from-rated-blind-on-upstream-pipework',
      'remove-tag-and-remove-rated-blind-from-upstream-pipework',
    ],
    'remove-blind-from': ['reinstate-blind-to'],
    remove: ['replace'],
  },
  heater: {
    'electrically-isolate-and-tag': ['electrically-reinstate'],
    'remove-plugcap-from': ['reinstate-capplug-to'],
    'install-and-tag-a-rated-spade-to': [
      'remove-tag-from-spade',
      'remove-tag-and-remove-spade',
    ],
    'turn-spec-blind-to-closed-position-and-tag': [
      'remove-tag-and-open-spec-blind',
      'remove-tag-and-leave-spec-blind-in-closed-position',
    ],
    'install-and-tag-a-rated-blind-to': [
      'remove-tag-from-rated-blind',
      'remove-tag-and-remove-rated-blind',
    ],
    'install-and-tag-a-rated-blind-to-on-pipework': [
      'remove-tag-from-rated-blind-on-upstream-pipework',
      'remove-tag-and-remove-rated-blind-from-upstream-pipework',
    ],
    'remove-manway-door-from': ['reinstate-manway-door-to'],
    'remove-blind-from': ['reinstate-blind-to'],
    remove: ['replace'],
  },
};
