import { Flex, Micro, Overline } from '@cognite/cogs.js';
import React from 'react';
import { TagsGroupWrapper } from './elements';

export interface WorkstepsTagsGroupProps {
  title: string;
  children?: any;
}

export const WorkstepsTagsGroup = React.memo(
  ({ title, children }: WorkstepsTagsGroupProps) => {
    return (
      <TagsGroupWrapper
        className="tags-group-wrapper"
        data-testid={`tags-group-wrapper-${title}`}
      >
        <Overline
          level="1"
          className="tags-group-title"
          data-testid="worksteps-tags-group-title"
        >
          {title}
        </Overline>
        <div className="tags-group-phase-container">
          <Flex direction="row">
            <div>
              <Overline level="2" className="tags-group-phase-title">
                Isolation steps
              </Overline>
              <Micro className="tags-group-step-title">Step</Micro>
            </div>
            <div>
              <Overline level="2" className="tags-group-phase-title">
                De-Isolation steps
              </Overline>
              <Micro className="tags-group-step-title">Step</Micro>
            </div>
          </Flex>
          {children}
        </div>
      </TagsGroupWrapper>
    );
  }
);
