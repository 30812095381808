import styled from 'styled-components/macro';
import zIndex from '../../../../utils/zindex';

export const ColorPreview = styled.div.attrs((props: { userColor: string }) => {
  return {
    style: {
      backgroundColor: props.userColor,
    },
  };
})<{ userColor: string }>`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  cursor: pointer;
  z-index: ${zIndex.MAXIMUM};
`;
