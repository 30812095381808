import React from 'react';
import { Drawer } from '@cognite/cogs.js';
import { Project } from 'types';
import SideBarContent from './SideBarContent';

export type SideBarProps = {
  drawerVisible: boolean;
  setDrawerVisible: Function;
  project: Project;
};

const SideBar = ({
  drawerVisible,
  setDrawerVisible,
  project,
}: SideBarProps) => {
  return (
    <Drawer
      visible={drawerVisible}
      footer={null}
      closeIcon={null}
      width={280}
      placement="left"
      onCancel={() => setDrawerVisible(false)}
    >
      <SideBarContent project={project} setDrawerVisible={setDrawerVisible} />
    </Drawer>
  );
};

export default SideBar;
