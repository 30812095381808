import React from 'react';
import {
  LogoArea,
  ProjectContainer,
  Title,
} from 'pages/Project/components/elements';
import { PreWorkItem } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import { ReactComponent as Icon } from './pwIcon.svg';
import PreWorkItemComponent from './PreWorkItem/PreWorkItem';
import NewPreWorkItem from './NewPreWorkItem/NewPreWorkItem';

type PreWorkCardProps = {
  isForDrawer?: boolean;
  projectId?: string;
  editMode: boolean;
  checkboxesMode: boolean;
  preWorkItems: PreWorkItem[];
  showLoading?: boolean;
};

const PreWorkItems = ({
  isForDrawer = false,
  projectId,
  editMode,
  checkboxesMode,
  preWorkItems,
  showLoading = false,
}: PreWorkCardProps) => {
  const filteredpreWorkItems = preWorkItems.filter(
    (item) => item.removed !== true
  );
  const counterForNewItem = filteredpreWorkItems.length + 1;
  return (
    <ProjectContainer>
      <LogoArea>
        {!isForDrawer && (
          <>
            <Icon style={{ marginRight: '6px' }} />
            <Title>Pre-work action items</Title>
          </>
        )}
      </LogoArea>
      {showLoading && (
        <div style={{ display: 'block', overflow: 'hidden', height: '150px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}

      {!!filteredpreWorkItems.length && (
        <>
          {filteredpreWorkItems.map((item, index) => {
            return (
              <PreWorkItemComponent
                key={`pre-work-item-${item.id}`}
                position={index + 1}
                preWorkItem={item}
                editMode={editMode}
                checkboxesMode={checkboxesMode}
              />
            );
          })}
        </>
      )}
      {!showLoading && editMode && (
        <NewPreWorkItem position={counterForNewItem} identifier={projectId} />
      )}
      {!showLoading && !editMode && !preWorkItems.length && (
        <div data-testid="no-pre-work-items-text">
          No pre-work items were specified
        </div>
      )}
    </ProjectContainer>
  );
};

export default React.memo(PreWorkItems);
