import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ProjectState } from './projectReducer';

export const useProject = () => {
  const project = useSelector<RootState, ProjectState>((state) => {
    return state.project;
  });

  const viewOnly = (): boolean => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get('viewOnly') === 'true';
  };

  const areAllPreWorkItemsChecked = (): boolean => {
    const allItems = [...project.preWorkItems];
    if (allItems.length) {
      const allItemsFiltered = allItems.filter((item) => item.removed !== true);
      const arrayOfUncheckedItems = allItemsFiltered.filter(
        (filtItem) => filtItem.checked !== true
      );
      if (!arrayOfUncheckedItems.length) {
        return true;
      }
      return false;
    }
    return true;
  };

  const areAllPassingValvesChecked = (): boolean => {
    const allValves = [...project.passValves];
    const allCheckedValves = project.project.checkedValves
      ? [...project.project.checkedValves]
      : [];
    if (allValves.length) {
      const arrayOfUncheckedValves = allValves.filter(
        (filtItem) => !allCheckedValves.includes(filtItem.id)
      );
      if (!arrayOfUncheckedValves.length) {
        return true;
      }
      return false;
    }
    return true;
  };

  return {
    ...project,
    viewOnly,
    areAllPreWorkItemsChecked,
    areAllPassingValvesChecked,
  };
};
