import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, Flex } from '@cognite/cogs.js';
import { actions } from 'redux/reducers/project';
import { PreWorkItem } from 'types';
import { PreWorkItemContainer } from '../elements';

type PreWorkItemType = {
  position: number;
  preWorkItem: PreWorkItem;
  editMode: boolean;
  checkboxesMode: boolean;
};

const PreWorkItemComponent = ({
  position,
  preWorkItem,
  editMode,
  checkboxesMode,
}: PreWorkItemType) => {
  const dispatch = useDispatch();
  const positionMargin = editMode ? '18px' : '4px';
  return (
    <PreWorkItemContainer data-testid="pre-work-item">
      <Flex direction="row">
        {checkboxesMode && (
          <Checkbox
            name={`pre-item-checkbox-${preWorkItem.id}`}
            data-testid={`pre-item-checkbox-${preWorkItem.id}`}
            checked={preWorkItem.checked}
            style={{ marginRight: '11px', alignItems: 'baseline' }}
            onChange={() =>
              dispatch(actions.checkPreWorkActionItem(preWorkItem))
            }
          />
        )}
        <div
          data-testid={`pre-work-position-${preWorkItem.id}`}
          style={{ marginRight: positionMargin }}
        >{`${position}.`}</div>
        <div style={{ flexGrow: 1, textAlign: 'left' }}>
          {preWorkItem.itemContent}
        </div>
        {editMode && (
          <Button
            style={{
              height: 'min-content',
              fontSize: '12px',
              margin: '3.5px 8px 0px 8px',
              color: '#4A67FB',
            }}
            data-testid="remove-pre-work-item"
            onClick={() =>
              dispatch(actions.removePreWorkActionItem(preWorkItem))
            }
          >
            Remove
          </Button>
        )}
      </Flex>
    </PreWorkItemContainer>
  );
};

export default PreWorkItemComponent;
