import styled from 'styled-components/macro';
import React from 'react';
import { LinkButton } from '../elements';

export const LogoSpan = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--cogs-yellow-6);
  margin-right: 6px;
  text-align: center;
`;

export const ApprovalFilesList = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  span.removed {
    word-break: break-all;
  }
`);

export const ApprovalFileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  & + & {
    padding-top: 10px;
    border-top: 1px solid var(--cogs-greyscale-grey3);
  }
`;

export const ApprovalFileContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3px;
`;

export const ApprovalFileActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ApprovalFileApprover = styled.div`
  font-weight: 500;
`;

export const ApprovalFileEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--cogs-greyscale-grey2);
  width: 100%;
  padding: 10px;
`;

export const ApprovalFileName = styled(LinkButton)`
  text-align: left;
  word-break: break-all;
  font-weight: 500 !important;
`;

export const FieldLabel = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin: 10px 0px 4px;
`;
