import React from 'react';

import QRCode from 'react-qr-code';
import classnames from 'classnames';

import { Phase } from '../../../../../types';
import { getQrCodeLink } from '../Qrcodes.utils';

export type WorkStepTemplateProps = {
  phases: Phase[];
  certificateNumber?: string;
  onlyTags?: boolean;
};

const WorkStepTemplate = (
  { phases, onlyTags = false, certificateNumber }: WorkStepTemplateProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div ref={ref}>
      {phases.map((phase) => {
        return phase.workSteps.map((workstep) => {
          const classesQrcode = classnames('print-qrcode-svg', {
            'has-isolation': phase.phaseType === 'isolation',
            'has-deisolation': phase.phaseType === 'deisolation',
          });

          if (onlyTags && !workstep.tag) {
            return null;
          }

          return (
            <div
              className="page-template"
              key={`qr-step${onlyTags ? '-tag-only' : ''}-${workstep.id}`}
            >
              <QRCode
                className={classesQrcode}
                value={getQrCodeLink()}
                viewBox="0 0 256 256"
              />
              <span>{`${phase.phaseType} #${workstep.position} Workstep`}</span>

              {workstep.tagNumber && (
                <span>{`tag : ${workstep.tagNumber}`}</span>
              )}
              {certificateNumber && <span>{`IC : ${certificateNumber}`}</span>}
            </div>
          );
        });
      })}
    </div>
  );
};

export default React.forwardRef(WorkStepTemplate);
