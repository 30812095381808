import { cacheProvider } from 'services';
import { logger } from './logger';

function arrayBufferToBase64(buffer: any) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
export const cacheRequest = (
  cacheKey: string,
  target: any,
  that: any,
  args: any,
  isPdf = false,
  isDrawing = false
): Promise<any> => {
  return new Promise((resolve, reject) => {
    cacheProvider.hasKey(cacheKey).then((cacheExists) => {
      if (cacheExists) {
        cacheProvider.getItem(cacheKey).then((response) => {
          resolve(response);
        });
      } else {
        target
          .apply(that, args)
          .then(
            (response: any) => {
              if (isPdf) {
                const fileDownloadInfo = response[0];
                if (!fileDownloadInfo || !fileDownloadInfo.downloadUrl) {
                  logger(
                    `Missing the download url in the response for ${cacheKey}`
                  );
                  resolve(response);
                  return;
                }
                const url = response[0].downloadUrl;
                fetch(url)
                  .then((res) => res.arrayBuffer())
                  .then((pdfArrayBuffer) => {
                    const pdfData = arrayBufferToBase64(pdfArrayBuffer);
                    const pdfResponse = [
                      {
                        ...fileDownloadInfo,
                        downloadUrl: `data:application/pdf;base64,${pdfData}`,
                      },
                    ];

                    cacheProvider.setItem(cacheKey, pdfResponse);
                    resolve(pdfResponse);
                  });
              } else if (!isDrawing) {
                const responseData = response.data ? response.data : response;
                cacheProvider.setItem(cacheKey, responseData);
                resolve(responseData);
              }
            },
            (err: any) => {
              reject(err);
            }
          )
          .catch((err: any) => reject(err));
      }
    });
  });
};
