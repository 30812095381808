import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import React, { useEffect, useState } from 'react';

export interface RouterContextData {
  location: {
    from?: Location;
    to?: Location;
  };
}

export const RouterContext = React.createContext<RouterContextData>({
  location: {
    from: undefined,
    to: undefined,
  },
});

export const RouterProvider = ({ children }: { children: any }) => {
  const location = useLocation();

  const [routerData, setRouterData] = useState<RouterContextData>({
    location: {
      to: location,
      from: undefined,
    },
  });

  useEffect(() => {
    setRouterData((prev) => ({
      location: { from: prev.location.to, to: location },
    }));
  }, [location]);

  return (
    <RouterContext.Provider value={routerData}>
      {children}
    </RouterContext.Provider>
  );
};
