import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@cognite/cogs.js';
import { SearchState } from 'redux/reducers/search';
import { RootState } from 'redux/store';
import { useProjectLoad } from 'hooks/useProjectLoad';
import { AssetItemButton, AssetsModal } from './elements';

const CreateProcedureButton = () => {
  const [activeChooseFlocModal, setActiveChooseFlocModal] = useState(false);

  const { assets, selectedRootAsset } = useSelector<RootState, SearchState>(
    (state) => {
      return state.search;
    }
  );

  const { createNewProcedure } = useProjectLoad();

  return (
    <>
      <AssetsModal
        onCancel={() => setActiveChooseFlocModal(false)}
        visible={activeChooseFlocModal}
        hideFooter
      >
        {assets.length
          ? assets.map((asset) => {
              return (
                <AssetItemButton
                  key={`asset-menu-${asset.assetId}`}
                  onClick={() => createNewProcedure(asset, selectedRootAsset)}
                >
                  {`${asset.assetFloc.split('.').pop()} | ${
                    asset.assetName
                  } | ${asset.assetDescription}`}
                </AssetItemButton>
              );
            })
          : 'Please start searching for FLOC in the input field'}
      </AssetsModal>
      <Button
        data-testid="createProcedure"
        type="primary"
        onClick={() => {
          setActiveChooseFlocModal(true);
        }}
      >
        Create new procedure
      </Button>
    </>
  );
};

export default CreateProcedureButton;
