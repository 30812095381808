import React from 'react';
import { Flex } from '@cognite/cogs.js';

import { ExecutorItemContainer, LinkButton } from '../elements';

type ExecutorItemProps = {
  handleDelete: () => void;
  executor: string;
  viewOnly: boolean;
};

const ExecutorItem = ({
  viewOnly = false,
  executor,
  handleDelete,
}: ExecutorItemProps) => {
  return (
    <ExecutorItemContainer>
      <Flex justifyContent="space-between">
        <span data-testid="executor-item-name">{executor}</span>
        {!viewOnly && (
          <LinkButton
            style={{
              height: 'min-content',
              fontSize: '12px',
              margin: '3.5px 8px 0px 8px',
              color: '#4A67FB',
            }}
            key={`executor-item-delete-${executor}`}
            data-testid={`executor-item-delete-${executor}`}
            onClick={handleDelete}
          >
            Delete
          </LinkButton>
        )}
      </Flex>
    </ExecutorItemContainer>
  );
};

export default ExecutorItem;
