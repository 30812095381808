import React from 'react';
import { Flex, Icon } from '@cognite/cogs.js';

import { openFileExternalIdInNewWindow } from 'services/services';
import { useAttachmentFileUpload } from 'hooks/useAttachmentFileUpload';
import { ProcedureFileAttachment } from 'types';
import { LoadingPlaceholder } from 'shared/LoadingPlaceholder';
import {
  Title,
  ProjectContainer,
  LogoArea,
  TextDetail,
  LinkButton,
} from '../elements';
import { LogoSpan, AttachmentsWrapper, AttachmentName } from './elements';

type AttachmentsProps = {
  attachmentsList: ProcedureFileAttachment[];
  canEdit?: boolean;
  showLoading?: boolean;
};

const Attachments = ({
  attachmentsList,
  canEdit = false,
  showLoading = false,
}: AttachmentsProps) => {
  const {
    fileUploadHandler,
    fileSoftDeleteHandler,
    undoFileSoftDeleteHandler,
  } = useAttachmentFileUpload();
  return (
    <ProjectContainer>
      <LogoArea>
        <LogoSpan>
          <Icon
            type="Attachment"
            style={{ color: 'var(--cogs-greyscale-grey9)', margin: '7px auto' }}
          />
        </LogoSpan>
        <Title>Attachments</Title>
      </LogoArea>
      {showLoading && (
        <div style={{ display: 'block', overflow: 'hidden', height: '180px' }}>
          <LoadingPlaceholder useCompactMode />
        </div>
      )}
      {!!attachmentsList.length && (
        <AttachmentsWrapper>
          {attachmentsList.map((attachment) => {
            return (
              <Flex
                key={`attachment-flex-${attachment.id}`}
                direction="row"
                className="topFlex"
              >
                {!attachment.removed ? (
                  <AttachmentName
                    key={`attachment-link-${attachment.id}`}
                    data-testid={`attachment-link-${attachment.id}`}
                    icon={attachment.uploading ? 'Loader' : undefined}
                    iconPlacement="right"
                    onClick={() => openFileExternalIdInNewWindow(attachment.id)}
                  >
                    {attachment.name}
                  </AttachmentName>
                ) : (
                  <span className="removed">{attachment.name} deleted.</span>
                )}
                {canEdit && attachment.removed && (
                  <LinkButton
                    key={`attachment-undo-${attachment.id}`}
                    data-testid={`attachment-undo-${attachment.id}`}
                    onClick={() => undoFileSoftDeleteHandler(attachment)}
                  >
                    Undo
                  </LinkButton>
                )}
                {canEdit && !attachment.removed && !attachment.uploading && (
                  <LinkButton
                    key={`attachment-remove-${attachment.id}`}
                    data-testid={`attachment-remove-${attachment.id}`}
                    onClick={() => fileSoftDeleteHandler(attachment)}
                  >
                    Delete
                  </LinkButton>
                )}
              </Flex>
            );
          })}
        </AttachmentsWrapper>
      )}
      {!showLoading && !attachmentsList.length && (
        <TextDetail
          data-testid="no-attachments-text"
          style={{ marginLeft: '3px' }}
        >
          No attachments were added
        </TextDetail>
      )}
      {!showLoading && canEdit && (
        <div style={{ alignSelf: 'flex-end' }}>
          <label
            htmlFor="attachments_file_input"
            className="cogs-btn cogs-btn-secondary"
            data-testid="attachments-add-button"
            style={{ marginTop: '22px', padding: '0 10px' }}
          >
            Add attachment
            <input
              hidden
              id="attachments_file_input"
              name="file"
              type="file"
              onChange={(event) => {
                if (event.currentTarget.files?.length) {
                  fileUploadHandler(event.currentTarget.files[0]);
                }
              }}
            />
          </label>
        </div>
      )}
    </ProjectContainer>
  );
};

export default Attachments;
