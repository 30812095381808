import { useActiveWorkStep } from 'redux/reducers/activeWorkStep/hooks';
import { useActiveWorkstepActions } from 'redux/reducers/activeWorkStep/workstepActions';
import { useAnnotations } from 'redux/reducers/annotations';
import { Reference, StepItem } from 'types';

export const useSelectingHotSpot = () => {
  const { selectionMode: selectionModeActiveWorkStep } = useActiveWorkStep();

  const {
    selectLine: selectLineForWorkStep,
    selectAsset: selectAssetForWorkStep,
    selectRelativeRef: selectRelativeRefForWorkStep,
  } = useActiveWorkstepActions();

  const {
    selectRelativeRef: selectRelativeRefForAnnotation,
    selectLine: selectLineForAnnotation,
    newAnnotation,
  } = useAnnotations();

  const selectAsset = (item: StepItem) => {
    selectAssetForWorkStep(item);
  };

  const selectRelativeRef = (item: StepItem, relation: Reference) => {
    if (newAnnotation?.needRelativeRef) {
      selectRelativeRefForAnnotation(item, relation);
    } else {
      selectRelativeRefForWorkStep(item, relation);
    }
  };

  const selectLine = (item: StepItem) => {
    if (newAnnotation?.needRelativeRef) {
      selectLineForAnnotation(item);
    } else {
      selectLineForWorkStep(item);
    }
  };

  return {
    selectAsset,
    selectRelativeRef,
    selectLine,
    selectionModeActiveWorkStep,
    createAnnotationMode: newAnnotation?.needRelativeRef,
  };
};
