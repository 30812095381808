/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { Status } from 'redux/types';
import {
  ColorFiles,
  FileAnnotationsDTO,
  FetchAnnotationsForFileDTO,
  FetchAnnotationsForProcedureDTO,
  NewAnnotation,
  Reference,
  StepItem,
  CogniteAnnotation,
  CogniteAnnotationSpec,
} from 'types';
import { annotationsResourceService, filesResourceService } from 'resources';
import { annotationsColorsService } from 'services';
import { logger } from 'utils/logger';
import { ToolType } from '@cognite/unified-file-viewer';
import { RootState } from 'redux/store';

export const fetchAnnotationsForFileId = createAsyncThunk<
  FileAnnotationsDTO,
  FetchAnnotationsForFileDTO,
  { state: RootState }
>('annotations/fetchAnnotationsForFileId', async (dto, thunkAPI) => {
  const { phases } = thunkAPI.getState().workSteps;
  const annotations =
    await annotationsResourceService.fetchAnnotationsForFileId(dto, phases);
  return annotations;
});

export const fetchAnnotationsForProcedure = createAsyncThunk<
  FileAnnotationsDTO[],
  FetchAnnotationsForProcedureDTO,
  { state: RootState }
>('annotations/fetchAnnotationsForProcedure', async (dto, thunkAPI) => {
  const { phases } = thunkAPI.getState().workSteps;
  try {
    const annotationsPromises = dto.fileAnnotations.map((fileDto) =>
      annotationsResourceService.fetchAnnotationsForFileId(fileDto, phases)
    );
    if (!annotationsResourceService) {
      return [];
    }

    const downloadUlrs = dto.fileAnnotations.map((fileDto) =>
      filesResourceService.getFileDownloadUrl(fileDto.fileId)
    );

    await Promise.all(downloadUlrs);

    return Promise.all(annotationsPromises);
  } catch (err) {
    logger(err, `Could not fetch annotations and download urls`);
    return [];
  }
});

export const saveColorFileAction = createAction<ColorFiles>('saveColorFile');

export function saveFileColor(
  annotation: CogniteAnnotation | CogniteAnnotationSpec,
  color: string
) {
  const colorFile = annotationsColorsService.saveFileColor(annotation, color);
  return saveColorFileAction(colorFile);
}

export function isNotAnnotationSpec(
  annotation: CogniteAnnotation | CogniteAnnotationSpec
): annotation is CogniteAnnotation {
  return (annotation as CogniteAnnotation)?.id !== undefined;
}

const annotationsSlice = createSlice({
  name: 'annotations',
  initialState: {
    editMode: false as boolean,
    annotations: {} as {
      [key: number]: Array<CogniteAnnotation | CogniteAnnotationSpec>;
    },
    newAnnotation: undefined as NewAnnotation | undefined,
    fileColors: [] as ColorFiles[],
    status: Status.idle,
    annotationsLoadedStatus: Status.idle,
    error: undefined as string | undefined,
    tool: ToolType.SELECT as string,
  },
  reducers: {
    addAnnotation: (
      state,
      action: PayloadAction<{ annotation: CogniteAnnotation }>
    ) => {
      const fileId = action.payload.annotation.annotatedResourceId;

      if (state.annotations[fileId]) {
        state.annotations[fileId] = annotationsColorsService.colorAnnotations(
          state,
          state.annotations[fileId].concat(action.payload.annotation)
        );
      } else {
        state.annotations[fileId] = annotationsColorsService.colorAnnotations(
          state,
          [action.payload.annotation]
        );
      }
    },

    removeAnnotation: (
      state,
      action: PayloadAction<{ annotation: CogniteAnnotation }>
    ) => {
      const fileId = action.payload.annotation.annotatedResourceId;
      state.annotations[fileId] = state.annotations[fileId].filter(
        (annotation) => {
          if (isNotAnnotationSpec(annotation)) {
            return annotation.id !== action.payload.annotation.id;
          }
          return false;
        }
      );
    },

    setEditMode: (state, action: PayloadAction<{ editMode: boolean }>) => {
      state.editMode = action.payload.editMode;
    },
    cleanUpNewAnnotation: (state) => {
      const fileId = state.newAnnotation?.annotation?.annotatedResourceId;
      if (fileId) {
        state.annotations[fileId] = state.annotations[fileId].filter(
          (annotation) => {
            if (isNotAnnotationSpec(annotation)) {
              return annotation;
            }
            return false;
          }
        );
      }
      state.newAnnotation = undefined;
      state.editMode = false;
    },
    setNewAnnotation: (
      state,
      action: PayloadAction<{
        newAnnotation: CogniteAnnotationSpec;
        oldAnnotation?: CogniteAnnotation;
      }>
    ) => {
      state.newAnnotation = {
        annotation: action.payload.newAnnotation,
        oldAnnotation: action.payload.oldAnnotation,
      };
      const fileId = action.payload.newAnnotation?.annotatedResourceId;
      if (fileId) {
        state.annotations[fileId] = state.annotations[fileId].concat(
          annotationsColorsService.colorAnnotations(state, [
            action.payload.newAnnotation,
          ])
        );
        state.editMode = false;
      }
    },

    setNewAnnotationNeedRelativeRef: (
      state,
      action: PayloadAction<{ needRelativeRef: boolean }>
    ) => {
      if (state.newAnnotation?.annotation) {
        state.newAnnotation.needRelativeRef = action.payload.needRelativeRef;
      }
    },

    setRelativeRef: (
      state,
      action: PayloadAction<{ item?: StepItem; relation?: Reference }>
    ) => {
      if (
        state.newAnnotation &&
        state.newAnnotation.annotation &&
        state.newAnnotation.needRelativeRef
      ) {
        if (!state.newAnnotation.annotation.data) {
          state.newAnnotation.annotation.data = {};
        }
        state.newAnnotation.annotation.data = {
          ...state.newAnnotation.annotation.data,
          ...(action.payload.item?.asset?.externalId && {
            indirectExternalId: {
              externalId: action.payload.item?.asset?.externalId,
            },
          }),
          indirectRelation: action.payload.relation?.text,
        };
      } else if (
        state.newAnnotation?.annotation?.data &&
        'indirectExternalId' in state.newAnnotation.annotation.data &&
        state.newAnnotation?.annotation?.data.indirectExternalId
      ) {
        delete state.newAnnotation?.annotation?.data.indirectExternalId
          .externalId;
        delete state.newAnnotation?.annotation?.data.indirectRelation;
      }
    },

    setLine: (state, action: PayloadAction<{ item?: StepItem }>) => {
      if (
        state.newAnnotation &&
        state.newAnnotation.annotation &&
        state.newAnnotation.needRelativeRef
      ) {
        if (!state.newAnnotation.annotation.data) {
          state.newAnnotation.annotation.data = {};
        }
        if (
          state.newAnnotation.annotation.data &&
          'lineExternalId' in state.newAnnotation?.annotation?.data &&
          state.newAnnotation.annotation.data.lineExternalId
        ) {
          state.newAnnotation.annotation.data.lineExternalId.externalId =
            action.payload.item?.asset?.externalId;
        }
      } else if (
        state.newAnnotation?.annotation?.data &&
        'lineExternalId' in state.newAnnotation?.annotation?.data &&
        state.newAnnotation.annotation.data.lineExternalId !== undefined
      ) {
        delete state.newAnnotation?.annotation?.data.lineExternalId.externalId;
      }
    },

    updateWithUserCreatedAnnotation: (
      state,
      action: PayloadAction<{
        pipeLineAnnotation: CogniteAnnotation;
        userAnnotation: CogniteAnnotation;
      }>
    ) => {
      const fileId = action.payload.pipeLineAnnotation.annotatedResourceId;
      const fileAnnotations = state.annotations[fileId].filter((annotation) => {
        if (isNotAnnotationSpec(annotation)) {
          let isRegionEqual: boolean = false;
          if (
            'textRegion' in annotation.data &&
            'textRegion' in action.payload.pipeLineAnnotation.data
          ) {
            isRegionEqual = isEqual(
              annotation.data.textRegion,
              action.payload.pipeLineAnnotation.data.textRegion
            );
          }
          return !isRegionEqual;
        }
        return true;
      });
      fileAnnotations.unshift(action.payload.userAnnotation);
      state.annotations[fileId] = annotationsColorsService.colorAnnotations(
        state,
        fileAnnotations
      );
    },
    setAnnotationCustomOffset: (
      state,
      action: PayloadAction<{
        fileId: number;
        annotationId: number;
        offsetX: number;
        offsetY: number;
      }>
    ) => {
      const { fileId, annotationId, offsetX, offsetY } = action.payload;
      state.annotations[fileId] = state.annotations[fileId].map(
        (annotation) => {
          if (
            isNotAnnotationSpec(annotation) &&
            annotation.id === annotationId
          ) {
            return {
              ...annotation,
              customOffset: {
                x: offsetX,
                y: offsetY,
              },
            };
          }

          return annotation;
        }
      );
      return state;
    },
    clearAnnotations: (state) => {
      state.editMode = false;
      state.annotations = {};
      state.newAnnotation = undefined as NewAnnotation | undefined;
      state.fileColors = [] as ColorFiles[];
      state.status = Status.idle;
      state.annotationsLoadedStatus = Status.idle;
      state.error = undefined as string | undefined;
    },
    setCurrentTool: (state, action: PayloadAction<{ tool: string }>) => {
      state.tool = action.payload.tool as string;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAnnotationsForFileId.fulfilled, (state, action) => {
      const { fileId, annotations } = action.payload;
      state.status = Status.success;
      if (fileId) {
        if (!state.annotations[fileId]) {
          const annotationsWithColors =
            annotationsColorsService.colorAnnotations(state, annotations);
          const updatedFileColors =
            annotationsColorsService.updatedAnnotationsColors(
              state,
              annotations
            );
          state.annotations[fileId] = annotationsWithColors;
          state.fileColors = updatedFileColors;
        }
      }
    });
    builder.addCase(fetchAnnotationsForFileId.rejected, (state, action) => {
      state.status = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(fetchAnnotationsForFileId.pending, (state) => {
      state.status = Status.loading;
    });
    builder.addCase(fetchAnnotationsForProcedure.fulfilled, (state, action) => {
      const procedureFilesAnnotations = action.payload;
      state.status = Status.success;
      state.annotationsLoadedStatus = Status.success;
      if (procedureFilesAnnotations && procedureFilesAnnotations.length) {
        procedureFilesAnnotations.forEach((fileAnnotations) => {
          if (fileAnnotations.fileId) {
            if (!state.annotations[fileAnnotations.fileId]) {
              const annotationsWithColors =
                annotationsColorsService.colorAnnotations(
                  state,
                  fileAnnotations.annotations
                );
              const updatedFileColors =
                annotationsColorsService.updatedAnnotationsColors(
                  state,
                  fileAnnotations.annotations
                );
              state.annotations[fileAnnotations.fileId] = annotationsWithColors;
              state.fileColors = updatedFileColors;
            }
          }
        });
      }
    });
    builder.addCase(fetchAnnotationsForProcedure.rejected, (state, action) => {
      state.annotationsLoadedStatus = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(fetchAnnotationsForProcedure.pending, (state) => {
      state.annotationsLoadedStatus = Status.loading;
    });
  },
});

export type AnnotationsState = ReturnType<typeof annotationsSlice.reducer>;
export const { actions } = annotationsSlice;
export default annotationsSlice;
