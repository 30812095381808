import { CogniteAnnotation, ActiveWorkStep } from 'types';
import features from 'config/features';
import { useCallback, useState } from 'react';

export enum ViewerQuality {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const usePnidZoom = (quality: ViewerQuality) => {
  const [zoomedAnnotation, setZoomedAnnotation] = useState<CogniteAnnotation>();

  let scale;
  switch (quality) {
    case ViewerQuality.LOW:
      scale = 1.5;
      break;
    case ViewerQuality.MEDIUM:
      scale = 0.8;
      break;
    default:
      scale = 0.3;
  }

  const zoomOnAnnotation = useCallback((active: ActiveWorkStep) => {
    if (!active.workStep) {
      return;
    }
    if (!features.ZOOM_TO_ACTIVE_WORKSTEP) {
      return;
    }

    if (!active.workStep?.stepItem || !active.workStep?.stepItem?.annotation) {
      setZoomedAnnotation(undefined);
      return;
    }

    if (
      active.workStep &&
      active.workStep.stepItem.annotation &&
      active.editable
    ) {
      setZoomedAnnotation(undefined);
      return;
    }

    const annotation = active.workStep?.stepItem?.annotation as any;
    if (annotation) {
      const newAnnotationToZoom = {
        ...annotation,
        box: { ...annotation.region },
      };

      setZoomedAnnotation(newAnnotationToZoom);
    }
  }, []);

  const curZoomedAnnotation =
    features.ZOOM_TO_ACTIVE_WORKSTEP && zoomedAnnotation
      ? { annotation: zoomedAnnotation, scale }
      : undefined;

  return {
    curZoomedAnnotation,
    zoomOnAnnotation,
    setZoomedAnnotation,
  };
};
