import React from 'react';

import { ProcedureApprovalFile } from 'types';

import { Input, Button, Textarea } from '@cognite/cogs.js';
import { Formik } from 'formik';
import { Row } from '../../../../containers/Grid';
import { FieldLabel } from './elements';

type ApprovalFileEditProps = {
  file: ProcedureApprovalFile;
  onCancel: () => void;
  onSubmit: (description: string, approvedBy: string) => void;
};

const ApprovalFileEdit = ({
  file,
  onCancel,
  onSubmit,
}: ApprovalFileEditProps) => (
  <Formik
    enableReinitialize
    initialValues={{
      approvedBy: file.approvedBy || '',
      description: file.description || '',
    }}
    onSubmit={({ description, approvedBy }) => {
      onSubmit(description, approvedBy);
    }}
  >
    {({ values, handleChange, handleBlur, handleSubmit }) => (
      <form
        onSubmit={handleSubmit}
        data-testid={`approval-file-form-${file.id}`}
      >
        <div>
          <Row direction="column" margin="10px 0px">
            <FieldLabel>Approved by</FieldLabel>
            <Input
              id={`approval-file-approvedBy-field-${file.id}`}
              name="approvedBy"
              data-testid={`approval-file-approvedBy-field-${file.id}`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.approvedBy}
              style={{
                width: '100%',
              }}
            />
          </Row>
          <Row direction="column" margin="10px 0px">
            <FieldLabel>Description</FieldLabel>
            <Textarea
              rows={3}
              id={`approval-file-description-field-${file.id}`}
              name="description"
              data-testid={`approval-file-description-field-${file.id}`}
              onChange={handleChange}
              value={values.description}
              style={{
                width: '100%',
              }}
            />
          </Row>
          <Row display="flex" direction="row" justify-content="flex-end">
            <Button
              style={{
                marginRight: '8px',
              }}
              type="ghost"
              data-testid={`approval-file-cancel-${file.id}`}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              data-testid={`approval-file-save-${file.id}`}
            >
              Save
            </Button>
          </Row>
        </div>
      </form>
    )}
  </Formik>
);

export default ApprovalFileEdit;
