import React, { useCallback, useState } from 'react';
import { Button, Icon } from '@cognite/cogs.js';
import { useHistory } from 'react-router';
import { KeyValuePair, Project } from 'types';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/worksteps/workstepsReducer';
import { actions as projectActions } from 'redux/reducers/project/projectReducer';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { useDrawings } from 'hooks/useDrawings';
import { NotificationDiv } from '../Notification/elements';

type Props = {
  pnidsNamesToUpdate: string[];
  pnidsToUpdateMapping: KeyValuePair;
  project: Project;
  viewOnly: boolean;
};

const UpdatedPnidNotification = ({
  pnidsNamesToUpdate,
  pnidsToUpdateMapping,
  project,
  viewOnly,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { copyDrawingsToNewProcedure } = useDrawings();
  const [isMigrateBtnDisabled, setIsMigrateBtnDisabled] = useState(false);
  const metrics = useMetrics(METRICS_AREA.PROJECT_MIGRATE);
  const projectId = project.id;

  const migrateProcedure = useCallback(() => {
    if (isMigrateBtnDisabled) {
      return;
    }
    setIsMigrateBtnDisabled(true);
    copyDrawingsToNewProcedure(project, pnidsToUpdateMapping).then(
      (pnIdsDrawings: KeyValuePair) => {
        dispatch(
          actions.migrateProcedureWorkSteps({
            filesIdsMap: pnidsToUpdateMapping,
          })
        );
        dispatch(
          projectActions.setProjectNeedsMigration({
            filesIdsMap: pnidsToUpdateMapping,
            pnIdsDrawings,
          })
        );

        metrics.track(METRICS.MIGRATE_PNID, {
          projectId,
          pnidsNamesToUpdate,
          pnidsToUpdateMapping,
          date: new Date().toISOString(),
          timestamp: new Date().getTime(),
        });

        setTimeout(() => {
          history.push(`/project/edit/${projectId}`);
        }, 500);
      }
    );
  }, [
    dispatch,
    history,
    projectId,
    project,
    pnidsToUpdateMapping,
    pnidsNamesToUpdate,
    metrics,
    isMigrateBtnDisabled,
    copyDrawingsToNewProcedure,
  ]);

  const pnidMessage =
    pnidsNamesToUpdate.length === 1
      ? `There’s a new revision of P&ID ${pnidsNamesToUpdate[0]}. You must update the P&ID in order to adapt this procedure.`
      : `There are new revisions of the P&IDs used in this procedure. Please update them in order to adapt this procedure.`;
  return (
    <NotificationDiv
      color="var(--cogs-pink-6)"
      spaceBetween
      data-testid="multiple-pnid-revisions-error-msg"
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          type="Warning"
          key="warning-icon"
          style={{
            marginRight: '10px',
            color: 'var(--cogs-pink-3)',
          }}
        />
        <div
          key="migrate-procedure-error-msg"
          data-testid="migrate-procedure-error-msg"
        >
          {pnidsNamesToUpdate.length === 1 ? (
            pnidMessage
          ) : (
            <div>
              <p>{pnidMessage}</p>
              <ul>
                {pnidsNamesToUpdate.map((name, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={idx}>{name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {!viewOnly && (
        <Button
          type="primary"
          size="small"
          data-testid="migrate-procedure-btn"
          disabled={isMigrateBtnDisabled}
          onClick={migrateProcedure}
        >
          Update
        </Button>
      )}
    </NotificationDiv>
  );
};

export default UpdatedPnidNotification;
