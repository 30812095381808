import React from 'react';
import styled from 'styled-components/macro';
import { PhaseType } from 'types';

export const LogoSpan = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--cogs-midorange-6);
  margin-right: 6px;
  text-align: center;

  .tags-icon {
    color: var(--cogs-greyscale-grey9);
    margin: 7px auto;
  }
`;

export const TagsGroupsContainer = React.memo(styled.div`
  display: block;
`);

export const TagsGroupWrapper = styled.div`
  .tags-group-title {
    margin-bottom: 11px;
  }

  .tags-group-phase-container {
    display: flex;
    flex-flow: column;
  }

  .tags-group-phase-title {
    margin-bottom: 3.5px;
    margin-right: 32px;
    color: var(--cogs-greyscale-grey9);
  }

  .tags-group-step-title {
    margin-bottom: 5px;
  }
`;

export const TagNumberInputWrapper = styled.div<{ type: PhaseType }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  justify-content: ${(props) =>
    props.type === 'isolation' ? 'space-between' : 'end'};
  width: 274px;

  .tags-single-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .step-number {
    margin-right: 5px;
    margin-top: 1px;
  }

  .tag-number > input {
    width: 96px;
    padding: 0 5px;
  }

  .tag-number-generate-icon {
    margin-left: 3px;
    color: var(--cogs-greyscale-grey5);
    &:hover {
      color: var(--cogs-primary);
      cursor: pointer;
    }
  }
`;

export const DeisolationTag = styled.div`
  width: 96px;
  height: 22px;
  padding: 0px 4px;
  background-color: var(--cogs-greyscale-grey2);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
