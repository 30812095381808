import React from 'react';
import { Flex } from '@cognite/cogs.js';
import Badge from 'shared/Badge';
import { ProjectStatus, WorkOrder } from 'types';
import { toDisplayDate } from 'utils/date';
import { LATEST_WO_DATE_TO_DISPLAY } from 'config/workstepsConfig';
import {
  WorkorderContainer,
  SmallWoText,
  MediumWoText,
  BaldWoText,
} from './elements';

type WorkorderBlockProps = {
  workorder: WorkOrder;
  timeToCompareWith?: number;
  projectStatus?: ProjectStatus;
};

const WorkorderBlock = ({
  workorder,
  timeToCompareWith,
  projectStatus,
}: WorkorderBlockProps) => {
  const isNew = timeToCompareWith
    ? workorder.createdDateInCdf > timeToCompareWith
    : false;
  const shouldShowIfNew =
    projectStatus === 'compilation' || projectStatus === 'review';
  // if it is more than 01.01.1990 then show
  const createdTime =
    workorder.createdDate > LATEST_WO_DATE_TO_DISPLAY
      ? toDisplayDate(workorder.createdDate, 'DD.MM.YYYY')
      : 'not specified';
  return (
    <WorkorderContainer new={isNew && shouldShowIfNew}>
      {isNew && shouldShowIfNew ? (
        <Flex
          direction="row"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Badge
            text="NEW"
            background="#6ED8BE"
            textColor="#000000"
            size={10}
            style={{ margin: '0px' }}
          />
          <SmallWoText>Created: {createdTime}</SmallWoText>
        </Flex>
      ) : (
        <Flex
          direction="row"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <div>
            <SmallWoText>Order: </SmallWoText>
            <BaldWoText>{workorder.id}</BaldWoText>
          </div>
          <SmallWoText>Created: {createdTime}</SmallWoText>
        </Flex>
      )}
      {isNew && shouldShowIfNew && (
        <div>
          <SmallWoText>Order: </SmallWoText>
          <BaldWoText>{workorder.id}</BaldWoText>
        </div>
      )}
      <div>
        <SmallWoText>FLOC: </SmallWoText>
        <BaldWoText>{workorder.floc.split('.').pop()}</BaldWoText>
      </div>
      <div>
        <SmallWoText>Description: </SmallWoText>
        <MediumWoText>{workorder.description}</MediumWoText>
      </div>
      <div>
        <SmallWoText>Due date: </SmallWoText>
        <MediumWoText>
          {toDisplayDate(workorder.endDate, 'DD.MM.YYYY')}
        </MediumWoText>
      </div>
      <div>
        <SmallWoText>Contact person: </SmallWoText>
        <MediumWoText>{workorder.author}</MediumWoText>
      </div>
    </WorkorderContainer>
  );
};

export default WorkorderBlock;
