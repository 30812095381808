import styled from 'styled-components/macro';

export const SingleValveDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .cogs-checkbox .checkbox-ui {
    margin: 0px;
  }
`;
