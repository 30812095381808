import isMatch from 'lodash/isMatch';
import { CogniteAnnotation, CogniteAnnotationSpec } from 'types';
import isNumber from 'lodash/isNumber';

const distance = (refBoundingBox: any, annotationBoundingBox: any) => {
  const dx = Math.max(
    refBoundingBox.xMin - annotationBoundingBox.xMax,
    annotationBoundingBox.xMin - refBoundingBox.xMax,
    0
  );
  const dy = Math.max(
    refBoundingBox.yMin - annotationBoundingBox.yMax,
    annotationBoundingBox.yMin - refBoundingBox.yMax,
    0
  );
  return Math.sqrt(dx * dx + dy * dy);
};

export const findClosest = (
  annotation?: CogniteAnnotation,
  annotations?: Array<CogniteAnnotation | CogniteAnnotationSpec>
) => {
  if (!annotation || !annotations) return undefined;
  // eslint-disable-next-line
  let closest: any = undefined;
  let distanceNum: number;
  annotations.forEach((current) => {
    let dis: number | undefined;
    if (
      annotation &&
      annotation.data &&
      'textRegion' in annotation.data &&
      current &&
      current.data &&
      'textRegion' in current.data
    ) {
      dis = distance(annotation.data.textRegion, current.data.textRegion);
    }

    if (!closest || (dis && dis < distanceNum)) {
      closest = current as CogniteAnnotation;
      if (isNumber(dis)) distanceNum = dis;
    }
  });
  return closest;
};

export const findWithSameRegion = (
  annotation?: CogniteAnnotation,
  annotations?: Array<CogniteAnnotation | CogniteAnnotationSpec>
) => {
  if (!annotation || !annotations) return undefined;
  // eslint-disable-next-line
  let closest: any = undefined;
  annotations.forEach((current) => {
    let dis: boolean = false;
    if (
      annotation &&
      annotation.data &&
      'textRegion' in annotation.data &&
      current &&
      current.data &&
      'textRegion' in current.data
    ) {
      if (current.data.textRegion && annotation.data.textRegion) {
        dis = isMatch(current.data.textRegion, annotation.data.textRegion);
      }
    }
    if (dis) {
      closest = current as CogniteAnnotation;
    }
  });
  return closest;
};
