import styled from 'styled-components/macro';

export const DescriptionDiv = styled.div`
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  border-bottom: 1px dashed #8c8c8c;
`;

export const InputDiv = styled.div`
  display: inline-block;
  border-bottom: 1px dashed #8c8c8c;
`;
