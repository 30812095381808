import { FileInfo } from '@cognite/sdk';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions, useFiles } from 'redux/reducers/files';
import { fetchAnnotationsForFileId } from 'redux/reducers/annotations/annotationsReducer';
import { AppDispatch } from 'redux/store';

// Moved the logic for working with files
// in separate hook from PnidPreview
export const useSideDrawerFilesActions = () => {
  const { activeFile: file, files, connectedFiles, fetchFile } = useFiles();
  const [fileId, setFileId] = useState(undefined as number | undefined);
  const dispatch = useDispatch<AppDispatch>();

  const onAddFile = useCallback(
    (fileToAdd: FileInfo) => {
      if (!connectedFiles.includes(fileToAdd.id)) {
        dispatch(actions.connectFile(fileToAdd.id));
        // new file is added, no procedure annotations are present
        dispatch(
          fetchAnnotationsForFileId({
            fileId: fileToAdd.id,
            projectAnnotations: [],
            procedureNeedsMigration: false,
          })
        );

        fetchFile(fileToAdd.id);
      }
    },
    // eslint-disable-next-line
    [connectedFiles]
  );

  const onFileRemoved = useCallback(
    (removedFile: FileInfo) => {
      // if we remove file that's currently displayed, display the latest connected file instead
      if (removedFile.id === file.id) {
        // since state is not updated yet, we manually back to the previous P&ID
        const oldFileIndex = connectedFiles.findIndex(
          (id: number) => id === fileId
        );
        const newFileIndex = oldFileIndex - 1 > 0 ? oldFileIndex - 1 : 0;
        const newFileId = connectedFiles[newFileIndex];
        if (newFileId) {
          setTimeout(() => dispatch(actions.setActiveFile(newFileId)), 300);
        }
      }
      dispatch(actions.disconnectFile(removedFile.id));
    },
    // eslint-disable-next-line
    [file, connectedFiles]
  );

  const onFileSelected = useCallback(
    (selectedFile: FileInfo) => {
      dispatch(actions.setActiveFile(selectedFile.id));
    },
    [dispatch]
  );

  const chooseStartingFile = useCallback(
    (selectedFile: FileInfo) => {
      dispatch(actions.setStartingFile(selectedFile.id));
    },
    [dispatch]
  );

  return {
    file,
    files,
    connectedFiles,
    fileId,
    fetchFile,
    setFileId,
    onAddFile,
    onFileRemoved,
    onFileSelected,
    chooseStartingFile,
  };
};
