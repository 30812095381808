import React, { useState } from 'react';
import { ColorPicker as ColorPalette, IColor } from 'react-color-palette';
import 'react-color-palette/css';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { ColorPreview } from './elements';
import zIndex from '../../../../utils/zindex';

type Props = {
  color: IColor;
  setColor: (color: IColor) => void;
};

const ColorPicker = (props: Props) => {
  const { color, setColor } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleColorPickerClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  return (
    <Popover
      isOpen={showColorPicker}
      onClickOutside={() => setShowColorPicker(false)}
      positions={['bottom', 'top']}
      containerStyle={{ zIndex: `${zIndex.MAXIMUM}` }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={10}
          style={{ zIndex: zIndex.MAXIMUM }}
        >
          <ColorPalette color={color} onChange={setColor} />
        </ArrowContainer>
      )}
    >
      <ColorPreview onClick={handleColorPickerClick} userColor={color.hex} />
    </Popover>
  );
};

export default ColorPicker;
