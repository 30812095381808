import { projectsService } from 'services';
import { ExecutionSubStates, Project } from 'types';
import { capitalize } from 'utils/text';
import { ExecuteDashboardVM, DashboardVM } from '../types';

export const useStartPageActions = () => {
  const getInitialState = (): DashboardVM => {
    return {
      projectsUnderCompilation: [],
      projectsUnderReviewReady: [],
      projectsUnderReview: [],
      projectsUnderExecutionReady: [],
      projectsUnderExecution: [],
    } as DashboardVM;
  };

  const getInitialExecuteDashboardState = (): ExecuteDashboardVM => {
    return {
      [ExecutionSubStates.NotStarted]: [],
      [ExecutionSubStates.IsolationStarted]: [],
      [ExecutionSubStates.IsolationDone]: [],
      [ExecutionSubStates.DeIsolationStarted]: [],
      [ExecutionSubStates.DeIsolationDone]: [],
    };
  };

  const filterRelevantProjects = (
    projects: Project[],
    isOnlyMyProjects: boolean,
    userEmail?: string
  ): Project[] => {
    const projectsFilteredByName =
      userEmail && isOnlyMyProjects
        ? projects.filter((project) => project.compiler === userEmail)
        : projects;

    return projectsFilteredByName;
  };

  const filterProjectsAndGroupByStatus = (
    projects: Project[],
    isOnlyMyProjects: boolean,
    userEmail?: string
  ): DashboardVM => {
    const statuses = [
      'compilation',
      'reviewReady',
      'review',
      'executionReady',
      'execution',
    ];

    const projectsVM = getInitialState();

    statuses.forEach((status) => {
      const property = `projectsUnder${capitalize(
        status
      )}` as keyof DashboardVM;

      projectsVM[property] = projectsService.filterProjectsBy(
        filterRelevantProjects(projects, isOnlyMyProjects, userEmail),
        'status',
        status
      );
    });
    return projectsVM;
  };

  const filterProjectsAndGroupByExecution = (
    projects: Project[]
  ): ExecuteDashboardVM => {
    const projectsVM = getInitialExecuteDashboardState();
    const selectedProjects = projects.filter(
      (project) =>
        project.status === 'execution' || project.status === 'executionReady'
    );
    if (selectedProjects) {
      selectedProjects.forEach((project) => {
        if (project.executionSubState) {
          projectsVM[project.executionSubState].push(project);
        } else {
          projectsVM[ExecutionSubStates.NotStarted].push(project);
        }
      });
    }

    return projectsVM;
  };

  return {
    getInitialState,
    filterProjectsAndGroupByStatus,
    filterProjectsAndGroupByExecution,
  };
};
