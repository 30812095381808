import { Asset } from '@cognite/sdk';
import { ASSETS_DATASET_ID, LINE_NUMBERS_DATASET_ID } from 'config/apiConfig';
import { ItemType } from '../types';
import { actions, ClassTypes } from '../utils/test/actions';
import { references, types } from '../utils/test/mockData';
import { getClient } from '../config/cognitesdk';

export async function fetchActions(type: string) {
  return Promise.resolve(actions[type]);
}

export async function fetchReferenceTypes(type: string) {
  if (type && references[type]) {
    return Promise.resolve(references[type]);
  }
  return Promise.resolve(references.asset);
}

export async function fetchAsset({ id }: { id: number }): Promise<Asset[]> {
  return getClient().assets.retrieve([{ id }]);
}

export async function searchAsset({
  name,
}: {
  name: string;
}): Promise<Asset[]> {
  return getClient().assets.search({
    filter: {
      dataSetIds: [
        { externalId: ASSETS_DATASET_ID },
        { externalId: LINE_NUMBERS_DATASET_ID },
      ],
    },
    search: { query: name },
    limit: 15,
  });
}

export async function fetchItemTypes(): Promise<ItemType[]> {
  return Promise.resolve(types);
}

export async function fetchClassTypes(): Promise<string[]> {
  return Promise.resolve(ClassTypes);
}

export const openFileIdInNewWindow = (fileId: number) => {
  (getClient().files as any)
    .getDownloadUrls([{ id: fileId }], { cache: false })
    .then((result: any) => {
      window.open(result[0].downloadUrl, '_blank');
    });
};

export const openFileExternalIdInNewWindow = (fileExternalId: string) => {
  getClient()
    .files.getDownloadUrls([{ externalId: fileExternalId }])
    .then((result) => {
      window.open(result[0].downloadUrl, '_blank');
    });
};
