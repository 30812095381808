import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  .start-page-tabs {
    background: transparent;
    .rc-tabs-tab:focus-within {
      box-shadow: none;
    }
  }
`;

export const Grid = styled.div`
  width: calc(100% - 50px);
  margin: 0px auto;
`;

export const CardLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  margin-top: 16px;
`;
