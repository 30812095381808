import { Middleware } from 'redux';

import { cookieServices } from '../../../services';
import searchSlice, { fetchRootAssetsContent } from '../../reducers/search';

export const searchProcedureMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === searchSlice.actions.setSelectedRootAsset.type) {
      cookieServices.setDefaultRootAsset(action.payload.assetFloc);
    }

    if (action.type === fetchRootAssetsContent.fulfilled.type) {
      const defaultRootAsset = cookieServices.getDefaultRootAsset(
        action.payload
      );
      if (defaultRootAsset) {
        dispatch(searchSlice.actions.setSelectedRootAsset(defaultRootAsset));
      }
    }

    next(action);
  };
